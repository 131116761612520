import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { AppNavRouteLink } from 'src/app/shared/commons/AppNavRouteLink';

@Component({
  selector: 'app-new-alert-dialog-success',
  templateUrl: './new-alert-dialog-success.component.html',
  styleUrls: ['./new-alert-dialog-success.component.scss']
})
export class NewAlertDialogSuccessComponent implements OnInit {

  @Input() title: string = 'Concluído'
  @Input() subTitle: string = 'OPERAÇÃO CONCLUÍDA COM SUCESSO'
  @Input() msg: string = null
  @Input() txtOk = 'Voltar'
  @Input() pixLimits: boolean
  
  confirmResult: Subject<boolean | string>

  constructor(
    public modalRef: BsModalRef,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.confirmResult = new Subject()
  }

  onClose(value?: boolean | string) {
    this.confirmResult.next(value)
    this.modalRef.hide()
  }

  openLimits() {
    this.modalRef.hide()
    this.router.navigate([AppNavRouteLink.TABS_PIX.LIMITS])
  }
}
