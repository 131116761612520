<div class="wrap">
    <div class="col-12">
        <div class="row py-4 px-2 p-md-5">
            <div class="col-12">
                <p class="title-medium bold">Filtrar</p>
            </div>
            <div class="col-12 field my-3" *ngFor="let filter of getOptions();let i = index">
                <div class="col-12 form-group" *ngIf="filter.format == 'currency'">
                    <span class="small">{{ filter.title }}</span>
                    <input [Currency]="{symbol: ''}" class="small form-control" type="text" [(ngModel)]="filter.model">
                </div>
                <div class="col-12 form-group" *ngIf="filter.format == 'numeric'">
                    <span class="small">{{ filter.title }}</span>
                    <input OnlyNumber class="small form-control" type="text" [(ngModel)]="filter.model">
                </div>
                <div class="col-12 form-group" *ngIf="filter.format == 'text'">
                    <span class="small">{{ filter.title }}</span>
                    <input class="small form-control" type="text" [(ngModel)]="filter.model">
                </div>
                <div class="col-12" *ngIf="filter.format == 'single'">
                    <span class="small">{{ filter.title }}</span>
                    <ng-select class="small single-filter" bindLabel="label" bindValue="description"
                        [items]="filter.items" [(ngModel)]="filter.model.description"
                        (change)="onChange(filter, $event)">
                    </ng-select>
                    <div class="w-100 mt-3" *ngIf="showDateRange && filter.model.description == 'custom_date'">
                        <input type="text" placeholder="DD/MM/YYYY - DD/MM/YYYY" class="form-control"
                            [(ngModel)]="filter.model.value" bsDaterangepicker [bsConfig]="bsConfig" readonly>
                    </div>
                </div>
                <div class="col-12" *ngIf="filter.format == 'tags'">
                    <span class="small">{{ filter.title }}</span>
                    <tag-input class="small" [(ngModel)]="filter.model" [identifyBy]="'value'" [displayBy]="'label'"
                        [onlyFromAutocomplete]="true" [placeholder]="filter.secondary_placeholder"
                        [secondaryPlaceholder]="filter.placeholder" [onAdding]="verifyExclusive">
                        <tag-input-dropdown [showDropdownIfEmpty]="true" [appendToBody]="false" [displayBy]="'label'"
                            [identifyBy]="'value'" [autocompleteItems]="filter.items" zIndex="10000">
                            <ng-template let-item="item" let-index="index">
                                {{ item.label }}
                            </ng-template>
                        </tag-input-dropdown>
                    </tag-input>
                </div>
            </div>
        </div>
        <div class="row col-12 justify-content-around p-3 mb-4 mx-auto mx-md-3">
            <button class="col-12 col-md btn btn-outline-primary col-auto expand" (click)="close()">CANCELAR</button>
            <button class="col-12 col-md mt-3 mt-md-0 btn btn-primary col-auto expand"
                (click)="search()">PESQUISAR</button>
        </div>
    </div>
</div>