<div class="content-login">
  <div class="content-image">
    <div class="col-lg-5 col-md-7 col-sm-12">
      <div class="shadow bg-white rounded pt-5" id="teste_pode_remover">
        <a href="/" class="d-flex justify-content-center">
          <img src="assets/images/png/logo_ladybank_lg.png" alt="">
        </a>
        <div class="px-5 poppins">
          <p class="my-4 poppins-bold text-center medium">Fazer Login</p>
          <form [formGroup]="formLogin" class="justify-content-center px-3 pb-5"
            [autocomplete]="autocomplete ? 'on' : 'off'" novalidate (submit)="login($event)">
            <div class="form-group">
              <span for="login" class="small">Nº do CPF</span>
              <input type="text" id="login" name="login" class="form-control" Mask="###.###.###-##"
                formControlName="login" maxlength="30">
              <div *ngIf="(!f.login.pristine || f.login.touched) && f.login.errors" class="invalid-feedback x-small">
                <div *ngIf="f.login.errors.required"> Campo obrigatório. </div>
                <div *ngIf="f.login.errors.invalid"> CPF inválido. </div>
              </div>
            </div>
            <div class="form-group">
              <span for="password" class="small">Senha</span>
              <div class="position-relative">
                <input [type]="typeInput" id="password" name="password" class="form-control" formControlName="password"
                  PreventCopyPast>
                <div class="position-absolute" style="top: 6px; right: 6px;">
                  <em class="material-icons pointer" (click)="previewPassword()">{{iconPassword}}</em>
                </div>
                <div *ngIf="(!f.password.pristine || f.password.touched) && f.password.errors"
                  class="invalid-feedback x-small">
                  <div *ngIf="f.password.errors.required"> Campo obrigatório. </div>
                  <div *ngIf="!f.password.errors.required && f.password.errors.invalidPassword"> A senha deve conter
                    no mínimo 6 caracteres, 1 número e 1 caractere especial. </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input pointer" id="remember" [(ngModel)]="autocomplete"
                  [ngModelOptions]="{standalone: true}">
                <label class="custom-control-label small" role="button" for="remember">Relembrar</label>
              </div>
              <button class="btn link m-0 p-0" type="button" (click)="onForgotPassword()">Esqueci a senha</button>
            </div>
            <div class="row mt-4">
              <button class="btn btn-outline-primary col my-1" type="button" (click)="back()">VOLTAR</button>
              <button class="btn btn-primary col my-1" type="submit" [disabled]="!formLogin.valid">ENTRAR</button>
            </div>
            <div class="d-flex justify-content-center align-items-center py-2 mt-3 x-medium">
              <span>Ainda não é cliente? <a class="pointer" (click)="onOpenAccount()">Cadastre-se</a></span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>