import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ForgotPasswordService } from 'src/app/shared/services/forgot-password.service';
import { RecoveryPasswordModel } from 'src/app/shared/interfaces/recovery-password-model';
import { Validations } from 'src/app/shared/commons/Validations';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  loginData = {} as RecoveryPasswordModel;
  form: FormGroup;

  confirmResult: Subject<boolean | string>;

  constructor(
    public modalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private forgotPasswordService: ForgotPasswordService
  ) { }

  ngOnInit(): void {
    this.confirmResult = new Subject();
    this.buildForm();
  }

  buildForm() {
    this.form = this.formBuilder.group(
      {
        document_number: [{
          value: this.loginData.document_number
        }, Validators.compose([
          Validators.required,
          Validations.CPF
        ])]
      }
    );
  }

  onClick() {
    this.forgotPasswordService.generateToken(this.loginData).then(res => {
      if (res.status === 400) {
        this.confirmResult.next(false);
        this.modalRef.hide();
      } else {
        this.confirmResult.next(true);
        this.modalRef.hide();
      }
    })
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }
}
