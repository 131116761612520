<div class="modal-content" *ngIf="assetOption && assetOption.value">
  <div class="modal-header border-0" [style.background-color]="AssetColor[assetOption.value]">
    <div class="d-flex justify-content-arround col-12">
      <span class="medium text-center font-weight-bold w-100 m-auto"
        [ngClass]="{'text-body': assetOption.value == 'exchange'}"> Adicionar {{assetOption.name}} </span>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span class="material-icons-outlined">highlight_off</span>
      </button>
    </div>
    <div class="d-flex justify-content-center col-12">
      <span class="poppins small-medium"
        [ngClass]="{'text-body': assetOption.value == 'exchange'}">{{assetOption.description}}</span>
    </div>
  </div>
  <div class="row col col-12 mx-0 mx-md-2 px-0 px-md-3">
    <app-filter [input_options]="getFilters()" (ChangeFilter)="filterAssets($event)"> </app-filter>
  </div>
  <div class="modal-body" style="margin-top: 0 !important;">
    <div class="col-12 x-small my-3"
      *ngIf="assetOption.value == 'exchange' || assetOption.value == 'investment-fund' || assetOption.value == 'retirement'">
      <span class="mr-5">Risco
        <button class="btn risk-low" (click)="filterRiskLevel(['1,2'])" tooltip="Conservador"></button>
        <button class="btn risk-medium" (click)="filterRiskLevel(['3,4'])" tooltip="Arrojado"></button>
        <button class="btn risk-high" (click)="filterRiskLevel(['5,6,7,8'])" tooltip="Agressivo"></button>
      </span>
      <!-- <span class="mr-5">
        Apenas para Investidor Qualificado
        <icon-svg svgName="star"></icon-svg>
      </span>
      <span class="mr-5 position-relative">
        Fundo fechado para aplicação
        <icon-svg svgName="x-circle" class="color-status-error"></icon-svg>
      </span> -->
    </div>
    <div class="col-12 small-medium my-3" *ngIf="assetOption.value == 'variable-income'">
      <span class="float-right">Atualizado às {{timeUpdate | date: 'HH:mm'}}. Delay 15 min <span class="text-danger">(em
          breve)</span></span>
    </div>
    <div class="col-12">
      <table class="table table-hover" style="line-height: 2px">
        <thead class="thead-dark">
          <tr
            *ngIf="assetOption.value == 'exchange' || assetOption.value == 'investment-fund' || assetOption.value == 'retirement'">
            <th colspan="5" class="border"></th>
            <th colspan="3" class="small-medium text-center text-white border" style="vertical-align:middle"
              tooltip="(em breve)">Rentabilidade</th>
          </tr>
          <tr>
            <th *ngFor="let column of columns; let i = index" class="border small-medium {{column.class}}"
              style="vertical-align:middle">
              <span [innerHTML]="column.name" class="" style="color: white !important"></span>
            </th>
          </tr>
        </thead>
        <tbody *ngIf="assetOption.value == 'direct-treasure'">
          <tr
            *ngFor="let item of filtered_assets | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }"
            class="small-medium" (click)="selectAsset(item)" [class.selected]="isSelected(item)">
            <td class="text-left">{{item.name}}</td>
            <td class="text-center">{{getAssetDueDate(item) | date: 'dd/MM/yyyy'}}</td>
            <td class="text-left">{{item.bond.is_yield_quote_mult ? item.bond.coupon_mult_rate :
              item.bond.coupon_add_rate | percent: '1.1-1' }}</td>
            <td class="text-center" *ngIf="item.bond.last_yield">{{item.bond.last_yield | currency: 'BRL'}}</td>
            <td class="text-center" *ngIf="!item.bond.last_yield">N/A</td>
            <td class="text-right">{{item.ask_price | currency: 'BRL'}}</td>
            <td class="text-right">{{item.bid_price | currency: 'BRL'}}</td>
            <td class="text-center">{{ item.partner_assets[0].trading_detail.min_invest_amount_multiplier *
              item.last_price | currency: 'BRL'}}</td>
          </tr>
        </tbody>
        <tbody *ngIf="assetOption.value == 'fixed-income'">
          <tr
            *ngFor="let item of filtered_assets | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }"
            class="small-medium" (click)="selectAsset(item)" [class.selected]="isSelected(item)">
            <td class="text-center">{{item.sub_sectype | uppercase}}</td>
            <td class="text-left">{{item.issuer.name}}</td>
            <td class="text-center">{{getAssetRentability(item)}}</td>
            <td class="text-center">{{getAssetDueDate(item) | date: 'dd/MM/yyyy'}}</td>
            <td class="text-center">{{getAssetLiquidity(item)}}</td>
            <td class="text-right">{{getAssetMinAmount(item) | currency: 'BRL'}}</td>
          </tr>
        </tbody>
        <tbody class="border-0" *ngIf="assetOption.value == 'investment-fund' || assetOption.value == 'retirement'">
          <tr
            *ngFor="let item of filtered_assets | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }"
            class="small-medium border-risk risk-{{getAssetRiskLevel(item)}}" (click)="selectAsset(item)"
            [class.selected]="isSelected(item)">
            <td class="text-left" tooltip="Quer saber mais? Clique aqui (em breve)">
              {{item.partner_assets[0].name || item.name}}
            </td>
            <td class="text-left">{{item.partner_assets[0].classification?.class_2 ||
              item.partner_assets[0].classification?.class_2}}</td>
            <td class="text-left">{{item.issuer.name}}</td>
            <td class="text-left">D + {{item.partner_assets[0].liquidity_tenor_min || 1}}</td>
            <td class="text-center">{{item.partner_assets[0].min_invest_amount | currency: 'BRL'}}</td>
            <td class="text-center" [ngClass]="item.monthlyRentability > 0 ? 'text-success' : 'text-danger'">
              {{item.monthlyRentability | percent: '1.1-1'}}</td>
            <td class="text-center" [ngClass]="item.rentabilidadeAnual > 0 ? 'text-success' : 'text-danger'">
              {{item.annualRentability | percent: '1.1-1'}}</td>
            <td class="text-center" [ngClass]="item.twelveMonthRentability > 0 ? 'text-success' : 'text-danger'">
              {{item.twelveMonthRentability | percent: '1.1-1'}}</td>
          </tr>
        </tbody>
        <tbody class="border-0" *ngIf="assetOption.value == 'exchange'">
          <tr
            *ngFor="let item of exchangeList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }"
            class="small-medium border-risk risk-{{getAssetRiskLevel(item)}}" (click)="selectAsset(item)"
            [class.selected]="isSelected(item)">
            <td class="text-left col">
              {{item.name}}
            </td>
            <td class="text-center col">{{item.type}}</td>
            <td class="text-center col">{{item.corretora}}</td>
            <td class="text-center col">{{item.valorMinimo | currency: 'BRL'}}</td>
            <td class="text-center col" [ngClass]="item.rentabilidadeMensal > 1 ? 'text-success' : 'text-danger'">
              {{item.rentabilidadeMensal | percent: '1.1-1'}}</td>
            <td class="text-center col" [ngClass]="item.rentabilidadeAnual > 1 ? 'text-success' : 'text-danger'">
              {{item.rentabilidadeAnual | percent: '1.1-1'}}</td>
            <td class="text-center col" [ngClass]="item.rentabilidade12Meses > 1 ? 'text-success' : 'text-danger'">
              {{item.rentabilidade12Meses | percent: '1.1-1'}}</td>
          </tr>
        </tbody>
        <tbody class="border-0" *ngIf="assetOption.value == 'variable-income'">
          <tr
            *ngFor="let item of filtered_assets | filter: filterPost | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }"
            class="small-medium" (click)="selectAsset(item)" [class.selected]="isSelected(item)">
            <td class="text-left">{{item.partner_assets[0].name || item.name}}</td>
            <td class="text-center">{{item.partner_assets[0].classification.asset_codigo}}</td>
            <td class="text-right">{{item.last_price | currency: 'BRL'}}</td>
            <td class="text-center"
              [ngClass]="item.last_price / item.close_price_dminus1 -1 > 0 ? 'text-success' : 'text-danger'">
              {{item.last_price / item.close_price_dminus1 -1
              | percent: '1.1-1'}}</td>
            <td class="text-center">Em breve</td>
          </tr>
        </tbody>
      </table>
      <div class="col-12 mt-3 d-flex justify-content-end align-items-center">
        <pagination-controls class="custom-pagination" (pageChange)="pageChanged($event)" [maxSize]="itemsPerPage"
          responsive="true" autoHide="true" (pageBoundsCorrection)="pageChanged($event)" directionLinks="true"
          previousLabel="" nextLabel="">
        </pagination-controls>
        <div class="form-group m-0 ml-5">
          <select [(ngModel)]="itemsPerPage" (change)="pageChanged(1)"
            class="form-control form-control-sm bg-light border-0">
            <option [ngValue]="5">5</option>
            <option [ngValue]="10">10</option>
            <option [ngValue]="15">15</option>
            <option [ngValue]="20">20</option>
          </select>
        </div>
        <div class="poppins x-small my-0 ml-2 text-secondary">Itens por página</div>
      </div>
    </div>
  </div>
  <div class="modal-footer border-0 d-flex justify-content-center">
    <span class="highlight small-medium">Após adicionar os produtos escolhidos, define quanto quer investir em cada
      um.</span>
    <div class="col-12 d-flex justify-content-center">
      <button type="button" class="btn btn-outline-primary" (click)="modalRef.hide()">CANCELAR</button>
      <button type="button" class="btn btn-primary" (click)="confirmAndClose()">ADICIONAR</button>
    </div>
  </div>
</div>