<div class="modal-content">
  <div class="modal-header border-0">
    <span class="text-center title-medium h1 w-100 mb-0">{{name}}</span>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span class="material-icons-outlined">highlight_off</span>
    </button>
  </div>
  <div class="position-relative w-100" [ngStyle]="{'height': getHeight()}">
    <pdf-viewer
      [src]="src"
      [rotation]="0"
      [original-size]="false"
      [show-all]="true"
      [fit-to-page]="false"
      [zoom]="1"
      [zoom-scale]="'page-width'"
      [stick-to-page]="false"
      [render-text]="true"
      [external-link-target]="'blank'"
      [autoresize]="true"
      [show-borders]="false"
    ></pdf-viewer>
  </div>
  <div class="col my-3 text-center">
    <button type="button" class="btn btn-primary" (click)="modalRef.hide()">OK, ENTENDI</button>
  </div>
</div>