import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { Utils } from 'src/app/shared/commons/Utils';
import { AssetsEnum } from 'src/app/shared/enums/assets.enum';
import { InstitutionTypeEnum } from 'src/app/shared/enums/institution-type.enum';
import { AccountModel } from 'src/app/shared/interfaces/account-model';
import { ClientModel } from 'src/app/shared/interfaces/client-model';
import { ClientService } from 'src/app/shared/services/client.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { StorageService as storage } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {

  @Input() type = ''
  @Input() input_options: any
  @Output() ChangeFilter = new EventEmitter

  client = {} as ClientModel
  account = {} as AccountModel
  account_balance: number
  formatted_tags: any = []
  filter: any = []
  filter_options: any = []
  accounts: any = []
  categories: any = []
  products: any = []
  listFactor: any = []

  FilterName = false
  FilterRentability = false
  FilterAssetType = false
  FilterMinimumValue = false

  FilterDescendingDate = false
  FilterDescendingMonth = false
  FilterAccount = false
  FilterCategories = false
  FilterProducts = false
  FilterTransactionTypes = false
  FilterFinancialTransactionTypes = false
  FilterBuySell = false
  FilterStatusOrder = false
  FilterFactors = false
  FilterRobobankerAccount = false

  constructor(
    private clientService: ClientService,
    private dialogService: DialogService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    moment.locale('pt-br')
    this.clientService.clientData.subscribe(d => {
      if (!d) { return }
      this.client = d
    });
    this.clientService.accountData.subscribe(d => {
      if (!d) { return }
      this.account = d
    });

    if (this.type == 'filter-orders') {
      this.FilterDescendingDate = true
      this.FilterBuySell = true
      this.FilterProducts = true
      this.FilterStatusOrder = true
    } else if (this.type == 'filter-extract') {
      this.FilterDescendingDate = true
      this.FilterAccount = true
      this.FilterTransactionTypes = true
      this.FilterCategories = true
    } else if (this.type == 'filter-insight') {
      this.FilterAccount = true
      this.FilterDescendingMonth = true
    } else if (this.type == 'filter-financial-Summary') {
      this.FilterDescendingDate = true
      this.FilterAccount = true
      this.FilterFinancialTransactionTypes = true
      this.FilterProducts = true
    } else if (this.type == 'filter-performance') {
      this.FilterRobobankerAccount = true
      this.FilterFactors = true
      this.FilterDescendingDate = true
    } else {
      this.filter_options = this.input_options;
    }

    this.setFilterOptions()
  }

  async getAccounts() {
    const $res = await this.clientService.getThirdPartyCredigoAccounts(this.client.uuid)
    this.accounts.push({ label: 'Todas', value: 'all' })
    this.accounts.push({ label: 'LadyBank', value: 0 })
    $res.accounts.forEach((acc: any) => {
      this.accounts.push({ label: `${acc.bank_name} (${InstitutionTypeEnum[acc.institution_type]})`, value: acc.id })
    })
    return this.accounts
  }

  async getRobobankerAccounts() {
    const $res = await this.clientService.getRobobankerAccounts(this.client.uuid)
    this.accounts.push({ label: 'Todas', value: 'all' })
    $res.accounts.forEach(account => {
      this.accounts.push({ label: account.name, value: account.account_uuid })
    });
    return this.accounts
  }

  /**
   * Get Categories
   */
  async getCategories() {
    let listCategories = [
      {
        group: '',
        options: [{
          checked: true,
          border: 'none',
          value: 'Todas'
        }]
      },
      {
        group: 'Receitas',
        options: []
      },
      {
        group: 'Despesas',
        options: []
      }
    ];

    const $res = await this.commonService.getStatementCategories()
    let rgb1 = [29, 188, 113]
    let rgb2 = [228, 133, 32]

    for (let index = 0; index < $res.length; index++) {
      const cat = $res[index];
      if (cat.type == 'revenue') {
        rgb1 = Utils.variableColorsRGB(index, rgb1)
        listCategories[1]['options'].push({
          checked: false,
          border: `.5rem solid ${Utils.rgbToHex(rgb1)}`,
          value: cat['name']
        });
      } else {
        rgb2 = Utils.variableColorsRGB(index, rgb2)
        listCategories[2]['options'].push({
          checked: false,
          border: `.5rem solid ${Utils.rgbToHex(rgb2)}`,
          value: cat['name']
        });
      }
    }

    this.categories = [{ 'label': 'Todas', 'value': 'all' }];

    for (const [index, option] of listCategories[1]['options'].entries()) {
      // this.categories.push({ 'label': option.value, 'value': index });
      this.categories.push({ label: option.value, value: option.value });
    }
    for (const [index, option] of listCategories[2]['options'].entries()) {
      // this.categories.push({ 'label': option.value, 'value': index + listCategories[1]['options'].length });
      this.categories.push({ label: option.value, value: option.value });
    }

    return this.categories
  }

  getAssets() {
    this.products = Object.keys(AssetsEnum).map(key => {
      return { label: AssetsEnum[key], value: key }
    })
    this.products.unshift({ 'label': 'Todos', 'value': 'all' })
    return this.products
  }

  async getFactor() {
    let factors = [
      { value: 'CDI_FACTOR', label: 'CDI', extra_data: {} },
      { value: 'IPCA_FACTOR', label: 'IPCA', extra_data: {} },
      { value: 'SELIC_FACTOR', label: 'Selic', extra_data: {} },
      { value: 'IBOV', label: 'Ibovespa', extra_data: {} },
      { value: 'USD_BRL_PTAX800_COMPRA', label: 'Dólar', extra_data: {} },
      { value: 'IMA', label: 'IMA', extra_data: {} },
      { value: 'IMA_B', label: 'IMA-B', extra_data: {} },
      { value: 'IMA_B5', label: 'IMA-B5', extra_data: {} },
      { value: 'IMA_B5PLUS', label: 'IMA-B5+', extra_data: {} },
      { value: 'IMA_C', label: 'IMA-C', extra_data: {} },
      { value: 'IMA_S', label: 'IMA-S', extra_data: {} }
    ];

    let count = 0;
    for (let key of factors) {
      const $res = await this.clientService.getListFactors(storage.getClientJwt(), key.label)
      if ($res.error) {
        console.log("err: ", $res.error);
        count++;
      } else {
        let asset = $res['assets'][0] ? $res['assets'] : '';
        let obj = {
          "factor": {
            "name": $res['filters']['asset_name'][0],
            "nickname": key.label,
            "sec_type": asset['sec_type'] ? asset['sec_type'] : '',
            "short_name": asset['short_name'] ? asset['short_name'] : '',
            "sub_sectype": asset['sub_sectype'] ? asset['sub_sectype'] : '',
            "uuid": asset['uuid'] ? asset['uuid'] : ''
          },
          "weight": 1,
          "coupon": 0,
          "checked": false
        }
        key.extra_data = obj
        this.listFactor.push(obj);
        count++;
        if (count == factors.length) {
          await this.getPerformance()
        }

      }
    }
    return factors
  }

  async getPerformance() {
    let requestFactor = this.listFactor
    requestFactor.forEach(el => {
      delete el.checked
    });
    let obj = {
      "allocation_id": 0,
      "factors": requestFactor,
      "input_horizons": [
        0.66,
        0.9,
        1.2,
        1.68,
        2,
        2.01,
        3
      ],
      "periods": [
        {
          "name": "my favorite period",
          "offset_type": "month",
          "offset_val": 6
        }
      ],
      "use": "allocation"
    }

    const $res = await this.clientService.getPerformance(storage.getClientUuid(), obj, storage.getClientJwt())
    console.log("performance: ", $res);

    // this.amountInvested = 0;
    // for(let pos of res.positions){

    //   let px_i = pos['asset']['last_price'] ? pos['asset']['last_price'] : 0;
    //   let qty_i = 0;
    //   for(let qtd of pos['tax_lots']){
    //     qty_i += qtd['quantity']
    //   }
    //   this.amountInvested += px_i * qty_i;
    // }

  }

  async setFilterOptions() {
    if (this.FilterName) {
      this.filter_options.push({
        'title': 'Nome',
        'format': 'text',
        'model': "",
        'placeholder': 'Filtre o nome do investimento',
      })
    }
    if (this.FilterDescendingDate) {
      this.filter_options.push({
        'title': 'Data',
        'type': 'date',
        'model': { label: 'Últimos 30 dias', description: 'last_30_days', value: moment().subtract(30, 'days').unix(), 'exclusive': true },
        'format': 'single',
        'items': [
          { label: 'Hoje', description: 'today', value: moment().unix() },
          { label: 'Ontem', description: 'yesterday', value: moment().subtract(1, 'days').unix() },
          { label: 'Últimos 7 dias', description: 'last_7_days', value: moment().subtract(7, 'days').unix() },
          { label: 'Últimos 15 dias', description: 'last_15_days', value: moment().subtract(15, 'days').unix() },
          { label: 'Últimos 30 dias', description: 'last_30_days', value: moment().subtract(30, 'days').unix() },
          { label: 'Últimos 90 dias', description: 'last_90_days', value: moment().subtract(90, 'days').unix() },
          { label: 'Ano Passado', description: 'last_year', value: moment().subtract(1, 'year').unix() },
        ]
      })
    }
    if (this.FilterDescendingMonth) {
      this.filter_options.push({
        'title': 'Data',
        'type': 'date',
        'model': { label: 'Mês atual', description: 'current_month', value: [moment().startOf('month'), moment()], 'exclusive': true },
        'format': 'single',
        'items': [
          { label: 'Personalizado', description: 'custom_date', value: '' },
          { label: 'Mês atual', description: 'current_month', value: [moment().startOf('month'), moment()] },
          { label: 'Último mês', description: 'last_month', value: [moment().subtract(1, 'M').startOf('month'), moment()] },
          { label: 'Últimos 3 meses', description: 'last_90_days', value: [moment().subtract(90, 'days'), moment()] },
          { label: 'Últimos 6 meses', description: 'last_180_days', value: [moment().subtract(180, 'days'), moment()] },
          { label: 'Último ano', description: 'last_year', value: [moment().subtract(1, 'year'), moment()] },
          { label: 'Início do ano', description: 'init_year', value: [moment().startOf('year'), moment()] },
          { label: moment().subtract(1, 'M').format("MMMM [de] yyyy"), description: 'month_second', value: [moment().subtract(1, 'M').startOf('month'), moment().subtract(1, 'M')] },
          { label: moment().subtract(2, 'M').format("MMMM [de] yyyy"), description: 'month_third', value: [moment().subtract(2, 'M').startOf('month'), moment().subtract(2, 'M')] },
          { label: moment().subtract(3, 'M').format("MMMM [de] yyyy"), description: 'month_fourth', value: [moment().subtract(3, 'M').startOf('month'), moment().subtract(3, 'M')] },
          { label: moment().subtract(4, 'M').format("MMMM [de] yyyy"), description: 'month_fifth', value: [moment().subtract(4, 'M').startOf('month'), moment().subtract(4, 'M')] },
          { label: moment().subtract(5, 'M').format("MMMM [de] yyyy"), description: 'month_sixth', value: [moment().subtract(5, 'M').startOf('month'), moment().subtract(5, 'M')] },
          { label: moment().subtract(6, 'M').format("MMMM [de] yyyy"), description: 'month_seventh', value: [moment().subtract(6, 'M').startOf('month'), moment().subtract(6, 'M')] },
          { label: moment().subtract(7, 'M').format("MMMM [de] yyyy"), description: 'month_eighth', value: [moment().subtract(7, 'M').startOf('month'), moment().subtract(7, 'M')] },
          { label: moment().subtract(8, 'M').format("MMMM [de] yyyy"), description: 'month_ninth', value: [moment().subtract(8, 'M').startOf('month'), moment().subtract(8, 'M')] },
          { label: moment().subtract(9, 'M').format("MMMM [de] yyyy"), description: 'month_tenth', value: [moment().subtract(9, 'M').startOf('month'), moment().subtract(9, 'M')] },
          { label: moment().subtract(10, 'M').format("MMMM [de] yyyy"), description: 'month_eleventh', value: [moment().subtract(10, 'M').startOf('month'), moment().subtract(10, 'M')] },
          { label: moment().subtract(11, 'M').format("MMMM [de] yyyy"), description: 'month_twelfth', value: [moment().subtract(11, 'M').startOf('month'), moment().subtract(11, 'M')] }
        ]
      })
    }
    if (this.FilterAccount) {
      this.filter_options.push({
        'title': 'Conta',
        'type': 'accounts',
        'model': [{ 'label': 'Todas', 'value': 'all', 'exclusive': true }],
        'format': 'tags',
        'placeholder': 'Filtre por conta bancária',
        'secondary_placeholder': 'Outras contas',
        'items': await this.getAccounts()
      })
    }
    if (this.FilterRobobankerAccount) {
      this.filter_options.push({
        'title': 'Carteira',
        'type': 'wallets',
        'model': [{ 'label': 'Todas', 'value': 'all', 'exclusive': true }],
        'format': 'tags',
        'placeholder': 'Filtre por conta',
        'secondary_placeholder': '',
        'items': await this.getRobobankerAccounts()
      })
    }
    if (this.FilterTransactionTypes) {
      this.filter_options.push({
        'title': 'Tipo de Transação',
        'type': 'transaction_type',
        'model': [{ label: 'Todas', value: 'all', 'exclusive': true }],
        'format': 'tags',
        'placeholder': 'Filtre por tipos',
        'secondary_placeholder': '',
        'items': [
          { label: 'Todas', value: 'all' },
          { label: 'Tranferências', value: 'transfer' },
          { label: 'Pix', value: 'pix' },
          { label: 'Boletos', value: 'bank_slip' },
          { label: 'Saques', value: 'withdraw' },
          { label: 'Remessas Internacionais', value: 'international_transfer' },
          { label: 'Tarifas', value: 'fee' },
        ]
      })
    }
    if (this.FilterFinancialTransactionTypes) {
      this.filter_options.push({
        'title': 'Tipo de Transação',
        'type': 'transaction_type',
        'model': [{ label: 'Todas', value: 'all', 'exclusive': true }],
        'format': 'tags',
        'placeholder': 'Filtre por tipos',
        'secondary_placeholder': '',
        'items': [
          { label: 'Todas', value: 'all' },
          { label: 'Aplicação', value: 'application' },
          { label: 'Impostos', value: 'taxs' },
          { label: 'Proventos', value: 'earnings' },
          { label: 'Resgate', value: 'rescue' },
        ]
      })
    }
    if (this.FilterProducts) {
      this.filter_options.push({
        'title': 'Produtos',
        'type': 'products',
        'model': [{ label: 'Todos', value: 'all', 'exclusive': true }],
        'format': 'tags',
        'placeholder': 'Filtre pelos produtos',
        'secondary_placeholder': '',
        'items': this.getAssets()
      })
    }
    if (this.FilterBuySell) {
      this.filter_options.push({
        'title': 'Tipo',
        'type': 'buy_sell',
        'model': [{ label: 'Todas', value: 'all', 'exclusive': true }],
        'format': 'tags',
        'placeholder': 'Filtre por tipos',
        'secondary_placeholder': '',
        'items': [
          { label: 'Todas', value: 'all' },
          { label: 'Compra', value: 'buy' },
          { label: 'Venda', value: 'sell' },
        ]
      })
    }
    if (this.FilterCategories) {
      this.filter_options.push({
        'title': 'Categoria',
        'type': 'category',
        'model': [{ 'label': 'Todas', 'value': 'all', 'exclusive': true }],
        'format': 'tags',
        'placeholder': 'Filtre por categorias',
        'secondary_placeholder': 'Outras categorias',
        'items': await this.getCategories()
      })
    }
    if (this.FilterStatusOrder) {
      this.filter_options.push({
        'title': 'Status',
        'type': 'status',
        'model': [{ 'label': 'Todas', 'value': 'all', 'exclusive': true }],
        'format': 'tags',
        'placeholder': 'Filtre os status',
        'secondary_placeholder': '',
        'items': [
          { 'label': 'Todas', 'value': 'all' },
          { 'label': 'Aberta', 'value': 'new' },
          { 'label': 'Calculada', 'value': 'calculated' },
          { 'label': 'Cancelada', 'value': 'canceled' },
          { 'label': 'Concluída', 'value': 'done_for_day' },
          { 'label': 'Em cancelamento', 'value': 'pending_cancel' },
          { 'label': 'Em reposição', 'value': 'pending_replace' },
          { 'label': 'Enviada', 'value': 'pending_new' },
          { 'label': 'Executada', 'value': 'filled' },
          { 'label': 'Executada Parc', 'value': 'partially_filled' },
          { 'label': 'Interrompida', 'value': 'stopped' },
          { 'label': 'Recebida', 'value': 'accepted' },
          { 'label': 'Rejeitada', 'value': 'rejected' },
          { 'label': 'Suspensa', 'value': 'suspended' },
          { 'label': 'Vencida', 'value': 'expired' }
        ]
      })
    }
    if (this.FilterFactors) {
      this.filter_options.push({
        'title': 'Benchmark',
        'type': 'listFactor',
        'model': [{ 'label': 'Todas', 'value': 'all', 'exclusive': true }],
        'format': 'tags',
        'placeholder': 'Filtre por categorias',
        'secondary_placeholder': 'Outras categorias',
        'items': await this.getFactor()
      })
    }
    this.formatted_tags = [];
    for (let field of this.filter_options) {
      if (field.format == 'single') {
        this.formatted_tags.push({ value: field.title, label: field.model.label });
      } else {
        for (let option of field['model']) {
          this.formatted_tags.push({ value: field.title, label: option['label'] });
        }
      }
    }
    this.filter = this.filter_options
    this.callFilter()
  }

  show_filter() {
    if (this.filter == undefined) {
      this.filter = this.filter_options;
    } else if (this.input_options) {
      this.filter = this.input_options;
    }

    this.dialogService.showFilter(this.filter).subscribe(filter_data => {
      this.filter = filter_data;
      this.callFilter()
    })
  }

  callFilter() {
    this.filter.forEach(field => {
      if (field.format == 'tags' && field.model.length == 0) {
        field.model.push({ label: 'Todas', value: 'all' })
      }
    });

    this.formatted_tags = [];
    for (let field of this.filter) {
      if (field.format == 'single') {
        this.formatted_tags.push({ value: field.title, label: field.model.label })
      }
      else if (field.format == 'tags') {
        for (let option of field['model']) {
          this.formatted_tags.push({ value: field.title, label: option['label'] })
        }
      } else {
        this.formatted_tags.push({ value: field.title, label: field.model })
      }
    }
    this.ChangeFilter.emit(this.filter);
  }
}
