import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ConfirmDialogViewModel } from 'src/app/components/confirm-dialog/confirm-dialog.view-model';
import { UserModel } from 'src/app/shared/interfaces/user-model';
import { AppNavRouteLink } from '../commons/AppNavRouteLink';
import { ApisEnum } from '../enums/apis.enum';
import { AppConfigService } from './app-config.service';
import { AppUpdateService } from './app-update.service';
import { DialogService } from './dialog.service';
import { StorageService as storage } from './storage.service';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  config: any;
  model: ConfirmDialogViewModel = new ConfirmDialogViewModel();
  helper = new JwtHelperService();
  
  constructor(
    private http: HttpClient,
    private router: Router,
    private dialogService: DialogService,
    private utilService: UtilService,
    private appUpdateService: AppUpdateService,
    private configService: AppConfigService
  ) {
    this.config = this.configService.config;
  }

  /**
   * Check App Update
   * @param res Response
   */
  private checkAppUpdate(res: Response | any): Response {
    if (res.headers.has('x-appversion')) {
      this.appUpdateService.appVersion.emit(res.headers.get('x-appversion'));
    }
    return res;
  }

  login(credentials: UserModel) {
    storage.clearSection();
    const url = this.utilService.makeUri([ApisEnum.Client, this.config.PARTNER_UUID, 'auth']);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    const dataPost = this.utilService.buildPostData(credentials);

    return this.http.post<any>(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        if (res.status === 200) {
          storage.saveClientJwt(res.body.jwt)
          storage.save('is_authenticate', true);
          const decoded: any = this.helper.decodeToken(res.body.jwt);
          if (decoded.sub) {
           storage.saveClientUuid(decoded.sub);
          }          
          
          this.router.navigate([AppNavRouteLink.NAV.DASHBOARD]);
        }
      })
      .catch(err => {
        if (err.status === 400) {
          this.model.subTitle = 'Usuário e/ou senha incorretos.';
          this.dialogService.showDialogTryAgain(this.model);
        } else {
          this.dialogService.showDialogError('Serviço temporariamente indisponível. Tente novamente mais tarde.');
        }
      });
  }

  logout() {
    const url = this.utilService.makeUri(['jwt', storage.getClientJwt()]);

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    return this.http.delete(url, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        this.router.navigate([AppNavRouteLink.PAGE.LOGIN]);
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  clearAuthentication() {
    storage.clearSection();
    this.router.navigate([AppNavRouteLink.PAGE.LOGIN]);
  }
}
