import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { AppNavRouteLink } from 'src/app/shared/commons/AppNavRouteLink';

@Component({
  selector: 'app-custom-loans-dialog',
  templateUrl: './custom-loans-dialog.component.html',
  styleUrls: ['./custom-loans-dialog.component.scss']
})
export class CustomLoansDialogComponent implements OnInit {

  confirmResult: Subject<boolean>
  accept = false

  constructor(
    public modalRef: BsModalRef,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.confirmResult = new Subject()
  }

  onScroll(event) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.accept = true
    }
  }

  onClose() {
    this.modalRef.hide()
    this.confirmResult.next(this.accept)
  }
}
