import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApisEnum } from '../enums/apis.enum';
import { AssetSecType, AssetSubSecType, DefaultPartnerRequest } from '../interfaces/assets';
import { StorageService as storage } from './storage.service';
import { UtilService } from './util.service';



@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  constructor(private http: HttpClient, private utilService: UtilService, private router: Router) { }


  getPartnerAssets(secType: AssetSecType, currentPage: number, itemsPerPage: number, subSecType?: AssetSubSecType[], others?: any): Promise<DefaultPartnerRequest> {
    const url = this.utilService.makeUri([ApisEnum.Partner, 'pms/assets', { sec_type: secType, page: currentPage, rows: itemsPerPage },
    subSecType ? { sub_sectype: subSecType.join(',') } : '', others, { jwt: storage.getClientJwt() }]);
    console.log('url: ', url);

    return this.http.get<DefaultPartnerRequest>(url).toPromise().catch(err => this.utilService.handleError(err, this.router));;
  }
}
