<div class="modal-content p-4">
  <div class="modal-header border-0">
    <span class="medium h1 w-100 m-0">{{model.title}}</span>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span class="material-icons-outlined">highlight_off</span>
    </button>
  </div>
  <div class="modal-body pt-0">
    <div [innerHtml]="model.msg" class="mb-3"></div>
    <form [formGroup]="formGroup" *ngIf="showForm" class="row" autocomplete="off" novalidate>

      <div class="form-group col-12" *ngIf="model.type == Type.active || model.type == Type.received">
        <label for="last4_digits">Últimos 4 dígitos do cartão</label>
        <input type="text" OnlyNumber name="last4_digits" class="form-control" formControlName="last4_digits"
          maxlength="4">
        <invalid-feedback [field]="f.last4_digits"></invalid-feedback>
      </div>
      <div class="form-group col-12" *ngIf="model.type == Type.active || model.type == Type.newPin">
        <label for="password">Senha</label>
        <div class="position-relative">
          <input [type]="showPassword ? 'text': 'password'" OnlyNumber name="password" class="form-control"
            formControlName="password" maxlength="4">
          <em class="material-icons pointer" (click)="showPassword = !showPassword">{{showPassword ? 'visibility_off' :
            'visibility'}}</em>
          <invalid-feedback [field]="f.password"></invalid-feedback>
        </div>
      </div>

      <div class="form-group col-12" *ngIf="model.type == Type.newPin">
        <label for="confirm_email">Repita a nova senha</label>
        <div class="position-relative">
          <input [type]="showConfirmPassword ? 'text': 'password'" OnlyNumber class="form-control" id="confirm_password"
            name="confirm_password" PreventCopyPast formControlName="confirm_password" maxlength="4">
          <em class="material-icons pointer" (click)="showConfirmPassword = !showConfirmPassword">{{showConfirmPassword
            ? 'visibility_off' : 'visibility'}}</em>
          <invalid-feedback [field]="f.confirm_password" label="os PINs digitados não conferem."></invalid-feedback>
        </div>
      </div>

      <div class="form-group col-12" *ngIf="model.type == Type.blocked">
        <label for="reason">{{model.reasonTitle}}</label>
        <div class="dropdown">
          <select class="form-control" formControlName="reason">
            <option selected value="">Selecione...</option>
            <option *ngFor="let item of reasonList" [value]="item.value">{{item.description}}</option>
          </select>
          <invalid-feedback [field]="f.reason"></invalid-feedback>
        </div>
      </div>
      <div class="col-12 btn-group mt-4">
        <button type="button" class="btn btn-primary ml-0" (click)="modalRef.hide()">CANCELAR</button>
        <button type="submit" class="btn btn-primary mr-0" (click)="send()">{{model.type == Type.blocked ? 'PRÓXIMO' : 'CONFIRMAR'}}</button>
      </div>
    </form>
  </div>
</div>