import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  // CLIENT JWT
  static saveClientJwt(jwt: string): boolean {
    sessionStorage.setItem('client_jwt', jwt);
    return true;
  }

  static getClientJwt(): string {
    const jwt: string = sessionStorage.getItem('client_jwt');
    return jwt;
  }

  static deleteClientJwt(): boolean {
    sessionStorage.removeItem('client_jwt');
    return true;
  }
  
  // CLIENT UUID
  static saveClientUuid(jwt: string): boolean {
    sessionStorage.setItem('client_uuid', jwt);
    return true;
  }

  static getClientUuid(): string {
    const uuid: string = sessionStorage.getItem('client_uuid');
    return uuid;
  }

  static deleteClientUuid(): boolean {
    sessionStorage.removeItem('client_uuid');
    return true;
  }
  
  static clearSection(): boolean {
    sessionStorage.clear();
    return true;
  }
  
  // Storage Tools
  static save(key: string, value: any): boolean {
    sessionStorage.setItem(key, JSON.stringify(value));
    return true;
  }

  static get(key: string): any {
    let value = null
    if (sessionStorage.getItem(key))
      value = JSON.parse(sessionStorage.getItem(key))

    return value
  }

  static delete(key: string): boolean {
    sessionStorage.removeItem(key);
    return true;
  }

  static clear() {
    sessionStorage.clear();
  }

  constructor() { }
}


