<div class="modal-content p-4">
  <div class="modal-header border-0">
    <span class="medium h1 w-100 m-0">Gerenciar o Saldo Mínimo da Conta</span>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span class="material-icons-outlined">highlight_off</span>
    </button>
  </div>
  <div class="modal-body pt-0">
    <div class="small text-muted mb-3">
      O piloto automático é uma ferramenta inovadora exclusiva para clientes da LadyBank.
      Todos os recursos sobrando em sua conta são aplicados automaticamente para atender seus objetivos. <br />
      Caso precise desses recursos para cobrir algum pagamento, resgatamos seus investimentos por você.
    </div>
    <div class="col-12 rounded bg-light poppins py-2 my-5">
      <div class="contained d-flex justify-content-between">
        <div class="small">Saldo na conta:</div>
        <div class="small-plus">{{accountBalance | currency: 'BRL'}}</div>
      </div>
      <div class="pizazz d-flex justify-content-between my-1">
        <div class="small">Saldo mínimo da conta:</div>
        <div class="small-plus">{{minimumBalance | currency: 'BRL'}}</div>
      </div>
      <div class="green d-flex justify-content-between">
        <div class="small">Saldo de investimento para ser resgatado:</div>
        <div class="small-plus">{{investmentBalance | currency: 'BRL'}}</div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <button type="button" class="btn btn-outline-dark" data-dismiss="modal" (click)="modalRef.hide()">CANCELAR</button>
      <button type="button" class="btn btn-primary" (click)="adjustMyLimit()">AJUSTAR MEU LIMITE</button>
    </div>
  </div>
</div>
