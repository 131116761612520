import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { CategoryEnum } from 'src/app/shared/enums/icon-category.enum';
import { ClientService } from 'src/app/shared/services/client.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-category-dialog',
  templateUrl: './category-dialog.component.html',
  styleUrls: ['./category-dialog.component.scss']
})
export class CategoryDialogComponent implements OnInit {

  @Input() title: string;
  @Input() subTitle: string;
  @Input() name: string;
  @Input() statement: Object;

  model: any = {};
  search: '';
  formGroup: FormGroup;
  customCategories = [];
  results = [];
  defaultCategory = false;
  confirmResult: Subject<boolean | string>;
  client: Object;

  constructor(
    public modalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.clientService.clientData.subscribe(d => {
      if (!d) { return; }
      this.client = d;
    });

    this.confirmResult = new Subject();
    this.getCategories();

    this.formGroup = this.formBuilder.group({
      field_1: [{
        value: ''
      }, Validators.required],
      field_2: [{
        value: ''
      }],
    })
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.name) {
        this.results.forEach(obj => {
          const result = obj.options.find(x => x.name.toLowerCase() === this.name.toLowerCase());
          if (result) {
            this.model = result;
          }
        });
      }
    });
  }

  onSearch(e) {
    this.getCategories();
    const text = e.target.value;
    if (text.length == 0) {
      return;
    }

    this.results = [];
    this.customCategories.forEach(obj => {
      const result = obj.options.filter(x => x.name.toLowerCase().startsWith(text.toLowerCase()));
      if (result.length > 0) {
        obj.options = result;
        this.results.push(obj);
      }
    });
  }

  onSelectCategory(item) {
    this.model = item;
    this.search = item.name;
  }

  clear() {
    this.model = {};
  }

  getCategories() {
    this.customCategories = [
      {
        group: 'Receitas',
        options: []
      },
      {
        group: 'Despesas',
        options: []
      }
    ];

    this.commonService.getStatementCategories().then(result => {
      for (const cat of result) {
        if (cat['type'] === 'revenue') {
          this.customCategories[0]['options'].push({
            default: false,
            border: '.5rem solid #1dbc71',
            name: cat['name'],
            icon: cat['icon']
          });
        } else if (cat['type'] === 'expense') {
          this.customCategories[1]['options'].push({
            default: false,
            border: '.5rem solid #1dbc71',
            name: cat['name'],
            icon: cat['icon']
          });
        }
      }

      this.customCategories.forEach(item => {
        return item.options.map(category => {
          category.icon = this.getIcon(category.name);
        })
      })
      this.results = Object.assign([], this.customCategories);
    })
  }

  getIcon(name) {
    return CategoryEnum[name] ? CategoryEnum[name] : '';
  }

  close() {
    this.modalRef.hide();
  }

  onConfirm() {
    if (this.defaultCategory)
      this.model.default = true
      
    this.confirmResult.next(this.model);
    this.modalRef.hide();
  }
}
