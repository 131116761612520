<div class="modal-content p-3">
  <div class="modal-header border-0">
    <span class="medium font-weight-bold w-100 m-auto"> Qual chave você quer cadastrar na LadyBank? </span>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span class="material-icons-outlined">highlight_off</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="small modal-text mb-4">
      É através dessa chave que as pessoas vão te encontrar para fazer pagamentos.
    </div>
    <div class="small alert alert-warning" role="alert" *ngIf="nextRegisterTime">
      Atenção:<br>Horário de funcionamento para cadastro de chaves Pix: 08:00 - 20:00.<br>
      É possível que o cadastro de sua chave Pix ocorra apenas {{ nextRegisterTime }}.
    </div>
    <div class="alert alert-warning small" *ngIf="pixKeys.length > 4">
      <span class="x-small">Alerta</span><br>
      Número máximo de chaves alcançado: 5
    </div>
    <label for="rb-cpf" class="card flex-row align-items-center px-4 py-2 my-2" [hidden]="isDisable(KeyPixTypeEnum.document)">
      <input type="radio" id="rb-cpf" name="keyPix" (click)="onSelectKey(model['document_number'], KeyPixTypeEnum.document)">
      <div class="ml-2">
        <div class="poppins small font-weight-bold">CPF</div>
        <div class="my-0 py-0 small-medium">{{model.document_number}}</div>
      </div>
    </label>
    <label for="rb-email" class="card flex-row align-items-center px-4 py-2 my-2" [hidden]="isDisable(KeyPixTypeEnum.email)">
      <input type="radio" id="rb-email" name="keyPix" (click)="onSelectKey(model['email'], KeyPixTypeEnum.email)">
      <div class="ml-2">
        <div class="poppins small font-weight-bold">E-mail</div>
        <div class="my-0 py-0 small-medium">{{model.email}}</div>
      </div>
    </label>
    <label for="rb-mobile" class="card flex-row align-items-center px-4 py-2 my-2" [hidden]="isDisable(KeyPixTypeEnum.mobile)">
      <input type="radio" id="rb-mobile" name="keyPix" (click)="onSelectKey(model['real_phone'], KeyPixTypeEnum.mobile)">
      <div class="ml-2">
        <div class="poppins small font-weight-bold">Celular</div>
        <div class="my-0 py-0 small-medium">{{model.phone}}</div>
      </div>
    </label>
    <label for="rb-random" class="card flex-row align-items-center p-4 my-2">
      <input type="radio" id="rb-random" name="keyPix" (click)="onSelectKey('random-key', KeyPixTypeEnum.evp)">
      <div class="m-0">
        <div class="poppins small font-weight-bold ml-2">Chave aleatória</div>
      </div>
    </label>
    <div class="col-12 mt-3">
      <p class="small-medium">A chave só poderá ser vinculada <span class="highlight">a uma conta</span></p>
    </div>
    <div class="col-12">
      <button type="submit" class="btn btn-primary btn-block mx-auto" [disabled]="!selectOption" (click)="save()">
        ESCOLHER ESSA CHAVE
      </button>
      <button type="button" class="btn btn-outline-dark btn-block mx-auto" data-dismiss="modal" (click)="modalRef.hide()">CANCELAR</button>
    </div>
  </div>
</div>
