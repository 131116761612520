<div class="modal-content">
  <div class="modal-header border-0">
    <span class="medium font-weight-bold text-right mx-auto col-9"> Características principais </span>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span class="material-icons-outlined">highlight_off</span>
    </button>
  </div>
  <div class="justify-content-md-center">
    <div class="col col-12 text-center small" [innerHTML]="msg"></div>
    <div class="col col-12 text-center mt-3">
      <span class="medium h1">Ativos e seus limites</span>
      <!--p class="x-small text-danger">
        <strong>Atualmente, o acesso à plataforma de investimentos não está liberado</strong>
      </p-->
    </div>
    <div class="col-12">
      <div class="d-flex align-items-center small my-2" *ngFor="let item of assetList.options" [ngClass]="{'disabled': item.limit == 0}">
        <div class="col-1">
          <span [style.background]="item.color" class="pl-1 pr-2 rounded"></span>
        </div>
        <div class="col-9 text-left">
          {{item.asset}}
        </div>
        <div class="col-1">
          {{item.percent | percent: '1.0-2'}}
        </div>
      </div>
    </div>
  </div>
  <div class="col my-3 text-center">
    <button type="button" class="btn btn-primary" (click)="modalRef.hide()">OK, ENTENDI</button>
  </div>
</div>
