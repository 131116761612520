import { Directive, Input, HostListener, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[Currency]',
  providers: [CurrencyPipe]
})
export class CurrencyDirective implements OnChanges {
  @Input('Currency') option = {
    symbol: 'BRL'
  };

  private specialKeys: Array<string> = [
    'Tab',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowRight',
    'Delete'
  ];

  constructor(
    private el: ElementRef,
    private fc: NgControl,
    private currencyPipe: CurrencyPipe
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.option.currentValue != changes.option.firstChange) {
      this.transform({ key: -1 })
    }
  }

  @HostListener('keyup', ['$event'])
  transform(event: any) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    let value = this.el.nativeElement?.value ? this.el.nativeElement.value.replace(/\D/g, '').replace(/^0+/, '') : event.key;
    value = value / 100;

    if (this.option.symbol == '')
      value = this.currencyPipe.transform(value, this.option.symbol, '')
    else
      value = this.currencyPipe.transform(value, this.option.symbol || 'BRL')

    this.fc.control.setValue(value);
  }

  @HostListener('blur', ['$event'])
  onBlur($event: any) {
    this.transform($event);
  }
}