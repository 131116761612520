import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-link-dialog',
  templateUrl: './link-dialog.component.html',
  styleUrls: ['./link-dialog.component.scss']
})
export class LinkDialogComponent implements OnInit {

  @Input() src: string;
  @Input() name: string;

  constructor(
    public modalRef: BsModalRef
  ) { }

  ngOnInit(): void {
  }

  getHeight() {
    return `${window.innerHeight - 210}px`
  }
}
