<div class="modal-content">
  <div class="modal-header border-0">
    <span class="medium h1 w-100 m-0">Editar documentos</span>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span class="material-icons-outlined">highlight_off</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="formGroup" autocomplete="off" novalidate>
      <div class="form-control-upload col-12">
        <upload-cropped idFile="photo" [type]="fileType.document" formControlName="selfie" [requiredFile]="true"
          requiredFileText="" [textTooltip]="textTooltipSelf" classUpload="col-12 bg-upload rounded p-4"
          [subType]="fileSubType.selfie" labelFile="Selfie:" [accept]="fileAccept" [size]="fileSizeMB"
          classLabelFile="small text-body" classButton="small" [buttonText]="labelFilePhoto" [showIcon]="true"
          (CompletedUpload)="onFileChange($event, fileType.document, fileSubType.selfie)">
        </upload-cropped>
        <div
          *ngIf="(!formGroup.controls.selfie.pristine || formGroup.controls.selfie.touched) && formGroup.controls.selfie.errors"
          class="invalid-feedback x-small">
          <div *ngIf="formGroup.controls.selfie.errors.required"> Campo obrigatório. </div>
          <div *ngIf="formGroup.controls.selfie.errors.requiredFileType">
            Apenas arquivos PNG, JPG, JPEG e PDF. Tamanho máximo {{ fileSizeMB }}MB.
          </div>
        </div>
      </div>
      <div class="form-control-upload bg-upload col-12" [ngClass]="{'disabled': disableFields}">
        <label class="col-12 small pt-3">Comprovante de endereço:</label>
        <custom-upload idFile="file_address" formControlName="address_path" [type]="fileType.document"
          [subType]="fileSubType.address" [labelFile]="labelFileAddress" [accept]="fileAccept" [size]="fileSizeMB"
          classLabelFile="small" classUpload="col-12 pb-3"
          textTooltip="Atenção! Seu comprovante deve ser dos últimos 3 meses. Deve ser apresentado por inteiro e não em partes!"
          (CompletedUpload)="onFileChange($event, fileType.document, fileSubType.address)"></custom-upload>
        <div
          *ngIf="(!formGroup.controls.address_path.pristine || formGroup.controls.address_path.touched) && formGroup.controls.address_path.errors"
          class="invalid-feedback x-small">
          <div *ngIf="formGroup.controls.address_path.errors.required"> Campo obrigatório. </div>
          <div *ngIf="formGroup.controls.address_path.errors.requiredFileType">
            Apenas arquivos PNG, JPG, JPEG e PDF. Tamanho máximo {{ fileSizeMB }}MB.
          </div>
        </div>
      </div>
      <div class="form-control-upload bg-upload mt-3" [ngClass]="{'disabled': disableFields}">
        <div class="col-12 d-flex align-items-center pt-3">
          <label class="small my-2">Enviar documento de identificação</label>
          <img src="assets/images/svg/help-circle.svg" class="help ml-2" alt="help"
            tooltip="Por favor antes de inserir a foto da sua CNH, ela deve estar fora do plástico de proteção, para que os dados sejam legíveis pelo sistema!">
        </div>
        <div class="col-12 small">Tire uma foto do seu documento (RG ou CNH), ou escaneie o seu documento</div>
        <div class="w-100" *ngIf="docFrontBack">
          <custom-upload idFile="file_document_front" formControlName="document_front" [type]="fileType.document"
            [subType]="fileSubType.rg_front" [labelFile]="labelFileFront" [accept]="fileAccept" [size]="fileSizeMB"
            classLabelFile="small" classUpload="col-12 my-2"
            (CompletedUpload)="onFileChange($event, fileType.document, fileSubType.rg_front)"></custom-upload>
          <div
            *ngIf="(!formGroup.controls.document_front.pristine || formGroup.controls.document_front.touched) && formGroup.controls.document_front.errors"
            class="invalid-feedback x-small">
            <div *ngIf="formGroup.controls.document_front.errors.required"> Campo obrigatório. </div>
            <div *ngIf="formGroup.controls.document_front.errors.requiredFileType">
              Apenas arquivos PNG, JPG, JPEG e PDF. Tamanho máximo {{ fileSizeMB }}MB.
            </div>
          </div>
          <custom-upload idFile="file_document_back" formControlName="document_back" [type]="fileType.document"
            [subType]="fileSubType.rg_back" [labelFile]="labelFileBack" [accept]="fileAccept" [size]="fileSizeMB"
            classLabelFile="small" classUpload="col-12 my-2"
            (CompletedUpload)="onFileChange($event, fileType.document, fileSubType.rg_back)"></custom-upload>
          <div
            *ngIf="(!formGroup.controls.document_back.pristine || formGroup.controls.document_back.touched) && formGroup.controls.document_back.errors"
            class="invalid-feedback x-small">
            <div *ngIf="formGroup.controls.document_back.errors.required"> Campo obrigatório. </div>
            <div *ngIf="formGroup.controls.document_back.errors.requiredFileType">
              Apenas arquivos PNG, JPG, JPEG e PDF. Tamanho máximo {{ fileSizeMB }}MB.
            </div>
          </div>
        </div>
        <div class="w-100" *ngIf="!docFrontBack">
          <custom-upload idFile="file_document" formControlName="document_path" [type]="fileType.document"
            [subType]="fileSubType.rg" [labelFile]="labelFileDocument" [accept]="fileAccept" [size]="fileSizeMB"
            classLabelFile="small" classUpload="col-12 my-2"
            (CompletedUpload)="onFileChange($event, fileType.document, fileSubType.rg)"></custom-upload>
          <div
            *ngIf="(!formGroup.controls.document_path.pristine || formGroup.controls.document_path.touched) && formGroup.controls.document_path.errors"
            class="invalid-feedback x-small">
            <div *ngIf="formGroup.controls.document_path.errors.required"> Campo obrigatório. </div>
            <div *ngIf="formGroup.controls.document_path.errors.requiredFileType">
              Apenas arquivos PNG, JPG, JPEG e PDF. Tamanho máximo {{ fileSizeMB }}MB.
            </div>
          </div>
        </div>
        <div class="form-group custom-checkbox">
          <input type="checkbox" id="field_2" [checked]="docFrontBack" [value]="docFrontBack"
            (change)="docFrontBack = !docFrontBack">
          <label class="ml-2" for="field_2">Quero enviar frente e verso separadamente</label>
        </div>
      </div>
    </form>
    <div class="row justify-content-center mt-3">
      <button type="button" class="btn btn-outline-dark" data-dismiss="modal" (click)="modalRef.hide()">CANCELAR</button>
      <button type="submit" class="btn btn-primary" (click)="close()" [disabled]="!changeFiles">SALVAR</button>
    </div>
  </div>
</div>