<div class="container">
  <div class="col-12 my-4 py-4 d-flex justify-content-start align-items-center background-gradient rounded">
    <div class="float-left logo"></div>
  </div>
  <div class="col-12" *ngIf="receipt && receipt.amount">
    <div class="row bg-green poppins small text-body rounded py-4">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <span *ngIf="receipt.schedule_date" class="font-weight-bold">Comprovante de TED agendado</span>
        <span *ngIf="receipt?.mappedType" class="font-weight-bold">Comprovante de {{
          translate_type(receipt['mappedType'])}} realizado</span>
        <span *ngIf="receipt.operationType == 'CARD_AUTHORIZATION'" class="font-weight-bold">Comprovante de compra
          realizada</span>
        <p *ngIf="receipt.day">Data de pagamento:
          <strong>{{utils.formatUnix(receipt.day) | date: 'dd/MM/yyyy - HH:MM'}}</strong></p>
        <p *ngIf="receipt.schedule_date">Data do agendamento:
          <strong>{{utils.formatUnix(receipt.schedule_date) | date: 'dd/MM/yyyy'}}</strong></p>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6">
        <span><strong>Valor</strong></span>
        <p>{{-1 * receipt.amount | currency: 'BRL' }}</p>
      </div>
    </div>
    <div class="row mb-5 poppins" *ngIf="receipt.operationType == 'CARD_AUTHORIZATION'">
      <div class="col-12 col-lg-4 mt-4">
        <span class="text-muted x-small">Id da Transação</span>
        <p class="text-body small font-weight-bold">{{receipt['otherInfo']['transactionId']}}</p>
      </div>
      <div class="col-12 col-lg-4 mt-4">
        <span class="text-muted x-small">Forma de autorização</span>
        <p class="text-body small font-weight-bold">{{receipt['otherInfo']['captureMode']}}</p>
      </div>
      <div class="col-12 col-lg-4 mt-4">
        <span class="text-muted x-small">Nome estabelecimento</span>
        <p class="text-body small font-weight-bold">{{receipt['otherInfo']['merchant']['name']}}</p>
      </div>
    </div>
    <div class="row mb-5 poppins" *ngIf="receipt.operationType == 'SENT_TRANSFERENCE'">
      <div class="col-12 col-lg-4 mt-4">
        <span class="text-muted x-small">Nome</span>
        <p class="text-body small font-weight-bold">{{receipt?.otherInfo?.fullName || receipt?.nameFavorite}}</p>
      </div>
      <div class="col-12 col-lg-2 mt-4">
        <span class="text-muted x-small">CPF</span>
        <p class="text-body small font-weight-bold">{{getDocument(receipt)}}</p>
      </div>
      <div class="col-12 col-lg-2 mt-4">
        <span class="text-muted x-small">Instituição</span>
        <p class="text-body small font-weight-bold">{{receipt?.bank?.name ? receipt?.bank?.name : 'LadyBank'}}</p>
      </div>
      <div class="col-12 col-lg-2 mt-4" *ngIf="receipt?.agency">
        <span class="text-muted x-small">Agência</span>
        <p class="text-body small font-weight-bold">{{receipt?.agency}}</p>
      </div>
      <div class="col-12 col-lg-2 mt-4" *ngIf="receipt?.account && receipt?.account_digit">
        <span class="text-muted x-small">Conta</span>
        <p class="text-body small font-weight-bold">{{receipt?.account}}-{{receipt?.account_digit}}</p>
      </div>
      <div class="col-12 col-lg-4 mt-4" *ngIf="receipt?.otherInfo?.transferId">
        <span class="text-muted x-small">Id da Transação</span>
        <p class="text-body small font-weight-bold">{{receipt['otherInfo']['transferId']}}</p>
      </div>
    </div>
    <div class="row mb-5 poppins"
      *ngIf="receipt.operationType == 'BANK_ACCOUNT_WITHDRAWAL' || receipt.operationType == 'PIX_CASH_OUT_TRANSACTION'">
      <div class="col-12 mt-4">
        <h4 class="font-weight-bold">Favorecido</h4>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <span class="text-muted x-small">Nome</span>
        <p class="text-body small font-weight-bold">{{getName(receipt)}}</p>
      </div>
      <div class="col-12 col-md">
        <span class="text-muted x-small">CPF/CNPJ</span>
        <p class="text-body small font-weight-bold">{{getDocument(receipt)}}</p>
      </div>
      <div class="col-12 col-md">
        <span class="text-muted x-small">Instituição</span>
        <p class="text-body small font-weight-bold" *ngIf="receipt.operationType == 'BANK_ACCOUNT_WITHDRAWAL'">
          {{filterBankName(receipt)}}</p>
        <p class="text-body small font-weight-bold" *ngIf="receipt.operationType == 'PIX_CASH_OUT_TRANSACTION'">
          {{receipt.otherInfo.receiver.ispbName}}</p>
      </div>
      <div class="col-12" *ngIf="receipt['mappedType'] == 'pix'">
        <span class="text-muted x-small">Id da Transação</span>
        <p class="text-body small font-weight-bold">{{receipt['otherInfo']['id']}}</p>
      </div>
      <div class="col-12 col-md-6 col-lg-2" *ngIf="receipt['mappedType'] == 'transfer'">
        <span class="text-muted x-small">Agência</span>
        <p class="text-body small">
          <strong
            *ngIf="receipt?.otherInfo?.destination?.bankAccount?.bankBranch">{{receipt?.otherInfo?.destination?.bankAccount?.bankBranch}}</strong>
          <strong
            *ngIf="!receipt?.otherInfo?.destination?.bankAccount?.bankBranch && receipt?.account_data?.branch">{{receipt?.account_data?.branch}}</strong>
        </p>
      </div>
      <div class="col-12 col-md-6 col-lg-2" *ngIf="receipt['mappedType'] == 'transfer'">
        <span class="text-muted x-small">Conta</span>
        <p class="text-body small font-weight-bold">
          <strong
            *ngIf="receipt?.otherInfo?.destination?.bankAccount?.bankAccountNumber">{{receipt?.otherInfo?.destination?.bankAccount?.bankAccountNumber}}</strong>
          <strong
            *ngIf="receipt?.otherInfo?.destination?.bankAccount?.bankAccountDigit">-{{receipt?.otherInfo?.destination?.bankAccount?.bankAccountDigit}}</strong>
        </p>
      </div>
    </div>
    <div class="row my-3 poppins">
      <div class="col-12">
        <h4 class="font-weight-bold">Origem</h4>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <span class="text-muted x-small">Nome</span>
        <p class="text-body small font-weight-bold">{{client['name']}}</p>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <span class="text-muted x-small">Instituição</span>
        <p class="text-body small font-weight-bold">LadyBank</p>
      </div>
      <div class="col-6 col-xl-2">
        <span class="text-muted x-small">Agência</span>
        <p class="text-body small font-weight-bold">{{account['branch']}}</p>
      </div>
      <div class="col">
        <span class="text-muted x-small">Conta</span>
        <p class="text-body small font-weight-bold">{{utils.formatBankDigit(account['number'])}}</p>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <span class="text-muted x-small">CPF</span>
        <p class="text-body small font-weight-bold">{{utils.maskCpf(client['document_number'])}}</p>
      </div>
      <div class="col-12">
        <span class="text-muted x-small">Descrição</span>
        <p class="text-body small font-weight-bold" *ngIf="receipt.operationType == 'CARD_AUTHORIZATION'">
          {{receipt?.description}}
        </p>
        <p class="text-body small font-weight-bold" *ngIf="receipt['mappedType'] == 'pix'">
          {{receipt?.receiver?.description}}
        </p>
        <p class="text-body small font-weight-bold" *ngIf="receipt['mappedType'] == 'transfer'">
          {{receipt?.otherInfo?.destination?.description || receipt?.otherInfo?.description || receipt?.description}}
        </p>
        <p class="text-body small font-weight-bold"
          *ngIf="receipt.operationType == 'BANK_SLIP_PAYMENT' || receipt.operationType == 'SENT_TRANSFERENCE'">
          {{receipt?.description}}
        </p>
      </div>
      <div class="poppins" *ngIf="receipt.operationType == 'BANK_SLIP_PAYMENT'">
        <div class="col-12 col-lg-4 my-4">
          <span class="text-muted x-small">Id da Transação</span>
          <p class="text-body small font-weight-bold">{{receipt['otherInfo']['boletoPaymentId']}}</p>
        </div>
        <div class="col-12" *ngIf="this.receipt['otherInfo']['cupom']">
          <span class="text-muted x-small">Cupom</span>
          <p class="text-body small font-weight-bold" *ngFor="let item of infoCupom">{{item}}</p>
        </div>
      </div>
    </div>
  </div>
</div>