import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})

export class FilterPipe implements PipeTransform {

  transform(array: any[], value: string): any {
    if (value && value.length > 2) {
      return this.filterByValue(array, value.trim());
    } else {
      return array;
    }
  }

  removeAccentuation(value) {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  filterByValue(array, value) {
    return array.filter(o =>
      Object.keys(o).some(k => {
        if (typeof value == 'string') {
          value = this.removeAccentuation(value.toLowerCase());
        }

        if (typeof o[k] == 'string' || typeof o[k] == 'number') {
          return this.removeAccentuation(o[k].toString().toLowerCase()).includes(value)
        } else if (o[k] != undefined) {
          return this.removeAccentuation(JSON.stringify(o[k]).toLowerCase()).includes(value)
        } else if (typeof o != undefined) {
          return this.removeAccentuation(JSON.stringify(o).toLowerCase()).includes(value)          
        }
      })
    );
  }
}