export class AccountPlans {
  public static get() {
    return [
      {
        name: 'Manutenção mensal',
        value: 0,
        description: ''
      },
      {
        name: 'TED',
        value: 2.60,
        description: 'por TED'
      },
      {
        name: 'Saque caixa 24h',
        value: 5.00,
        description: 'por saque'
      },
      {
        name: 'Emissão de boleto',
        value: 2.10,
        description: 'por boleto'
      },
      {
        name: 'Transferência entre contas LadyBank',
        value: 0,
        description: ''
      },
      {
        name: 'Enviar PIX',
        value: 0,
        description: 'por operação'
      },
      {
        name: 'Receber PIX',
        value: 0,
        description: ''
      },
      {
        name: 'Emissão de primeira via do cartão',
        value: 0,
        description: ''
      },
      {
        name: 'Emissão demais vias do cartão',
        value: 17.50,
        description: 'por cartão'
      },
      {
        name: 'Abertura de conta',
        value: 0,
        description: ''
      },
      {
        name: 'Fechamento de conta',
        value: 0,
        description: ''
      },
      {
        name: 'Pagamento de boleto',
        value: 0,
        description: ''
      },
      {
        name: 'Gestão de investimentos',
        value: null,
        description: '1% ao ano'
      },
      {
        name: 'Integração com outras contas',
        value: 1.50,
        description: 'por mês'
      }
    ]
  }
}