<div class="modal-content">
  <div class="modal-header border-0">
    <span class="medium text-center font-weight-bold w-100 m-auto"> Ótimo! Agora vamos definir os seus objetivos </span>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span class="material-icons-outlined">highlight_off</span>
    </button>
  </div>
  <p class="text-center small modal-text">
    Cada situação de vida pode definir algumas prioridades. Criamos para você os objetivos abaixo como sugestões. Ao
    clicar em continuar, fique à vontade para mudar o que achar necessário.
  </p>
  <div class="modal-body">
    <div class="row justify-content-start">
      <ul *ngFor="let item of listGoal" class="col-12 col-md-6">
        <li *ngIf="item.contem">
          <div class="card">
            <div class="card-body py-3">
              <icon-svg [svgName]="item.icon" class="small mr-2"></icon-svg>
              <span class="poppins small">{{item.name}}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="d-flex justify-content-center">
    <button type="button" class="btn btn-outline-dark" data-dismiss="modal" (click)="modalRef.hide()">VOLTAR</button>
    <button type="button" class="btn btn-primary" (click)="onConfirm()">CONTINUAR</button>
  </div>
</div>