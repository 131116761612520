<div class="form-group">
  <div *ngIf="editing">
    <input #inlineEditControl [required]="required" (blur)="onBlur($event)" (keypress)="onKeyPress($event)"  [name]="value" [placeholder]="label"
      class="form-control" [style.width.px]="width"/>
  </div>
  <div *ngIf="!editing" (click)="beginEdit()" (focus)="beginEdit();">
    <div tabindex="0" class="form-control no-editing my-1" [style.width.px]="width">
      {{value}}
      <span class="icon-edit">
        <icon-svg [hidden]="hiddenIconEdit" svgName="pencil"></icon-svg>
      </span>
    </div>
  </div>
</div>
