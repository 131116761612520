import { AfterViewInit, Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { Subject } from 'rxjs'
import { AppNavRouteLink } from 'src/app/shared/commons/AppNavRouteLink'
import { AccountModel } from 'src/app/shared/interfaces/account-model'
import { ClientModel } from 'src/app/shared/interfaces/client-model'
import { ClientService } from 'src/app/shared/services/client.service'
import { DialogService } from 'src/app/shared/services/dialog.service'
import { FinancialService } from 'src/app/shared/services/financial.service'
import { UtilService } from 'src/app/shared/services/util.service'
import { StorageService as storage } from 'src/app/shared/services/storage.service';
import { OptimizeOptionsEnum } from 'src/app/shared/enums/optimize-options.enum'
import { InstitutionTypeEnum } from 'src/app/shared/enums/institution-type.enum'
import { Utils, valueOrDefault } from 'src/app/shared/commons/Utils'

@Component({
  selector: 'app-optimize-ideal-portfolio',
  templateUrl: './optimize-ideal-portfolio.component.html',
  styleUrls: ['./optimize-ideal-portfolio.component.scss']
})
export class OptimizeIdealPortfolioComponent implements OnInit, AfterViewInit {

  step = 1
  progress = 33
  confirmResult: Subject<any>
  total_balance = 0
  total_positions = 0
  total_invested = 0
  optional: any = {
    themes: false,
    expectations: false,
    incomeTax: false,
  }
  accounts: any = []
  rb_account_id: number
  robobanker_account: any
  client = {} as ClientModel
  account = {} as AccountModel
  hasGoals: boolean
  optimizeOption: string
  extra_value: string
  amount_to_redeem: string

  constructor(
    private clientService: ClientService,
    private dialogService: DialogService,
    private financialService: FinancialService,
    public modalRef: BsModalRef,
    private router: Router,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.clientService.clientData.subscribe(d => {
      if (!d) return
      else this.client = d
    })

    this.clientService.accountData.subscribe(d => {
      if (!d) return
      else this.account = d
    })
    this.confirmResult = new Subject()

  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getRobobankerAccountBydId()
    }, 300);
  }

  async getRobobankerAccountBydId() {
    this.robobanker_account = await this.clientService.getRobobankerAccountBydId(this.client.uuid, this.rb_account_id)
    this.extra_value = valueOrDefault(this.robobanker_account.account_robobanker.total_assets, '')
    this.loadPage()
  }

  loadPage() {
    this.getCredigoAccounts()
    this.getPositions()
    this.getChangeSuggestions()
    this.getGoals()
  }

  getChangeSuggestions() {
    this.clientService.getChangeSuggestionsAllocations().then((res: any) => {
      if (res.portfolio_actual) {
        res.portfolio_actual.forEach(el => {
          this.total_invested += el.value
        });
      }
    });
  }

  getCredigoAccounts() {
    this.clientService.getThirdPartyCredigoAccounts(this.client.uuid, { institution_type: 'bank' }).then((res: any) => {
      for (const acc of res.accounts) {
        if (acc.institution_type != 'credit_card') {
          this.accounts.push({
            id: acc.id,
            main: false,
            name: `${acc.bank_name} (${InstitutionTypeEnum[acc.institution_type]})`,
            connected: acc?.connected,
            isBank: acc?.institution_type === 'bank',
            currentBalance: acc?.balance,
          });
        }
      }
      // this.getAccountBalance();
    }).catch(err => {
      console.error(err);
    });
  }


  getPositions() {
    this.total_positions = 0
    this.clientService.getPositions(this.client['uuid'], { rb_account_uuid: this.robobanker_account.account_uuid }).then(res => {
      this.total_positions = valueOrDefault(res?.total_financial, 0)
    })
  }

  getGoals() {
    this.financialService.getPlansBack(storage.getClientUuid(), storage.getClientJwt()).then(result => {
      this.hasGoals = result?.goals?.length > 0
    })
  }

  changeStep(value, percent) {
    this.step = value
    this.progress = percent
  }

  adjustGoals() {
    this.modalRef.hide()
    this.router.navigate([AppNavRouteLink.TABS_FINANCIAL_PLANNING.GOALS])
  }

  adjustThemes() {
    this.modalRef.hide()
    this.router.navigate([AppNavRouteLink.TABS_INVESTMENT.MY_THEMES])
  }

  adjustExpectations() {
    this.modalRef.hide()
    this.router.navigate([AppNavRouteLink.TABS_INVESTMENT.MY_EXPECTATIONS])
  }

  adjustIncomeTax() {
    this.modalRef.hide()
  }

  simulateAnotherValue() {
    this.dialogService.modalSimulateValue().subscribe(res => {
      if (res && res.update) {
        this.total_balance = res.new_balance
      }
    })
  }

  /**
   * CHECK PROTECTED ROUTER
   * @param url URL
   */
  checkProtectedRouter(url: string) {
    if (this.client) {
      return this.utilService.routerIsProtected(url, this.client);
    } else {
      return this.utilService.routerIsProtected(url);
    }
  }

  finish() {
    let inBody: any = {}
    inBody.keep_position = false
    if (this.optimizeOption == OptimizeOptionsEnum.contribute) {
      inBody.incomes_simulated = []
      inBody.incomes_simulated.push(Utils.moneyTofloat(this.extra_value))
    } else if (this.optimizeOption == OptimizeOptionsEnum.redeem) {
      inBody.goals_simulated = []
      inBody.goals_simulated.push(Utils.moneyTofloat(this.amount_to_redeem))
    }

    this.confirmResult.next(inBody)
    this.modalRef.hide()
  }

  get routerLink() {
    return AppNavRouteLink
  }

  get OptimizeOptions() {
    return OptimizeOptionsEnum
  }
}
