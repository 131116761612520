<app-header-menu></app-header-menu>
<div class="my-5 pt-md-5">
  <div class="">
    <div class="">
      <div class="row mx-auto justify-content-center col-12 p-5">
        <a href="/" class="mx-auto d-none d-md-inline-flex">
          <img src="assets/images/png/logo_ladybank.png" alt="">
        </a>
        <div *ngIf="!expiredLink" class="row justify-content-start mt-3 mt-md-5">
          <p class="small font-weight-bold col-12 text-md-center">Quase pronto, basta digitar sua nova senha abaixo.</p>
          <div class="row col-12 col-md-6 justify-content-center mx-auto align-self-center">
            <form [formGroup]="formPassword" autocomplete="off" novalidate class="row justify-content-center">
              <div class="form-group col-12 col-md-8 mb-2 mb-md-3">
                <label for="cpf">Digite seu CPF</label>
                <input type="text" class="form-control" id="cpf" name="cpf" Mask="###.###.###-##"
                  [(ngModel)]="client.document_number" formControlName="cpf">
                <div *ngIf="(!f.cpf.pristine || f.cpf.touched) && f.cpf.errors" class="invalid-feedback x-small">
                  <div *ngIf="f.cpf.errors.required"> Campo obrigatório. </div>
                  <div *ngIf="f.cpf.errors.invalid"> CPF inválido. </div>
                </div>
              </div>
              <div class="form-group col-12 col-md-8 position-relative mb-2 mb-md-3">
                <label for="password">Nova senha</label>
                <div class="position-relative">
                  <input [type]="showPassword ? 'text': 'password'" class="form-control" id="password" name="password" PreventCopyPast
                    [(ngModel)]="client.password" formControlName="password" maxlength="200" PasswordStrength>
                  <div class="row mx-2 justify-content-end my-1">
                    <icon-svg svgName="info" class="text-center ml-auto"
                              tooltip="A senha deve conter no mínimo 6 caracteres, 1 número e 1 caractere especial" placement="bottom"></icon-svg>
                  </div>
                  <em class="material-icons pointer"
                    (click)="showPassword = !showPassword">{{showPassword ? 'visibility_off' : 'visibility'}}</em>
                  <div *ngIf="(!f.password.pristine || f.password.touched) && f.password.errors"
                    class="invalid-feedback x-small">
                    <div *ngIf="f.password.errors.required"> Campo obrigatório. </div>
                    <div *ngIf="!f.password.errors.required && f.password.errors.invalidPassword"> A senha deve conter
                      no mínimo 6 caracteres, 1 número e 1 caractere especial. </div>
                  </div>
                  <!--<label id="password-strength">Nível de segurança: </label>-->
                </div>
              </div>
              <div class="form-group col-12 col-md-8">
                <label>Repita a nova senha</label>
                <div class="position-relative">
                  <input [type]="showConfirmPassword ? 'text': 'password'" class="form-control" id="confirm_password"
                    name="confirm_password" PreventCopyPast [(ngModel)]="confirmPassword"
                    formControlName="confirm_password" maxlength="200">
                  <em class="material-icons pointer"
                    (click)="showConfirmPassword = !showConfirmPassword">{{showConfirmPassword ? 'visibility_off' : 'visibility'}}</em>
                  <div *ngIf="(!f.confirm_password.pristine || f.confirm_password.touched) && f.confirm_password.errors"
                    class="invalid-feedback x-small">
                    <div *ngIf="f.confirm_password.errors.required"> Campo obrigatório. </div>
                    <div *ngIf="f.confirm_password.errors.doNotMatch"> As senhas informadas não conferem. </div>
                  </div>
                </div>
              </div>
            </form>
            <div class="mt-3 mx-auto">
              <button type="submit" class="btn btn-primary m-0" [disabled]="!formPassword.valid"
                (click)="showModalToken()">CADASTRAR NOVA SENHA</button>
            </div>
          </div>
        </div>
        <div *ngIf="expiredLink" class="row justify-content-start mt-5">
          <div class="col col-6">
            <p class="color-status-error small font-weight bold"> O link para redefinir sua senha expirou. </p>
            <p class="small"> Isso pode acontecer porque o link está expirado ou você já recuperou sua senha por este
              link, você precisa gerar um novo link clicando em 'Esqueci minha senha'.</p>
            <a class="link small" (click)="recoverPassword()">ESQUECI MINHA SENHA</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
