import { Injectable, Output } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

import { BehaviorSubject } from 'rxjs';
import { AcceptTermDialogComponent } from 'src/app/components/accept-term-dialog/accept-term-dialog.component';
import { AddressCardDialogComponent } from 'src/app/components/address-card-dialog/address-card-dialog.component';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { AssetChoiceDialogComponent } from 'src/app/components/asset-choice-dialog/asset-choice-dialog.component';
import { AssetDialogComponent } from 'src/app/components/asset-dialog/asset-dialog.component';
import { BrokerDialogComponent } from 'src/app/components/broker-dialog/broker-dialog.component';
import { CategoryDialogComponent } from 'src/app/components/category-dialog/category-dialog.component';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogViewModel } from 'src/app/components/confirm-dialog/confirm-dialog.view-model';
import { ConfirmPaymentDialogComponent } from 'src/app/components/confirm-payment-dialog/confirm-payment-dialog.component';
import { ConfirmTransferDialogComponent } from 'src/app/components/confirm-transfer-dialog/confirm-transfer-dialog.component';
import { ContactDialogComponent } from 'src/app/components/contact-dialog/contact-dialog.component';
import { CustomLoansDialogComponent } from 'src/app/components/custom-loans-dialog/custom-loans-dialog.component';
import { DialogWithoutAccountsComponent } from 'src/app/components/dialog-without-accounts/dialog-without-accounts.component';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { CloseAccountComponent } from 'src/app/components/dialogs/close-account/close-account.component';
import { LinkDialogComponent } from 'src/app/components/dialogs/link-dialog/link-dialog.component';
import { DuplicateCardDialogComponent } from 'src/app/components/duplicate-card-dialog/duplicate-card-dialog.component';
import { EditFavoriteDialogComponent } from 'src/app/components/edit-favorite-dialog/edit-favorite-dialog.component';
import { EditPatrimonyDialogComponent } from 'src/app/components/edit-patrimony-dialog/edit-patrimony-dialog.component';
import { EditScheduledTransfersComponent } from 'src/app/components/edit-scheduled-transfers/edit-scheduled-transfers.component';
import { FavoriteDialogComponent } from "src/app/components/favorite-dialog/favorite-dialog.component";
import { ForgotPasswordComponent } from 'src/app/components/forgot-password/forgot-password.component';
import { FormDialogComponent } from 'src/app/components/form-dialog/form-dialog.component';
import { GoalDialogComponent } from 'src/app/components/goal-dialog/goal-dialog.component';
import { GoalInsightDialogComponent } from 'src/app/components/goal-insight-dialog/goal-insight-dialog.component';
import { InvalidGoalDialogComponent } from 'src/app/components/invalid-goal-dialog/invalid-goal-dialog.component';
import { ManageCardLimitComponent } from 'src/app/components/manage-card-limit/manage-card-limit.component';
import { MyFilesDialogComponent } from 'src/app/components/my-files-dialog/my-files-dialog.component';
import { NewAlertDialogErrorComponent } from 'src/app/components/new-alert-dialog-error/new-alert-dialog-error.component';
import { NewAlertDialogSuccessComponent } from 'src/app/components/new-alert-dialog-success/new-alert-dialog-success.component';
import { OptimizeIdealPortfolioComponent } from 'src/app/components/optimize-ideal-portfolio/optimize-ideal-portfolio.component';
import { OptionsCardDialogComponent } from 'src/app/components/options-card-dialog/options-card-dialog.component';
import { PasswordCardDialogComponent } from 'src/app/components/password-card-dialog/password-card-dialog.component';
import { PersonalDataDialogComponent } from 'src/app/components/personal-data-dialog/personal-data-dialog.component';
import { PixDialogComponent } from 'src/app/components/pix-dialog/pix-dialog.component';
import { PixReverseDialogComponent } from 'src/app/components/pix-reverse-dialog/pix-reverse-dialog.component';
import { PlansDialogComponent } from 'src/app/components/plans-dialog/plans-dialog.component';
import { SessionExpiredComponent } from 'src/app/components/session-expired/session-expired.component';
import { SimulateValueDialogComponent } from 'src/app/components/simulate-value-dialog/simulate-value-dialog.component';
import { SuitabilityMainFeaturesComponent } from 'src/app/components/suitability-main-features/suitability-main-features.component';
import { TokenDialogComponent } from 'src/app/components/token-dialog/token-dialog.component';
import { FilterDialogComponent } from "../../components/dialogs/filter-dialog/filter-dialog.component";
import { ReceiptDialogComponent } from "../../components/dialogs/receipt-dialog/receipt-dialog.component";
import { EmailDialogComponent } from "../../components/email-dialog/email-dialog.component";
import { PixChannelDialogComponent } from "../../components/pix-channel-dialog/pix-channel-dialog.component";
import { QrcodeDialogComponent } from "../../components/qrcode-dialog/qrcode-dialog.component";
import { SelfieInfoDialogComponent } from "../../components/selfie-info-dialog/selfie-info-dialog.component";
import { AccountModel } from '../interfaces/account-model';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  @Output() descriptionPage: BehaviorSubject<string> = new BehaviorSubject<string>('');

  bsModalRef: BsModalRef;
  modalOptions: ModalOptions;
  constructor(
    public modalService: BsModalService
  ) { }

  showConfirm(viewModel: ConfirmDialogViewModel) {
    this.modalOptions = {
      class: viewModel.class ? viewModel.class : 'modal-dialog-centered',
      backdrop: 'static',
      keyboard: false
    };

    this.bsModalRef = this.modalService.show(ConfirmDialogComponent, this.modalOptions);
    this.bsModalRef.content.subTitle = viewModel.subTitle;
    this.bsModalRef.content.msg = viewModel.msg;

    if (viewModel.title || viewModel.subTitle)
      this.bsModalRef.content.title = viewModel.title || viewModel.subTitle;

    if (viewModel.txtOk)
      this.bsModalRef.content.txtOk = viewModel.txtOk;

    if (viewModel.hiddenCancel)
      this.bsModalRef.content.hiddenCancel = viewModel.hiddenCancel;

    if (viewModel.txtCancel)
      this.bsModalRef.content.txtCancel = viewModel.txtCancel;

    if (viewModel.txtLink)
      this.bsModalRef.content.txtLink = viewModel.txtLink;

    return (this.bsModalRef.content as ConfirmDialogComponent).confirmResult;
  }

  showDialog(title: string, msg: string, custom?: string) {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: `modal-dialog-centered ${custom}`
    };
    this.bsModalRef = this.modalService.show(DialogComponent, this.modalOptions);
    this.bsModalRef.content.title = title;
    this.bsModalRef.content.msg = msg;

    return (this.bsModalRef.content as DialogComponent).confirmResult;
  }

  showPdfDialog(src: string, name) {
    this.modalOptions = {
      backdrop: true,
      keyboard: true,
      class: `modal-lg modal-dialog-centered`
    };
    this.bsModalRef = this.modalService.show(LinkDialogComponent, this.modalOptions);
    this.bsModalRef.content.src = src;
    this.bsModalRef.content.name = name;

    return (this.bsModalRef.content as LinkDialogComponent);
  }

  showDialogSuccess(viewModel: ConfirmDialogViewModel) {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: `modal-dialog-centered ${viewModel.class}`
    };
    this.bsModalRef = this.modalService.show(NewAlertDialogSuccessComponent, this.modalOptions);
    this.bsModalRef.content.msg = viewModel.msg;
    this.bsModalRef.content.subTitle = viewModel.subTitle;
    this.bsModalRef.content.txtLink = viewModel.txtLink;
    this.bsModalRef.content.tryAgain = false;
    this.bsModalRef.content.pixLimits = viewModel.showPixLimits

    if (viewModel.title)
      this.bsModalRef.content.title = viewModel.title;
    if (viewModel.txtOk)
      this.bsModalRef.content.txtOk = viewModel.txtOk;

    return (this.bsModalRef.content as NewAlertDialogSuccessComponent).confirmResult;
  }

  showDialogError(msg) {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: `modal-sm modal-dialog-centered`
    };
    this.bsModalRef = this.modalService.show(NewAlertDialogErrorComponent, this.modalOptions);
    this.bsModalRef.content.msg = msg;
    return (this.bsModalRef.content as NewAlertDialogErrorComponent).confirmResult;
  }

  showDialogTryAgain(viewModel: ConfirmDialogViewModel) {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(AlertDialogComponent, this.modalOptions);
    this.bsModalRef.content.success = false;
    this.bsModalRef.content.msg = viewModel.msg;
    this.bsModalRef.content.subTitle = viewModel.subTitle;
    this.bsModalRef.content.tryAgain = true;

    if (viewModel.txtOk)
      this.bsModalRef.content.txtOk = viewModel.txtOk;

    return (this.bsModalRef.content as AlertDialogComponent).confirmResult;
  }

  showDialogoSessionExpired(model?: any) {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: `modal-sm modal-dialog-centered`
    };
    this.bsModalRef = this.modalService.show(SessionExpiredComponent, this.modalOptions);
    if (model.title)
      this.bsModalRef.content.title = model.title;
    if (model.msg)
      this.bsModalRef.content.msg = model.msg;

    return (this.bsModalRef.content as SessionExpiredComponent).confirmResult;
  }

  showForgotPassword() {
    this.modalOptions = {
      class: 'modal-lg modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(ForgotPasswordComponent, this.modalOptions);
    return (this.bsModalRef.content as ForgotPasswordComponent).confirmResult;
  }

  showTokenDialog() {
    this.modalOptions = {
      class: 'modal-md modal-dialog-centered',
      backdrop: 'static',
      keyboard: false
    };
    this.bsModalRef = this.modalService.show(TokenDialogComponent, this.modalOptions);
    return (this.bsModalRef.content as TokenDialogComponent).confirmResult;
  }

  showEmailDialog() {
    this.modalOptions = {
      class: 'modal-md modal-dialog-centered',
      backdrop: 'static',
      keyboard: false
    };
    this.bsModalRef = this.modalService.show(EmailDialogComponent, this.modalOptions);
    return (this.bsModalRef.content as EmailDialogComponent).confirmResult;
  }

  showFormAddForecast(type, subTitle) {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(FormDialogComponent, this.modalOptions);
    this.bsModalRef.content.type = type;
    this.bsModalRef.content.title = 'Adicionar uma nova previsão';
    this.bsModalRef.content.subTitle = subTitle;
    this.bsModalRef.content.modalType = 'add-forecast'
    return (this.bsModalRef.content as FormDialogComponent).confirmResult;
  }

  showFormImportForecast() {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(FormDialogComponent, this.modalOptions);
    this.bsModalRef.content.title = 'Importar previsão';
    this.bsModalRef.content.subTitle = 'Escolhe aqui uma das previsões já salvas. Você pode aproveitar e incluir também uma das previsões que gravamos a partir da internet.';
    this.bsModalRef.content.modalType = 'import-forecast'
    return (this.bsModalRef.content as FormDialogComponent).confirmResult;
  }

  editFavoriteDialog(model) {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(EditFavoriteDialogComponent, this.modalOptions);
    this.bsModalRef.content.model = model;
    return (this.bsModalRef.content as EditFavoriteDialogComponent).confirmResult;
  }

  editScheduleTransferDialog(model, balance) {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(EditScheduledTransfersComponent, this.modalOptions);
    this.bsModalRef.content.model = model;
    this.bsModalRef.content.balance = balance;
    return (this.bsModalRef.content as EditScheduledTransfersComponent).confirmResult;
  }

  showCategoryDialog(name) {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered'
    };

    this.bsModalRef = this.modalService.show(CategoryDialogComponent, this.modalOptions);
    this.bsModalRef.content.title = 'Editar Categoria';
    this.bsModalRef.content.subTitle = 'Defina a categoria desta transação.';
    this.bsModalRef.content.name = name;
    return (this.bsModalRef.content as CategoryDialogComponent).confirmResult;
  }

  showGoalDialog() {
    this.modalOptions = {
      class: 'modal-lg modal-dialog-centered',
      backdrop: 'static',
      keyboard: false
    };
    this.bsModalRef = this.modalService.show(GoalDialogComponent, this.modalOptions);

    return (this.bsModalRef.content as GoalDialogComponent).confirmResult;
  }

  showPlansDialog() {
    this.modalOptions = {
      class: 'modal-lg modal-dialog-centered',
      backdrop: 'static',
      keyboard: false
    };
    this.bsModalRef = this.modalService.show(PlansDialogComponent, this.modalOptions);

    return (this.bsModalRef.content as PlansDialogComponent).confirmResult;
  }

  showSnackBar(msg: string, type, timeout = 5000) {
    const snackbar = document.getElementById('snackbar');
    snackbar.className = `show ${type}`;
    snackbar.firstElementChild.innerHTML = msg;
    setTimeout(function () { snackbar.className = ''; }, timeout);
  }

  editPersonalData() {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(PersonalDataDialogComponent, this.modalOptions);
    return (this.bsModalRef.content as PersonalDataDialogComponent).confirmResult;
  }

  editFilesData() {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(MyFilesDialogComponent, this.modalOptions);
    return (this.bsModalRef.content as MyFilesDialogComponent).confirmResult;
  }

  editContactData() {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(ContactDialogComponent, this.modalOptions);
    return (this.bsModalRef.content as ContactDialogComponent).confirmResult;
  }

  showAddressCard() {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(AddressCardDialogComponent, this.modalOptions);
    return (this.bsModalRef.content as AddressCardDialogComponent).confirmResult;
  }

  duplicateCard(cardType) {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(DuplicateCardDialogComponent, this.modalOptions);
    this.bsModalRef.content.cardType = cardType;
    return (this.bsModalRef.content as DuplicateCardDialogComponent).confirmResult;
  }

  manageCardLimit(model) {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(ManageCardLimitComponent, this.modalOptions);
    this.bsModalRef.content.minimumBalance = model.minimumBalance;
    this.bsModalRef.content.accountBalance = model.accountBalance;
    this.bsModalRef.content.investmentBalance = model.investmentBalance;
  }

  showAssetDialog() {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-lg modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(AssetDialogComponent, this.modalOptions);
    return (this.bsModalRef.content as AssetDialogComponent).confirmResult;
  }

  showAssetChoiceDialog(option, actualWalletDataset) {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-xl modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(AssetChoiceDialogComponent, this.modalOptions);
    this.bsModalRef.content.assetOption = option;
    this.bsModalRef.content.actualWalletDataset = actualWalletDataset;
    return (this.bsModalRef.content as AssetChoiceDialogComponent).confirmResult;
  }

  showPixDialog() {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(PixDialogComponent, this.modalOptions);
    return (this.bsModalRef.content as PixDialogComponent).confirmResult;
  }

  showQrCodeDialog(qr_code, key, amount) {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(QrcodeDialogComponent, this.modalOptions);
    this.bsModalRef.content.qrCode.next(qr_code);
    this.bsModalRef.content.key.next(key);
    this.bsModalRef.content.amount.next(amount);
    return (this.bsModalRef.content as QrcodeDialogComponent).confirmResult;
  }

  optimizeIdealPortfolio(rb_account_id?: string) {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(OptimizeIdealPortfolioComponent, this.modalOptions);
    this.bsModalRef.content.rb_account_id = rb_account_id
    return (this.bsModalRef.content as OptimizeIdealPortfolioComponent).confirmResult;
  }

  showSuitabilityMainFeatures(profile: string) {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(SuitabilityMainFeaturesComponent, this.modalOptions);
    this.bsModalRef.content.actualProfileName = profile;
  }

  showPasswordCard(model: any) {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(PasswordCardDialogComponent, this.modalOptions);
    this.bsModalRef.content.model = model
    return (this.bsModalRef.content as PasswordCardDialogComponent).confirmResult;
  }

  editPatrimonyDialog(item) {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(EditPatrimonyDialogComponent, this.modalOptions);
    this.bsModalRef.content.item = item
    return (this.bsModalRef.content as EditPatrimonyDialogComponent).confirmResult;
  }

  showDialogBrokers() {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(BrokerDialogComponent, this.modalOptions);
    return (this.bsModalRef.content as BrokerDialogComponent).confirmResult;
  }

  showOptionsCardDialog(model: any) {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(OptionsCardDialogComponent, this.modalOptions);
    this.bsModalRef.content.model = model
    return (this.bsModalRef.content as OptionsCardDialogComponent).confirmResult;
  }

  invalidGoalsDialog() {
    this.modalOptions = {
      class: 'modal-lg modal-dialog-centered',
      backdrop: 'static',
      keyboard: false
    };
    this.bsModalRef = this.modalService.show(InvalidGoalDialogComponent, this.modalOptions);
    return (this.bsModalRef.content as InvalidGoalDialogComponent).listGoals;
  }

  favoritePixDialog() {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-lg modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(FavoriteDialogComponent, this.modalOptions);
    return (this.bsModalRef.content as FavoriteDialogComponent).confirmResult;
  }

  confirmTransfer(pix, data, additional_message) {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-lg modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(ConfirmTransferDialogComponent, this.modalOptions);
    this.bsModalRef.content.pix.next(pix);
    this.bsModalRef.content.data.next(data);
    this.bsModalRef.content.msg = additional_message
    return (this.bsModalRef.content as ConfirmTransferDialogComponent).confirmResult;
  }

  confirmPayment(data) {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-lg modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(ConfirmPaymentDialogComponent, this.modalOptions);
    this.bsModalRef.content.loaded_data = data
    return (this.bsModalRef.content as ConfirmPaymentDialogComponent).confirmResult;
  }

  showPixChannelDialog() {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-xl modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(PixChannelDialogComponent, this.modalOptions);
  }


  showSelfieInfoDialog() {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-lg modal-dialog-centered'
    };

    this.bsModalRef = this.modalService.show(SelfieInfoDialogComponent, this.modalOptions);

    return (this.bsModalRef.content as ConfirmDialogComponent).confirmResult;
  }

  showPixReverse(data, balanceAccount) {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(PixReverseDialogComponent, this.modalOptions)
    this.bsModalRef.content.model = data.model
    this.bsModalRef.content.step = data.step

    if (data.transactionId)
      this.bsModalRef.content.transactionId = data.transactionId

    this.bsModalRef.content.balanceAccount = balanceAccount
    return (this.bsModalRef.content as PixReverseDialogComponent).confirmResult
  }

  showFilter(filter_options) {
    this.modalOptions = {
      backdrop: true,
      keyboard: true,
      class: 'modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(FilterDialogComponent, this.modalOptions)
    this.bsModalRef.content.filter_options.next(filter_options);

    return (this.bsModalRef.content as FilterDialogComponent).filter_data;
  }

  showReceipt(receipt_data, account_data, client_data?) {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered modal-xl'
    };
    this.bsModalRef = this.modalService.show(ReceiptDialogComponent, this.modalOptions)
    this.bsModalRef.content.client = client_data;
    this.bsModalRef.content.account = account_data;
    this.bsModalRef.content.receipt = receipt_data;

    return (this.bsModalRef.content as ReceiptDialogComponent);
  }

  showCustomLoansDialog() {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-lg modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(CustomLoansDialogComponent, this.modalOptions)
    return (this.bsModalRef.content as CustomLoansDialogComponent).confirmResult
  }

  modalSimulateValue() {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered'
    };

    this.bsModalRef = this.modalService.show(SimulateValueDialogComponent, this.modalOptions)
    return (this.bsModalRef.content as SimulateValueDialogComponent).confirmResult
  }

  showAcceptTerm() {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-lg modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(AcceptTermDialogComponent, this.modalOptions)
    return (this.bsModalRef.content as AcceptTermDialogComponent).confirmResult
  }

  showGoalInsight(model) {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(GoalInsightDialogComponent, this.modalOptions)
    this.bsModalRef.content.model = model
    return (this.bsModalRef.content as GoalInsightDialogComponent).confirmResult
  }

  dialogCloseAccount(balance: number) {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: `modal-lg modal-dialog-centered`
    };
    this.bsModalRef = this.modalService.show(CloseAccountComponent, this.modalOptions);
    this.bsModalRef.content.balance = balance;

    return (this.bsModalRef.content as CloseAccountComponent).confirmResult;
  }

  dialogWithoutAccounts(model?) {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(DialogWithoutAccountsComponent, this.modalOptions)
    this.bsModalRef.content.model = model
  }

}
