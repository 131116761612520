<img id="{{idFile}}-img" *ngIf="previewImg" [ngStyle]="{content : srcImg, cursor: 'zoom-in'}"
  (click)="showModal = !showModal" class="mb-5" alt="">
<div *ngIf="callout" class="m-0">{{ callout }}</div>
<div class="upload d-flex align-items-center" [ngClass]="classUpload">
  <span class="link" [ngClass]="classLabelFile ? classLabelFile : 'small'" (click)="inputFile.click()">{{file ?
    file.name : labelFile}}</span>
  <div *ngIf="showIcon" class="icon clip"></div>
  <img *ngIf="textTooltip" src="assets/images/svg/help-circle.svg" class="help" alt="help" [tooltip]="textTooltip">
  <input type="file" class="custom-file-input" [id]="idFile" style="height: 0; width: 0;" #inputFile>
  <br>
  <div class="progress" *ngIf="progress > 0">
    <div class="progress-bar" role="progressbar" [style.width.%]="progress"></div>
  </div>
</div>
<div *ngIf="requiredFileText && requiredFile" class="required-file">{{ requiredFileText }}</div>

<!-- Modal feito somente com bootstrap -->
<div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body" (click)="showModal = !showModal">
        <img [ngStyle]="{content : srcImg}" alt="">
      </div>
    </div>
  </div>
</div>