import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApisEnum } from '../enums/apis.enum';
import { EnumFinancial } from '../enums/situation-financial.enum'
import { AppUpdateService } from './app-update.service';
import { StorageService as storage } from './storage.service';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class FinancialService {

  goals = [
    {
      value: 'car',
      name: 'Carro',
      icon: 'icon-transporte',
      priority: 1,
      numeraire: 'BRL',
      contem: false
    },
    {
      value: 'education',
      name: 'Educação',
      icon: 'icon-estudos',
      priority: 1,
      numeraire: 'CDI',
      contem: false
    },
    {
      value: 'travel',
      name: 'Viagens',
      icon: 'icon-viagens',
      priority: 1,
      numeraire: 'USD',
      contem: false
    },
    {
      value: 'house',
      name: 'Casa',
      icon: 'icon-moradia',
      priority: 1,
      numeraire: 'CDI',
      contem: false
    },
    {
      value: 'retirement',
      name: 'Aposentadoria',
      icon: 'icon-aposentadoria',
      priority: 1,
      numeraire: 'IPCA',
      contem: false
    },
    {
      value: 'wealth',
      name: 'Crescer',
      icon: 'icon-viagens',
      priority: 1,
      numeraire: 'CDI',
      contem: false
    },
    {
      value: 'emergency',
      name: 'Reserva',
      icon: 'icon-emergencia',
      priority: 1,
      numeraire: 'CDI',
      contem: false
    },
    {
      value: 'others',
      name: 'Personalizado',
      icon: 'icon-outros-gastos',
      priority: 1,
      numeraire: 'BRL',
      contem: false
    },
    {
      value: 'mariage',
      name: 'Casamento',
      icon: 'icon-casamento',
      priority: 1,
      numeraire: 'BRL',
      contem: false
    },
    {
      value: 'health',
      name: 'Saúde',
      icon: 'icon-saude',
      priority: 1,
      numeraire: 'IPCA',
      contem: false
    },
  ]

  constructor(
    private http: HttpClient,
    private router: Router,
    private utilService: UtilService,
    private appUpdateService: AppUpdateService
  ) { }


  /**
   * Check App Update
   * @param res Response
   */
  private checkAppUpdate(res: Response | any): Response {
    if (res.headers.has('x-appversion')) {
      this.appUpdateService.appVersion.emit(res.headers.get('x-appversion'));
    }
    return res;
  }

  getPlans() {
    return [
      { id: 1, title: 'Formando um casal', group_id: 0, selected: false },
      { id: 2, title: 'Aumentando a família', group_id: 0, selected: false },
      { id: 3, title: 'Educação das crianças ou outros queridos', group_id: 0, selected: false },
      { id: 4, title: 'Separação do casal', group_id: 0, selected: false },
      { id: 5, title: 'Filhos saindo de casa', group_id: 0, selected: false },
      { id: 6, title: 'Filhos adultos voltando para casa', group_id: 0, selected: false },
      { id: 7, title: 'Pais ou outros queridos voltando para casa', group_id: 0, selected: false },
      { id: 8, title: 'Cuidando de um ente querido', group_id: 0, selected: false },
      { id: 9, title: 'Doença ou acidente', group_id: 0, selected: false },
      { id: 10, title: 'Perda de um familiar querido', group_id: 0, selected: false },
      { id: 11, title: 'Recebeu uma herança', group_id: 0, selected: false },
      { id: 12, title: 'Primeiro emprego', group_id: 1, selected: false },
      { id: 13, title: 'Trabalhando tempo parcial', group_id: 1, selected: false },
      { id: 14, title: 'Saiu do emprego', group_id: 1, selected: false },
      { id: 15, title: 'Começando um negócio', group_id: 1, selected: false },
      { id: 16, title: 'Vendendo um negócio', group_id: 1, selected: false },
      { id: 17, title: 'Antecipar aposentadoria', group_id: 1, selected: false },
      { id: 18, title: 'Aposentar', group_id: 1, selected: false },
      { id: 19, title: 'Cuidando de um ente querido', group_id: 2, selected: false },
      { id: 20, title: 'Doença ou acidente', group_id: 2, selected: false },
      { id: 21, title: 'Ficando velho', group_id: 2, selected: false },
      { id: 22, title: 'Comprando uma casa (e/ou troca)', group_id: 3, selected: false },
      { id: 23, title: 'Reforma de casa ', group_id: 3, selected: false },
      { id: 24, title: 'Diminuindo a casa', group_id: 3, selected: false },
      { id: 25, title: 'Perda de patrimônio por desastre natural', group_id: 3, selected: false },
      { id: 26, title: 'Comprando um carro', group_id: 3, selected: false },
      { id: 27, title: 'Comprando outro bem de valor', group_id: 3, selected: false },
    ]
  }

  addPriority(situation) {
    this.clearGoals();
    //Passar pela lista de situações selecionadas
    for (let j of situation) {

      let arrPriority = this.searchPrioritySituation(j.id);
      let order = 1;
      //Pegar a ordem de prioridades
      for (let i of arrPriority) {
        //Setar a order de prioridade e true para exibir o item
        for (let h of this.goals) {
          if (i == h.value) {
            h.priority = order;
            h.contem = true;
          }
        }
        order++;
      }

    }

    this.goals.sort(function (x, y) {
      return x.priority - y.priority;
    })
  }

  clearGoals() {
    this.goals.forEach(element => {
      element.contem = false;
    });
  }

  searchPrioritySituation(situation) {
    switch (situation) {
      case EnumFinancial.FormingCouple: //Formando um Casal
        return ['mariage', 'house', 'education', 'car', 'travel', 'emergency', 'retirement', 'health']
      case EnumFinancial.RaisingFamily: //Aumentando a família
        return ['health', 'emergency', 'house', 'car', 'education', 'retirement']
      case EnumFinancial.EducationChildren: //Educação das crianças ou outros queridos
        return ['education', 'health', 'emergency', 'retirement', 'travel']
      case EnumFinancial.SeparationCouple: //Separação do casal
        return ['house', 'emergency', 'health', 'retirement']
      case EnumFinancial.ChildrenLeavingHome: //Crianças saindo de casa
        return ['retirement', 'health', 'wealth', 'travel']
      case EnumFinancial.AdultChildrenComingHome: //Filhos adultos voltando para casa
        return ['retirement', 'health', 'house', 'wealth']
      case EnumFinancial.CountryComingHome: //País ou outros queridos voltando para casa
        return ['emergency', 'retirement', 'health']
      case EnumFinancial.CaringLovedOneFamily: //Cuidando de um ente querido
        return ['health', 'emergency', 'retirement']
      case EnumFinancial.IllnessOrAccidentFamily: //Doença ou acidente
        return ['health', 'emergency', 'retirement']
      case EnumFinancial.LossOfFamily: //Perda de um familiar querido 
        return ['health', 'retirement', 'emergency', 'travel', 'others']
      case EnumFinancial.ReceivedAnInheritance: //Recebeu uma herança
        return ['retirement', 'wealth', 'travel']
      case EnumFinancial.FirstJob: //Primeiro emprego
        return ['travel', 'wealth', 'education']
      case EnumFinancial.WorkingPartTime: //Trabalhando tempo parcial
        return ['emergency', 'wealth', 'education']
      case EnumFinancial.QuitYourJUob: //Saiu do emprego
        return ['emergency', 'education', 'retirement']
      case EnumFinancial.StartingABusiness: //Começando um negócio
        return ['emergency', 'wealth', 'health']
      case EnumFinancial.SellingABusiness: //Vendendo um negócio
        return ['retirement', 'wealth', 'emergency', 'health', 'others']
      case EnumFinancial.AnticipateRetirement: //Antecipar Aposentadoria
        return ['emergency', 'wealth', 'health']
      case EnumFinancial.Retire: //Aposentar
        return ['health', 'emergency', 'wealth']
      case EnumFinancial.CaringLovedOneHealth: //Cuidando de um ente querido
        return ['emergency', 'health', 'retirement']
      case EnumFinancial.IllnessOrAccidentHeath: //Doença ou acidente
        return ['health', 'emergency', 'retirement']
      case EnumFinancial.GettingOld: //Ficando velho
        return ['health', 'emergency', 'retirement', 'wealth']
      case EnumFinancial.BuyingHouse: //Comprando uma casa (e troca)
        return ['house', 'wealth']
      case EnumFinancial.HomeReform: //Reforma de casa 
        return ['house', 'emergency', 'wealth']
      case EnumFinancial.DecreasingTheHouse: //Diminuindo a casa
        return ['emergency', 'wealth']
      case EnumFinancial.LossNaturalDisaster: //Perda de patrimônio por desastre natural
        return ['emergency', 'house', 'wealth']
      case EnumFinancial.BuyingACar: //Comprando um carro
        return ['car', 'wealth']
      case EnumFinancial.BuyingAnothergoodvalue: //Comprando outro bem de valor
        return ['wealth', 'others']
      default:
        break;
    }
  }

  /**
   * Create a Client Goal
   * @param data Data Goals
   * @param jwt Client JWT
   */
  createFinancial(client_uuid: string, data: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri(['/client', client_uuid, 'pms/goal', { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    const dataPost = this.utilService.buildPostData(data);

    return this.http.post(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Update a Client Goal
   * @param data Data Client Goal
   * @param jwt Client JWT
   */
  updateGoal(client_uuid: string, goal_id: number, data: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri(['/client', client_uuid, 'pms/goal', goal_id, { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    const dataPost = this.utilService.buildPostData(data);

    return this.http.put(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Update a Client Goal
   * @param data Data Client Goal
   * @param jwt Client JWT
   */
  deleteGoal(client_uuid: string, goal_id: number, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri(['/client', client_uuid, 'pms/goal', goal_id, { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    return this.http.delete(url, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Get Client Goals
   * @param client_uuid Client UUID 
   * @param jwt Client JWT
   */
  getPlansBack(client_uuid: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri(['/client', client_uuid, 'pms/goals', { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Get Client Goal
   * @param client_uuid Client UUID 
   * @param jwt Client JWT
   */
  getPlan(client_uuid: string, goal_id: number, data: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri(['/client', client_uuid, 'pms/goal', goal_id, { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  orderList(list) {
    return list.sort(function (x, y) {
      return x.priority - y.priority;
    });
  }

  getImgGoal(type) {
    switch (type) {
      case 'car':
        return 'bg-mini-car';
      case 'education':
        return 'bg-mini-studies';
      case 'travel':
        return 'bg-mini-travels';
      case 'house':
        return 'bg-mini-house';
      case 'emergency':
        return 'bg-mini-emergency';
      case 'mariage':
        return 'bg-mini-marriage';
      case 'retirement':
        return 'bg-mini-retirement';
      case 'other':
        return 'bg-mini-custom';
      case 'wealth':
        return 'bg-mini-growth';
      case 'health':
        return 'bg-mini-health';

      default:
        break;
    }
  }

  getColorGoal(type) {
    switch (type) {
      case 'car':
        return '#05c4a3';
      case 'education':
        return '#8939ad';
      case 'travel':
        return '#fe2865';
      case 'house':
        return '#3546ab';
      case 'emergency':
        return '#267a48';
      case 'mariage':
        return '#f17048';
      case 'retirement':
        return '#0075fc';
      case 'other':
        return '#F0A174';
      case 'wealth':
        return '#ff829d';
      case 'health':
        return '#26a69a';
      default:
        break;
    }
  }

  getTitleGoal(type) {
    switch (type) {
      case 'car':
        return '#05c4a3';
      case 'education':
        return '#8939ad';
      case 'travel':
        return '#fe2865';
      case 'house':
        return '#3546ab';
      case 'emergency':
        return '#267a48';
      case 'mariage':
        return '#f17048';
      case 'retirement':
        return '#0075fc';
      case 'other':
        return '#F0A174';
      case 'wealth':
        return '#ff829d';
      case 'health':
        return '#26a69a';
      default:
        break;
    }
  }

  getCurrency(type) {
    switch (type) {
      case 'car':
        return 'BRL';
      case 'education':
        return 'CDI';
      case 'travel':
        return 'USD';
      case 'house':
        return 'CDI';
      case 'emergency':
        return 'CDI';
      case 'mariage':
        return 'BRL';
      case 'retirement':
        return 'IPCA';
      case 'other':
        return 'IPCA';
      case 'wealth':
        return 'CDI';
      case 'health':
        return 'IPCA';
      default:
        break;
    }
  }


  /**
   * Create a Client Goal
   * @param data Data Goals
   * @param jwt Client JWT
   */
  optimizePortfolio(client_uuid: string, data: any, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri(['/client', client_uuid, 'pms/optimize', { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(url, data, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => {
        this.utilService.handleError(err, this.router)
        return err
      });
  }

  /**
   * Apply Allocation Simulated to Model
   * @param client_uuid Client UUID
   * @param allocation_id Allocation Simulated ID
   * @param jwt Client JWT
   * @returns 
   */
  applyAllocationSimulatedToModel(client_uuid: string, allocation_id: number, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri(['/client', client_uuid, 'pms', 'allocation', allocation_id, { jwt: jwt || storage.getClientJwt() }]);

    const dataPost = {};

    return this.http.put(url, dataPost, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => {
        this.utilService.handleError(err, this.router)
        return err
      });
  }

  getThemes() {
    const url = this.utilService.makeUri([ApisEnum.Partner, 'pms', 'investment-themes', { jwt: storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => { return this.utilService.handleError(err, this.router) });
  }

}
