export enum AssetColorsEnum {
  'direct-treasure' = '#E6A844',
  'fixed-income' = '#5FA1ED',
  'public-fixed-income' = '#B8F3F0',
  'private-fixed-income' = '#BFD9F8',
  'variable-income' = '#F26577',
  'exchange' = '#D5D9DA',
  'investment-fund' = '#4EE1DA',
  'retirement' = '#9060D2',
  'actions' = '#EAE1B8',
  'bitcoin' = '#E97737',
  'savings' = '#49C3BC',
  'derivative' = '#A7A7A7',
  'real-estate-funds' = '#F7F3E3',
  'american-shares' = '#EDC2FC',
  'others' = '#89a772',
  'index' = '#e30c76',
  'multimarket' = '#673cfa',

  'tesouro direto' = '#E6A844',
  'renda fixa' = '#5FA1ED',
  'renda fixa pública' = '#B8F3F0',
  'renda fixa privada' = '#BFD9F8',
  'renda variável' = '#F26577',
  'câmbio' = '#D5D9DA',
  'fundos de investimentos' = '#4EE1DA',
  'aposentadoria' = '#9060D2',
  'ações e etfs' = '#EAE1B8',
  'criptomoedas' = '#E97737',
  'poupança' = '#49C3BC',
  'derivativo' = '#A7A7A7',
  'fundos imobiliários' = '#F7F3E3',
  'ações americanas' = '#EDC2FC',
  'outros' = '#89a772',
  'índice' = '#e30c76',
  'multimercado' = '#673cfa'
}