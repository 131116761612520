import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Subject } from 'rxjs';
import { Utils } from 'src/app/shared/commons/Utils';
import { AccountTypeEnum } from 'src/app/shared/enums/account-type.enum';
import { StorageService as storage } from 'src/app/shared/services/storage.service';
import { TransferTypeEnum } from 'src/app/shared/enums/transfer-type.enum';

@Component({
  selector: 'app-confirm-transfer-dialog',
  templateUrl: './confirm-transfer-dialog.component.html',
  styleUrls: ['./confirm-transfer-dialog.component.scss']
})
export class ConfirmTransferDialogComponent implements AfterViewInit {

  @Input() data = new BehaviorSubject<any>([]);
  @Input() pix = new BehaviorSubject<any>([]);
  @Input() msg: any
  bankListIspb = storage.get('ListBanks');
  loaded_data: any;
  loaded_pix;
  today = new Date

  confirmResult: Subject<boolean>

  constructor(public modalRef: BsModalRef) { }

  ngAfterViewInit(): void {
    this.confirmResult = new Subject()

    this.data.subscribe(res => {
      this.loaded_data = res;
    });

    this.pix.subscribe(res => {
      this.loaded_pix = res;
    })
  }

  maskDocument(value) {
    return Utils.maskCpfCnpj(value)
  }

  bankName(bank_code, ispbName) {
    if (ispbName) {
      return ispbName
    } else {
      const bank = this.bankListIspb.find(x => x.compe == bank_code || x.name == bank_code)
      return bank ? `${bank.compe} - ${bank.name}` : ''
    }
  }

  onConfirm(): void {
    this.confirmResult.next(true)
    this.modalRef.hide()
  }

  get TransferType() { return TransferTypeEnum }

  get AccountType() { return AccountTypeEnum }
}
