import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {
  @Output() appVersion: EventEmitter<string> = new EventEmitter();

  constructor() { }
}
