<div class="modal-content">
  <div class="modal-header border-0">
    <span class="medium h1 w-100 m-0">Solicitação de cartão</span>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span class="material-icons-outlined">highlight_off</span>
    </button>
  </div>
  <div class="modal-body pt-0">    
    <div class="small mb-3">Por favor, informe aqui para onde devemos enviar seu cartão.
      Verifique-se que todos os detalhes, como o complemento, estão corretos.</div>
    <form [formGroup]="formGroup" class="row" autocomplete="off" novalidate>
      <div class="form-group col-4">
        <label for="zip_code">CEP</label>
        <input type="text" class="form-control" id="zip_code" name="zip_code" Mask="#####-###"
          formControlName="zip_code" (keyup)="getZipCode($event)">
        <invalid-feedback [field]="f.zip_code" label="CEP"></invalid-feedback>
      </div>
      <div class="form-group col-8">
        <label for="street">Endereço</label>
        <input type="text" class="form-control" id="street" name="street" formControlName="street" maxlength="128">
        <invalid-feedback [field]="f.street"></invalid-feedback>
      </div>
      <div class="form-group col-4">
        <label for="street_number">Número</label>
        <input type="text" OnlyNumber class="form-control" id="street_number" name="street_number" formControlName="street_number" maxlength="128">
        <invalid-feedback [field]="f.street_number"></invalid-feedback>
      </div>
      <div class="form-group col-8">
        <label for="complement">Complemento</label>
        <input type="text" class="form-control" id="complement" name="complement" formControlName="complement" maxlength="128">
        <invalid-feedback [field]="f.complement"></invalid-feedback>
      </div>
      <div class="form-group col-12">
        <label for="district">Bairro</label>
        <input type="text" class="form-control" id="district" name="district" formControlName="district" maxlength="128">
        <invalid-feedback [field]="f.district"></invalid-feedback>
      </div>
      <div class="form-group col-9">
        <label for="city">Cidade</label>
        <input type="text" id="city" name="city" class="form-control" formControlName="city"
          maxlength="128">
        <invalid-feedback [field]="f.city"></invalid-feedback>
      </div>
      <div class="form-group col-3">
        <label for="state">Estado</label>
        <input type="text" class="form-control" id="state" name="state" formControlName="state" maxlength="200">
        <invalid-feedback [field]="f.state"></invalid-feedback>
      </div>
      <div class="col-12">
        <p class="poppins small">*A primeira emissão do cartão não possui <span class="highlight normal">nenhum custo</span>.</p>
      </div>
    </form>
    <div class="d-flex justify-content-between">
      <button type="button" class="btn btn-outline-primary px-5 mx-0" (click)="close(false)">CANCELAR</button>
      <button type="submit" class="btn btn-primary px-5 mx-0" (click)="next()">CONTINUAR</button>
    </div>
  </div>
</div>