import { UploadFileService } from './../upload/upload-file.service';
import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DialogService } from "../../shared/services/dialog.service";

@Component({
  selector: 'upload-cropped',
  templateUrl: './upload-cropped.component.html',
  styleUrls: ['./upload-cropped.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: UploadCroppedComponent,
      multi: true
    }
  ]
})
export class UploadCroppedComponent implements ControlValueAccessor {

  @Input() idFile = 'upload-cropped';
  @Input() labelFile = 'Selecionar arquivo';
  @Input() accept: any = []
  @Input() size = 5; // valor em MB
  @Input() type: string;
  @Input() subType: string; // Texto explicativo
  @Input() requiredFile: boolean = null; // Control Show Required Text
  @Input() requiredFileText: string = null; // Text Required File
  @Input() textTooltip = '';
  @Input() uploadCustomPhoto: string
  @Input() src: string | ArrayBuffer = '';
  @Input() classUpload = 'upload input-group align-items-center justify-content-center'
  @Input() classButton = 'btn btn-primary btn-circle mt-4 py-2'
  @Input() classLabelFile = ''
  @Input() buttonText = '+'
  @Input() showIcon = false
  @Output() CompletedUpload = new EventEmitter();
  @Output() ChangeImageCropper = new EventEmitter();


  showImageCropper = false
  showModal = false
  croppedImage: any = {};
  imageChangedEvent: any = {};
  maxSize: number
  progress: number;
  srcImg: string;

  onChange: Function;
  file: File | null = null;

  constructor(
    private host: ElementRef<HTMLInputElement>,
    private service: UploadFileService,
    private dialogService: DialogService,
  ) { }

  writeValue(): void {
    this.host.nativeElement.value = '';
    // this.file = null;
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // throw new Error('Method not implemented.');
  }

  setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.srcImg = this.src ? `url(${this.src})` : null;
    this.maxSize = this.size * 1024000;

    this.service.emitterChangeFile.subscribe(
      file => {
        if (file.id === this.idFile && file.src) {
          this.srcImg = `url(${file.src})`;
          this.requiredFileText = ''
        } else if (file.id === this.idFile && file.label_file) {
          this.labelFile = file.labelFile;
        }
      }
    );
  }

  cancel(file) {
    this.showImageCropper = false
    this.requiredFileText = '(Campo obrigatório)'
    file.value = null
    this.ChangeImageCropper.emit(this.showImageCropper)
  }

  fileChangeEvent(event: any): void {
    const file = event.currentTarget.files[0]
    if (file.size < this.maxSize && this.accept.includes(file.type)) {
      const change = file && file.name;
      this.onChange(change)
      this.imageChangedEvent = event;
      this.showImageCropper = true
      this.requiredFileText = ''
      this.ChangeImageCropper.emit(this.showImageCropper)
    } else {
      this.requiredFileText = `Apenas arquivos PNG, JPG, JPEG e PDF. Tamanho máximo ${this.size}MB.`
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  croppedSubmit(event) {
    const blob = this.service.base64ToFile(this.croppedImage);
    if (blob.size < this.maxSize && this.accept.includes(blob.type)) {
      this.onSubmit(blob, event.files[0]);
    } else {
      this.requiredFileText = `Apenas arquivos PNG, JPG, JPEG e PDF. Tamanho máximo ${this.size}MB.`
    }
  }

  onSubmit(blob: any, file: File) {

    this.service.create(file, blob, this.type, this.subType).subscribe((event: HttpEvent<any>) => {
      try {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            this.progress = Math.round((100 * event.loaded) / event.total);
            break;
          case HttpEventType.Response:
            this.progress = 0;
            this.showImageCropper = false
            this.srcImg = `url(${event.body.url_path})`
            this.file = file
            this.ChangeImageCropper.emit(this.showImageCropper)
            this.CompletedUpload.emit(file);
            break
        }
      } catch (error) {
        console.error(error);
        this.progress = 0;
      }
    })
  }

  addSelfie() {
    this.dialogService.showSelfieInfoDialog().subscribe(res => {
      if (res) {
        document.getElementById("photo").click();
      }
    });
  }
}
