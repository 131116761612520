import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { AccountPlans } from 'src/app/shared/commons/AccountPlans';

@Component({
  selector: 'app-confirm-payment-dialog',
  templateUrl: './confirm-payment-dialog.component.html',
  styleUrls: ['./confirm-payment-dialog.component.scss']
})
export class ConfirmPaymentDialogComponent implements OnInit {

  confirmResult: Subject<boolean>
  loaded_data: any
	accountPlans: any = AccountPlans.get();
  cost = 0
  
  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {
    this.confirmResult = new Subject()
    this.cost = this.accountPlans.find(x => x.name == 'Pagamento de boleto').value
  }

  onConfirm() {
    this.confirmResult.next(true)
    this.modalRef.hide()
  }
}
