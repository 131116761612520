<div class="modal-content">
    <div class="container">
        <div class="modal-header border-0">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span class="material-icons-outlined">highlight_off</span>
            </button>
        </div>
        <div class="row justify-content-md-center pb-5">
            <div class="col col-12 text-center">
                <p class="big h1">Confirmação</p>
            </div>
            <div class="col col-12 mx-5 text-center">
                <span class="small">Enviamos um código de confirmação ao seu </span><strong class="small-plus">email</strong>
            </div>
            <div class="col col-12 mx-5">
                <p class="small text-center">Verifique também se a sua mensagem não foi encaminhada para a pasta de SPAM.</p>
            </div>
            <form [formGroup]="formToken" class="row col col-12 mx-auto px-3 justify-content-center" autocomplete="off" novalidate>
                <div class="col col-12 px-0 col-md-8 text-center justify-content-center">
                    <input type="text" id="sms_code" name="sms_code" OnlyNumber placeholder="000000" class="form-control"
                           [(ngModel)]="sms_code" formControlName="sms_code" (keyup)="padLeft($event)">
                    <div *ngIf="(!f.sms_code.pristine || f.sms_code.touched) && f.sms_code.errors"
                         class="invalid-feedback x-small">
                        <div *ngIf="f.sms_code.errors.required"> Campo obrigatório. </div>
                        <div *ngIf="f.sms_code.errors.minlength"> No mínimo {{f.sms_code.errors.minlength.requiredLength}}
                            caracteres. </div>
                    </div>
                </div>
            </form>
            <div class="col col-12 text-center my-3">
                <button class="btn link" (click)="onResend()">
                    <icon-svg svgName="reload" class="float-left mr-2"></icon-svg>REENVIAR
                </button>
            </div>
            <div class="row col-12 mx-auto text-center justify-content-center">
                <button type="button" class="col col-12 col-md-5 btn btn-outline-dark" data-dismiss="modal" (click)="modalRef.hide()">CANCELAR</button>
                <button type="button" type="submit" class="col col-12 col-md-5 my-3 my-md-0 btn btn-primary" [disabled]="!formToken.valid"
                        (click)="onConfirm()">VALIDAR</button>
            </div>
        </div>
    </div>
</div>
