import { ClientModel } from './../interfaces/client-model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ConfirmDialogViewModel } from 'src/app/components/confirm-dialog/confirm-dialog.view-model';
import { environment as env } from '../../../environments/environment';
import { AppNavRouteLink } from '../commons/AppNavRouteLink';
import { ApisEnum } from '../enums/apis.enum';
import { AppConfigService } from './app-config.service';
import { DialogService } from './dialog.service';
import { StorageService as storage } from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class UtilService {

    model = new ConfirmDialogViewModel()
    helper = new JwtHelperService()

    constructor(
        private http: HttpClient,
        private confService: AppConfigService,
        private dialogService: DialogService
    ) {
    }

    without_account_routers: string [] = [
        AppNavRouteLink.NAV.DEPOSIT_BY_BANK_SLIP,
        AppNavRouteLink.NAV.EXCHANGE,
        AppNavRouteLink.NAV.MY_CARDS,
        AppNavRouteLink.NAV.MY_LOANS,
        AppNavRouteLink.PAGE.NOTIFICATIONS,
        AppNavRouteLink.TABS_ACCOUNT.AREA,
        AppNavRouteLink.TABS_ACCOUNT.EXTRACT,
        AppNavRouteLink.TABS_ACCOUNT.INSIGHT,
        AppNavRouteLink.TABS_ACCOUNT.PAYMENTS,
        AppNavRouteLink.TABS_ACCOUNT.TRANSFERS,        
        AppNavRouteLink.TABS_FINANCIAL_PLANNING.MY_RECIPES,
        AppNavRouteLink.TABS_FINANCIAL_PLANNING.FUTURE_PLANS,
        // AppNavRouteLink.TABS_FINANCIAL_PLANNING.HOW_TO_PLAN,
        // AppNavRouteLink.TABS_FINANCIAL_PLANNING.PATRIMONY,
        // AppNavRouteLink.TABS_FINANCIAL_PLANNING.GOALS,
        AppNavRouteLink.TABS_FINANCIAL_PLANNING.INSIGHT,
        AppNavRouteLink.TABS_FINANCIAL_PLANNING.BUDGET,
        AppNavRouteLink.TABS_INVESTMENT.SUMMARY,
        // AppNavRouteLink.TABS_INVESTMENT.CURRENT_PORTFOLIO,
        // AppNavRouteLink.TABS_INVESTMENT.MY_ORDERS,
        // AppNavRouteLink.TABS_INVESTMENT.IDEAL_PORTFOLIO,
        AppNavRouteLink.TABS_INVESTMENT.MY_THEMES,
        AppNavRouteLink.TABS_INVESTMENT.MY_EXPECTATIONS,
        // AppNavRouteLink.TABS_INVESTMENT.MY_WALLETS,
        AppNavRouteLink.TABS_INVESTMENT.PERFORMANCE,
        AppNavRouteLink.TABS_MY_DATA.ACCOUNT_PLAN,
        AppNavRouteLink.TABS_MY_DATA.AUTOMATIC_PILOT,
        AppNavRouteLink.TABS_PIX.AREA,
        AppNavRouteLink.TABS_PIX.TRANSFERS,
        AppNavRouteLink.TABS_PIX.PORTABILITY,
        AppNavRouteLink.TABS_PIX.RECEIVE,
        AppNavRouteLink.TABS_PIX.LIMITS,
        AppNavRouteLink.TABS_PIX.COPY_PASTE,
        AppNavRouteLink.TABS_PIX.PAY_QRCODE,
        AppNavRouteLink.TABS_PIX.EXTRACT,
        AppNavRouteLink.TABS_PIX.MY_KEYS,
        AppNavRouteLink.TABS_PIX.NOTIFICATIONS,
        AppNavRouteLink.TABS_SUMMARY.CURRENT_POSITION
    ]

    protected_routers: string[] = [
        AppNavRouteLink.TABS_SUMMARY.CURRENT_POSITION,
        AppNavRouteLink.TABS_ACCOUNT.INSIGHT,
        AppNavRouteLink.TABS_MY_DATA.AUTOMATIC_PILOT,
        AppNavRouteLink.TABS_INVESTMENT.SUMMARY,
        AppNavRouteLink.TABS_INVESTMENT.MY_ORDERS,
        AppNavRouteLink.TABS_INVESTMENT.MY_THEMES,
        AppNavRouteLink.TABS_INVESTMENT.MY_EXPECTATIONS,
        AppNavRouteLink.TABS_INVESTMENT.PERFORMANCE,
        AppNavRouteLink.NAV.DEPOSIT_BY_BANK_SLIP,
        AppNavRouteLink.NAV.EXCHANGE,
        AppNavRouteLink.NAV.MY_LOANS
    ];

    allowed_pix_users = [
        '5b00b297-4d93-4618-91f1-217bfb65ab8e',
        '60acd69b-c6b6-438a-88dd-21d69d9b6fc8',
        '82e139a8-b409-4c1e-a360-2d13c6ef7916',
        '86af5a2a-38a4-4aa3-9540-875dde4d63db',
        'f33480da-55d4-423d-9b3d-89802d0351ee',
        '47fa7e5e-699c-4d74-91d3-edc06d027f09',
        'a184d5fd-bd41-4712-a472-3ca36d27777f',
        '8352993a-c6c8-4120-af3a-6b0d54791bd1'
    ];

    /**
     * Check if Router is Protected
     * @param routeActive URL Route Active
     */
    routerIsProtected(routeActive: string, client?: ClientModel): boolean {
        const config = this.confService.config;
        const env = config['API_ENV'];

        if (!client?.has_bank_account && this.without_account_routers.includes(routeActive)) {
            return this.without_account_routers.includes(routeActive)
        }

        if (env !== 'production') {
            return false;
        }

        if (!routeActive || routeActive.length < 1) {
            return true;
        }

        let router_is_protected = this.protected_routers.includes(routeActive);

        // allowed clients
        if (client?.uuid && router_is_protected) {
            router_is_protected = !this.check_allowed_pix_users(client.uuid);
        }        

        return router_is_protected
    }

    functionProtected(): boolean {
        const config = this.confService.config;
        const apiEnv = config['API_ENV'];
        if (apiEnv == 'production') {
            return true;
        }

        return false
    }

    /**
     * Check Allowed PIX Users
     * @param uuid Client UUID
     * @returns
     */
    check_allowed_pix_users(uuid: string): boolean {
        return uuid && this.allowed_pix_users.includes(uuid);
    }

    /**
     * Make URI
     * @param data Data Path
     */
    makeUri(data: any[]): string {
        this.isAuthenticate()

        const config = this.confService.config;

        let url: string = config['API_HOST'];
        let qs = '';

        // port
        if ('API_PORT' in config && config['API_PORT'] !== 80 && config['API_PORT'] !== 443) {
            url += ':' + config['API_PORT'];
        }

        // base api
        url += '/' + config['API_BASE'];

        // data
        data.forEach((elem) => {
            if (!elem) {
                return;
            }
            if (typeof (elem) === 'object') {
                Object.keys(elem).sort().forEach((key) => {
                    qs += encodeURIComponent(key) + '=' + encodeURIComponent(elem[key]) + '&';
                });
            } else {
                url += '/' + elem;
            }
        });

        // double /
        url = url.replace(/\/{2,}/g, '/');

        // query string
        if (qs.length > 0) {
            qs = qs.replace(/\&$/, '');
            url += '?' + qs;
        }

        // protocolo http or https
        url = config['API_PROT'] + '://' + url;

        // debug
        if (env.production === false && config['API_DEBUG'] === true) {
            console.log('MAKE URI: ' + url);
        }
        return url;
    }

    /**
     * Builda data post
     * @param data Data for post
     */
    buildPostData(data: object): string {
        let fromData = '';
        if (data) {
            Object.keys(data).forEach((value) => {
                fromData += value + '=' + encodeURIComponent(data[value]) + '&';
            });
        }
        return fromData;
    }

    handleError(error: Response | any, router: Router): Promise<any> {
        this.model = new ConfirmDialogViewModel()
        if (error.status == 404 && error.error.errors.msg) {
            this.model.msg = error.error.errors.msg
        }
        // 401 erros authorization
        else if (error.status === 401 || error.status === 403) {
            const json = error.error['errors'] || error.error;
            let msg = json['msg'] || json['message'];
            if ('jwt' in json && json['jwt'].length > 0) {
                msg = json['jwt'][0];
            }

            // logout if expired token
            if (msg === 'expired token' || msg === 'jwt decode error' || msg === 'this jwt was revoked' || msg === 'this jwt is wrong'
                || msg === 'this jwt is wrong, invalid or missing') {
                storage.clearSection();
                return
            }

            this.model.msg = msg
        }

        if (this.model.msg)
            this.dialogService.showDialogError(this.model.msg)

        return Promise.reject(error);
    }

    /**
     * Get a controls dirty from form object
     * @param controls AbstractControl
     */
    dirty_controls_data(controls: any) {
        const data: Object = {};
        Object.keys(controls).forEach(name => {
            const field = controls[name];
            if (field.dirty) {
                data[name] = field.value;
            }
        });
        return data;
    }

    mark_controls_dirty(controls: any) {
        setTimeout(() => {
            Object.keys(controls).forEach(name => {
                const field = controls[name];
                if (field.valid) {
                    field.markAsDirty();
                }
            });
        }, 300);
    }

    mark_controls_touched(controls: any) {
        setTimeout(() => {
            Object.keys(controls).forEach(name => {
                const field = controls[name];
                field.markAsTouched();
            });
        }, 300);
    }

    refreshJWT(jwt?: string): Promise<any> {
        const url = this.makeUri([ApisEnum.Jwt, { jwt: jwt || storage.getClientJwt() }]);
        return this.http.put(url, {}, { observe: 'response' })
            .toPromise()
            .then((res) => {
                storage.save('is_authenticate', true)
                storage.saveClientJwt(res['body']['jwt'])
                return res;
            })
            .catch(err => {
                storage.save('is_authenticate', true)
                return err
            });
    }

    isAuthenticate() {
        const token = storage.getClientJwt();
        if (JSON.parse(storage.get('is_authenticate')) && this.isTokenExpired(token)) {
            storage.save('is_authenticate', false)
            this.refreshJWT()
        }
    }

    getTokenExpirationDate(token: string): Date {
        const decoded: any = this.helper.decodeToken(token);

        if (decoded.exp === undefined) {
            return null;
        }

        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    }

    isTokenExpired(token?: string): boolean {
        if (!token || token == 'undefined') {
            return true;
        }

        const date = this.getTokenExpirationDate(token);
        if (date === undefined) {
            return false;
        }

        return date.valueOf() <= new Date().valueOf();
    }
}
