import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApisEnum } from '../enums/apis.enum';
import { ClientService } from '../services/client.service';
import { CommonService } from '../services/common.service';
import { StorageService as storage } from '../services/storage.service';
import { PhoneService } from './../services/phone.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private clientService: ClientService,
    private phoneService: PhoneService,
    private commonService: CommonService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const jwt = storage.getClientJwt();

    if (!jwt) {
      storage.clear();
      this.router.navigate(['/login']);
      return false;
    }

    // check jwt
    return this.commonService.getJWTInfo(jwt)
      .then(res => {
        const is_client = res['type'] === 'client';
        if (is_client === false) {
          storage.clear();
          this.router.navigate(['/login']);
          return false;
        }

        // get client info
        let client_uuid = storage.getClientUuid();
        return this.clientService.getInfo(client_uuid)
          .then(res1 => {
            this.clientService.clientData.next(res1);
            client_uuid = res1['uuid'];
            this.getPhone(client_uuid)
            // get client main account
            if (res1.has_bank_account) {
              return this.clientService.getBankAccounts(client_uuid, { type: 'main_account' })
                .then(res2 => {
                  if (res2['accounts'].length < 1) {
                    console.error('Error get client main account');
                    this.clientService.clientData.next(null);
                    return false;
                  }
  
                  this.clientService.accountData.next(res2['accounts'][0]);
                  return true;
                })
                .catch(err => {
                  console.error(err);
                  return false;
                })
            }
          })
          .catch(err => {
            console.error(err);
            return false;
          })

      })
      .catch(err => {
        storage.clear();
        this.router.navigate(['/login']);
        return false;
      });
  }

  getPhone(client_uuid) {
    this.phoneService.read(ApisEnum.Client, client_uuid).then(result => {
      const phone = result.phones.find(p => p.is_verified)
      this.clientService.phoneData.next(phone)
    })
  }
}
