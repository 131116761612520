<app-custom-loader></app-custom-loader>
<router-outlet (deactivate)="onDeactivate()"></router-outlet>

<!-- The actual snackbar -->
<div id="snackbar">
  <span></span>
  <icon-svg svgName="check-circle" class="float-left" size="1.5"></icon-svg>
</div>

<div class="version">
  LadyBank<span *ngIf="appVersion"> - {{ appVersion }}</span><span> - API {{ apiVersion.version }}</span>
</div>