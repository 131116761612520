import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogViewModel } from '../components/confirm-dialog/confirm-dialog.view-model';
import { AppNavRouteLink } from '../shared/commons/AppNavRouteLink';
import { Validations } from '../shared/commons/Validations';
import { ApisEnum } from '../shared/enums/apis.enum';
import { ClientModel } from '../shared/interfaces/client-model';
import { PhoneModel } from '../shared/interfaces/phone-model';
import { RecoveryPasswordModel } from '../shared/interfaces/recovery-password-model';
import { DialogService } from '../shared/services/dialog.service';
import { ForgotPasswordService } from '../shared/services/forgot-password.service';
import { PhoneService } from '../shared/services/phone.service';
import { UtilService } from '../shared/services/util.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  client = {} as ClientModel;
  alterPassword = {} as RecoveryPasswordModel;
  expiredLink = false;
  confirmPassword: string;
  formPassword: FormGroup;
  showPassword = false;
  showConfirmPassword = false;
  phoneVerifyRetry = 0
  jwt: string;
  partner_uuid: string;
  phone = {} as PhoneModel;
  dialogViewModel: ConfirmDialogViewModel

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private dialogService: DialogService,
    private activatedRouter: ActivatedRoute,
    private forgotPasswordService: ForgotPasswordService,
    private phoneService: PhoneService
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.activatedRouter.queryParams.subscribe((params) => {
      this.jwt = params.jwt;
      this.partner_uuid = params.partner_uuid;
      this.phone.id = params.phone_id
      this.validToken();
    });
  }

  buildForm() {
    this.formPassword = this.formBuilder.group(
      {
        cpf: [{
          value: this.client.document_number
        }, Validators.compose([
          Validators.required,
          Validations.CPF
        ])],
        password: [{
          value: this.client.password
        }, Validators.compose([
          Validators.required,
          Validations.Password
        ])],
        confirm_password: [{
          value: this.confirmPassword
        }, Validators.compose([
          Validators.required
        ])]
      },
      {
        validator: Validations.PasswordMatch
      }
    )
  }

  validToken() {
    this.forgotPasswordService.getTokenPasswordRecovery(this.partner_uuid, this.jwt).then(result => {
      if (result) {
        this.client.name = result.name;
        this.client.uuid = result.uuid;
      } else {
        this.expiredLink = true;
      }
    })
  }

  showModalToken() {
    this.phoneService.sendVerify(ApisEnum.Client, this.client.uuid, this.phone, this.phoneVerifyRetry, this.jwt);
    this.dialogService.showTokenDialog().subscribe(result => {
      if (result == 'resend') {
        this.phoneVerifyRetry += 1;
        this.phoneService.sendVerify(ApisEnum.Client, this.client.uuid, this.phone, this.phoneVerifyRetry, this.jwt);
      } else {
        this.checkVerify({ sms_code: result });
      }
    });
  }

  checkVerify(data: any) {
    this.phoneService.checkVerify(ApisEnum.Client, this.client.uuid, this.phone.id, data, this.jwt).then(res => {
      this.dialogViewModel = {} as ConfirmDialogViewModel;
      if (res.status === 400) {
        this.dialogViewModel.subTitle = 'Código incorreto';
        this.dialogViewModel.msg = 'infelizmente, algo deu errado com o código.';
        this.dialogViewModel.txtOk = 'TENTAR OUTRA VEZ';
        this.showDialogTryAgain(this.dialogViewModel);
      } else if (res.status === 404) {
        this.dialogViewModel.msg = 'Seu código de confirmação está expirado. Você deve gerar um novo código de confirmação e tentar novamente.';
        this.dialogViewModel.tryAgain = true;
        this.dialogViewModel.txtOk = 'TENTAR OUTRA VEZ';
        this.showDialogTryAgain(this.dialogViewModel);
      } else if (res.status === 200) {
        this.next()
      }
    })
  }

  showDialogTryAgain(model) {
    this.dialogService.showDialogTryAgain(model).subscribe(x => {
      if (x === 'try-again') {
        this.showModalToken();
      }
    })
  }

  next() {
    if (this.formPassword.valid) {
      const clientData = Object.assign({} as any, this.utilService.dirty_controls_data(this.formPassword.controls))
      delete clientData.confirm_password;
      this.alterPassword.document_number = clientData.cpf;
      this.alterPassword.password = clientData.password;
      this.forgotPasswordService.alterPassword(this.alterPassword, this.partner_uuid, this.jwt).then(() => {
        this.showDialogSuccess()
      })
    } else {
      this.formPassword.markAllAsTouched()
    }
  }

  showDialogSuccess() {
    const viewModel = new ConfirmDialogViewModel();
    viewModel.subTitle = 'Sucesso!';
    viewModel.msg = 'Sua senha foi alterada com sucesso.\nPor favor, use a nova senha para efetuar login';
    viewModel.txtOk = 'CLIQUE AQUI PARA EFETUAR LOGIN';
    this.dialogService.showDialogSuccess(viewModel).subscribe((res) => {
      this.router.navigate([AppNavRouteLink.PAGE.LOGIN]);
    })
  }

  recoverPassword() {
    const result$ = confirm(`TODO: call service with jwt and client_uuid and redirect recover`);
    if (result$) {
      this.expiredLink = false;
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.formPassword.controls }
}
