<div class="modal-content">
  <div class="row justify-content-center">
    <div class="col col-10 mx-auto text-center mt-3 mt-md-4">
      <img src="assets/images/svg/ilustration-success.svg" alt="">
      <p *ngIf="subTitle" class="h1 big my-3">{{subTitle}}</p>
      <div *ngIf="msg" class="msg small" [innerHTML]="msg"></div>
      <p *ngIf="tryAgain" class="small-plus">Por favor, tente outra vez!</p>
      <p *ngIf="txtLink" class="link small mt-3" (click)="onClose('click-link')">{{txtLink}}</p>
    </div>
  </div>
  <div class="col my-4 my-md-3 text-center">
    <button type="button" class="btn btn-primary" (click)="onClose()">{{txtOk}}</button>
  </div>
</div>
