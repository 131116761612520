import { Component, ElementRef, Input, Renderer2, ChangeDetectorRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'invalid-feedback',
  templateUrl: './invalid-feedback.component.html',
  styleUrls: ['./invalid-feedback.component.scss']
})
export class InvalidFeedbackComponent {

  @Input() field;
  @Input() label;
  @Input() complement;
  @Input() value;
  @Input() minCurrency;
  @Input() maxCurrency;

  constructor(
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    hostElement: ElementRef) {
    renderer.addClass(hostElement.nativeElement, 'invalid-feedback')
  }

  required(control) {
    return control.touched && control.errors?.required;
  }

  invalid(control) {
    return control.errors?.invalid || control.errors?.mask;
  }

  invalidPassword(control) {
    return !control.errors?.required && control.errors?.invalidPassword;
  }

  minLength(control) {
    return control.errors?.minlength
  }

  pattern(control) {
    return control.errors?.pattern;
  }

  date(control) {
    return !control.errors?.required && (control.errors?.invalidDate);
  }

  minDate(control: AbstractControl) {
    if (!control.errors?.required && control.errors?.bsDate?.minDate) {
      control.setValue(control.errors?.bsDate.minDate)
      this.cdr.detectChanges()
    }
  }

  minValue(control) {
    return !control.errors?.required && control.errors?.minValue;
  }

  maxValue(control) {
    return !control.errors?.required && control.errors?.maxValue;
  }

  confirmPassword(control) {
    return !control.errors?.required && control.errors?.doNotMatch;
  }
}
