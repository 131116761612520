import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TagModel } from "ngx-chips/core/accessor";
import { BehaviorSubject, Observable, of } from 'rxjs';

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {

  @Output() filter_data = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  @Input() public filter_options = new BehaviorSubject<any>([]);

  showDateRange = false
  bsConfig: Partial<BsDatepickerConfig>

  constructor(
    public modalRef: BsModalRef
  ) { }

  ngOnInit(): void {
    // this.filter_options.subscribe(options => {
    //   for (let option of options) {
    //     if (option.type == 'accounts' && !option?.items.find(x => x.label == 'Todas')) {
    //       option.items.unshift({ 'label': 'Todas', 'value': 'all' })
    //     }
    //   }
    // })
    this.bsConfig = Object.assign({}, {
      containerClass: 'theme-blue',
      adaptivePosition: true,
      showWeekNumbers: false,
      selectFromOtherMonth: true,
      isAnimated: true,
      dateInputFormat: 'DD-MM-YYYY'
    })
  }

  public verifyExclusive(tag): Observable<TagModel> {
    return of(tag);
  }

  search(): void {
    this.filter_data.next(this.filter_options.getValue());
    this.close();
  }

  resetFilters() {

  }

  getOptions() {
    this.filter_options.getValue()?.forEach(filter => {
      if (filter.type == 'date') {
        this.showDateRange = filter.model.description == 'custom_date'
      }
    });
    return this.filter_options.getValue();
  }

  onChange(filter, event) {
    this.showDateRange = false
    filter.model.label = event.label
    if (event.description == 'custom_date') {
      this.showDateRange = true
    } else {
      filter.model.value = event.value
    }
  }

  close(): void {
    this.confirmAndClose(false);
  }

  private confirmAndClose(value: boolean | string) {
    this.modalRef.hide();
  }
}
