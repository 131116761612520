import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApisEnum } from '../enums/apis.enum';
import { AppConfigService } from './app-config.service';
import { AppUpdateService } from './app-update.service';
import { UtilService } from './util.service';
import { StorageService as storage } from './storage.service'
import { RecoveryPasswordModel } from './../interfaces/recovery-password-model';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {

  config: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private utilService: UtilService,
    private appUpdateService: AppUpdateService,
    private configService: AppConfigService
  ) {
    this.config = this.configService.config;
  }

  /**
   * Check App Update
   * @param res Response
   */
  private checkAppUpdate(res: Response | any): Response {
    if (res.headers.has('x-appversion')) {
      this.appUpdateService.appVersion.emit(res.headers.get('x-appversion'));
    }
    return res;
  }

  /**
   * POST ENTITY
   * @param data Object Recovery Password
   */
  generateToken(data: RecoveryPasswordModel, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, this.config.PARTNER_UUID, '/password/recover']);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    const dataPost = this.utilService.buildPostData(data);

    return this.http.post(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err =>  {
        this.utilService.handleError(err, this.router)
        return err;
      });
  }

  /**
 * PUT ENTITY
 * @param data Object Recovery Password
 * @param jwt Client JWT
 */
  alterPassword(data: RecoveryPasswordModel, PARTNER_UUID: string, jwt?: string): Promise<RecoveryPasswordModel> {
    const url = this.utilService.makeUri([ApisEnum.Client, PARTNER_UUID, '/password/recover', { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    const dataPost = this.utilService.buildPostData(data);

    return this.http.put(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
 * GET TOKEN PASSWORD RECOVERY
 */
  getTokenPasswordRecovery(PARTNER_UUID: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, PARTNER_UUID, '/password/recover', { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => {
        this.utilService.handleError(err, this.router)
        return null;
      });
  }

}
