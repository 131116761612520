<div class="modal-content">
  <div class="justify-content-center">
    <div class="col col-12 text-center">
      <p class="medium h1 my-4">Confirmação de transferência</p>
    </div>
    <div class="col col-12 mb-4">
      <form class="row">
        <div class="col-6 text-right">
          <label>Valor</label>
        </div>
        <div class="col-6 text-left">
          <p class="text-muted small">{{loaded_data?.amount}}</p>
        </div>
        <div class="col-6 text-right">
          <label>Tipo de transferência</label>
        </div>
        <div class="col-6 text-left">
          <p class="text-muted small">{{loaded_data?.transferType}}</p>
        </div>
        <div class="col-6 text-right">
          <label>Favorecido</label>
        </div>
        <div class="col-6 text-left">
          <p class="text-muted small">{{loaded_data?.name || loaded_pix?.name}}</p>
        </div>
        <div class="col-6 text-right" *ngIf="loaded_data?.transferType != TransferType.INTERNAL">
          <label>CPF/CNPJ</label>
        </div>
        <div class="col-6 text-left" *ngIf="loaded_data?.transferType == TransferType.PIX">
          <p class="text-muted small">{{loaded_pix?.extra_data?.formattedDocument || maskDocument(loaded_data?.keyPix)}}
          </p>
        </div>
        <div class="col-6 text-left" *ngIf="loaded_data?.transferType == TransferType.TED">
          <p class="text-muted small">{{maskDocument(loaded_data?.document_number)}}</p>
        </div>
        <div class="col-6 text-right">
          <label>Banco</label>
        </div>
        <div class="col-6 text-left" *ngIf="loaded_data?.transferType == TransferType.INTERNAL">
          <p class="text-muted small">LadyBank</p>
        </div>
        <div class="col-6 text-left" *ngIf="loaded_data?.transferType != TransferType.INTERNAL">
          <p class="text-muted small">{{bankName(loaded_data?.bank_code, loaded_pix?.extra_data?.ispbName)}}</p>
        </div>
        <div class="col-6 text-right"
          *ngIf="loaded_data?.transferType == TransferType.INTERNAL || loaded_data?.transferType == TransferType.TED">
          <label>Agência</label>
        </div>
        <div class="col-6 text-left" *ngIf="loaded_data?.transferType == TransferType.INTERNAL">
          <p class="text-muted small">0001</p>
        </div>
        <div class="col-6 text-left" *ngIf="loaded_data?.transferType == TransferType.TED">
          <p class="text-muted small">{{loaded_data?.branch || loaded_pix?.extra_data.branch}}</p>
        </div>
        <div class="col-6 text-right"
          *ngIf="loaded_data?.transferType == TransferType.INTERNAL || loaded_data?.transferType == TransferType.TED">
          <label>Conta</label>
        </div>
        <div class="col-6 text-left"
          *ngIf="loaded_data?.transferType == TransferType.INTERNAL || loaded_data?.transferType == TransferType.TED">
          <p class="text-muted small">
            {{loaded_data?.account_number || loaded_pix?.number}}-{{loaded_data?.account_digit || loaded_pix?.digit}}
          </p>
        </div>
        <div class="col-6 text-right" *ngIf="loaded_data?.transferType != TransferType.PIX">
          <label>Tipo de conta</label>
        </div>
        <div class="col-6 text-left" *ngIf="loaded_data?.transferType == TransferType.INTERNAL">
          <p class="text-muted small">Corrente</p>
        </div>
        <div class="col-6 text-left" *ngIf="loaded_data?.transferType == TransferType.TED">
          <p class="text-muted small">{{AccountType[loaded_data?.accountType]}}</p>
        </div>
        <div class="col-6 text-right">
          <label>Data</label>
        </div>
        <div class="col-6 text-left">
          <p class="text-muted small">{{loaded_data?.dateTransfer | date: 'dd/MM/yyyy'}}</p>
        </div>
        <div class="col-6 text-right">
          <label>Descrição</label>
        </div>
        <div class="col-6 text-left">
          <p class="text-muted small">{{loaded_data?.description}}</p>
        </div>
      </form>
    </div>
    <!-- {{loaded_data | json}}
    {{loaded_pix | json}} -->
    <div class="col col-12 text-center my-2" [innerHTML]="msg"></div>
    <div class="col col-12 text-center mb-4 text-danger fw-bold small">
      As transações PIX estão limitadas no período noturno das 20hs às 06hs e aos finais de semana e nos
      feriados ao valor máximo de R$ 1.000,00. Verifique sua tabela de limites.
    </div>
  </div>
  <div class="btn-group my-3 mx-lg-3">
    <button type="button" class="btn btn-outline-dark w-100" loaded_data-dismiss="modal"
      (click)="modalRef.hide()">VOLTAR</button>
    <button type="button" class="btn btn-primary w-100" (click)="onConfirm()">CONFIRMAR</button>
  </div>
</div>