import { Utils } from './../../shared/commons/Utils';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-simulate-value-dialog',
  templateUrl: './simulate-value-dialog.component.html',
  styleUrls: ['./simulate-value-dialog.component.scss']
})
export class SimulateValueDialogComponent implements OnInit {

  confirmResult: Subject<any>
  formGroup: FormGroup

  constructor(
    private fb: FormBuilder,
    public modalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
    this.confirmResult = new Subject()

    this.formGroup = this.fb.group({
      newValue: ['']
    })
  }

  update() {
    if (this.formGroup.valid) {
      const form = this.formGroup.value
      form.newValue = Utils.moneyTofloat(form.newValue)
      this.confirmResult.next({update: true, new_balance: form.newValue})
      this.modalRef.hide()
    }
  }
}
