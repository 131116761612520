<div class="modal-content" *ngIf="showModal">
  <div class="modal-header border-0">
    <div class="w-50" *ngIf="step == 4">
      <div class="alert alert-success d-flex align-items-center mb-0" role="alert">
        <img src="assets/images/svg/check-square.svg" alt="" class="w-10">
        <span class="text-body small poppins font-weight-bold ml-2">Valor devolvido</span>
      </div>
    </div>
    <span class="medium h1 w-100 m-0" *ngIf="step == 0">Devolução</span>
    <span class="medium h1 w-100 m-0" *ngIf="step == 1">Qual valor você quer devolver?</span>
    <span class="medium h1 w-100 m-0" *ngIf="step == 2">Qual o motivo da devolução?</span>
    <span class="medium h1 w-100 m-0" *ngIf="step == 3">Agora é só confirmar</span>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span class="material-icons-outlined">highlight_off</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row" *ngIf="step == 0">
      <div class="form-group col-12">
        <p class="small poppins">Você tem até 90 dias para devolver um valor que caiu na sua conta.</p>
        <p class="small poppins">É possível devolver tudo ou valores menores em diversas devoluções.</p>
      </div>
      <div class="form-group col-12">
        <input type="checkbox" id="step-check" (change)="iUnderstood($event.target.value)">
        <label class="font-weight-bold small poppins ml-1" for="step-check">Não mostrar novamente</label>
      </div>
    </div>
    <form [formGroup]="formGroup" autocomplete="off" novalidate *ngIf="formGroup?.controls.amount">
      <div class="row" *ngIf="step == 1">
        <div class="form-group col-12">
          <span class="small poppins">É possível devolver até:</span>
          <p class="x-medium font-weight-bold">{{model.amount | currency: 'BRL'}}</p>
        </div>
        <div class="form-group col-12">
          <label for="field_1" class="poppins">Valor</label>
          <input type="text" Currency class="form-control" formControlName="amount">
          <invalid-feedback [field]="formGroup.controls.amount" [minCurrency]="minCurrency" [maxCurrency]="maxCurrency">
          </invalid-feedback>
        </div>
        <div class="form-group col-12 d-flex justify-content-around px-1">
          <button type="button" class="btn btn-outline-primary" style="min-width: 100px;" (click)="addMount(1)">R$
            1</button>
          <button type="button" class="btn btn-outline-primary" style="min-width: 100px;" (click)="addMount(10)">R$
            10</button>
          <button type="button" class="btn btn-outline-primary" style="min-width: 100px;" (click)="addMount(50)">R$
            50</button>
          <button type="button" class="btn btn-outline-primary" style="min-width: 100px;" (click)="addMount(100)">R$
            100</button>
        </div>
        <div class="form-group col-12 d-flex justify-content-between align-items-center mt-3">
          <div class="small">
            Saldo disponível:
            <span *ngIf="!showBalance">*******</span>
            <span *ngIf="showBalance">{{balanceAccount | currency: 'BRL'}}</span>
          </div>
          <div>
            <span class="small link" role="button" (click)="showBalance = !showBalance">Mostrar</span>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="step == 2">
        <div class="form-group col-12">
          <label for="field_1" class="poppins">Escreva o motivo</label>
          <input type="text" class="form-control" formControlName="description">
          <span class="x-small poppins">Opcional</span>
        </div>
      </div>
      <div class="row poppins" *ngIf="step == 3">
        <div class="form-group col-12 my-4">
          <label class="text-black-50 small font-weight-bold m-0">DADOS DE QUEM VAI RECEBER</label>
        </div>
        <div class="form-group col-12 mb-0">
          <label class="text-black-50 small font-weight-bold m-0">Nome</label>
          <p class="text-body small-plus font-weight-bold">{{model?.otherInfo?.payer.name}}</p>
        </div>
        <div class="form-group col-12 mb-0">
          <label class="text-black-50 small font-weight-bold m-0">CPF/CNPJ</label>
          <p class="text-body small-plus font-weight-bold">{{setFormattedDocument(model?.otherInfo?.payer.document)}}</p>
        </div>
        <div class="form-group col-12 mb-0">
          <label class="text-black-50 small font-weight-bold m-0">Número da Conta</label>
          <p class="text-body small-plus font-weight-bold">{{model?.otherInfo?.payer.accountNumber}}</p>
        </div>
        <div class="form-group col-12 mb-0">
          <label class="text-black-50 small font-weight-bold m-0">ISPB</label>
          <p class="text-body small-plus font-weight-bold">{{model?.otherInfo?.payer.ispb}}</p>
        </div>
        <div class="form-group col-12 my-4">
          <label class="text-black-50 small font-weight-bold m-0">DADOS DA DEVOLUÇÃO</label>
        </div>
        <div class="form-group col-12 mb-0">
          <label class="text-black-50 small font-weight-bold m-0">Valor</label>
          <p class="text-body small-plus font-weight-bold">{{formGroup.value.amount}}</p>
        </div>
        <div class="form-group col-12 mb-0">
          <label class="text-black-50 small font-weight-bold m-0">Motivo</label>
          <p class="text-body small-plus font-weight-bold">{{formGroup.value.description}}</p>
        </div>
        <div class="form-group col-12 mb-0" *ngIf="step == 3">
          <label class="text-black-50 small font-weight-bold m-0">Debitar da</label>
          <p class="text-body small-plus font-weight-bold">Conta Corrente</p>
        </div>
      </div>
      <div class="row poppins" *ngIf="step == 4">
        <div class="form-group col-12 my-4">
          <label class="text-black-50 small font-weight-bold m-0">DADOS DE QUEM VAI RECEBER</label>
        </div>
        <div class="form-group col-12 mb-0">
          <label class="text-black-50 small font-weight-bold m-0">Nome</label>
          <p class="text-body small-plus font-weight-bold">{{model?.receiver?.name}}</p>
        </div>
        <div class="form-group col-12 mb-0">
          <label class="text-black-50 small font-weight-bold m-0">CPF/CNPJ</label>
          <p class="text-body small-plus font-weight-bold">{{setFormattedDocument(model?.receiver?.document)}}</p>
        </div>
        <div class="form-group col-12 mb-0">
          <label class="text-black-50 small font-weight-bold m-0">Número da Conta</label>
          <p class="text-body small-plus font-weight-bold">{{model?.receiver?.account}}</p>
        </div>
        <div class="form-group col-12 mb-0">
          <label class="text-black-50 small font-weight-bold m-0">ISPB</label>
          <p class="text-body small-plus font-weight-bold">{{model?.receiver?.ispb}}</p>
        </div>
        <div class="form-group col-12 my-4">
          <label class="text-black-50 small font-weight-bold m-0">DADOS DA DEVOLUÇÃO</label>
        </div>
        <div class="form-group col-12 mb-0">
          <label class="text-black-50 small font-weight-bold m-0">Valor</label>
          <p class="text-body small-plus font-weight-bold">{{model?.amount | currency: 'BRL'}}</p>
        </div>
        <div class="form-group col-12 mb-0">
          <label class="text-black-50 small font-weight-bold m-0">Identificador da Transação</label>
          <p class="text-body small-plus font-weight-bold">{{transactionId}}</p>
        </div>
        <div class="form-group col-12 mb-0">
          <label class="text-black-50 small font-weight-bold m-0">Data e Hora</label>
          <p class="text-body small-plus font-weight-bold">{{today * 1000 | date: 'dd/MM/yyyy - HH:mm'}}</p>
        </div>
        <div class="form-group col-12 mb-0">
          <label class="text-black-50 small font-weight-bold m-0">Motivo</label>
          <p class="text-body small-plus font-weight-bold">{{model?.description}}</p>
        </div>
        <div class="form-group col-12 mb-0" *ngIf="step == 3">
          <label class="text-black-50 small font-weight-bold m-0">Debitar da</label>
          <p class="text-body small-plus font-weight-bold">Conta Corrente</p>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-12 mt-5">
          <button type="button" class="btn btn-primary btn-block mx-0" (click)="changeStep(step + 1)">
            {{step == 0 ? 'Ok, entendi' : step == 4 ? 'Ok' : 'Continuar'}}</button>
          <button type="submit" class="btn btn-outline-primary btn-block mx-0" *ngIf="step > 0 && step < 4"
            (click)="modalRef.hide()">Cancelar</button>
        </div>
      </div>
    </form>
  </div>
</div>
