import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  @Input() title: string;
  @Input() msg: string;
  confirmResult: Subject<any>
  
  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void { 
    this.confirmResult = new Subject();
  }

  close() {
    this.confirmResult.next(true);
    this.modalRef.hide()
  }
}
