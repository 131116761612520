export enum StatusCardEnum {
  active = 'active',
  blocked = 'blocked',
  unblocked = 'unblocked',
  canceled = 'canceled',
  delivering = 'delivering',
  paid = 'paid',
  processing = 'processing',
  received = 'received',
  request = 'request',
  waitingEmbossing = 'waitingEmbossing',
  waitingCourier = 'waitingCourier',
  waitingProcessor = 'waitingProcessor',
  waitingRetrieval = 'waitingRetrieval',
  newPin = 'newPin'
}

export enum ActionCardEnum {
  duplicate = 'duplicate',
  block = 'block',
  cancel = 'cancel'
}