<div class="modal-content">
  <div class="row justify-content-center">
    <span class="font-weight-bolder text-gold pt-3 x-big">{{title}}</span>
    <div class="col col-11 mx-auto text-center mt-4">
      <img src="assets/images/png/success.png" class="w-50" alt="">
      <p class="small fw-bold my-3">{{subTitle}}</p>
      <div *ngIf="msg" class="msg small fw-bold" [innerHTML]="msg"></div>
      <div *ngIf="pixLimits">
        <button type="button" class="btn btn-link" (click)="openLimits()">MEUS LIMITES PIX</button>
      </div>
    </div>
  </div>
  <div class="col my-3 text-center mt-3">
    <button type="button" class="btn bg-bold text-white" (click)="onClose(true)">{{txtOk}}</button>
  </div>
  <div class="d-flex justify-content-center align-items-center pb-3">
    <a href="https://blog.ladybank.com.br/" target="_blank">
      <icon-svg svgName="icon-blog"></icon-svg>
    </a>
    <a href="https://t.me/LadyBank_Bot" target="_blank">
      <icon-svg svgName="social_media/telegram"></icon-svg>
    </a>
  </div>
</div>