import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-pix-channel-dialog',
  templateUrl: './pix-channel-dialog.component.html',
  styleUrls: ['./pix-channel-dialog.component.scss']
})
export class PixChannelDialogComponent {

  constructor(public modalRef: BsModalRef) { }

}
