<div class="modal-content">
  <div class="modal-header border-0">
    <span class="medium h1 w-100 m-0">Simular saldo em conta</span>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span class="material-icons-outlined">highlight_off</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="formGroup" class="row mb-5" autocomplete="off" novalidate>
        <div class="form-group col-12">
          <label for="newValue">Digite o novo valor</label>
          <input type="text" Currency class="form-control" placeholder="R$ 0,00" formControlName="newValue">
          <invalid-feedback [field]="formGroup.controls.newValue"></invalid-feedback>
        </div>
    </form>
    <div class="row justify-content-md-center">
    <button type="button" class="btn btn-outline-primary" (click)="modalRef.hide()">CANCELAR</button>
    <button type="submit" class="btn btn-primary" (click)="update()">ATUALIZAR VALOR</button>
    </div>
  </div>
</div>