import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Banks } from 'src/app/shared/commons/Banks';
import { Validations } from 'src/app/shared/commons/Validations';
import { AccountTypeEnum } from 'src/app/shared/enums/account-type.enum';
import { TransferTypeEnum } from 'src/app/shared/enums/transfer-type.enum';
import { Utils } from 'src/app/shared/commons/Utils';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-scheduled-transfers',
  templateUrl: './edit-scheduled-transfers.component.html',
  styleUrls: ['./edit-scheduled-transfers.component.scss']
})
export class EditScheduledTransfersComponent implements OnInit {

  model: any = {};
  bankList: any = Banks.get();
  formGroup: FormGroup;
  confirmResult: Subject<any>;
  bsConfig: Partial<BsDatepickerConfig>;
  balance = 0;
  changeModel = false;
  valueTransfer = 0;
  firstStep = true;
  editModel: any = {};
  today = new Date();

  constructor(
    public modalRef: BsModalRef,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.confirmResult = new Subject();
    this.loadForm();
    this.bsConfig = Object.assign({}, { dateInputFormat: 'DD', selectDay: false });
    setTimeout(() => {
      const currencyPipe: CurrencyPipe = new CurrencyPipe('pt-BR');
      this.editModel = Object.assign({}, this.model)
      this.editModel.value = currencyPipe.transform(this.model.value, 'BRL', 'R$', `1.2-2`)
      this.formGroup.get('value').setValidators(Validations.maxCurrency(this.balance));
      this.editModel.transferType = this.model.transferType == 'interna' ? TransferTypeEnum.INTERNAL : this.model.transferType.toUpperCase()
      this.editModel.document = Utils.maskCpfCnpj(this.model.document)
      this.editModel.dateTransfer = moment(this.editModel.dateTransfer).format('DD/MM/YYYY')
      this.onChangeRepeatTransfer();
    }, 300);
  }

  loadForm() {
    this.formGroup = this.formBuilder.group({
      value: ['', Validators.required],
      transferType: ['', Validators.required],
      nameFavorite: ['', Validators.required],
      bank: ['', Validators.required],
      agency: ['', Validators.required],
      account: ['', Validators.required],
      accountDigit: ['', Validators.required],
      document: ['', Validators.compose([Validators.required, Validations.CpfOrCnpj])],
      saveFavorite: [''],
      accountType: ['', Validators.required],
      dateTransfer: ['', Validators.compose([Validators.required, Validations.Date])],
      repeatTransfer: [''],
    })
  }

  onChangeTransferType() {
    if (this.model.transferType == 'PIX') {
      this.formGroup.addControl('keyPix', new FormControl('', Validators.required));
    } else {
      this.formGroup.removeControl('keyPix')
    }
  }

  onChangeRepeatTransfer() {
    if (this.model.repeatTransfer) {
      this.formGroup.addControl('howManyTimes', new FormControl('', Validators.required));
      this.formGroup.addControl('dayMonth', new FormControl('', Validators.required));
    } else {
      this.formGroup.removeControl('howManyTimes')
      this.formGroup.removeControl('dayMonth')
    }
  }

  changeStep(value) {
    this.firstStep = value;
  }

  finish() {
    if (this.formGroup.valid) {
      this.editModel.dateTransfer = moment(this.editModel.dateTransfer, 'DD/MM/YYYY').toDate()
      this.confirmResult.next(this.editModel);
      this.modalRef.hide();
    } else {
      this.formGroup.markAllAsTouched();
    }
  }

  get TransferType() {
    return Object.values(TransferTypeEnum);
  }

  get AccountType() {
    return Object.keys(AccountTypeEnum).map(key => {
      return ({ id: key, name: AccountTypeEnum[key] })
    })
  }

  get f() {
    return this.formGroup.controls;
  }
}
