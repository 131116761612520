import { Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ClientModel } from './../interfaces/client-model';
import { UtilService } from './util.service';
import { AppUpdateService } from './app-update.service';
import { Router } from '@angular/router';
import { StorageService as storage } from './storage.service'
import { AppConfigService } from './app-config.service';
import { ApisEnum } from '../enums/apis.enum';
import { BehaviorSubject } from 'rxjs';
import { ChangeSuggestions } from '../interfaces/portfolio';
import { AccountModel } from '../interfaces/account-model';
@Injectable({
  providedIn: 'root'
})
export class ClientService {
  @Output() clientData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  @Output() accountData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  @Output() rbAccountData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  @Output() phoneData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  @Output() pixData: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  config: any;
  constructor(
    private http: HttpClient,
    private router: Router,
    private utilService: UtilService,
    private appUpdateService: AppUpdateService,
    private configService: AppConfigService
  ) {
    this.config = this.configService.config;
  }

  redirectLandingPage() {
    window.open(`${this.config.API_PROT}://${this.config.API_HOST}`, '_top', '')
  }

  /**
   * Check App Update
   * @param res Response
   */
  private checkAppUpdate(res: Response | any): Response {
    if (res.headers.has('x-appversion')) {
      this.appUpdateService.appVersion.emit(res.headers.get('x-appversion'));
    }
    return res;
  }

  /**
   * RESET CLIENT DATA
   */
  resetData() {
    this.clientData.next(null);
    this.accountData.next(null);
    this.phoneData.next(null);
    this.pixData.next(null);
    storage.clear();
  }

  create(client: ClientModel): Promise<ClientModel> {
    storage.clearSection();
    const url = this.utilService.makeUri([ApisEnum.Client, this.config.PARTNER_UUID]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    client.bank_uuid = this.config.BANK_UUID;
    const dataPost = this.utilService.buildPostData(client);

    return this.http.post<ClientModel>(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Client Login
   * @param data Data Client Login
   */
  login(data: Object): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, this.config.PARTNER_UUID, 'auth']);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    const dataPost = this.utilService.buildPostData(data);

    return this.http.post(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => { return this.utilService.handleError(err, this.router) });
  }

  /**
   * PUT ENTITY
   * @param uri call service api
   * @param client_uuid Id api call
   * @param entity_id Id from entity
   * @param data Data Client Phone
   * @param jwt Client JWT
   */
  update(data: ClientModel, client_uuid?: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid || storage.getClientUuid(), { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    const dataPost = this.utilService.buildPostData(data);

    return this.http.put(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * GET ALL ENTITY
   * @param uri call service api
   * @param client_uuid Client UUID 
   * @param jwt Client JWT
   */
  read(client_uuid?: string, jwt?: string): Promise<ClientModel> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid || storage.getClientUuid(), { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  getClients(data: any) {
    const url = this.utilService.makeUri(['clients', data, { jwt: storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Get Client Information
   * @param client_uuid Client UUID
   * @param jwt Client JWT
   */
  getInfo(client_uuid: string, jwt?: string): Promise<ClientModel> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid || storage.getClientUuid(), { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * GET ENTITY BY ID
   * @param uri call service api
   * @param client_uuid Client UUID 
   * @param entity_id Id from entity
   * @param jwt Client JWT
   */
  readById(client_uuid?: string, jwt?: string): Promise<ClientModel> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid || storage.getClientUuid(), { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  closeAccount(account_uuid): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, storage.getClientUuid(), 'account', account_uuid, 'close', { jwt: storage.getClientJwt() }]);
    return this.http.delete(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Planejamento
   */
  getGoals(client_uuid: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'pms', 'goals', { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => { return this.utilService.handleError(err, this.router) });
  }

  /**
   * Patrimônio
   */
  getIncomes(client_uuid: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'pms', 'incomes', { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Meus investimentos
   */
  getPositions(client_uuid: string, rb_account_uuid?: any, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'pms', 'positions', rb_account_uuid, { jwt: jwt || storage.getClientJwt() }]);
    console.log('url: ', url);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Performance
   */
  getPerformance(client_uuid: string, data?: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'pms', 'performance-historical', { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(url, data, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }


  /**
   * Get Client Bank Accounts
   * @param data Data Search
   */
  getBankAccounts(client_uuid: string, data?: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'accounts', { jwt: jwt || storage.getClientJwt() }, data]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Get Account Balance
   * @param account_uuid 
   */
  getAccountBalance(client_uuid: string, account_uuid: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'balance', { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => { return this.utilService.handleError(err, this.router) });
  }

  getAssets(data) {
    const url = this.utilService.makeUri([ApisEnum.Partner, 'pms', 'assets', { jwt: storage.getClientJwt() }, data]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Get Client Allocations
   * @param client_uuid
   */
  getAllocations(client_uuid: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'pms', 'allocations', { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  getAccounts(): Promise<{ accounts: AccountModel[] }> {
    const client_uuid = storage.getClientUuid();
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'accounts', { jwt: storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  updateAllocations(account_uuid: string, starting_cash: number, final_cash: number, assetsToAdd: Array<{
    weights: number,
    partner_asset_uuid: string
  }>): Promise<any> {
    const client_uuid = storage.getClientUuid();
    const data = {
      allocation_type: 'model',
      assets: assetsToAdd,
      extra: {
        "incomes_simulated": [
          {
            "type": "simulated",
            "value": -final_cash
          },
          {
            "type": "position",
            "value": starting_cash
          }
        ]
      }
    }
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'pms', 'allocation', { jwt: storage.getClientJwt() }]);
    return this.http.post(url, data, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  updateAllocations_new(account_uuid: string, income_id: number, act_date: string, name: string, total_value: number, starting_cash: number, final_cash: number, assetsToAdd: Array<{
    weights: number,
    partner_asset_uuid: string
  }>): Promise<any> {
    const client_uuid = storage.getClientUuid();
    const data = {
      allocation_type: 'model',
      assets: assetsToAdd,
      extra: {
        "incomes_simulated": [
          {
            "id": income_id,
            "date": act_date,
            "name": name,
            "type": "simulated",
            "tenor": 0,
            "value": total_value,
            "frequency": 1,
            "repetition": 0,
            "numeraire": "5107929e-29d6-4e09-8c9b-20d25b8df4c3",
            "tenor_type": "actual_actual"
          }
        ]
      }
    }
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'pms', 'allocation', { jwt: storage.getClientJwt() }]);
    return this.http.post(url, data, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }


  getChangeSuggestionsAllocations(): Promise<ChangeSuggestions> {
    const client_uuid = storage.getClientUuid();
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'pms', 'allocation', 'change-suggestion', { jwt: storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  getClassificationEnum(jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Partner, 'pms', 'classification-enum', { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Update Password
   * @param data Data Client Password Update
   * @param jwt Client JWT
   */
  updatePassword(data: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, 'password', { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    const dataPost = this.utilService.buildPostData(data);

    return this.http.put(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Get Client Account Statements
   * @param client_uuid Client UUID
   * @param account_uuid  Client Account UUID
   * @param jwt JSON Web Token
   */
  getAccountStatements(client_uuid: string, account_uuid: string, data: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid || storage.getClientUuid(), 'account', account_uuid, 'statements', { jwt: jwt || storage.getClientJwt() }, data]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
  * Get Client Account Statements PDF
  * @param client_uuid Client UUID
  * @param account_uuid  Client Account UUID
  * @param jwt JSON Web Token
  */
  getAccountStatementsPDF(client_uuid: string, account_uuid: string, data: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'statements', 'pdf', { jwt: jwt || storage.getClientJwt() }, data]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Get Account Boleto Information
   * @param client_uuid Client UUID
   * @param account_uuid Client Account UUID
   * @param data Data Boleto information
   * @param jwt JSON Web Token
   */
  getAccountBoletoInfo(client_uuid: string, account_uuid: string, data: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'boleto', { jwt: jwt || storage.getClientJwt() }, data]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Pay Boleto
   * @param client_uuid Client UUID
   * @param account_uuid Client Account UUID
   * @param data Data Boleto information
   * @param jwt JSON Web Token
   */
  payAccountBoleto(client_uuid: string, account_uuid: string, data: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'boleto', { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    const dataPost = this.utilService.buildPostData(data);

    return this.http.post(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => { return this.utilService.handleError(err, this.router) });
  }

  /**
   *
   * @param client_uuid Client UUID
   * @param data Client Data Phone
   * @param jwt JSON Web Token
   */
  createPhone(client_uuid: string, data: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'phone', { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    const dataPost = this.utilService.buildPostData(data);

    return this.http.post(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   *
   * @param client_uuid Client UUID
   * @param phone_id Phone ID
   * @param data Data Phone to Update
   * @param jwt JSON Web Token
   */
  updatePhone(client_uuid: string, phone_id: number, data: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'phone', phone_id, { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    const dataPost = this.utilService.buildPostData(data);

    return this.http.put(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Send Phone SMS Verify
   * @param client_uuid Client UUID
   * @param phone_id Phone ID
   * @param jwt JSON Web Token
   */
  sendPhoneSmsVerify(client_uuid: string, phone_id: number, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'phone', phone_id, 'verify', { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    return this.http.post(url, null, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Check Phone SMS Verify
   * @param client_uuid Client UUID
   * @param phone_id Phone ID
   * @param sms_code SMS Code
   * @param jwt JSON Web Token
   */
  checkPhoneSmsVerify(client_uuid: string, phone_id: number, sms_code: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'phone', phone_id, 'verify', { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const dataPost = this.utilService.buildPostData({ sms_code: sms_code });

    return this.http.put(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  emailVerifyToken(client_uuid: string, jwt: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'email', 'verify', { jwt: jwt }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  emailVerifyDocument(client_uuid: string, document_number: any, jwt: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'email', 'verify', { jwt: jwt }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const dataPost = this.utilService.buildPostData({ document_number: document_number });

    return this.http.put(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Get Client Phones
   * @param client_uuid Client UUID
   * @param data Data Query
   * @param jwt JSON Web Token
   */
  getPhones(client_uuid: string, data?: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'phones', { jwt: jwt || storage.getClientJwt() }, data]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Get a Client Phone
   * @param client_uuid Client UUID
   * @param phone_id Client Phone ID
   * @param jwt JSON Web Token
   */
  getPhone(client_uuid: string, phone_id: number, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'boleto', phone_id, { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Create Account Favorite
   * @param client_uuid Client UUID
   * @param account_uuid Client Account UUID
   * @param data Favorite Data
   * @param jwt JSON Web Token
   */
  createAccountFavorite(client_uuid: string, account_uuid: string, data: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'favorite', { jwt: jwt || storage.getClientJwt() }]);

    return this.http.post(url, data, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => { return this.utilService.handleError(err, this.router) });
  }

  /**
   *
   * @param client_uuid Client UUID
   * @param account_uuid Client Account UUID
   * @param favorite_id Favorite ID
   * @param data Favorite Data
   * @param jwt JSON Web Token
   */
  updateAccountFavorite(client_uuid: string, account_uuid: string, favorite_id: number, data: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'favorite', favorite_id, { jwt: jwt || storage.getClientJwt() }]);

    return this.http.put(url, data, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => { return this.utilService.handleError(err, this.router) });
  }

  /**
   * Get Client Account Favorites
   * @param client_uuid Client UUID
   * @param account_uuid Client Account UUID
   * @param jwt JSON Web Token
   */
  getAccountFavorites(client_uuid: string, account_uuid: string, data?: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'favorites', { jwt: jwt || storage.getClientJwt() }, data]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Get a Client Account Favorite
   * @param client_uuid Client UUID
   * @param account_uuid Client Account UUID
   * @param favorite_id Favorite ID
   * @param jwt JSON Web Token
   */
  getAccountFavorite(client_uuid: string, account_uuid: string, favorite_id: number, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'favorite', favorite_id, { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   *
   * @param client_uuid Client UUID
   * @param account_uuid Client Account UUID
   * @param favorite_id Favorite ID
   * @param jwt JSON Web Token
   */
  removeAccountFavorite(client_uuid: string, account_uuid: string, favorite_id: number, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'favorite', favorite_id, { jwt: jwt || storage.getClientJwt() }]);
    return this.http.delete(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Client Account External Transfer
   * @param client_uuid Client UUID
   * @param account_uuid Client Account UUID
   * @param data Data Transfer
   * @param jwt JSON Web Token
   */
  clientAccountExternalTransfer(client_uuid: string, account_uuid: string, data: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'external-transfer', { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const dataPost = this.utilService.buildPostData(data);

    return this.http.post(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Get List External Transfers
   * @param client_uuid Client UUID
   * @param account_uuid Client Account UUID
   * @param jwt JSON Web Token
   */
  getClientAccountExternalTransfers(client_uuid: string, account_uuid: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'external-transfers', { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Get a Client External Transfer
   * @param client_uuid Client UUID
   * @param account_uuid Client Account UUID
   * @param transfer_id Transfer ID
   * @param jwt JSON Web Token
   */
  getClientAccountExternalTransferById(client_uuid: string, account_uuid: string, transfer_id: number, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'external-transfer', transfer_id, { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Client Account Internal Transfer
   * @param client_uuid Client UUID
   * @param account_uuid Client Account UUID
   * @param data Data Transfer
   * @param jwt JSON Web Token
   */
  clientAccountInternalTransfer(client_uuid: string, account_uuid: string, data: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'transfer', { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const dataPost = this.utilService.buildPostData(data);

    return this.http.post(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Get List Internal Transfers
   * @param client_uuid Client UUID
   * @param account_uuid Client Account UUID
   * @param jwt JSON Web Token
   */
  getClientAccountInternalTransfers(client_uuid: string, account_uuid: string, data: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'transfers', { jwt: jwt || storage.getClientJwt() }, data]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Get a Client Internal Transfer
   * @param client_uuid Client UUID
   * @param account_uuid Client Account UUID
   * @param transfer_id Transfer ID
   * @param jwt JSON Web Token
   */
  getClientAccountInternalTransfer(client_uuid: string, account_uuid: string, transfer_id: number, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'transfer', transfer_id, { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Client Account PIX Transfer
   * @param client_uuid Client UUID
   * @param account_uuid Client Account UUID
   * @param data Data Transfer
   * @param jwt JSON Web Token
   */
  clientAccountPIXTransfer(client_uuid: string, account_uuid: string, data: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'pix', 'transfer', { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(url, data, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => { return this.utilService.handleError(err, this.router) });
  }

  /**
   * Get a Client PIX Transfer
   * @param client_uuid Client UUID
   * @param account_uuid Client Account UUID
   * @param transfer_id Transfer ID
   * @param jwt JSON Web Token
   */
  getClientAccountPIXTransfer(client_uuid: string, account_uuid: string, transfer_id: number, data: any, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'pix', 'transfer', transfer_id, { jwt: jwt || storage.getClientJwt() }]);
    const dataPost = this.utilService.buildPostData(data);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  deleteClientAccountPIXTransfer(client_uuid: string, account_uuid: string, transfer_id, jwt?: string) {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'pix', 'transfer', transfer_id, 'schedule', { jwt: jwt || storage.getClientJwt() }]);
    return this.http.delete(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Get List PIX Transfers
   * @param client_uuid Client UUID
   * @param account_uuid Client Account UUID
   * @param jwt JSON Web Token
   */
  getClientAccountPIXTransfers(client_uuid: string, account_uuid: string, data?: any): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'pix', 'statements', data, { jwt: storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Consulta chaves PIX LadyBank
   * @param client_uuid
   * @param account_uuid
   * @param jwt
   * @returns
   */
  getClientAccountPIXs(client_uuid: string, account_uuid: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'pixs', { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Validar chave PIX para transferência
   * @param client_uuid Client UUID
   * @param account_uuid Client Account UUID
   * @param transfer_id Transfer ID
   * @param jwt JSON Web Token
   */
  getClientAccountPIXSearch(client_uuid: string, account_uuid: string, key: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'pix', 'search', { jwt: jwt || storage.getClientJwt(), key: key }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  getClientAccountPIXStatements(client_uuid: string, account_uuid: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'pix', 'statements', { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Criação de chave PIX
   * @param client_uuid
   * @param account_uuid
   * @param data
   * @param jwt
   * @returns
   */
  createClientAccountPIXKey(client_uuid: string, account_uuid: string, data: any, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'pix', { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const dataPost = this.utilService.buildPostData(data);

    return this.http.post(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  deleteClientAccountPIXKey(client_uuid: string, account_uuid: string, key, jwt?: string) {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'pix', key, { jwt: jwt || storage.getClientJwt() }]);
    return this.http.delete(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Create a Client Address
   * @param client_uuid Client UUID
   * @param data Data Address
   * @param jwt JSON Web Token
   */
  createClientAddress(client_uuid: string, data: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'address', { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const dataPost = this.utilService.buildPostData(data);

    return this.http.post(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Edit a Client Address
   * @param client_uuid Client UUID
   * @param address_id Address ID
   * @param data Data Address
   * @param jwt JSON Web Token
   */
  editClientAddress(client_uuid: string, address_id: number, data: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'address', address_id, { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const dataPost = this.utilService.buildPostData(data);

    return this.http.put(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Get a Client Address
   * @param client_uuid Client UUID
   * @param address_id Address ID
   * @param jwt JSON Web Token
   */
  getClientAddress(client_uuid: string, address_id: number, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'address', address_id, { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    return this.http.get(url, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Get Client Addresses
   * @param client_uuid Client UUID
   * @param jwt JSON Web Token
   */
  getClientAddresses(client_uuid: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'addresses', { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    return this.http.get(url, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Get Client Files
   * @param client_uuid Client UUID
   * @param jwt JSON Web Token
   */
  getClientFiles(client_uuid: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'upload-file', { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    return this.http.get(url, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Get ThirdParty CrediGo Accounts
   * @param client_uuid Client UUID
   * @param jwt JSON Web Token
   */
  getThirdPartyCredigoAccounts(client_uuid: string, data?: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'third_party', 'credigo', 'accounts', { jwt: jwt || storage.getClientJwt() }, data]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    return this.http.get(url, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Get ThirdParty GrediGo Account History
   * @param client_uuid Client UUID
   * @param account_id Account ID
   * @param jwt JSON Web tokne
   */
  getThirdPartyCredigoAccounthistory(client_uuid: string, account_id: number, data?: any, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'third_party', 'credigo', 'account', account_id, 'history', data, { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    return this.http.get(url, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Get ThirdParty GrediGo Categories
   * @param client_uuid Client UUID
   * @param jwt JSON Web Token
   */
  getThirdPartyCredigoCategories(client_uuid: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'third_party', 'credigo', 'categories', { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    return this.http.get(url, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Delete a ThirdParty Credigo Account
   * @param client_uuid Client UUID
   * @param account_id Account ID
   * @param jwt JSON Web Token
   */
  deleteThirdPartyCredigoAccount(client_uuid: string, account_id: number, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'third_party', 'credigo', 'account', account_id, { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    return this.http.delete(url, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   *
   * @param client_uuid Client UUID
   * @param account_id Account ID
   * @param data Data Update
   * @param jwt JSON Web Token
   */
  updateThirdPartyCredigoAccount(client_uuid: string, account_id: number, data: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'third_party', 'credigo', 'account', account_id, { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const dataPost = this.utilService.buildPostData(data);

    return this.http.put(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Get News
   * @param client_uuid Client UUID
   * @param jwt JSON Web Token
   */
  getNews(client_uuid: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'news', { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    return this.http.get(url, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Create Client Category
   * @param client_uuid Client UUID
   * @param data Category Data
   * @param jwt JSON Web Token
   */
  createClientCategory(client_uuid: string, data: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'category', { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const dataPost = this.utilService.buildPostData(data);

    return this.http.post(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Update Client Category
   * @param client_uuid Client UUID
   * @param category_id Category ID
   * @param data Category Data
   * @param jwt JSON Web Token
   */
  updateClientCategory(client_uuid: string, category_id: number, data: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'category', category_id, { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const dataPost = this.utilService.buildPostData(data);

    return this.http.put(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Get a Client Category
   * @param client_uuid Client UUID
   * @param category_id Category ID
   * @param jwt JSON Web Token
   */
  getClientCategory(client_uuid: string, category_id: number, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'category', category_id, { jwt: jwt || storage.getClientJwt() }]);

    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Delete a Client Category
   * @param client_uuid Client UUID
   * @param category_id Category ID
   * @param jwt JSON Web Token
   */
  deleteClientCategory(client_uuid: string, category_id: number, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'category', category_id, { jwt: jwt || storage.getClientJwt() }]);

    return this.http.delete(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Get Client Categories (statement)
   * @param client_uuid Client UUID
   * @param jwt JSON Web Token
   */
  getClientCategories(client_uuid: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'categories', { jwt: jwt || storage.getClientJwt() }]);

    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * atualizar categoria do extrato
   * @param client_uuid
   * @param data
   * @param jwt
   * @returns
   */
  createClientStatementCategory(client_uuid: string, data: any, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'statement', 'category', { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const dataPost = this.utilService.buildPostData(data);

    return this.http.post(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  updateClientStatementCategory(client_uuid: string, data: any, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'statement', 'category', data.ref_statement_id, { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const dataPost = this.utilService.buildPostData(data);

    return this.http.put(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Delete Client Category Statement
   * @param client_uuid Client UUID
   * @param category_id Category ID
   * @param ref_statement_id Reference Statement ID
   * @param jwt JSON Web Token
   */
  deleteClientCategoryStatement(client_uuid: string, category_id: number, ref_statement_id: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'category', category_id, 'statement', ref_statement_id, { jwt: jwt || storage.getClientJwt() }]);

    return this.http.delete(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }
  /**
   * Agendar transferências
   * @param client_uuid
   * @param account_uuid
   * @param data
   * @param jwt
   * @returns
   */
  createPaymentSchedule(client_uuid: string, account_uuid: string, data: any, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'payment', 'schedule', { jwt: jwt || storage.getClientJwt() }]);

    return this.http.post(url, data, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => { return this.utilService.handleError(err, this.router) });
  }

  getPaymentsSchedules(client_uuid: string, account_uuid: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'payment', 'schedules', { jwt: jwt || storage.getClientJwt() }]);

    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  updatePaymentSchedule(client_uuid: string, account_uuid: string, schedule_id, data: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'payment', 'schedule', schedule_id, { jwt: jwt || storage.getClientJwt() }]);

    return this.http.put(url, data, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  deletePaymentSchedule(client_uuid: string, account_uuid: string, schedule_id, jwt?: string) {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'payment', 'schedule', schedule_id, { jwt: jwt || storage.getClientJwt() }]);
    return this.http.delete(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => {
        this.utilService.handleError(err, this.router)
        return err
      });
  }

  getOrders(client_uuid: string, rb_account_uuid?: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'pms', 'orders', { rb_account_uuid: rb_account_uuid }, { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Mostrar as notificações
   * @param client_uuid
   * @param jwt
   * @returns
   */
  getMessages(client_uuid: string, params?: any, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'messages', params, { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   *
   * @param client_uuid Buscar as profissões
   * @param profession
   * @param jwt
   * @returns
   */
  getCob(client_uuid: string, data?: any, jwt?: string) {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'cbo', data, { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Sync Client Account CrediGO
   * @param client_uuid Client UUID
   * @param account_id Account ID
   * @param jwt Client JWT
   * @returns
   */
  syncClientAccountCrediGo(client_uuid: string, account_id: number, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'third_party', 'credigo', 'account', account_id, 'sync', { jwt: jwt || storage.getClientJwt() }]);

    return this.http.post(url, {}, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  getNumaraires(num) {
    switch (num) {
      case 'BRL':
        return this.config.NUMERAIRES.CASH_BRL;
      case 'CDI':
        return this.config.NUMERAIRES.CDI_FACTOR;
      case 'IPCA':
        return this.config.NUMERAIRES.IPCA_FACTOR;
      case 'USD':
        return this.config.NUMERAIRES.CASH_USD;
      default:
        break;
    }
  }

  getBudgets(client_uuid: string, order_by?: any, category?: string, jwt?: string) {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'budgets', { jwt: jwt || storage.getClientJwt() }]);

    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  saveBudget(client_uuid, data, jwt?) {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'budget', { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    const dataPost = this.utilService.buildPostData(data);

    return this.http.post(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  updateBudget(client_uuid: string, data: any, budget_id: number, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid || storage.getClientUuid(), 'budget', budget_id, { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    const dataPost = this.utilService.buildPostData(data);

    return this.http.put(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  removeBudget(client_uuid: string, budget_id: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'budget', budget_id, { jwt: jwt || storage.getClientJwt() }]);
    return this.http.delete(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  getListBrokers(jwt?: string) {
    const url = this.utilService.makeUri(['brokers', { jwt: jwt || storage.getClientJwt() }]);

    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  getListFactors(jwt?: string, factor?: string) {
    const url = this.utilService.makeUri(['pms/assets' + '?asset_name=' + factor + '&' + 'jwt=' + jwt]);

    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  saveBroker(client_uuid, broker_uuid, data, jwt?) {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'broker', broker_uuid, { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(url, data, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  getBrokers(client_uuid: string, jwt?: string) {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'brokers', { jwt: jwt || storage.getClientJwt() }]);

    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  removeBroker(client_uuid: string, broker_id: string) {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'broker', broker_id, { jwt: storage.getClientJwt() }]);

    return this.http.delete(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  receivedCard(client_uuid: string, account_id: string, card_id: string, data: any) {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_id, 'card-pre', 'card', card_id, 'receive', { jwt: storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(url, data, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  trackCard(client_uuid: string, account_uuid: string, card_id: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'card-pre', 'card', card_id, 'track', { jwt: storage.getClientJwt() }]);

    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  saveCard(client_uuid: string, account_id: string, data: any, jwt?: string) {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_id, 'card-pre', 'card', { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const dataPost = this.utilService.buildPostData(data);

    return this.http.post(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  readCards(client_uuid: string, account_id: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_id, 'card-pre', 'cards', { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => { return this.utilService.handleError(err, this.router) });
  }

  updateCard(client_uuid: string, account_uuid: string, card_id: string, data: any): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'card-pre', 'card', card_id, { jwt: storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.put(url, data, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  activateCard(client_uuid: string, account_uuid: string, card_id: string, data: any): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'card-pre', 'card', card_id, 'activation', { jwt: storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(url, data, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  changePin(client_uuid: string, account_uuid: string, card_id: string, data: any): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'card-pre', 'card', card_id, 'pin', { jwt: storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(url, data, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  getQRCodePix(client_uuid: string, account_uuid: string, emv: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'pix', 'qrcode', { jwt: jwt || storage.getClientJwt() }, { emv: emv }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  generateQRCode(client_uuid: string, account_uuid: string, data?: any, jwt?: string) {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'pix', 'qrcode', { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    const dataPost = this.utilService.buildPostData(data);

    return this.http.post(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  getClaims(client_uuid: string, account_uuid: string) {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'pix', 'claims', { jwt: storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  deleteClaim(client_uuid: string, account_uuid: string, key_uuid: string, reason: string) {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'pix', 'claims',
    { id: key_uuid, reason: reason, jwt: storage.getClientJwt() }]);
    return this.http.delete(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  postClaims(client_uuid: string, account_uuid: string, data: any): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'pix', 'claims', { jwt: storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    const dataPost = this.utilService.buildPostData(data);

    return this.http.post(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  putClaims(client_uuid: string, account_uuid: string, data: any): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'pix', 'claims', { jwt: storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.put(url, data, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  verifyPixKey(client_uuid: string, account_uuid: string, key: string, key_type: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'pix', 'verify', { key: key, key_type: key_type, jwt: storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  checkPixVerify(client_uuid: string, account_uuid: string, data: any): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'pix', 'verify', { jwt: storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(url, data, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  getLimits(client_uuid: string, account_uuid: string) {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'pix', 'limits', { jwt: storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => { return this.utilService.handleError(err, this.router) });
  }

  updateLimits(client_uuid: string, account_uuid: string, data) {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'pix', 'limits', { jwt: storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const dataPost = this.utilService.buildPostData(data);

    return this.http.put(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => { return this.utilService.handleError(err, this.router) });
  }

  getBanksWithIspb(params?) {
    const url = this.utilService.makeUri(['institutions', params, { jwt: storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  generateBankSplip(client_uuid: string, account_uuid: string, data?: any): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'deposit-boleto', { jwt: storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(url, data, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  getListBankSplip(client_uuid: string, account_uuid: string, data: any): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'account', account_uuid, 'deposit-boletos', { jwt: storage.getClientJwt() }, data]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  getSettings(client_uuid, type = 'dashboard') {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'settings', { type: type, jwt: storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  saveSettings(client_uuid, data) {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'setting', { jwt: storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })

    return this.http.post(url, data, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  updateSettings(client_uuid, setting_id, data) {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'setting', setting_id, { jwt: storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.put(url, data, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  getRobobankerAccounts(client_uuid) {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'robobanker', 'accounts', { jwt: storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  getRobobankerAccountBydId(client_uuid, robobanker_account_id) {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'robobanker', 'account', robobanker_account_id, { jwt: storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  removeRobobankerAccount(client_uuid, robobanker_account_id) {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'robobanker', 'account', robobanker_account_id, { jwt: storage.getClientJwt() }]);
    return this.http.delete(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  rebalance(client_uuid, robobanker_account_id, data): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'pms', 'rebalance', { rb_account_uuid: robobanker_account_id, jwt: storage.getClientJwt() }]);
    return this.http.post(url, data, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }
}
