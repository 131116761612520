import { CurrencyPipe, PercentPipe } from '@angular/common';
import { Component, Input, forwardRef, ViewChild, Output, EventEmitter, Renderer2, HostListener } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Utils } from 'src/app/shared/commons/Utils';

@Component({
  selector: 'inline-edit',
  templateUrl: './inline-edit.component.html',
  styleUrls: ['./inline-edit.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InlineEditComponent),
      multi: true
    },
    CurrencyPipe,
    PercentPipe
  ]
})
export class InlineEditComponent implements ControlValueAccessor {

  @ViewChild('inlineEditControl') inlineEditControl;
  @Input() label: string = '';
  @Input() required: boolean = true;
  @Input() maxValue?: number;
  @Input() isPercent: boolean = false;
  @Input() isAllocation: boolean = false;
  @Input() isCurrency: boolean = false;
  @Input() isValue: boolean = false;
  @Input() hiddenIconEdit: boolean = false;
  @Input() width: number;
  @Input() float: string;
  @Output() ChangeValue = new EventEmitter<Number>();
  private _value: string = '';
  public editing: boolean = false;
  public onChange: any = Function.prototype;
  public onTouched: any = Function.prototype;
  private regex: RegExp = new RegExp(/^[-+]?\d*\,?\d*$/g);
  currencyPipe: CurrencyPipe = new CurrencyPipe('pt-BR');
  percentPipe: PercentPipe = new PercentPipe('pt-BR');

  constructor(
    private renderer: Renderer2,
  ) { }

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    this.ChangeValue.emit(v);
    this.onChange(v);
    this._value = v;
  }

  writeValue(value: any) {
    if (value === null || value === '') {
      return;
    } else {
      let unmask = Utils.moneyTofloat(value);
      
      if (this.isCurrency || this.isValue) {
        this._value = this.currencyPipe.transform(unmask, 'BRL');
      } else if (this.isPercent) {
        this._value = this.percentPipe.transform(unmask / 100, '1.2-3');
      } else {
        this._value = this.percentPipe.transform(unmask, '1.0-4');
      }
    }
  }

  public registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  onBlur($event) {
    this.editing = false;
    if (this._value === '' || $event.target.value === '') {
      return;
    } else if (this.maxValue && Utils.moneyTofloat($event.target.value) > this.maxValue) {
      this.showSnackBar(`Não é permitido valores maiores que ${this.maxValue}${this.isPercent ? '%' : ''}`, 'error');
      return;
    } else if (this.isPercent) {
      this.value = this.percentPipe.transform(Utils.moneyTofloat($event.target.value) / 100, '1.2-3');
    } else if (this.isCurrency) {
      this.value = this.currencyPipe.transform(Utils.moneyTofloat($event.target.value), 'BRL');
    }
    else if (this.isAllocation || this.isValue) { }
    else {
      this.value = $event.target.value;
    }
  }

  onKeyPress(event) {
    if (this.isPercent) {
      const next: string = event.key;
      if (next && !String(next).match(this.regex)) {
        event.preventDefault();
        return;
      }
    }
  }

  beginEdit() {
    this.editing = true;
    setTimeout(() => {
      this.renderer.selectRootElement(this.inlineEditControl['nativeElement']).focus();
    }, 100);
  }

  @HostListener('keypress', ['$event'])
  keyPress($event: any) {
    const next: string = $event.key;
    if (next == 'Enter') {
      this.value = $event.target.value;
      this.renderer.selectRootElement(this.inlineEditControl['nativeElement']).blur();
      return;
    }
    else if (next && !String(next).match(this.regex)) {
      $event.preventDefault();
      return;
    }
  }

  showSnackBar(msg: string, type) {
    const snackbar = document.getElementById('snackbar');
    snackbar.className =  `show ${type}`;
    snackbar.firstElementChild.innerHTML = msg;
    setTimeout(function(){ snackbar.className = ''; }, 3000);
  }
}
