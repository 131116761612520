import { Component, OnInit, Input } from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {ClientService} from "../../shared/services/client.service";
import {DialogService} from "../../shared/services/dialog.service";
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-qrcode-dialog',
  templateUrl: './qrcode-dialog.component.html',
  styleUrls: ['./qrcode-dialog.component.scss']
})
export class QrcodeDialogComponent implements OnInit {

  @Input() amount = new BehaviorSubject<any>([]);
  @Input() key = new BehaviorSubject<any>([]);
  @Input() qrCode = new BehaviorSubject<any>([]);

  client: any;
  account: any;
  disableSharedButton = true

  confirmResult: Subject<any>

  constructor(
      public modalRef: BsModalRef,
      private clientService: ClientService,
      private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    if (window.navigator.share) {
      this.disableSharedButton = false
    }
    
    this.confirmResult = new Subject();

    this.clientService.clientData.subscribe(d => {
      if (!d) { return; }
      this.client = d;
    });
  }

  share() {

    let info_text = `
    Para pagar o PIX, use o QR Code ou a chave do PIX.
    Banco: LadyBank
    Nome: ${this.client.name}
    Chave Pix: ${this.key_value}
    Quero ser Elas: www.ladybank.com.br`

    const Canvas = document.getElementById("qrcode").children[0] as HTMLCanvasElement; // Id here

    const image = Canvas.toDataURL();

    fetch(image)
        .then(res => res.blob())
        .then(blob => {

          const file = new File([blob], 'image.png', { type: 'image/png' });
          window.navigator.share({text: info_text, files: [file]} as ShareData);

        })
  }

  copy() {
    this.onConfirm();
    navigator.clipboard.writeText(this.qr_code).then(() => {
      this.dialogService.showSnackBar('Código copiado para área de trabalho', 'success');
    }).catch(e => console.error(e));
  }

  onConfirm() {
    this.modalRef.hide();
    this.confirmResult.next({ success: true, msg: ''})
  }

  get key_value() { return this.key.getValue() }
  get qr_code() { return this.qrCode.getValue() }
  get transfer_amount() { return parseFloat(this.amount.getValue()) }
}
