<div class="modal-content">
  <div class="modal-header border-0">
    <p class="font-weight-bolder text-gold x-big mt-2">Encerramento de conta</p>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span class="material-icons-outlined">highlight_off</span>
    </button>
  </div>
  <div class="row justify-content-center">
    <div class="col col-11 mx-auto text-justify mt-4">
      <p class="title">A LadyBank vai encerrar as contas digitais</p>
      <div class="small my-4">
        Até o dia 20 de agosto de 2022, você deverá:
        <ol>
          <li>Transferir o saldo em conta para outro banco <strong>ou</strong> sacar o valor nos caixas da Rede
            Banco24h.</li>
          <li>Encerrar a sua conta.</li>
        </ol>
      </div>
      <div class="msg small">
        <p>A conta será encerrada automaticamente a partir do dia 20 de agosto de 2022. Após esta data, se você ainda
          tiver saldo na conta, entre em contato com nossa Central de Atendimento:
          <a href="mailto:atendimento@ladybank.com.br" target="_blank">atendimento@ladybank.com.br</a>
        </p>
      </div>
    </div>
    <!-- <div class="col col-11 mx-auto text-center my-4">
      <strong class="small-plus" [ngClass]="{'text-success': balance > 0}">
        Saldo em conta {{balance | currency: 'BRL'}}</strong>
    </div> -->
  </div>
  <div class="col my-3 text-center mt-3">
    <button type="button" class="btn btn-primary" (click)="transfer()">TRANSFERIR DINHEIRO</button>
    <button type="button" class="btn btn-primary" (click)="onClose()">ENCERRAR A CONTA</button>
  </div>
</div>