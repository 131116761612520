export enum ApisEnum {
  Bank = '/bank',
  Partner = '/partner',
  Broker = '/broker',
  Client = '/client',
  Enums = '/enum',
  StatementsCategories = '/statement-categories',
  Jwt = '/jwt',
  LookupAccount = '/lookup-account',
  Loan = '/loan'
}
