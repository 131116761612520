<div class="content-register bg-register">
  <div class="col-12 col-md-8 col-lg-6 col-xl-5 mx-auto">
    <div class="card my-5 mx-lg-3">
      <div class="card-body">
        <div class="card-title text-center">
          <a href="/" class="d-flex justify-content-center">
            <img src="assets/images/png/logo_ladybank_lg.png" alt="">
          </a>
          <p class="my-5 poppins-bold text-center x-medium">
            Cadastre e confirme a sua senha
          </p>
        </div>
        <form [formGroup]="formPassword" class="row justify-content-md-center" (submit)="next()" autocomplete="off"
          novalidate>
          <div class="form-group col-12">
            <label for="password">Definir senha</label>
            <div class="position-relative">
              <input [type]="showPassword ? 'text': 'password'" class="form-control" id="password" name="password"
                formControlName="password" maxlength="200" PasswordStrength PreventCopyPast>
              <em class="material-icons pointer" (click)="showPassword = !showPassword">{{showPassword ?
                  'visibility_off' : 'visibility'}}</em>
              <invalid-feedback [field]="f.password"></invalid-feedback>
              <label id="password-strength">Nível de segurança: </label>
            </div>
          </div>
          <div class="form-group col-12">
            <label for="confirm_email">Confirmar senha</label>
            <div class="position-relative">
              <input [type]="showConfirmPassword ? 'text': 'password'" class="form-control" id="confirm_password"
                name="confirm_password" PreventCopyPast formControlName="confirm_password" maxlength="200">
              <em class="material-icons pointer" (click)="showConfirmPassword = !showConfirmPassword">{{showConfirmPassword ? 'visibility_off' :
                  'visibility'}}</em>
              <invalid-feedback [field]="f.confirm_password"></invalid-feedback>
            </div>
          </div>
          <div class="form-group col-12">
            <div class="form-check input-group">
              <input type="checkbox" id="accepted_terms" class="form-check-input" formControlName="accepted_terms">
              <label class="ml-1 mb-0 x-small" for="accepted_terms">
                Aceito os <a
                  href="https://elasbank-public.s3-sa-east-1.amazonaws.com/documents/ladybank_termos_e_condicoes.pdf"
                  target="_blank" class="link pointer">termo de uso</a> e a <a
                  href="https://elasbank-public.s3-sa-east-1.amazonaws.com/documents/elasbank_politica_de_protecao_de_dados.pdf"
                  class="link pointer" target="_blank">política de privacidade</a> da LadyBank
              </label>
              <invalid-feedback [field]="f.accepted_terms" class="row"></invalid-feedback>
            </div>
          </div>
          <div class="form-group col-12">
            <div class="form-check input-group">
              <input type="checkbox" id="email_opt_in" class="form-check-input" formControlName="email_opt_in">
              <label class="ml-1 mb-0 x-small" for="email_opt_in">
                Aceito receber informações e novidades por e-mail
              </label>
            </div>
          </div>
          <div class="form-group col-12">
            <div class="form-check input-group">
              <input type="checkbox" id="whatsapp_opt_in" class="form-check-input" formControlName="whatsapp_opt_in">
              <label class="ml-1 mb-0 x-small" for="whatsapp_opt_in">
                Aceito receber informações por WhatsApp
              </label>
            </div>
          </div>
          <div class="col-12">
            <div class="row justify-content-between my-4 px-1">
              <button type="button" class="mt-2 btn btn-outline-primary col-5" aria-label="Close"
                (click)="cancel()">CANCELAR</button>
              <button type="submit" class="mt-2 btn btn-primary col-5">PRÓXIMO</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>