<div class="modal-content">
  <div class="modal-header border-0">
    <span class="medium h1 w-100 m-0 text-center mr-n4">Criar um novo objetivo personalizado</span>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span class="material-icons-outlined">highlight_off</span>
    </button>
  </div>
  <div class="modal-body pt-0">
    <p class="m-0 small">Que tal incluir esse item de orçamento no seu planejamento futuro.</p>
    <p class="m-0 small">Quando se trata de um item recorrente, indicamos você ficar de olho nele.</p>
    <p class="m-0 small">Você pode definir por quantos meses a despesa (ou receita!!!) se repete - por exemplo: durante
      24 meses (2 anos).</p>
    <p class="m-0 small">Além disso, pode informar a frequência (em dias) com qual você espera ocorrer o pagamento.</p>
    <p class="m-0 small">Ou seja, indicando se o pagamento será a cada 30 dias, ou cada 60 dias etc.</p>
    <p class="m-0 small">Quer criar esse objetivo agora?</p>
  </div>
  <div class="modal-footer justify-content-center border-0">
    <button type="button" class="col col-12 col-md-5 btn btn-outline-dark" data-dismiss="modal"
      (click)="onClose('cancel')">CANCELAR</button>
    <button type="button" class="col col-12 col-md-5 my-3 my-md-0 btn btn-primary" (click)="onConfirm()">OK</button>
  </div>
</div>