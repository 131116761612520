import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppUpdateService } from './app-update.service';
import { UtilService } from './util.service';
import { StorageService as storage } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class IncomeService {

  incomes = [
    {
      value: "house",
      label: "Casa",
    },
    {
      value: "car",
      label: "Carro",
    },
    {
      value: "jewel",
      label: "Jóia",
    },
    {
      value: "coins",
      label: "Moedas",
    },
    {
      value: "actions",
      label: "Ações",
    },
    {
      value: "other",
      label: "Outro",
    },
  ]

  constructor(
    private http: HttpClient,
    private router: Router,
    private utilService: UtilService,
    private appUpdateService: AppUpdateService
  ) { }

    /**
   * Check App Update
   * @param res Response
   */
     private checkAppUpdate(res: Response | any): Response {
      if (res.headers.has('x-appversion')) {
        this.appUpdateService.appVersion.emit(res.headers.get('x-appversion'));
      }
      return res;
    }
  
   /**
   * Create a Client Income
   * @param data Data Incomes
   * @param jwt Client JWT
   */
    createIncome(client_uuid: string, data: Object, jwt?: string): Promise<any> {
      const url = this.utilService.makeUri(['/client', client_uuid, 'pms/income', { jwt: jwt || storage.getClientJwt() }]);
      const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

      const dataPost = this.utilService.buildPostData(data);

      return this.http.post(url, dataPost, { headers, observe: 'response' })
        .toPromise()
        .then(res => {
          this.checkAppUpdate(res);
          return res.body;
        })
        .catch(err => this.utilService.handleError(err, this.router));
    }
  
    /**
     * Update a Client Income
     * @param data Data Client Income
     * @param jwt Client JWT
     */
    updateIncome(client_uuid: string, income_id: number, data: Object, jwt?: string): Promise<any> {
      const url = this.utilService.makeUri(['/client', client_uuid, 'pms/income', income_id, { jwt: jwt || storage.getClientJwt() }]);
      const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

      const dataPost = this.utilService.buildPostData(data);

      return this.http.put(url, dataPost, { headers, observe: 'response' })
        .toPromise()
        .then(res => {
          this.checkAppUpdate(res);
          return res.body;
        })
        .catch(err => this.utilService.handleError(err, this.router));
    }
  
    /**
     * Update a Client Income
     * @param data Data Client Income
     * @param jwt Client JWT
     */
    deleteIncome(client_uuid: string, income_id: number, data: Object, jwt?: string): Promise<any> {
      const url = this.utilService.makeUri(['/client', client_uuid, 'pms/income', income_id, { jwt: jwt || storage.getClientJwt() }]);
      const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

      const dataPost = this.utilService.buildPostData(data);

      return this.http.delete(url, { headers, observe: 'response' })
        .toPromise()
        .then(res => {
          this.checkAppUpdate(res);
          return res.body;
        })
        .catch(err => this.utilService.handleError(err, this.router));
    }
      
      
    /**
     * Get Client Incomes
     * @param client_uuid Client UUID 
     * @param jwt Client JWT
     */
    getIncome(client_uuid: string, jwt?: string): Promise<any> {
      const url = this.utilService.makeUri(['/client', client_uuid, 'pms/incomes', { jwt: jwt || storage.getClientJwt() }]);
      return this.http.get(url, { observe: 'response' })
        .toPromise()
        .then(res => {
          this.checkAppUpdate(res);
          return res.body;
        })
        .catch(err => this.utilService.handleError(err, this.router));
    }

    getSubtype(subtype){
      switch (subtype) {
        case 'house':
          return 'Casa';
        case 'jewel':
          return 'Jóia';
        case 'car':
          return 'Carro';
        case 'coins':
          return 'Moedas';
        case 'actions':
          return 'Ações';
        case 'house':
          return 'Casa';     
        default:
          return 'Outro';
      }
    }
}
