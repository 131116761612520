import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Utils } from 'src/app/shared/commons/Utils';
import { Validations } from 'src/app/shared/commons/Validations';
import { AccountModel } from 'src/app/shared/interfaces/account-model';
import { ClientModel } from 'src/app/shared/interfaces/client-model';
import { ClientService } from 'src/app/shared/services/client.service';
import { StorageService as storage } from 'src/app/shared/services/storage.service';
import * as moment from 'moment';

@Component({
  selector: 'app-pix-reverse-dialog',
  templateUrl: './pix-reverse-dialog.component.html',
  styleUrls: ['./pix-reverse-dialog.component.scss'],
  providers: [CurrencyPipe]
})
export class PixReverseDialogComponent implements OnInit {

  client = {} as ClientModel
  account = {} as AccountModel
  model: any = null;
  step: number
  formGroup: FormGroup
  confirmResult: Subject<any | boolean>;
  balanceAccount: number
  transactionId: string
  minCurrency = 1
  maxCurrency = 1
  showModal = false

  constructor(
    private clientService: ClientService,
    private currencyPipe: CurrencyPipe,
    private fb: FormBuilder,
    public modalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
    this.confirmResult = new Subject()

    this.clientService.clientData.subscribe(d => {
      if (!d) { return }
      this.client = d;
    });

    this.clientService.accountData.subscribe(d => {
      if (!d) { return }
      this.account = d;
    });

    if (storage.get('i_understood_reverse_pix'))
      this.step = 1

    setTimeout(() => {
      this.maxCurrency = this.model.amount
      this.formGroup = this.fb.group({
        amount: ['', Validators.compose([
          Validators.required, Validations.minCurrency(this.minCurrency), Validations.maxCurrency(this.maxCurrency)
        ])],
        description: ['']
      })
      this.showModal = true
    }, 300);
  }

  
  setFormattedDocument(document) {
    let element = ''
    for (let index = 0; index < document.length; index++) {
      if (index < 3 || index > 8)
        element += '*';
      else 
        element += document[index];
    }
    return element
  }

  addMount(value) {
    let amount = Utils.moneyTofloat(this.formGroup.get('amount').value)
    amount += value
    this.formGroup.get('amount').setValue(this.currencyPipe.transform(amount, 'BRL'))
  }

  iUnderstood(value) {
    storage.save('i_understood_reverse_pix', value)
  }

  changeStep(value) {
    if (value == 4) {
      if (this.formGroup.valid) {
        const data = {
          amount: Utils.moneyTofloat(this.formGroup.get('amount').value),
          description: this.formGroup.get('description').value,
          original_transaction_id: this.model.otherInfo.id,
          receiver: {
            account: this.model.otherInfo.payer.accountNumber,
            account_type: 'CACC',
            bank_branch: this.model.otherInfo.payer.bankBranch,
            document: this.model.otherInfo.payer.document,
            ispb: this.model.otherInfo.payer.ispb,
            name: this.model.otherInfo.payer.name,
            type: this.model.otherInfo.payer.personType
          }
        }
        this.confirmResult.next(data)
        this.modalRef.hide()
      } else {
        this.formGroup.markAllAsTouched()
      }
    } else if (value == 5) {
      this.confirmResult.next(true)
      this.modalRef.hide()
    } else if (value == 2) {
      if (this.formGroup.controls['amount'].valid) {
        this.step = value
      } else {
        this.formGroup.controls['amount'].markAsTouched();
      }
    } else {
      this.step = value
    }
  }

  get today() { return new Date(moment().unix()) }
}
