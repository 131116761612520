import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ApisEnum } from '../enums/apis.enum';
import { AppUpdateService } from './app-update.service';
import { StorageService as storage } from './storage.service'
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  @Output() progressData: BehaviorSubject<any> = new BehaviorSubject<any>(null)

  constructor(
    private http: HttpClient,
    private router: Router,
    private appUpdateService: AppUpdateService,
    private utilService: UtilService
  ) { }

  /**
   * Check App Update
   * @param res Response
   */
  private checkAppUpdate(res: Response | any): Response {
    if (res.headers.has('x-appversion')) {
      this.appUpdateService.appVersion.emit(res.headers.get('x-appversion'));
    }
    return res;
  }

  /**
   * Get JWT Information
   * @param jwt JSON Web Token
   */
  getJWTInfo(jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Jwt, { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Revoke JWT
   * @param jwt JSON Web Token
   */
  revokeJWT(jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Jwt, { jwt: jwt || storage.getClientJwt() }]);
    return this.http.delete(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Renew JWT
   * @param jwt JSON Web Token
   */
   renewJWT(jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Jwt, { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    return this.http.put(url, {}, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Lookup Account
   * @param data Data Search
   * @param jwt JSON Web Token
   */
  lookupAccount(data: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.LookupAccount, { jwt: jwt || storage.getClientJwt() }, data]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Get Statement Categories
   * @param data Data Search
   * @param jwt JSON Web Token
   */
  getStatementCategories(jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.StatementsCategories, { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }


  getNews(jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Partner, 'news_all', { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  getNextWorkDay(date, jwt?: string) {
    const url = this.utilService.makeUri(['next-workday', 'BR', { date: date }, { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  getFirms(data?) {
    const url = this.utilService.makeUri(['pms/firms', data, { jwt: storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  getVersion() {
    const url = this.utilService.makeUri(['version']);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }
}
