import { PhoneService, PhoneEnum } from './../../shared/services/phone.service';
import { UtilService } from './../../shared/services/util.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Utils } from 'src/app/shared/commons/Utils';
import { Validations } from 'src/app/shared/commons/Validations';
import { ClientModel } from 'src/app/shared/interfaces/client-model';
import { ClientService } from 'src/app/shared/services/client.service';
import { PhoneModel } from 'src/app/shared/interfaces/phone-model';
import { ApisEnum } from 'src/app/shared/enums/apis.enum';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ConfirmDialogViewModel } from '../confirm-dialog/confirm-dialog.view-model';

@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss']
})
export class ContactDialogComponent implements OnInit {

  client = {} as ClientModel;
  phone = {} as PhoneModel;
  confirmResult: Subject<boolean | string>;
  formGroup: FormGroup;
  strPhone = '';
  phoneVerifyRetry: number = 0;
  resPhone: PhoneModel;
  confirmEmail: string;

  constructor(
    public modalRef: BsModalRef,
    private clientService: ClientService,
    private dialogService: DialogService,
    private phoneService: PhoneService,
    private formBuilder: FormBuilder,
    private utilService: UtilService
  ) { 
    this.clientService.clientData.subscribe(d => {
      if (!d) { return; }
      this.client = d;
      if (this.client.whatsapp_opt_in == null) {
        this.client.whatsapp_opt_in = true;
        this.groupClient.whatsapp_opt_in.markAsDirty();
      }
      if (this.client.email)
        this.confirmEmail = this.client.email
    });
    this.clientService.phoneData.subscribe(d => {
      if (!d) { return; }
      this.phone = d;
      this.strPhone = Utils.maskMobile(d.code_area + d.number)
    });
  }

  ngOnInit() {
    this.confirmResult = new Subject();
    this.buildForm();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      client: this.formBuilder.group({
        whatsapp_opt_in: [{
          value: this.client.whatsapp_opt_in,
        }, Validators.required],
        email: [{
          value: this.client.email,
        }, Validators.compose([
          Validators.required,
          Validators.pattern(Utils.getEmailPattern())
        ])],
        confirm_email: [{
          value: this.confirmEmail
        }, Validators.compose([
          Validators.required,
          Validators.pattern(Utils.getEmailPattern())
        ])]
      }),
      phone: this.formBuilder.group({
        phone: [{
          value: { value: this.strPhone, disabled: true }
        }, Validators.compose([
          Validators.required,
          Validations.Phone
        ])],
      })
    })
    this.formGroup.get('client').setValidators(Validations.EmailsMatch)
  }

  close() {
    this.confirmResult.next(false);
    this.modalRef.hide();
  }

  update() {
    if (this.formGroup.valid) {
      const clientData = Object.assign({} as any, this.utilService.dirty_controls_data(this.groupClient));
      const phoneData = Object.assign({} as any, this.utilService.dirty_controls_data(this.groupPhone));

      if (Object.keys(clientData).length > 0) {
        delete clientData.confirm_email
        this.clientService.update(clientData).then(result => {
          this.client = result
          this.confirmResult.next(true);
        }).catch(error => {
          this.confirmResult.next(false);
          this.dialogService.showDialogError('Email inválido')
        })

      }

      if (Object.keys(phoneData).length > 0) {
        const phone = Utils.unMask(phoneData.phone)
        phoneData.code_country = '55'
        phoneData.code_area = phone.substring(0, 2)
        phoneData.number = phone.substring(2, 11)
        phoneData.type = PhoneEnum.mobile
        phoneData.force = true
        delete phoneData.phone;
        this.phoneService.create(ApisEnum.Client, this.client.uuid, phoneData, this.client.jwt).then(result => {
          this.resPhone = result;
          this.showModalToken(this.client, this.resPhone);
        });

        this.confirmResult.next(true);
      }


      this.modalRef.hide();

    } else {
      this.formGroup.markAllAsTouched()
    }
  }

  showModalToken(client: ClientModel, phone: PhoneModel) {
    this.phoneService.sendVerify(ApisEnum.Client, client.uuid, phone, this.phoneVerifyRetry, client.jwt);
    const result$ = this.dialogService.showTokenDialog();
    result$.subscribe(result => {
      if (result == 'resend') {
        this.phoneVerifyRetry += 1;
        this.phoneService.sendVerify(ApisEnum.Client, client.uuid, phone, this.phoneVerifyRetry, client.jwt);
      } else {
        this.checkVerify(client, phone, { sms_code: result });
      }
    });
  }

  checkVerify(client: ClientModel, phone: PhoneModel, data: any) {
    this.phoneService.checkVerify(ApisEnum.Client, client.uuid, phone.id, data, client.jwt).then(res => {
      let dialogViewModel = {} as ConfirmDialogViewModel;
      if (res.status === 400) {
        dialogViewModel.subTitle = 'Código incorreto';
        dialogViewModel.msg = 'infelizmente, algo deu errado com o código.';
        dialogViewModel.txtOk = 'TENTAR OUTRA VEZ';
        this.dialogService.showDialogTryAgain(dialogViewModel).subscribe(result => {
          this.resultDialog(result)
        })
      } else if (res.status === 404) {
        dialogViewModel.msg = 'Seu código de confirmação está expirado. Você deve gerar um novo código de confirmação e tentar novamente.';
        dialogViewModel.tryAgain = true;
        dialogViewModel.txtOk = 'TENTAR OUTRA VEZ';
        this.dialogService.showDialogTryAgain(dialogViewModel).subscribe(result => {
          this.resultDialog(result)
        })
      } else if (res.status === 'cancel') {
        return;
      } else if (res.status === 200) {
        dialogViewModel.subTitle = 'Código validado com sucesso';
        this.dialogService.showDialogSuccess(dialogViewModel).subscribe(result => {
          this.resultDialog(result)
        })
      }
    })
  }

  resultDialog(result) {
    if (result === 'try-again') {
      this.showModalToken(this.client, this.resPhone);
    } else {
      this.deleteOldPhone()
    }
  }

  deleteOldPhone() {
    this.phoneService.delete(this.client.uuid, this.phone.id, this.client.jwt).then(() => {
      this.confirmResult.next(true);
      this.modalRef.hide();
    })
  }

  // convenience getter for easy access to form fields
  get groupClient() { return this.formGroup.get('client')['controls']; }
  get groupPhone() { return this.formGroup.get('phone')['controls']; }
}
