import { Directive, Input, HostListener } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[Mask]'
})
export class MaskDirective {

  @Input('Mask') customMask: string;

  constructor() { }

  @HostListener('keypress', ['$event'])
  onkeyup($event: any) {
    if (!this.customMask)
      return

    const fieldSize = $event.target.value.length;
    let boolMask;

    const keyCode = $event.keyCode;
    const exp = /\-|\.|\/|\(|\)|\ |\d+$ /g;
    const filter = $event.target.value.toString().replace(exp, '');

    let position = 0;
    let newValue = '';
    let maskSize = filter.length;

    if (fieldSize === this.customMask.length
      || !(keyCode >= 48 && keyCode <= 57)) {
      $event.preventDefault();
    }

    if (keyCode !== 8) { // backspace
      for (let i = 0; i <= maskSize; i++) {
        boolMask = ((this.customMask.charAt(i) === '-') || (this.customMask.charAt(i) === '.')
          || (this.customMask.charAt(i) === '/'));
        boolMask = boolMask || ((this.customMask.charAt(i) === '(')
          || (this.customMask.charAt(i) === ')') || (this.customMask.charAt(i) === ' '));
        if (boolMask) {
          newValue += this.customMask.charAt(i);
          maskSize++;
        } else {
          newValue += filter.charAt(position);
          position++;
        }
      }
      $event.target.value = newValue;
      return true;
    } else {
      return true;
    }
  }

  @HostListener('blur', ['$event'])
  onBlur($event: any) {
    this.onkeyup($event);
  }
}
