import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: ['./session-expired.component.scss']
})
export class SessionExpiredComponent implements OnInit {

  @Input() title = 'SUA SESSÃO FOI EXPIRADA POR TEMPO INATIVO'
  @Input() msg: string = 'Faça login novamente!';
  confirmResult: Subject<boolean>;
  
  constructor(
    public modalRef: BsModalRef
  ) { }

  ngOnInit(): void {
    this.confirmResult = new Subject();
  }

  onClose() {
    this.confirmResult.next(true);
    this.modalRef.hide();
  }
  
}
