import { Component, OnInit, Input, Output, EventEmitter, ViewChild, HostListener, ElementRef } from '@angular/core';
import { UploadFileService } from './upload-file.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'custom-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: UploadComponent,
      multi: true
    }
  ]
})
export class UploadComponent implements ControlValueAccessor {

  @Input() idFile = 'customFile';
  @Input() previewImg = false;
  @Input() previewFilePath = true;
  @Input() labelFile = 'Selecionar arquivo';
  @Input() classLabelFile;
  @Input() classUpload;
  @Input() callout: string = null; // Texto explicativo
  @Input() requiredFileText: string = null; // Text Required File
  @Input() requiredFile: boolean = null; // Control Show Required Text
  @Input() type: string;
  @Input() subType: string; // Texto explicativo
  @Input() accept: string;
  @Input() size: number; // valor em MB
  @Input() src: string | ArrayBuffer;
  @Input() showIcon = true;
  @Input() showTooltip = false;
  @Input() textTooltip = '';
  @Output() CompletedUpload = new EventEmitter();
  srcImg: string;
  maxSize: number;
  showModal = false;
  progress: number = 0;

  file: File | null = null;
  onChange: Function;

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    const file = event && event.item(0);
    this.onChange(file)
    this.submit(file);
  }

  constructor(
    private host: ElementRef<HTMLInputElement>,
    private service: UploadFileService
  ) { }

  writeValue(value: null) {
    // clear file input
    this.host.nativeElement.value = '';
    this.file = null;
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) {
    // console.log(fn);
  }

  ngOnInit(): void {
    this.srcImg = `url(${this.src})`;
    this.maxSize = this.size * 1024000;

    this.service.emitterChangeFile.subscribe(
      file => {
        if (file.id === this.idFile && file.src) {
          this.srcImg = `url(${file.src})`;
        } else if (file.id === this.idFile && file.label_file) {
          this.labelFile = file.labelFile;
        }
      }
    );
  }

  submit(file) {
    if (file.size < this.maxSize && this.accept.includes(file.type)) {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = (e) => {
        // let base64 = e.target.result.toString().split(',')[1];
        // let blob = new Blob([window.atob(base64)], { type: file.type });
        const blob = this.service.base64ToFile(e.target.result.toString());
        this.onSubmit(reader.result, blob, file);
      };
    }
  }

  onSubmit(img, blob: any, file: File) {

    this.service.create(file, blob, this.type, this.subType).subscribe((event: HttpEvent<any>) => {
      try {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            this.progress = Math.round((100 * event.loaded) / event.total);
            break;
          case HttpEventType.Response:
            setTimeout(() => {
              this.progress = 0;
              if (this.previewImg) {
                this.srcImg = `url(${img})`;
              }
              this.file = file;
              this.CompletedUpload.emit(this.file);
            }, 1500);
        }
      } catch (error) {
        console.error(error);
        this.progress = 0;
      }
    })
  }
}
