import { Validations } from 'src/app/shared/commons/Validations';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { TransferTypeEnum } from 'src/app/shared/enums/transfer-type.enum';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AccountTypeEnum } from 'src/app/shared/enums/account-type.enum';
import { Banks } from 'src/app/shared/commons/Banks';

@Component({
  selector: 'app-edit-favorite-dialog',
  templateUrl: './edit-favorite-dialog.component.html',
  styleUrls: ['./edit-favorite-dialog.component.scss']
})
export class EditFavoriteDialogComponent implements OnInit {

  model: any = {};
  formGroup: FormGroup;
  bankList: any = Banks.get();
  bankId: number;
  confirmResult: Subject<any>;
  editModel: any = {}

  constructor(
    public modalRef: BsModalRef,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.confirmResult = new Subject();
    this.loadForm();
  }

  loadForm() {
    this.formGroup = this.formBuilder.group({
      nameFavorite: ['', Validators.required],
      bank: ['', Validators.required],
      agency: ['', Validators.required],
      account: ['', Validators.required],
      digit: ['', Validators.required],
      document: ['', Validators.compose([Validators.required, Validations.CpfOrCnpj])],
      // accountType: ['', Validators.required]
    })
    setTimeout(() => {
      this.editModel = Object.assign({}, this.model)
      const t = this.model.number.length - 1
      this.editModel.digit = this.model.number.substr(t)
      this.editModel.account = this.model.number.substr(0, t)
      if (this.editModel.extra_data && this.editModel.extra_data.internal) {
        this.formGroup.controls.document.clearValidators()
        this.formGroup.controls.document.disable()
      }
    }, 100);
  }

  onConfirm() {
    if (this.formGroup.valid) {
      this.confirmResult.next(this.editModel);
      this.modalRef.hide();
    } else {
      this.formGroup.markAllAsTouched();
    }
  }

  get TransferType() {
    return TransferTypeEnum
  }
 
  get AccountType() {
    return Object.keys(AccountTypeEnum).map(key => {
      return ({ id: key, name: AccountTypeEnum[key] })
    })
  }
  
  get f() { return this.formGroup.controls; }
}
