<div class="content-login">
  <div class="content-image">
    <div class="col-lg-6 col-md-8 col-sm-12">
      <div class="shadow bg-white rounded pt-3">
        <a href="/" class="navbar-brand ml-3">
          <img src="assets/images/png/logo_ladybank.png" alt="">
        </a>
        <p class="big ml-2 mt-5 font-weight-bold px-3">Verificação de E-mail</p>
        <div class="px-2">
          <form [formGroup]="formGroup" class="justify-content-center px-3 pb-4" autocomplete="off" novalidate
            (submit)="verify()">
            <div class="form-group">
              <input type="text" id="document_number" name="document_number" class="form-control" Mask="###.###.###-##"
                formControlName="document_number" placeholder="000.000.000-00" maxlength="30">
              <div *ngIf="(!formGroup.controls.document_number.pristine || formGroup.controls.document_number.touched) 
                && formGroup.controls.document_number.errors" class="invalid-feedback x-small">
                <div *ngIf="formGroup.controls.document_number.errors.required"> Campo obrigatório. </div>
                <div *ngIf="formGroup.controls.document_number.errors.invalid"> CPF inválido. </div>
              </div>
            </div>
            <div class="text-center">
              <button class="btn btn-primary" type="submit">ENTRAR</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>