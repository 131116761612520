import { ActivatedRoute } from '@angular/router';
import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment as env } from '../environments/environment';
import { AppUpdateService } from './shared/services/app-update.service';
import { ClientService } from './shared/services/client.service';
import { StorageService as storage } from 'src/app/shared/services/storage.service';
import { CommonService } from './shared/services/common.service';
import { AppNavRouteLink } from './shared/commons/AppNavRouteLink';
import { filter, map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { AppConfigService } from './shared/services/app-config.service';

declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'elasbank';
  clientData: any = null;
  appVersion: string = null;
  apiVersion: any = {};
  checkJWTRenew: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private clientService: ClientService,
    private commonService: CommonService,
    private gtmService: GoogleTagManagerService,
    private appUpdateService: AppUpdateService,
    private titleService: Title,
    private appConfigService: AppConfigService
  ) {
    this.clientService.clientData.subscribe(d => {
      if (!d) { return; }
      this.clientData = d;
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild
        while (child) {
          if (child.firstChild) {
            child = child.firstChild
          } else if (child.snapshot.data && child.snapshot.data['title']) {
            return child.snapshot.data['title']
          } else {
            return null
          }
        }
        return null;
      })
    ).subscribe((data: any) => {
      if (data) {
        this.titleService.setTitle(`${data} - LadyBank`)
      }
    })
  }

  @HostListener('click', ['$event'])
  @HostListener('window:keydown', ['$event'])
  @HostListener('mouseover', ['$event'])
  handleEvents(e: Event) {
    if (this.checkJWTRenew) {
      return;
    }

    this.checkJWTRenew = true;
    const expires_in = storage.get('session_expires_in');

    if (expires_in) {
      const date_renew = (Date.now() / 1000) + 120;
      if (expires_in < date_renew && date_renew > expires_in) {

        const client_jwt = storage.getClientJwt();
        if (client_jwt) {
          this.commonService.renewJWT(client_jwt)
            .then(res => {
              if ('jwt' in res) {
                storage.saveClientJwt(res['jwt']);
                storage.save('session_expires_in', res['expires_in']);
              }
              this.checkJWTRenew = false;
            })
            .catch(() => {
              storage.delete('session_expires_in');
              storage.deleteClientJwt();
              storage.clear();
              this.clientService.resetData();
              // this.router.navigate([AppNavRouteLink.PAGE.LOGIN]);
              this.checkJWTRenew = false;
            });
        } else {
          this.checkJWTRenew = false;
        }
      } else {
        this.checkJWTRenew = false;
      }
    } else {
      this.checkJWTRenew = false;
    }
    return;
  }

  ngOnInit() {
    this.commonService.getVersion().then(res => {
      this.apiVersion = res
    })
    this.appUpdateService.appVersion.subscribe(d => {
      if (!d) { return; }

      const env_name = this.appConfigService.config['API_ENV'];

      if (env_name && env_name !== 'production') {
        this.appVersion = env_name.toUpperCase() + ' - ' + d;
      } else {
        this.appVersion = d;
      }
    })

    const reClenQs = /(\/[^?]*).*/;
    const reUUID = /\/[a-f\d]{8}(\-[a-f\d]{4}){3}\-[a-f\d]{12}/gi;

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {

        // Google Tag Manager
        if (env.production === true) {
          const url = e.urlAfterRedirects;
          const path = url.replace(reClenQs, '$1')
            .replace(reUUID, '')
            .replace(/\/{2,}/g, '/');

          const gtmTag = {
            event: 'page',
            pageName: path
          };

          if (this.clientData) {
            gtmTag['userID'] = this.clientData['uuid'];
          }
          this.gtmService.pushTag(gtmTag);
        }
      }
    });
  }

  onDeactivate() {
    document.body.scrollTop = 0;
  }
}
