<div class="modal-content">
  <div class="modal-header border-0">
    <span class="text-center title-medium h1 w-100 mx-0">{{title}}</span>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span class="material-icons-outlined">highlight_off</span>
    </button>
  </div>
  <div class="justify-content-md-center">
    <div class="col col-12 text-center small-plus" [innerHTML]="msg"></div>
  </div>
  <div class="col my-3 text-center">
    <button type="button" class="btn btn-primary" (click)="close()">OK, ENTENDI</button>
  </div>
</div>
