<div class="modal-content">
  <div class="modal-header border-0">
    <span class="medium text-center font-weight-bold w-100 m-auto"> Criar novo objetivo </span>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span class="material-icons-outlined">highlight_off</span>
    </button>
  </div>
  <p class="text-center small">Por favor, escolha um objetivo pré-definido ou crie um objetivo personalizado.</p>
  <div class="modal-body">
    <div class="row">
      <ul *ngFor="let item of listGoal">
        <li>
          <button class="btn btn-goal m-0" [class.active]="goalActive === item" (click)="goalActive = item">
            <icon-svg [svgName]="item.icon" class="small mr-2"></icon-svg>
            <span class="poppins xx-small">{{item.name}}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
  <div class="modal-footer text-center border-0 m-auto">
    <button type="button" class="btn btn-outline-dark" data-dismiss="modal" (click)="modalRef.hide()">VOLTAR</button>
    <button type="button" class="btn btn-primary" (click)="onConfirm()" [disabled]="!goalActive">CONTINUAR</button>
  </div>
</div>