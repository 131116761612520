<div id="mySidenav" class="sidenav left shadow-lg pb-5" [ngClass]="{'show': showMenu}">
  <header class="header-menu mt-3">
    <section class="text-center">
      <p class="title">{{client.name | titlecase}}</p>
    </section>
  </header>
  <div class="list-group list-group-flush border-bottom d-inline-flex d-md-none" *ngIf="client.has_bank_account">
    <p class="col-12 small-plus"><span class="font-weight-bold">Banco: </span>{{account.bank_code}}</p>
    <p class="col-12 small-plus"><span class="font-weight-bold">AG: </span>{{account.branch}}</p>
    <p class="col-12 small-plus"><span class="font-weight-bold">Conta: </span>{{account.number}}</p>
  </div>
  <nav class="list-group list-group-flush border-bottom" aria-label="Meus dados">
    <div class="menu-item" [routerLink]="routerLink.TABS_MY_DATA.ACCOUNT_DATA" (click)="scroll_top()">
      <div class="icon-menu person"></div>
      <span class="menu-item-link">Meus Dados</span>
    </div>
    <div class="menu-item" [routerLink]="routerLink.TABS_MY_DATA.ACCOUNT_PLAN"
      [class.disabled]="checkProtectedRouter(routerLink.TABS_MY_DATA.ACCOUNT_PLAN)" (click)="scroll_top()">
      <div class="icon-menu price"></div>
      <span class="menu-item-link">Meu plano de conta</span>
    </div>
    <div class="menu-item" [routerLink]="routerLink.TABS_MY_DATA.INVESTOR_PROFILE" (click)="scroll_top()">
      <div class="icon-menu investor-profile"></div>
      <span class="menu-item-link">Perfil de Investidor</span>
    </div>
  </nav>
  <nav class="list-group list-group-flush border-bottom py-3" aria-label="Menu" *ngIf="client.has_bank_account">
    <div class="menu-item" [routerLink]="routerLink.NAV.DASHBOARD"
      [class.disabled]="checkProtectedRouter(routerLink.NAV.DASHBOARD)" (click)="scroll_top()">
      <div class="icon-menu dashboard"></div>
      <span class="menu-item-link">Início</span>
    </div>
    <div class="menu-item" [routerLink]="routerLink.TABS_ACCOUNT.PIX"
      [class.disabled]="checkProtectedRouter(routerLink.TABS_PIX.AREA)" (click)="scroll_top()">
      <div class="icon-menu pix"></div>
      <span class="menu-item-link">PIX</span>
    </div>
    <div class="menu-item" [routerLink]="routerLink.TABS_ACCOUNT.EXTRACT"
      [class.disabled]="checkProtectedRouter(routerLink.TABS_ACCOUNT.EXTRACT)" (click)="scroll_top()">
      <div class="icon-menu extract"></div>
      <span class="menu-item-link">Extratos e comprovantes</span>
    </div>
    <div class="menu-item" [routerLink]="routerLink.TABS_ACCOUNT.TRANSFERS"
      [class.disabled]="checkProtectedRouter(routerLink.TABS_ACCOUNT.TRANSFERS)">
      <div class="icon-menu transfer"></div>
      <span class="menu-item-link">Transferir dinheiro</span>
    </div>
    <div class="menu-item" [routerLink]="routerLink.TABS_ACCOUNT.PAYMENTS"
      [class.disabled]="checkProtectedRouter(routerLink.TABS_ACCOUNT.PAYMENTS)">
      <div class="icon-menu payment"></div>
      <span class="menu-item-link">Pagar conta</span>
    </div>
    <div class="menu-item" [routerLink]="routerLink.NAV.DEPOSIT_BY_BANK_SLIP"
      [class.disabled]="checkProtectedRouter(routerLink.NAV.DEPOSIT_BY_BANK_SLIP)">
      <div class="icon-menu deposit"></div>
      <span class="menu-item-link">Depósito por boleto</span>
    </div>
    <div class="menu-item" [routerLink]="routerLink.TABS_ACCOUNT.AREA"
      [class.disabled]="checkProtectedRouter(routerLink.TABS_ACCOUNT.AREA)" (click)="scroll_top()">
      <div class="icon-menu accounts"></div>
      <span class="menu-item-link">Minhas Contas</span>
    </div>
    <div class="menu-item" [routerLink]="routerLink.NAV.MY_CARDS"
      [class.disabled]="checkProtectedRouter(routerLink.NAV.MY_CARDS)" (click)="scroll_top()">
      <div class="icon-menu cards"></div>
      <span class="menu-item-link">Meus Cartões</span>
    </div>
    <div class="menu-item" [routerLink]="routerLink.TABS_FINANCIAL_PLANNING.HOW_TO_PLAN"
      [class.disabled]="checkProtectedRouter(routerLink.TABS_FINANCIAL_PLANNING.HOW_TO_PLAN)">
      <div class="icon-menu financial-planning"></div>
      <span class="menu-item-link">Planejamento financeiro</span>
    </div>
    <div class="menu-item" [routerLink]="routerLink.NAV.MY_INVESTMENTS">
      <div class="icon-menu investments"></div>
      <span class="menu-item-link">Investimentos</span>
    </div>
    <div class="menu-item" [routerLink]="routerLink.NAV.EXCHANGE"
      [class.disabled]="checkProtectedRouter(routerLink.NAV.EXCHANGE)">
      <div class="icon-menu cambio"></div>
      <span class="menu-item-link">Câmbio</span>
    </div>
    <div class="menu-item" [routerLink]="routerLink.NAV.MY_LOANS"
      [class.disabled]="checkProtectedRouter(routerLink.NAV.MY_LOANS)">
      <div class="icon-menu loans"></div>
      <span class="menu-item-link">Empréstimos</span>
    </div>
  </nav>
  <ng-container *ngIf="!client.has_bank_account">
    <nav class="list-group list-group-flush border-bottom py-2" aria-label="Menu">
      <div class="menu-item" [routerLink]="routerLink.NAV.DASHBOARD"
        [class.disabled]="checkProtectedRouter(routerLink.NAV.DASHBOARD)" (click)="scroll_top()">
        <div class="icon-menu dashboard"></div>
        <span class="menu-item-link">Início</span>
      </div>
    </nav>

    <nav class="list-group list-group-flush border-bottom py-2" aria-label="Menu">
      <div class="menu-item" [routerLink]="routerLink.TABS_FINANCIAL_PLANNING.HOW_TO_PLAN"
        [class.disabled]="checkProtectedRouter(routerLink.TABS_FINANCIAL_PLANNING.HOW_TO_PLAN)">
        <div class="icon-menu financial-planning"></div>
        <span class="menu-item-link fw-bold">Planejamento financeiro</span>
      </div>
      <div class="menu-item sub-item" [routerLink]="routerLink.TABS_FINANCIAL_PLANNING.MANAGE_ACCOUNTS"
        [class.disabled]="checkProtectedRouter(routerLink.TABS_FINANCIAL_PLANNING.MANAGE_ACCOUNTS)">
        <div class="icon-menu accounts"></div>
        <span class="menu-item-link font-weight-normal">Minhas contas</span>
      </div>
      <div class="menu-item sub-item" [routerLink]="routerLink.TABS_FINANCIAL_PLANNING.BUDGET"
        [class.disabled]="checkProtectedRouter(routerLink.TABS_FINANCIAL_PLANNING.BUDGET)">
        <div class="icon-menu budget"></div>
        <span class="menu-item-link font-weight-normal">Analisar minhas contas</span>
      </div>
      <div class="menu-item sub-item" [routerLink]="routerLink.TABS_FINANCIAL_PLANNING.INSIGHT"
        [class.disabled]="checkProtectedRouter(routerLink.TABS_FINANCIAL_PLANNING.INSIGHT)">
        <div class="icon-menu insight"></div>
        <span class="menu-item-link font-weight-normal">Fazer orçamento</span>
      </div>
      <div class="menu-item sub-item" [routerLink]="routerLink.TABS_FINANCIAL_PLANNING.LIFE_SITUATION"
        [class.disabled]="checkProtectedRouter(routerLink.TABS_FINANCIAL_PLANNING.LIFE_SITUATION)">
        <div class="icon-menu life-situation"></div>
        <span class="menu-item-link font-weight-normal">Definir situação de vida</span>
      </div>
      <div class="menu-item sub-item" [routerLink]="routerLink.TABS_FINANCIAL_PLANNING.GOALS"
        [class.disabled]="checkProtectedRouter(routerLink.TABS_FINANCIAL_PLANNING.GOALS)">
        <div class="icon-menu goals"></div>
        <span class="menu-item-link font-weight-normal">Definir meus objetivos de vida</span>
      </div>
      <div class="menu-item sub-item" [routerLink]="routerLink.TABS_FINANCIAL_PLANNING.PATRIMONY"
        [class.disabled]="checkProtectedRouter(routerLink.TABS_FINANCIAL_PLANNING.PATRIMONY)">
        <div class="icon-menu patrimony"></div>
        <span class="menu-item-link font-weight-normal">Gerenciar patrimônio</span>
      </div>
      <div class="menu-item sub-item" [routerLink]="routerLink.TABS_FINANCIAL_PLANNING.MY_RECIPES"
        [class.disabled]="checkProtectedRouter(routerLink.TABS_FINANCIAL_PLANNING.MY_RECIPES)">
        <div class="icon-menu my-recipes"></div>
        <span class="menu-item-link font-weight-normal">Planejar minhas receitas</span>
      </div>
      <div class="menu-item sub-item" [routerLink]="routerLink.TABS_FINANCIAL_PLANNING.FUTURE_PLANS"
        [class.disabled]="checkProtectedRouter(routerLink.TABS_FINANCIAL_PLANNING.FUTURE_PLANS)">
        <div class="icon-menu future-plans"></div>
        <span class="menu-item-link font-weight-normal">Entenda seus planos futuros</span>
      </div>
    </nav>

    <nav class="list-group list-group-flush border-bottom py-2" aria-label="Menu">
      <div class="menu-item" [routerLink]="routerLink.NAV.MY_INVESTMENTS"
        [class.disabled]="checkProtectedRouter(routerLink.NAV.MY_INVESTMENTS)">
        <div class="icon-menu investments"></div>
        <span class="menu-item-link fw-bold">Investimentos</span>
      </div>
      <div class="menu-item sub-item" [routerLink]="routerLink.TABS_INVESTMENT.CURRENT_PORTFOLIO"
        [class.disabled]="checkProtectedRouter(routerLink.TABS_INVESTMENT.CURRENT_PORTFOLIO)">
        <div class="icon-menu current-portfolio"></div>
        <span class="menu-item-link font-weight-normal">Carteira atual</span>
      </div>
      <div class="menu-item sub-item" [routerLink]="routerLink.TABS_INVESTMENT.IDEAL_PORTFOLIO"
        [class.disabled]="checkProtectedRouter(routerLink.TABS_INVESTMENT.IDEAL_PORTFOLIO)">
        <div class="icon-menu ideal-portfolio"></div>
        <span class="menu-item-link font-weight-normal">Carteira ideal</span>
      </div>
      <div class="menu-item sub-item" [routerLink]="routerLink.TABS_INVESTMENT.MY_ORDERS"
        [class.disabled]="checkProtectedRouter(routerLink.TABS_INVESTMENT.MY_ORDERS)">
        <div class="icon-menu my-orders"></div>
        <span class="menu-item-link font-weight-normal">Minhas ordens</span>
      </div>
      <div class="menu-item sub-item" [routerLink]="routerLink.TABS_INVESTMENT.MY_EXPECTATIONS"
        [class.disabled]="checkProtectedRouter(routerLink.TABS_INVESTMENT.MY_EXPECTATIONS)">
        <div class="icon-menu my-expectations"></div>
        <span class="menu-item-link font-weight-normal">Minhas expectativas</span>
      </div>
      <div class="menu-item sub-item" [routerLink]="routerLink.TABS_INVESTMENT.MY_WALLETS"
        [class.disabled]="checkProtectedRouter(routerLink.TABS_INVESTMENT.MY_WALLETS)">
        <div class="icon-menu my-wallets"></div>
        <span class="menu-item-link font-weight-normal">Gerenciar carteiras</span>
      </div>
      <div class="menu-item sub-item" [routerLink]="routerLink.TABS_INVESTMENT.MY_THEMES"
        [class.disabled]="checkProtectedRouter(routerLink.TABS_INVESTMENT.MY_THEMES)">
        <div class="icon-menu my-themes"></div>
        <span class="menu-item-link font-weight-normal">Meus temas</span>
      </div>
      <div class="menu-item sub-item" [routerLink]="routerLink.TABS_INVESTMENT.PERFORMANCE"
        [class.disabled]="checkProtectedRouter(routerLink.TABS_INVESTMENT.PERFORMANCE)">
        <div class="icon-menu performance"></div>
        <span class="menu-item-link font-weight-normal">Performance</span>
      </div>
      <div class="menu-item sub-item" [routerLink]="routerLink.TABS_INVESTMENT.SUMMARY"
        [class.disabled]="checkProtectedRouter(routerLink.SUMMARY)">
        <div class="icon-menu summary"></div>
        <span class="menu-item-link font-weight-normal">Resumo financeiro</span>
      </div>
    </nav>
  </ng-container>

  <nav class="list-group list-group-flush" aria-label="Chat e notificações">
    <div class="menu-item">
      <div class="icon-menu chat-dots"></div>
      <a href="https://t.me/LadyBank_Bot" class="menu-item-link" target="_blank">Telegram</a>
    </div>
    <!-- <div class="menu-item" [routerLink]="routerLink.PAGE.NOTIFICATIONS"
      [class.disabled]="checkProtectedRouter(routerLink.PAGE.NOTIFICATIONS)" (click)="scroll_top()">
      <div class="icon-menu notification"></div>
      <span class="menu-item-link disabled">Notificações</span>
    </div> -->
  </nav>
  <div class="text-center mt-3 mb-2">
    <icon-svg svgName="header-menu/sair" (click)="logout()"></icon-svg>
  </div>
</div>
<nav class="row navbar navbar-light bg-white fixed-top shadow-sm justify-content-center background-gradient"
  style="margin-top: -2px; margin-bottom: 85px">
  <div class="row col-md-12 d-flex justify-content-start-between align-items-center nav-container">
    <div class="col-lg-3 col-md-4 col-3 header mx-n0 px-0 d-flex justify-content-start align-items-center"
      [ngClass]="{'col-6 py-3 py-lg-0': !showNavigateMenu}">
      <button class="col-auto navbar-toggler" style="border: none" type="button" data-toggle="collapse"
        *ngIf="showNavigateMenu" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent"
        aria-expanded="false" aria-label="Toggle navigation" (click)="showMenu = !showMenu; showMenuRight = false">
        <icon-svg svgName="header-menu/hamburger"></icon-svg>
      </button>
      <a href="/app/portal/dashboard" class="col-md-9 col-lg-12 navbar-brand ml-lg-3 my-n4 my-lg-1"
        [ngClass]="{'d-none d-md-inline-flex': showNavigateMenu}">
        <icon-svg svgName="logo-white-ladybank"></icon-svg>
      </a>
    </div>
    <div class="col-lg-6 col-md-6 col-9 px-0 justify-content-center" *ngIf="showNavigateMenu">
      <div class="row text-center mt-2 mt-sm-1 px-1 px-md-0 text-white font-weight-bold big cursor-pointer"
        [ngClass]="{'px-2 pr-2': this.router.url.split('/')[2] == 'my-accounts'}"
        [class]="{'mr-5': this.router.url.split('/')[2] == 'dashboard'}">
        <div class="col-12 d-flex justify-content-center px-0"
          *ngIf="!this.router.url.split('/')[3] || this.router.url.split('/')[3] != 'pix'">
          <div class="float-left" style="max-width: 90%;" (click)="navigate('any');"
            [ngClass]="{'pe-none': !client?.has_bank_account}">
            {{ routes[this.router.url.split('/')[2]] }}
          </div>
          <!-- <span class="ml-2" (click)="quick_access = !quick_access">
            <icon-svg svgName="chevron-white-down" id="open-quick-access"
              *ngIf="!quick_access && this.router.url.split('/')[2] != 'dashboard'"></icon-svg>
            <icon-svg svgName="chevron-white-up" id="close-quick-access"
              *ngIf="quick_access && this.router.url.split('/')[2] != 'dashboard'"></icon-svg>
          </span> -->
        </div>
        <div class="col-12 d-flex justify-content-center px-0"
          *ngIf="this.router.url.split('/')[3] && this.router.url.split('/')[3] == 'pix'">
          <div class="float-left" (click)="navigate('pix');">
            Pix
          </div>
          <span class="ml-2" (click)="quick_access = !quick_access">
            <icon-svg svgName="chevron-white-down" id="open-quick-access"
              *ngIf="!quick_access && this.router.url.split('/')[2] != 'dashboard'"></icon-svg>
            <icon-svg svgName="chevron-white-up" id="close-quick-access"
              *ngIf="quick_access && this.router.url.split('/')[2] != 'dashboard'"></icon-svg>
          </span>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-2 justify-content-end align-items-center d-none d-sm-inline-flex"
      *ngIf="showNavigateMenu">
      <div class="col-auto d-none d-lg-block justify-content-end my-auto small" *ngIf="client.has_bank_account">
        <p class="my-0"><span class="font-weight-bold">Banco: </span>{{account.bank_code}}</p>
        <p class="my-0"><span class="font-weight-bold">AG: </span>{{account.branch}}</p>
        <p class="my-0"><span class="font-weight-bold">Conta: </span>{{account.number}}</p>
      </div>
      <div class="col-auto profile-circle pointer p-0 mx-2 d-none d-lg-block" *ngIf="client.has_bank_account"
        [routerLink]="routerLink.TABS_MY_DATA.ACCOUNT_DATA">
        <img class="mx-auto" [src]="photo" alt="photo-profile" style="width: 100%">
      </div>
      <div class="col-auto d-none d-lg-inline-flex">
        <icon-svg svgName="header-menu/logout" (click)="logout()" role="button"></icon-svg>
      </div>
      <div class="dropdown-content pointer" [ngClass]="{'show': showMenuRight}">
        <header class="header-menu">
          <section class="text-center">
            <p class="title">{{utils.getFirstName(client.name | titlecase) + " " + utils.getLastName(client.name |
              titlecase)}}</p>
          </section>
        </header>
        <nav class="list-group list-group-flush border-bottom" aria-label="Meus dados">
          <span class="menu-item" [routerLink]="routerLink.TABS_ACCOUNT.PIX">
            <div class="icon-menu pix"></div>
            <span class="menu-item-link">PIX</span>
          </span>
          <div class="menu-item" [routerLink]="routerLink.TABS_MY_DATA.ACCOUNT_DATA"
            [class.disabled]="checkProtectedRouter(routerLink.TABS_MY_DATA.ACCOUNT_DATA)">
            <div class="icon-menu person"></div>
            <span class="menu-item-link">Meus Dados</span>
          </div>
          <div class="menu-item" [routerLink]="routerLink.NAV.PORTAL_PASSWORD"
            [class.disabled]="checkProtectedRouter(routerLink.NAV.PORTAL_PASSWORD)">
            <div class="icon-menu lock"></div>
            <span class="menu-item-link">Alterar senha</span>
          </div>
        </nav>
        <nav class="list-group list-group-flush border-bottom" aria-label="Menu">
          <div class="menu-item">
            <div class="icon-menu manage-accounts"></div>
            <span class="menu-item-link" [routerLink]="routerLink.TABS_MY_DATA.MANAGE_ACCOUNTS">Minhas contas</span>
          </div>
          <div class="menu-item">
            <div class="icon-menu price"></div>
            <span class="menu-item-link" [routerLink]="routerLink.TABS_MY_DATA.ACCOUNT_PLAN">Meu plano de conta</span>
          </div>
          <div class="menu-item">
            <div class="icon-menu investor-profile"></div>
            <span class="menu-item-link" [routerLink]="routerLink.TABS_MY_DATA.INVESTOR_PROFILE">Perfil de
              investidor</span>
          </div>
        </nav>
      </div>
    </div>
  </div>
</nav>
<!-- <app-new-quick-access class="header-quick-access" [is_inline]="true" (onNavigate)="quick_access = $event"
  *ngIf="this.router.url.split('/')[2] != 'dashboard' && quick_access"></app-new-quick-access> -->
<!-- <card-quick-access class="header-quick-access" [is_inline]="true" *ngIf="this.router.url.split('/')[2] != 'dashboard' && quick_access"></card-quick-access> -->