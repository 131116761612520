import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Utils } from 'src/app/shared/commons/Utils';
import { AddressTypeEnum } from 'src/app/shared/enums/address-type.enum';
import { ApisEnum } from 'src/app/shared/enums/apis.enum';
import { AccountModel } from 'src/app/shared/interfaces/account-model';
import { AddressModel } from 'src/app/shared/interfaces/address-model';
import { ClientModel } from 'src/app/shared/interfaces/client-model';
import { AddressService } from 'src/app/shared/services/address.service';
import { ClientService } from 'src/app/shared/services/client.service';
import { UtilService } from 'src/app/shared/services/util.service';
@Component({
  selector: 'app-address-card-dialog',
  templateUrl: './address-card-dialog.component.html',
  styleUrls: ['./address-card-dialog.component.scss']
})
export class AddressCardDialogComponent implements OnInit {

  address = {} as AddressModel
  account = {} as AccountModel
  client = {} as ClientModel
  formGroup: FormGroup
  confirmResult: Subject<boolean | string>

  constructor(
    public modalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private addressService: AddressService,
    private clientService: ClientService,
    private utilService: UtilService,
  ) { }

  ngOnInit() {
    this.clientService.clientData.subscribe(d => {
      if (!d) { return }
      this.client = d
    })
    this.clientService.accountData.subscribe(d => {
      if (!d) { return }
      this.account = d
    })
    this.confirmResult = new Subject()
    this.buildForm()
    this.getAddress()
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      zip_code: ['', Validators.compose([Validators.required, Validators.minLength(9)])],
      street: ['', Validators.required],
      street_number: ['', Validators.required],
      state: ['', Validators.required],
      district: ['', Validators.required],
      city: ['', Validators.required],
      type: [AddressTypeEnum.comercial],
      complement: ['']
    })
  }

  setFormValues(address) {
    this.f.zip_code.setValue(Utils.maskZipCode(address.zip_code || address.cep))
    this.f.street.setValue(address.street || address.logradouro)
    this.f.street_number.setValue(address.street_number)
    this.f.state.setValue(address.state || address.uf)
    this.f.district.setValue(address.district || address.bairro)
    this.f.city.setValue(address.city || address.localidade)
    this.f.complement.setValue(address.complement)
  }

  getAddress() {
    this.address = {} as AddressModel
    this.addressService.read(ApisEnum.Client).then(result => {
      if (result.addresses) {
        this.address = result.addresses.filter(x => x.id !== undefined).shift()
        this.setFormValues(this.address)
      }
    })
  }

  getZipCode(event) {
    const zip_code = Utils.unMask(event.target.value)
    if (event.key !== "Backspace" && zip_code != undefined && zip_code.length === 8) {
      this.addressService.readByCep(Utils.maskZipCode(zip_code)).then(result => {
        this.setFormValues(result)
        this.utilService.mark_controls_dirty(this.formGroup.controls)
      })
    }
  }

  next() {
    try {
      if (this.formGroup.valid) {
        const addressData = Object.assign({} as any, this.utilService.dirty_controls_data(this.formGroup.controls))
        if (Object.keys(addressData).length > 0) {
          if (!addressData.zip_code || (Utils.unMask(this.address.zip_code) == Utils.unMask(addressData.zip_code))) {
            this.updateAddress(addressData)
          } else {
            this.saveAddress(addressData)
          }
        } else {
          this.newCard(this.address.id)
        }
      } else {
        this.formGroup.markAllAsTouched()
      }
    } catch (error) {
      this.close(false)
    }
  }

  saveAddress(addressData) {
    addressData.zip_code = Utils.unMask(addressData.zip_code)
    addressData.name = 'Home'
    addressData.country = 'Brazil'
    this.addressService.create(ApisEnum.Client, addressData).then(res => {
      this.address = res
      this.newCard(this.address.id)
    })
  }

  updateAddress(addressData) {
    this.addressService.update(ApisEnum.Client, this.address.id, addressData).then(res => {
      this.address = res
      this.newCard(this.address.id)
    })
  }

  newCard(address_id) {
    const in_body = {
      card_type: 'main',
      delivery_address_id: address_id,
      gender: 'female'
    }
    this.clientService.saveCard(this.client.uuid, this.account.uuid, in_body).then(res => {
      if (res.error)
        this.close(false)
      else
        this.close(true)
    })
  }

  close(value) {
    this.confirmResult.next(value)
    this.modalRef.hide()
  }

  // convenience getter for easy access to form fields
  get f() { return this.formGroup.controls }
}
