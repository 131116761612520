import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActionCardEnum } from 'src/app/shared/enums/status-card.enum';
import { BlockStatusCard } from 'src/app/shared/commons/BlockStatusCard';

@Component({
  selector: 'app-duplicate-card-dialog',
  templateUrl: './duplicate-card-dialog.component.html',
  styleUrls: ['./duplicate-card-dialog.component.scss']
})
export class DuplicateCardDialogComponent implements OnInit {

  cardType = '';
  reason = '';
  confirmResult: Subject<boolean>;
  formGroup: FormGroup
  reasonList: any = [];

  constructor(
    public modalRef: BsModalRef,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.confirmResult = new Subject();
    this.formGroup = this.formBuilder.group({
      reason: ['', Validators.required]
    })
    this.getReasonList();
  }

  getReasonList() {
    this.reasonList =  BlockStatusCard.get().filter(x => x.type.includes(ActionCardEnum.duplicate))
  }

  close(value) {
    this.confirmResult.next(value);
    this.modalRef.hide();
  }

  send() {
    if (this.formGroup.valid) {
      // console.log('TODO: call service duplicate card');
      this.close(true);
    }
  }

  get f() {
    return this.formGroup.controls;
  }
}
