<div class="modal-content">
  <div class="modal-header border-0">
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span class="material-icons-outlined">highlight_off</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row justify-content-md-center">
      <div class="col col-12 text-center">
        <p class="medium h1 mb-4">{{title}}</p>
      </div>
    </div>
    <form *ngIf="modalType === 'add-forecast'" [formGroup]="formGroup" class="row justify-content-md-center"
      autocomplete="off" novalidate>
      <div class="form-title col-12">
        <p class="small mb-4">{{subTitle}}</p>
      </div>
      <div class="form-group col-12">
        <label for="field_1">Nome da previsão</label>
        <input type="text" id="field_1" name="field_1" class="form-control" [(ngModel)]="model.field_1" maxlength="200"
          formControlName="field_1">
        <div *ngIf="(!f.field_1.pristine || f.field_1.touched) && f.field_1.errors" class="invalid-feedback x-small">
          <div *ngIf="f.field_1.errors.required"> Campo obrigatório. </div>
        </div>
      </div>
      <div class="form-group col-12">
        <label for="field_2">Data</label>
        <input type="text" id="field_2" name="field_2" class="form-control" [(ngModel)]="model.field_2" bsDatepicker
          Mask="##/##/####" formControlName="field_2">
        <div *ngIf="(!f.field_2.pristine || f.field_2.touched) && f.field_2.errors" class="invalid-feedback x-small">
          <div *ngIf="f.field_2.errors.required"> Campo obrigatório. </div>
        </div>
      </div>
      <div class="form-group col-12" *ngIf="type !== FormTypeEnum.PIB">
        <div class="row">
          <div class="col col-12">
            <div class="form-check form-check-inline">
              <input type="radio" class="form-check-input" id="position-relative" [(ngModel)]="model.field_3"
                name="field_3" value="relative" formControlName="field_3" (click)="getSelectOptions('relative')">
              <label class="form-check-label" for="position-relative">Relativo</label>
            </div>
            <div class="form-check form-check-inline">
              <input type="radio" class="form-check-input" id="position-absolute" [(ngModel)]="model.field_3"
                name="field_3" value="absolute" formControlName="field_3" (click)="getSelectOptions('absolute')">
              <label class="form-check-label" for="position-absolute">Absoluto</label>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group col-12" *ngIf="type === FormTypeEnum.ACTIONS">
        <label for="field_4">Ação target</label>
        <input type="text" id="field_4" name="field_4" class="form-control" [(ngModel)]="model.field_4" maxlength="200"
          formControlName="field_4">
        <div *ngIf="(!f.field_4.pristine || f.field_4.touched) && f.field_4.errors" class="invalid-feedback x-small">
          <div *ngIf="f.field_4.errors.required"> Campo obrigatório. </div>
        </div>
      </div>
      <div class="form-group col-12" *ngIf="showSelectOptions === false">
        <label for="field_5">Ativo base</label>
        <input type="text" id="field_5" name="field_5" class="form-control" [(ngModel)]="model.field_5" maxlength="200"
          formControlName="field_5">
        <div *ngIf="(!f.field_5.pristine || f.field_5.touched) && f.field_5.errors" class="invalid-feedback x-small">
          <div *ngIf="f.field_5.errors.required"> Campo obrigatório. </div>
        </div>
      </div>
      <div class="form-group col-12" *ngIf="showSelectOptions === true">
        <label for="field_5">Moeda</label>
        <div class="dropdown">
          <select class="form-control" [(ngModel)]="model.field_5" formControlName="field_5">
            <option value="" selected>Selecione uma moeda...</option>
            <option *ngFor="let item of selectOptions">{{item}}</option>
          </select>
          <div *ngIf="(!f.field_5.pristine || f.field_5.touched) && f.field_5.errors" class="invalid-feedback x-small">
            <div *ngIf="f.field_5.errors.required"> Campo obrigatório. </div>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <button type="submit" class="btn btn-primary" (click)="add()" [disabled]="formGroup.invalid">ADICIONAR</button>
      </div>
    </form>
    <form *ngIf="modalType === 'import-forecast'" [formGroup]="formGroup" class="row justify-content-md-center"
      autocomplete="off" novalidate>
      <div class="form-title col-12">
        <p class="small mb-4">{{subTitle}}</p>
      </div>
      <div class="form-group col-12">
        <label for="field_1">Qual a fonte?</label>
        <div class="dropdown">
          <select class="form-control" [(ngModel)]="model.field_1" formControlName="field_1">
            <option value="" selected>Selecione uma fonte...</option>
            <option *ngFor="let item of selectFonts">{{item}}</option>
          </select>
          <div *ngIf="(!f.field_1.pristine || f.field_1.touched) && f.field_1.errors" class="invalid-feedback x-small">
            <div *ngIf="f.field_1.errors.required"> Campo obrigatório. </div>
          </div>
        </div>
      </div>
      <div class="form-group col-12">
        <label for="field_2">Data</label>
        <input type="text" id="field_2" name="field_2" class="form-control" [(ngModel)]="model.field_2" bsDatepicker
          Mask="##/##/####" formControlName="field_2">
        <div *ngIf="(!f.field_2.pristine || f.field_2.touched) && f.field_2.errors" class="invalid-feedback x-small">
          <div *ngIf="f.field_2.errors.required"> Campo obrigatório. </div>
        </div>
      </div>
      <div class="form-group col-12">
        <label for="field_5">Previsões para o PIB</label>
        <div class="dropdown">
          <select class="form-control" [(ngModel)]="model.field_5" formControlName="field_5">
            <option value="" selected>Selecione uma previsão...</option>
            <option *ngFor="let item of selectPibs">{{item}}</option>
          </select>
          <div *ngIf="(!f.field_5.pristine || f.field_5.touched) && f.field_5.errors" class="invalid-feedback x-small">
            <div *ngIf="f.field_5.errors.required"> Campo obrigatório. </div>
          </div>
        </div>
      </div>
      <div class="mt-3 col-12 d-flex justify-content-md-center">
        <button type="button" class="btn btn-outline-primary" (click)="close()">CANCELAR</button>
        <button type="submit" class="btn btn-primary" (click)="add()" [disabled]="formGroup.invalid">IMPORTAR</button>
      </div>
    </form>
  </div>
</div>