<div class="modal-content">
  <div class="modal-header border-0">
    <span class="medium text-center font-weight-bold w-100 m-auto"> Editar patrimonio </span>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span class="material-icons-outlined">highlight_off</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="row" [formGroup]="formGroup" autocomplete="off" novalidate>

      <!--TIPO DE PATRIMONIO-->
      <div class="form-group col-12">
        <label for="type">Tipo de patrimônio:</label>
        <div class="dropdown">
          <select class="form-control" formControlName="type">
            <option selected value="">Selecione o patrimônio...</option>
            <option *ngFor="let item of types" [ngValue]="item.value">{{item.label}}</option>
          </select>
          <div *ngIf="(!f.type.pristine || f.type.touched) && f.type.errors" class="invalid-feedback x-small">
            <div *ngIf="f.type.errors.required"> Campo obrigatório. </div>
          </div>
        </div>
      </div>

      <!--TITULO DE PATRIMONIO-->
      <div class="form-group col-12" *ngIf="formGroup.get('type').value === 'other'">
        <label for="title">Título do patrimônio</label>
        <input type="text" class="form-control" id="title" name="title" 
          formControlName="title" >
        <div *ngIf="(!f.title.pristine || f.title.touched) && f.title.errors" class="invalid-feedback x-small">
          <div *ngIf="f.title.errors.required"> Campo obrigatório. </div>
        </div>
      </div>

      <!--DESCRIÇÂO-->
      <div class="form-group col-12">
        <label for="description">Descrição</label>
        <input type="text" class="form-control" id="description" name="description" 
          formControlName="description">
        <div *ngIf="(!f.description.pristine || f.description.touched) && f.description.errors" class="invalid-feedback x-small">
          <div *ngIf="f.description.errors.required"> Campo obrigatório. </div>
        </div>
      </div>

      <!--VALOR-->
      <div class="form-group col-12">
        <label for="optradio">Qual o valor estimado desse patrimônio?</label>
        <input type="text" Currency class="form-control" id="value" name="value" 
          formControlName="value" >
        <div *ngIf="(!f.value.pristine || f.value.touched) && f.value.errors" class="invalid-feedback x-small">
          <div *ngIf="f.value.errors.required"> Campo obrigatório. </div>
        </div>
      </div>

      <div class="col-12 d-flex justify-content-between my-4">
        <button class="btn btn btn-outline-primary ml-0" (click)="modalRef.hide()">CANCELAR</button>
        <button type="button" class="btn btn-primary" (click)="onConfirm()" [disabled]="formGroup.invalid">SALVAR</button>
      </div>
    </form>
  </div>
</div>