<div class="modal-content">
  <div class="modal-header border-0">
    <span class="medium text-center font-weight-bold w-100 m-auto"> Otimizar sua Carteira ideal </span>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span class="material-icons-outlined">highlight_off</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 mt-4">
        <div class="d-flex justify-content-between mb-2">
          <div (click)="changeStep(1, 33)" class="pointer">
            <span class="badge" [ngClass]="{'active' : step == 1}">1</span>
            <span class="badge-description ml-2" [ngClass]="{'active' : step == 1}">Objetivos</span>
          </div>
          <div (click)="changeStep(2, 66)" class="pointer">
            <span class="badge" [ngClass]="{'active' : step == 2}">2</span>
            <span class="badge-description ml-2" [ngClass]="{'active' : step == 2}">Opcionais</span>
          </div>
          <div (click)="changeStep(3, 100)" class="pointer">
            <span class="badge" [ngClass]="{'active' : step == 3}">3</span>
            <span class="badge-description ml-2" [ngClass]="{'active' : step == 3}">Receitas</span>
          </div>
        </div>
        <div class="progress w-100">
          <div class="progress-bar" [style.width.%]="progress" role="progressbar" aria-valuenow="20" aria-valuemin="0"
            aria-valuemax="100">
          </div>
        </div>
      </div>
    </div>
    <form autocomplete="off" novalidate>
      <div class="row" *ngIf="step == 1">
        <div class="col-12">
          <p class="small my-4">
            Para otimizar uma carteira de investimentos, precisamos que define seus objetivos de vida primeiro.
          </p>
          <!-- SEM OBJETIVO -->
          <!-- <section>
            <div class="alert alert-danger d-flex align-items-center" role="alert">
              <icon-svg svgName="danger" class="w-20"></icon-svg>
              <span class="poppins x-small">Você ainda não cadastrou nenhum objetivo, para otimizar a carteira precisamos
                saber quais são os seus objetivos.</span>
            </div>
            <div class="text-center mb-3 mt-5">
              <button type="button" class="btn btn-primary" (click)="adjustGoals()">QUERO AJUSTAR OS OBJETIVOS</button>
            </div>
          </section> -->
          <section>
            <div class="alert alert-primary d-flex align-items-center" role="alert">
              <icon-svg *ngIf="hasGoals" svgName="check-success" class="w-10 mr-3"></icon-svg>
              <icon-svg *ngIf="!hasGoals" svgName="x-red" class="w-10 mr-3"></icon-svg>
              <div>
                <span class="poppins x-small text-dark">Usando seus objetivos de vida</span>
                <p class="link x-small mb-0 x-small" (click)="adjustGoals()">Quero ajustar os objetivos</p>
              </div>
            </div>
            <div class="text-center mb-3 mt-5">
              <button type="button" class="btn btn-primary" (click)="changeStep(2, 66)"
                [disabled]="!hasGoals">CONTINUAR</button>
            </div>
          </section>
        </div>
      </div>
      <div class="row" *ngIf="step == 2">
        <div class="col-12">
          <p class="x-small mt-4 mb-2">
            Você pode incluir seus temas e/ou expectativas sobre os mercados. Se prefere diminuir seus custos no
            rebalanceamento, indique isso também.
          </p>
          <p class="x-small font-weight-bold">
            Verifica se quer usar suas visões do mercado e otimizar impostos <span class="text-danger">(em breve)</span>
          </p>
          <div class="card border flex-row align-items-center px-4 py-2 my-2" [ngClass]="{'bg-active': optional.themes}"
            [class.disabled]="true">
            <input type="checkbox" id="themes" [checked]="optional.themes"
              (change)="optional.themes = !optional.themes">
            <div class="ml-2">
              <label for="themes" class="poppins text-dark x-small m-0">Usando temas definidos</label>
              <p class="link x-small mb-0" (click)="adjustThemes()">Quero ajustar os temas
                escolhidos <span class="text-danger">(em breve)</span></p>
            </div>
          </div>
          <div class="card border flex-row align-items-center px-4 py-2 my-2"
            [ngClass]="{'bg-active': optional.expectations}" [class.disabled]="true">
            <input type="checkbox" id="expectations" [checked]="optional.expectations"
              (change)="optional.expectations = !optional.expectations">
            <div class="ml-2">
              <label for="expectations" class="poppins text-dark x-small m-0">Usando minhas expectativas futuras</label>
              <p class="link x-small mb-0" (click)="adjustExpectations()">Quero ajustar as
                expectativas <span class="text-danger">(em breve)</span></p>
            </div>
          </div>
          <div class="card border flex-row align-items-center px-4 py-2 my-2"
            [ngClass]="{'bg-active': optional.incomeTax}" [class.disabled]="true">
            <input type="checkbox" id="income-tax" [checked]="optional.incomeTax"
              (change)="optional.incomeTax = !optional.incomeTax">
            <div class="ml-2">
              <label for="income-tax" class="poppins text-dark x-small m-0">Usando imposto de renda</label>
              <p class="link x-small mb-0" (click)="adjustIncomeTax()">Isso demora um pouco mais... <span
                  class="text-danger">(em breve)</span></p>
            </div>
          </div>
          <div class="d-flex justify-content-between mb-3 mt-5">
            <button type="button" class="btn  btn-outline-dark ml-0" (click)="changeStep(1, 33)">VOLTAR</button>
            <button type="button" class="btn btn-primary mr-0" (click)="changeStep(3, 100)">CONTINUAR</button>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="step == 3">
        <div class="col-12">
          <p class="x-small mt-4 mb-2">
            Como último passo, define qual valor quer utilizar para sua carteira de investimentos. Pode ser só o valor
            da carteira atual e/ou os valores disponíveis na conta. Alternativamente, pode também simular um valor
            investido.
          </p>
          <div class="card border px-4 py-2 my-2" [class.disabled]="total_positions <= 0" [ngClass]="{'bg-active': optimizeOption == OptimizeOptions.rebalance}">
            <section class="row d-flex align-items-center">
              <div class="col-11 d-flex align-items-center">
                <div class="form-check form-check-inline">
                  <input type="radio" class="d-none" id="rebalance" [(ngModel)]="optimizeOption" name="type-value"
                    [value]="OptimizeOptions.rebalance">
                  <label class="form-check-label" for="rebalance">Quero rebalancear minha carteira atual</label>
                </div>
              </div>
            </section>
          </div>
          <div class="card border px-4 py-2 my-2" [ngClass]="{'bg-active': optimizeOption == OptimizeOptions.contribute}">
            <section class="row d-flex align-items-center">
              <div class="col-11 d-flex align-items-center">
                <div class="form-check form-check-inline">
                  <input type="radio" class="d-none" id="add" [(ngModel)]="optimizeOption" name="type-value"
                    [value]="OptimizeOptions.contribute">
                  <label class="form-check-label" for="add">Quero aportar um valor extra</label>
                </div>
              </div>
            </section>
          </div>
          <div class="form-group" *ngIf="optimizeOption == OptimizeOptions.contribute">
            <input type="text" Currency class="form-control" placeholder="Valor extra" [(ngModel)]="extra_value"
              [ngModelOptions]="{standalone: true}">
          </div>
          <div class="card border px-4 py-2 my-2" [class.disabled]="total_positions <= 0" [ngClass]="{'bg-active': optimizeOption == OptimizeOptions.redeem}">
            <section class="row d-flex align-items-center">
              <div class="col-11 d-flex align-items-center">
                <div class="form-check form-check-inline">
                  <input type="radio" class="d-none" id="redeem" [(ngModel)]="optimizeOption" name="type-value"
                    [value]="OptimizeOptions.redeem">
                  <label class="form-check-label" for="redeem">Quero resgatar um valor</label>
                </div>
              </div>
            </section>
          </div>
          <div class="form-group" *ngIf="optimizeOption == OptimizeOptions.redeem">
            <input type="text" Currency class="form-control" placeholder="Valor a resgatar"
              [(ngModel)]="amount_to_redeem" [ngModelOptions]="{standalone: true}">
          </div>
          <div class="d-flex justify-content-between mb-3 mt-5">
            <button type="button" class="btn  btn-outline-dark ml-0" (click)="changeStep(2, 66)">VOLTAR</button>
            <button type="button" class="btn btn-primary mr-0" [disabled]="!optimizeOption" (click)="finish()">OTIMIZAR</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>