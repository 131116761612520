import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService as storage } from './storage.service'
import { Router } from '@angular/router';
import { UtilService } from './util.service';
import { AppUpdateService } from './app-update.service';
import { AddressModel } from './../interfaces/address-model';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private utilService: UtilService,
    private appUpdateService: AppUpdateService
  ) { }

  /**
   * Check App Update
   * @param res Response
   */
  private checkAppUpdate(res: Response | any): Response {
    if (res.headers.has('x-appversion')) {
      this.appUpdateService.appVersion.emit(res.headers.get('x-appversion'));
    }
    return res;
  }

  /**
   * POST ENTITY
   * @param uri call service api
   * @param client_uuid Id api call
   * @param data Data Client Phone
   * @param jwt Client JWT
   */
  create(uri: string, data: AddressModel, client_uuid?: string, jwt?: string): Promise<AddressModel> {
    const url = this.utilService.makeUri([uri, client_uuid || storage.getClientUuid(), 'address', { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    const dataPost = this.utilService.buildPostData(data);

    return this.http.post(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * PUT ENTITY
   * @param uri call service api
   * @param client_uuid Id api call
   * @param entity_id Id from entity
   * @param data Data Client Phone
   * @param jwt Client JWT
   */
  update(uri: string, address_id: number, data: AddressModel, client_uuid?: string, jwt?: string): Promise<AddressModel> {
    const url = this.utilService.makeUri([uri, client_uuid || storage.getClientUuid(), 'address', address_id, { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    const dataPost = this.utilService.buildPostData(data);

    return this.http.put(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * GET ALL ENTITY
   * @param uri call service api
   * @param client_uuid Client UUID 
   * @param jwt Client JWT
   */
  read(uri: string, client_uuid?: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([uri, client_uuid || storage.getClientUuid(), 'addresses', { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * GET ENTITY BY ID
   * @param uri 
   * @param client_uuid 
   * @param entity_id 
   * @param jwt 
   */
  readById(uri: string, entity_id: number, client_uuid?: string, jwt?: string): Promise<AddressModel> {
    const url = this.utilService.makeUri([uri, client_uuid || storage.getClientUuid(), 'address', entity_id, { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  readByCep(cep: string): Promise<any> {
    const url = `https://viacep.com.br/ws/${cep}/json`;
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  readUFs(): Promise<any> {
    const url = `https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome`;
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  readCountiesById(id): Promise<any> {
    const url = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${id}/municipios?orderBy=nome`;
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }
}
