export enum FileType {
  document = 'document'
}

export enum FileSubType {
  selfie = 'selfie',
  rg = 'rg',
  rg_front = 'rg_front',
  rg_back = 'rg_back',
  address = 'address'
}