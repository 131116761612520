import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-accept-term-dialog',
  templateUrl: './accept-term-dialog.component.html',
  styleUrls: ['./accept-term-dialog.component.scss']
})
export class AcceptTermDialogComponent implements OnInit {

  confirmResult: Subject<boolean | string>
  accept = false
  
  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {
    this.confirmResult = new Subject()
  }

  onClose() {
    this.confirmResult.next(this.accept)
    this.modalRef.hide()
  }
}
