import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { Subject } from 'rxjs'

@Component({
  selector: 'app-password-card-dialog',
  templateUrl: './password-card-dialog.component.html',
  styleUrls: ['./password-card-dialog.component.scss']
})
export class PasswordCardDialogComponent implements OnInit {

  confirmResult: Subject<any>
  formGroup: FormGroup
  password: string
  model: any

  constructor(
    public modalRef: BsModalRef,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.confirmResult = new Subject()

    this.formGroup = this.fb.group({
      password: ['', Validators.required]
    })
  }

  next() {
    if (this.formGroup.valid) {
      this.model.status = 'Reinvindicação pendente'
      this.confirmAndClose(this.model)
    } else {
      this.formGroup.markAllAsTouched()
    }
  }

  private confirmAndClose(value) {
    this.confirmResult.next(value)
    this.modalRef.hide()
  }
}
