<div class="modal-content">
    <div class="modal-header border-0">
        <span class="medium text-center font-weight-bold w-100 m-auto"> Configurações </span>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span class="material-icons-outlined">highlight_off</span>
        </button>
    </div>
    <div class="modal-body">
        <tabset>
          <tab heading="Corretora" id="tab1">
              <div class="form-group col-12 my-4">
                <form [formGroup]="formBroker">
                    <label for="broker" class="font-weight-bold">Escolha a corretora</label>
                     <div class="dropdown">
                        <select class="form-control" formControlName="broker" aria-label="selecione a corretora">
                           <option *ngFor="let item of brokerList" [value]="item.uuid">{{item.name}}</option>
                        </select>
                     </div>
                </form>

                 <!--FORM ORAMA-->
                 <div *ngIf="formBroker.get('broker').value == '343b2ff2-e2bd-4942-8d4f-38e4b6937ccd'" class="col-12">
                    <form [formGroup]="formOrame" autocomplete="off" novalidate>
                        <div class="row my-4">
                          <label class="font-weight-bold" for="cpf">Digite seu CPF</label>
                          <input type="text" class="form-control" id="cpf" name="cpf" Mask="###.###.###-##"
                             formControlName="cpf">
                          <div *ngIf="(!fo.cpf.pristine || fo.cpf.touched) && fo.cpf.errors" class="invalid-feedback x-small">
                            <div *ngIf="fo.cpf.errors.required"> Campo obrigatório. </div>
                            <div *ngIf="fo.cpf.errors.invalid"> CPF inválido. </div>
                          </div>
                        </div>
                        <button type="submit" class="btn btn-primary m-0 col-12" (click)="saveBrokerOrame()">ENVIAR</button>
                    </form>
                  </div>
                  
                  <!--FORM MERCADO BITCOIN-->
                  <div *ngIf="formBroker.get('broker').value == '2'" class="col-12">
                    <form [formGroup]="formBitcoin" autocomplete="off" novalidate>
                      <div class="row my-4">
                        <label class="font-weight-bold">Instruções para obter suas credenciais da corretora</label>
                        <div class="col-12 py-4" style="border: 1px solid #D8DEDF; border-radius: 8px;">
                          <p class="small">Como obter meus dados?</p>
                          <p class="small">1. Acesse a área de aplicativos e integrações do MercadoBitcoin.</p>
                          <p class="small">2. Clique emm Gerenciar na guia Chaves de API de Negociações.</p>
                          <p class="small">3. Preencha um nome e crie uma Nova Chave de Acesso.</p>
                          <p class="small">4. Preencha seu PIN e clique em OK para listar suas chaves de acesso.</p>
                        </div>
                      </div>
                      <div class="row my-4">
                        <label class="font-weight-bold">Informe seus credenciais da corretora</label>
                        <div class="col-12 py-4" style="border: 1px solid #D8DEDF; border-radius: 8px;">                        
                           <div class="row col-12 py-2">
                             <!--identifier-->
                             <label for="identifier">Identificador</label>
                             <p class="small">Obs: Preencha o identificador informado pelo MercadoBitcon</p>
                             <input type="text" class="form-control" id="identifier" name="identifier"
                               formControlName="identifier">
                             <div *ngIf="(!fb.identifier.pristine || fb.identifier.touched) && fb.identifier.errors" class="invalid-feedback x-small">
                               <div *ngIf="fb.identifier.errors.required"> Campo obrigatório. </div>
                             </div>
                           </div>

                            <!--secret-->
                            <div class="row col-12 py-2">
                              <label for="identifier">Segredo</label>
                              <p class="small">Obs: Preencha o identificador informado pelo MercadoBitcon</p>
                              <input type="text" class="form-control" id="secret" name="secret"
                                formControlName="secret">
                              <div *ngIf="(!fb.secret.pristine || fb.secret.touched) && fb.secret.errors" class="invalid-feedback x-small">
                                <div *ngIf="fb.secret.errors.required"> Campo obrigatório. </div>
                              </div>
                            </div>
                        </div>
                      </div>
                    </form>
                  </div>
              </div>
          </tab>
          <tab heading="Cadastro Eletrônico Investidor" id="tab2"></tab>
          <tab heading="Notificações" id="tab3"></tab>
        </tabset>
      </div>
</div>
