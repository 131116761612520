import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {

  @Input() subTitle: string = null;
  @Input() msg: string = null;
  @Input() tryAgain: boolean;
  @Input() txtLink: string = null;
  @Input() txtOk = 'OK, ENTENDI';

  confirmResult: Subject<boolean | string>;

  constructor(public modalRef: BsModalRef) { }
  
  ngOnInit(): void {
    this.confirmResult = new Subject();
  }

  onClose(value?: string) {
    if (value) {
      this.confirmAndClose('value');
    } else if (this.tryAgain) {
      this.confirmAndClose('try-again');
    } else {
      this.confirmAndClose('redirect');
    }
  }

  private confirmAndClose(value: boolean | string) {
    this.confirmResult.next(value);
    this.modalRef.hide();
  }
}
