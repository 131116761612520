import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PhoneModel } from '../interfaces/phone-model';
import { Router } from '@angular/router';
import { UtilService } from './util.service';
import { AppUpdateService } from './app-update.service';
import { StorageService as storage } from './storage.service'
import { AppConfigService } from './app-config.service';
import { ApisEnum } from '../enums/apis.enum';
import { ClientService } from './client.service';

export enum PhoneEnum {
  mobile = 'mobile',
  home = 'home',
  contact = 'contact',
  comercial = 'comercial'
}

@Injectable({
  providedIn: 'root'
})
export class PhoneService {
  config: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private utilService: UtilService,
    private appUpdateService: AppUpdateService,
    private configService: AppConfigService,
    private clientService: ClientService
  ) {
    this.config = this.configService.config;
  }

  /**
   * Check App Update
   * @param res Response
   */
  private checkAppUpdate(res: Response | any): Response {
    if (res.headers.has('x-appversion')) {
      this.appUpdateService.appVersion.emit(res.headers.get('x-appversion'));
    }
    return res;
  }

  /**
   * POST ENTITY
   * @param uri call service apiFde
   * @param client_uuid Id api call
   * @param data Data Client Phone
   * @param jwt Client JWT
   */
  create(uri: string, client_uuid: string, data: PhoneModel, jwt?: string): Promise<PhoneModel> {

    const url = this.utilService.makeUri([uri, client_uuid, 'phone', { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    const dataPost = this.utilService.buildPostData(data);

    return this.http.post(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * PUT ENTITY
   * @param uri call service api
   * @param client_uuid Id api call
   * @param entity_id Id from entity
   * @param data Data Client Phone
   * @param jwt Client JWT
   */
  update(uri: string, phone_id: number, data: PhoneModel, client_uuid?: string, jwt?: string): Promise<PhoneModel> {
    const url = this.utilService.makeUri([uri, client_uuid || storage.getClientUuid(), 'phone', phone_id, { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    const dataPost = this.utilService.buildPostData(data);

    return this.http.put(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * GET ALL ENTITY
   * @param uri call service api
   * @param client_uuid Client UUID 
   * @param jwt Client JWT
   */
  read(uri: string, client_uuid?: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([uri, client_uuid || storage.getClientUuid(), 'phones', { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * GET ENTITY BY ID
   * @param uri call service api
   * @param client_uuid Client UUID 
   * @param entity_id Id from entity
   * @param jwt Client JWT
   */
  readById(uri: string, entity_id: number, client_uuid?: string, jwt?: string): Promise<PhoneModel> {
    const url = this.utilService.makeUri([uri, client_uuid || storage.getClientUuid(), 'phones', entity_id, { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  sendVerify(uri: string, client_uuid: string, data: PhoneModel, retry: number = 0, jwt?: string): Promise<PhoneModel> {
    const url = this.utilService.makeUri([uri, client_uuid, 'phone', data.id, 'verify', { jwt: jwt || storage.getClientJwt(), retry: retry }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    // const dataPost = this.utilService.buildPostData(data);

    return this.http.post(url, {}, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  checkVerify(uri: string, client_uuid: string, phone_id: number, data: any, jwt?: string, updateJwt = true): Promise<any> {

    const url = this.utilService.makeUri([uri, client_uuid, 'phone', phone_id, 'verify', { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    const dataPost = this.utilService.buildPostData(data);

    return this.http.put<any>(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        if (updateJwt) storage.saveClientJwt(res.body.jwt);
        storage.saveClientUuid(client_uuid);
        return res;
      })
      .catch(err => {
        this.utilService.handleError(err, this.router)
        return err
      });
  }

  delete(client_uuid: string, phone_id: number, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid, 'phone', phone_id, { jwt: jwt || storage.getClientJwt() }])
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    return this.http.delete<any>(url, { headers, observe: 'response'})
      .toPromise()
      .then((res: any) => {
        this.checkAppUpdate(res);
        return res;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }
}
