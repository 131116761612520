export enum EnumFinancial {
    FormingCouple           = 1, //Formando um Casal
    RaisingFamily           = 2, //Aumentando a família
    EducationChildren       = 3, //Educação das crianças ou outros queridos
    SeparationCouple        = 4, //Separação do casal
    ChildrenLeavingHome     = 5, //Crianças saindo de casa
    AdultChildrenComingHome = 6, //Filhos adultos voltando para casa
    CountryComingHome       = 7, //País ou outros queridos voltando para casa
    CaringLovedOneFamily    = 8, //Cuidando de um ente querido
    IllnessOrAccidentFamily = 9, //Doença ou acidente
    LossOfFamily            = 10,//Perda de um familiar querido 
    ReceivedAnInheritance   = 11,//Recebeu uma herança
    FirstJob                = 12,//Primeiro emprego
    WorkingPartTime         = 13,//Trabalhando tempo parcial
    QuitYourJUob            = 14,//Saiu do emprego
    StartingABusiness       = 15,//Começando um negócio
    SellingABusiness        = 16,//Vendendo um negócio
    AnticipateRetirement    = 17,//Antecipar Aposentadoria
    Retire                  = 18,//Aposentar
    CaringLovedOneHealth    = 19,//Cuidando de um ente querido
    IllnessOrAccidentHeath  = 20,//Doença ou acidente
    GettingOld              = 21,//Ficando velho
    BuyingHouse             = 22,//Comprando uma casa (e troca)
    HomeReform              = 23,//Reforma de casa 
    DecreasingTheHouse      = 24,//Diminuindo a casa
    LossNaturalDisaster     = 25,//Perda de patrimônio por desastre natural
    BuyingACar              = 26,//Comprando um carro
    BuyingAnothergoodvalue  = 27,//Comprando outro bem de valor
}