<div class="modal-content">
  <div class="modal-header border-0">
    <span class="medium text-center font-weight-bold w-100 m-auto"> Editar transferência agendada </span>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span class="material-icons-outlined">highlight_off</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-between mb-2">
          <div (click)="changeStep(true)" class="pointer">
            <span class="badge" [ngClass]="firstStep ? 'active' : 'default'">1</span>
            <span class="badge-description ml-2" [ngClass]="firstStep ? 'active' : 'default'">Passo</span>
          </div>
          <div (click)="changeStep(false)" class="pointer">
            <span class="badge" [ngClass]="!firstStep ? 'active' : 'default'">2</span>
            <span class="badge-description ml-2" [ngClass]="!firstStep ? 'active' : 'default'">Passo</span>
          </div>
        </div>
        <div class="progress w-100">
          <div class="progress-bar" [ngClass]="firstStep ? 'w-50' : 'w-100'" role="progressbar" aria-valuenow="20"
            aria-valuemin="0" aria-valuemax="100">
          </div>
        </div>
      </div>
    </div>
    <form [formGroup]="formGroup" autocomplete="off" novalidate>
      <div class="row" *ngIf="firstStep">
        <div class="col-12">
          <p class="text-center small-plus poppins mb-0 mt-5"><strong>Quanto você quer transferir?</strong></p>
          <div class="form-group">
            <input type="text" Currency [(ngModel)]="editModel.value" class="balance text-center form-control"
              formControlName="value">
            <invalid-feedback [field]="f.value" [value]="balance"></invalid-feedback>
          </div>
        </div>
        <div class="form-group col-12">
          <label for="transferType">Tipo de transferência</label>
          <div class="dropdown">
            <select class="form-control" [(ngModel)]="editModel.transferType" formControlName="transferType"
              (change)="onChangeTransferType()">
              <option selected value="">Selecione...</option>
              <option *ngFor="let item of TransferType" [ngValue]="item">{{item}}</option>
            </select>
            <invalid-feedback [field]="f.transferType"></invalid-feedback>
          </div>
        </div>
        <div class="form-group col-12">
          <label for="nameFavorite">Nome do favorecido</label>
          <input type="text" class="form-control" name="nameFavorite" [(ngModel)]="editModel.nameFavorite"
            formControlName="nameFavorite">
          <invalid-feedback [field]="f.nameFavorite"></invalid-feedback>
        </div>
        <div class="form-group col-12">
          <label for="bank">Nome do banco</label>
          <div class="dropdown">
            <select class="form-control" [(ngModel)]="editModel.bankCode" formControlName="bank">
              <option selected value="">Selecione...</option>
              <option *ngFor="let item of bankList" [ngValue]="item.code">{{item.code}} {{item.bank}}</option>
            </select>
            <invalid-feedback [field]="f.bank"></invalid-feedback>
          </div>
        </div>
        <div class="modal-footer text-center border-0 m-auto">
          <button type="button" class="btn btn-primary" (click)="changeStep(false)">PRÓXIMO</button>
        </div>
      </div>
      <div class="row mt-5" *ngIf="!firstStep">
        <div class="form-group col-4">
          <label for="agency">Agência</label>
          <input type="text" OnlyNumber class="form-control" id="agency" name="agency" [(ngModel)]="editModel.agency"
            formControlName="agency" maxlength="4">
          <invalid-feedback [field]="f.agency"></invalid-feedback>
        </div>
        <div class="form-group col-6">
          <label for="account">Conta</label>
          <input type="text" OnlyNumber class="form-control" id="account" name="account" [(ngModel)]="editModel.account"
            formControlName="account" maxlength="19">
          <invalid-feedback [field]="f.account"></invalid-feedback>
        </div>
        <div class="form-group col-2">
          <label for="account">Dig.</label>
          <input type="text" OnlyNumber class="form-control" id="accountDigit" name="accountDigit"
            [(ngModel)]="editModel.account_digit" formControlName="accountDigit" maxlength="1">
          <invalid-feedback [field]="f.account"></invalid-feedback>
        </div>
        <div class="form-group col-12">
          <label for="document">CPF/CNPJ</label>
          <input type="text" CpfCnpjMask class="form-control" id="document" name="document"
            [(ngModel)]="editModel.document" formControlName="document">
          <invalid-feedback [field]="f.document" label="CPF/CNPJ"></invalid-feedback>
        </div>
        <div class="form-group col-12">
          <label for="accountType">Tipo de conta</label>
          <div class="dropdown">
            <select class="form-control" [(ngModel)]="editModel.accountType" formControlName="accountType">
              <option selected value="">Selecione...</option>
              <option *ngFor="let item of AccountType" [ngValue]="item.id">{{item.name}}</option>
            </select>
            <invalid-feedback [field]="f.accountType"></invalid-feedback>
          </div>
        </div>
        <div class="form-group col-12">
          <label for="dateTransfer">Data de transferência</label>
          <input type="text" id="dateTransfer" name="dateTransfer" class="form-control" [minDate]="today"
            [(ngModel)]="editModel.dateTransfer" bsDatepicker Mask="##/##/####" formControlName="dateTransfer">
          <invalid-feedback [field]="f.dateTransfer" label="Data"></invalid-feedback>
        </div>
        <div class="form-group col-12">
          <input type="checkbox" id="repeatTransfer" [(ngModel)]="editModel.repeatTransfer"
            formControlName="repeatTransfer" (change)="onChangeRepeatTransfer()">
          <label class="ml-2" for="repeatTransfer">Repetir transferência</label>
        </div>
        <div class="form-group col-6" *ngIf="editModel.repeatTransfer">
          <label for="howManyTimes">Quantas vezes?</label>
          <input type="text" OnlyNumber class="form-control" id="howManyTimes" name="howManyTimes"
            [(ngModel)]="editModel.howManyTimes" formControlName="howManyTimes">
          <invalid-feedback [field]="f.howManyTimes"></invalid-feedback>
        </div>
        <div class="form-group col-6" *ngIf="editModel.repeatTransfer">
          <label for="dayMonth">Dia do mês</label>
          <input type="text" id="dayMonth" name="dayMonth" class="form-control" [bsConfig]="bsConfig"
            [(ngModel)]="editModel.dayMonth" bsDatepicker Mask="##" formControlName="dayMonth">
          <invalid-feedback [field]="f.dayMonth"></invalid-feedback>
        </div>
        <div class="modal-footer text-center border-0 m-auto">
          <button type="button" class="btn btn-outline-primary" (click)="modalRef.hide()">CANCELAR</button>
          <button type="button" class="btn btn-primary" (click)="finish()"
            [disabled]="formGroup.invalid">EXECUTAR</button>
        </div>
      </div>
    </form>
  </div>
</div>