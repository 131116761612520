import { AfterViewInit, Component, EventEmitter } from '@angular/core';
import { AccountModel } from 'src/app/shared/interfaces/account-model';
import { ClientModel } from 'src/app/shared/interfaces/client-model';
import { StorageService as storage } from 'src/app/shared/services/storage.service';
import { Utils } from 'src/app/shared/commons/Utils';

@Component({
  selector: 'app-receipt-pdf',
  templateUrl: './receipt-pdf.component.html',
  styleUrls: ['./receipt-pdf.component.scss']
})
export class ReceiptPdfComponent implements AfterViewInit {

  emitter: EventEmitter<void> = new EventEmitter()

  client = {} as ClientModel
  account = {} as AccountModel
  receipt = {} as any
  infoCupom: any = []
  bankListIspb = storage.get('ListBanks');
  translated_types = {
    'bank_slip': 'boleto',
    'transfer': 'ted',
    'pix': 'pix',
    'internal': 'transferência entre contas',
    'schedule_transfer': 'ted agendado'
  }

  ngAfterViewInit(): void {
    this.emitter.emit()

    if (this.receipt?.otherInfo?.cupom) {
      let list = this.receipt['otherInfo']['cupom'].split('@@')
      for (let index = 0; index < list.length; index++) {
        if (index > 1) {
          const item = list[index].replace('@', ' ').replace('@', '').replace(/( )+/g, ' ').trim()
          if (!item.includes('ewally'))
            this.infoCupom.push(item);
        }
      }
    }
  }

  getName(receipt) {
    let name = ''
    if (receipt?.receiver?.name)
      name = receipt.receiver.name
    else if (receipt?.otherInfo?.destination?.bankAccount?.fullName)
      name = receipt.otherInfo.destination.bankAccount.fullName

    return Utils.capitalizeName(name)
  }

  filterBankName(favored) {
    return `${this.bankListIspb.find(x => x.compe == favored.otherInfo.destination.bankAccount.bankCode)?.name}`
  }

  getDocument(receipt) {
    let document = ''
    if (receipt?.operationType == 'SENT_TRANSFERENCE')
      return Utils.maskCpfCnpj(receipt?.otherInfo?.document || receipt?.document)
    else if (receipt?.otherInfo?.receiver?.personType == 'LEGAL_PERSON')
      return Utils.maskCpfCnpj(receipt?.receiver?.document)
    else if (receipt?.receiver?.document)
      document = receipt.receiver.document
    else if (receipt?.otherInfo?.destination?.bankAccount?.document)
      document = receipt.otherInfo.destination.bankAccount.document

    return Utils.protectedCPF(document)
  }

  translate_type(type) {
    return this.translated_types[type].charAt(0).toUpperCase() + this.translated_types[type].slice(1);
  }

  get utils() { return Utils }
}
