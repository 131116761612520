import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmDialogViewModel } from '../components/confirm-dialog/confirm-dialog.view-model';
import { AppNavRouteLink } from '../shared/commons/AppNavRouteLink';
import { Utils } from '../shared/commons/Utils';
import { Validations } from '../shared/commons/Validations';
import { ClientService } from '../shared/services/client.service';
import { DialogService } from '../shared/services/dialog.service';

@Component({
  selector: 'app-email-verify',
  templateUrl: './email-verify.component.html',
  styleUrls: ['./email-verify.component.scss']
})
export class EmailVerifyComponent implements OnInit {

  formGroup: FormGroup
  data: any = {}

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private clientService: ClientService,
    private dialogService: DialogService
  ) { }

  ngOnInit() {
    this.data = this.activatedRoute.snapshot.queryParams
    this.formGroup = this.formBuilder.group({
      document_number: ['', Validators.compose([Validators.required, Validations.CPF])]
    })
  }

  checkEmail() {
    this.clientService.emailVerifyToken(this.data.client_uuid, this.data.jwt).then(res => {
      if (res.error) {
        this.showMsg('Link inválido ou expirado', true)
      } else {
        this.data = res
      }
    })
  }

  verify() {
    if (this.formGroup.valid) {
      this.clientService.emailVerifyDocument(this.data.client_uuid, Utils.unMask(this.formGroup.value.document_number), this.data.jwt).then(res => {
        if (res.error)
          this.showMsg('Cliente não encontrado', true)
        else
          this.showMsg('Cliente validado', false)
      })
    } else {
      this.formGroup.markAllAsTouched()
    }
  }

  showMsg(title, erro) {
    const model = new ConfirmDialogViewModel();
    model.subTitle = title
    model.msg = 'Você será redirecionado para a tela de login.'
    if (erro) {
      this.dialogService.showDialogError(`${model.subTitle}. ${model.msg}`).subscribe(() => {
        this.router.navigate([AppNavRouteLink.PAGE.LOGIN])
      })
    } else {
      this.dialogService.showDialogSuccess(model).subscribe(() => {
        this.router.navigate([AppNavRouteLink.PAGE.LOGIN])
      })
    }
  }
}
