import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { AppNavRouteLink } from 'src/app/shared/commons/AppNavRouteLink';
import { StorageService as storage } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-close-account',
  templateUrl: './close-account.component.html',
  styleUrls: ['./close-account.component.scss']
})
export class CloseAccountComponent implements OnInit {

  @Input() balance = 0

  confirmResult: Subject<any>

  constructor(
    public modalRef: BsModalRef,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.confirmResult = new Subject()
  }

  transfer() {
    storage.save('close-account', true)
    this.router.navigate([AppNavRouteLink.TABS_TRANSFERS.NEW])
    this.modalRef.hide()
  }

  onClose() {
    if (this.balance > 0) {
      storage.save('close-account', true)
      this.confirmResult.next('transfer')
    } else {
      storage.delete('close-account')
      this.confirmResult.next(true)
    }
    this.modalRef.hide()
  }
}
