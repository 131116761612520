<div class="modal-content">
  <div class="modal-header border-0">
    <span class="medium h1 w-100 m-0">Editar dados pessoais</span>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span class="material-icons-outlined">highlight_off</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="formGroup" class="" autocomplete="off" novalidate>
      <div formGroupName="client" class="row">
        <div class="form-group col-12">
          <label for="friendly_name">Nome social</label>
          <input type="text" id="friendly_name" name="friendly_name" class="form-control" [(ngModel)]="client.friendly_name" formControlName="friendly_name"
                 maxlength="200">
        </div>
        <div class="form-group col-12">
          <label for="name">Nome completo</label>
          <input type="text" id="name" name="name" class="form-control" [(ngModel)]="client.name" formControlName="name"
                 maxlength="200" disabled>
          <div *ngIf="(!groupClient.name.pristine || groupClient.name.touched) && groupClient.name.errors"
               class="invalid-feedback x-small">
            <div *ngIf="groupClient.name.errors.required"> Campo obrigatório. </div>
            <div *ngIf="groupClient.name.errors?.pattern"> Nome inválido. </div>
          </div>
        </div>
        <div class="form-group col-12">
          <label for="mother_name">Nome completo da mãe</label>
          <input type="text" class="form-control" id="mother_name" name="mother_name" [(ngModel)]="client.mother_name"
                 formControlName="mother_name" maxlength="128" disabled>
          <div
                  *ngIf="(!groupClient.mother_name.pristine || groupClient.mother_name.touched) && groupClient.mother_name.errors"
                  class="invalid-feedback x-small">
            <div *ngIf="groupClient.mother_name.errors.required"> Campo obrigatório. </div>
            <div *ngIf="groupClient.mother_name.errors?.pattern"> Nome inválido. </div>
          </div>
        </div>
        <div class="form-group col-6">
          <label for="birth_date">Data de Nascimento</label>
          <input type="text" id="birth_date" name="birth_date" class="form-control" [(ngModel)]="birthDate"
                 formControlName="birth_date" Mask="##/##/####" disabled>
          <div
                  *ngIf="(!groupClient.birth_date.pristine || groupClient.birth_date.touched) && groupClient.birth_date.errors"
                  class="invalid-feedback x-small">
            <div *ngIf="groupClient.birth_date.errors.required"> Campo obrigatório. </div>
            <div *ngIf="!groupClient.birth_date.errors.required && groupClient.birth_date.errors.invalidDate">
              Data inválida. Por favor, informe uma data válida. </div>
            <div *ngIf="!groupClient.birth_date.errors.required && groupClient.birth_date.errors.isMinor"> Idade
              mínima 18 anos. </div>
          </div>
        </div>
        <div class="form-group col-12 col-md-6">
          <label for="marital_status">Estado civil</label>
          <div class="dropdown">
            <select class="form-control" [(ngModel)]="client.marital_status" formControlName="marital_status">
              <option selected value="">Selecione...</option>
              <option *ngFor="let item of listMaritalStatus" [value]="item.value">{{item.name}}</option>
            </select>
            <div
                    *ngIf="(!groupClient.marital_status.pristine || groupClient.marital_status.touched) && groupClient.marital_status.errors"
                    class="invalid-feedback x-small">
              <div *ngIf="groupClient.marital_status.errors.required"> Campo obrigatório. </div>
            </div>
          </div>
        </div>
        <div class="form-group col-6">
          <label for="secondary_document_number">Número do RG</label>
          <input type="text" OnlyNumber class="form-control" id="secondary_document_number"
                 name="secondary_document_number" [(ngModel)]="client.secondary_document_number"
                 formControlName="secondary_document_number" readonly>
          <div
                  *ngIf="(!groupClient.secondary_document_number.pristine || groupClient.secondary_document_number.touched) && groupClient.secondary_document_number.errors"
                  class="invalid-feedback x-small">
            <div *ngIf="groupClient.secondary_document_number.errors.required"> Campo obrigatório. </div>
          </div>
        </div>
        <div class="form-group col-6">
          <label for="document_number">Número do CPF</label>
          <input type="tel" class="form-control" id="document_number" name="document_number" Mask="###.###.###-##"
                 [(ngModel)]="client.document_number" formControlName="document_number" readonly>
          <div
                  *ngIf="(!groupClient.document_number.pristine || groupClient.document_number.touched) && groupClient.document_number.errors"
                  class="invalid-feedback x-small">
            <div *ngIf="groupClient.document_number.errors.required"> Campo obrigatório. </div>
            <div *ngIf="groupClient.document_number.errors.invalid"> CPF inválido. </div>
          </div>
        </div>
        <div class="form-group col-6">
          <label for="income">Renda mensal aproximada</label>
          <input type="tel" Currency class="form-control" id="income" name="income" [(ngModel)]="income"
                 formControlName="income">
          <div *ngIf="(!groupClient.income.pristine || groupClient.income.touched) && groupClient.income.errors"
               class="invalid-feedback x-small">
            <div *ngIf="groupClient.income.errors.required"> Campo obrigatório. </div>
          </div>
        </div>
        <div class="form-group col-12 col-md-6">
          <label for="occupation">Ocupação</label>
          <input type="text" class="form-control" id="occupation" formControlName="occupation" name="occupation" [(ngModel)]="client.occupation" (ngModelChange)="setOccupation($event)">
          <div *ngIf="groupClient.occupation.errors?.required" class="invalid-feedback x-small">
            Campo obrigatório.
          </div>
        </div>
      </div>
      <div formGroupName="address" class="row">
        <div class="form-group col-4">
          <label for="zip_code">CEP</label>
          <input type="text" class="form-control" id="zip_code" name="zip_code" Mask="#####-###"
                 [(ngModel)]="address.zip_code" formControlName="zip_code" (keyup)="getZipCode()">
          <div
                  *ngIf="(!groupAddress.zip_code.pristine || groupAddress.zip_code.touched) && groupAddress.zip_code.errors"
                  class="invalid-feedback x-small">
            <div *ngIf="groupAddress.zip_code.errors.required"> Campo obrigatório. </div>
            <div *ngIf="groupAddress.zip_code.errors.minlength"> CEP inválido. Por favor, informe um CEP válido.
            </div>
          </div>
        </div>
        <div class="form-group col-8">
          <label for="street">Endereço</label>
          <input type="text" class="form-control" id="street" name="street" [(ngModel)]="address.street"
                 formControlName="street" maxlength="128" [readonly]="disableAddress.street">
          <div *ngIf="(!groupAddress.street.pristine || groupAddress.street.touched) && groupAddress.street.errors"
               class="invalid-feedback x-small">
            <div *ngIf="groupAddress.street.errors.required"> Campo obrigatório. </div>
          </div>
        </div>
        <div class="form-group col-4">
          <label for="street_number">Número</label>
          <input type="text" OnlyNumber class="form-control" id="street_number" name="street_number"
                 [(ngModel)]="address.street_number" formControlName="street_number" maxlength="128">
          <div
                  *ngIf="(!groupAddress.street_number.pristine || groupAddress.street_number.touched) && groupAddress.street_number.errors"
                  class="invalid-feedback x-small">
            <div *ngIf="groupAddress.street_number.errors.required"> Campo obrigatório. </div>
          </div>
        </div>
        <div class="form-group col-8">
          <label for="complement">Complemento</label>
          <input type="text" class="form-control" id="complement" name="complement"
                 [(ngModel)]="address.complement" formControlName="complement" maxlength="128">
          <div
                  *ngIf="(!groupAddress.complement.pristine || groupAddress.complement.touched) && groupAddress.complement.errors"
                  class="invalid-feedback x-small">
            <div *ngIf="groupAddress.complement.errors.required"> Campo obrigatório. </div>
          </div>
        </div>
        <div class="form-group col-12">
          <input type="checkbox" id="no_number" [checked]="noNumber" (change)="setNoNumber($event.target.checked, '')">
          <label class="font-weight-bold x-small poppins ml-1" for="no_number">Não possui numeração</label>
        </div>
        <div class="form-group col-12">
          <label for="district">Bairro</label>
          <input type="text" class="form-control" id="district" name="district" [(ngModel)]="address.district"
                 formControlName="district" maxlength="128" [readonly]="disableAddress.district">
          <div
                  *ngIf="(!groupAddress.district.pristine || groupAddress.district.touched) && groupAddress.district.errors"
                  class="invalid-feedback x-small">
            <div *ngIf="groupAddress.district.errors.required"> Campo obrigatório. </div>
          </div>
        </div>
        <div class="form-group col-9">
          <label for="city">Cidade</label>
          <input type="text" id="city" name="city" class="form-control" [(ngModel)]="address.city"
                 formControlName="city" maxlength="128" [readonly]="disableAddress.city">
          <div *ngIf="(!groupAddress.city.pristine || groupAddress.city.touched) && groupAddress.city.errors"
               class="invalid-feedback x-small">
            <div *ngIf="groupAddress.city.errors.required"> Campo obrigatório. </div>
          </div>
        </div>
        <div class="form-group col-3">
          <label for="state">Estado</label>
          <input type="text" class="form-control" id="state" name="state" [(ngModel)]="address.state"
                 formControlName="state" maxlength="200" [readonly]="disableAddress.state">
          <div *ngIf="(!groupAddress.state.pristine || groupAddress.state.touched) && groupAddress.state.errors"
               class="invalid-feedback x-small">
            <div *ngIf="groupAddress.state.errors.required"> Campo obrigatório. </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <button type="submit" class="mx-2 btn btn-outline-dark" (click)="close()">CANCELAR</button>
        <button type="submit" class="mx-2 btn btn-primary" (click)="save()">SALVAR</button>
      </div>
    </form>
  </div>
</div>
