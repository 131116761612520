<div class="modal-content">
  <div class="row">
    <div class="col-6 d-none d-md-inline-flex login-background">
    </div>
    <div class="col-12 col-md-6">
      <div class="modal-header border-0">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span class="material-icons-outlined">highlight_off</span>
        </button>
      </div>
      <div class="modal-body mx-3 pt-0 pt-md-2">
        <p class="h1 big mb-0 mb-md-3">Esqueci minha senha</p>
        <div class="my-1 my-md-3">
          <p class="small">Digite seu CPF, por favor. Enviaremos um link para redefinição de senha para seu e-mail
            cadastrado</p>
          <strong class="small" style="color: gray">Verifique também se a sua mensagem não foi encaminhada para a pasta de SPAM</strong>
        </div>
        <form [formGroup]="form" autocomplete="off" novalidate>
          <div class="form-group mt-3 mb-4 my-md-0">
            <label for="document_number" class="small poppins-bold">CPF</label>
            <input type="text" class="form-control" id="document_number" name="document_number" Mask="###.###.###-##"
              [(ngModel)]="loginData.document_number" formControlName="document_number">
            <div *ngIf="(!f.document_number.pristine || f.document_number.touched) && f.document_number.errors"
              class="invalid-feedback x-small">
              <div *ngIf="f.document_number.errors.required"> Campo obrigatório. </div>
              <div *ngIf="f.document_number.errors.invalid"> CPF inválido. </div>
            </div>
          </div>
        </form>
        <div class="row justify-content-between text-right mt-3 mb-3 mt-md-5 mx-0">
          <button type="button" class="btn btn-outline-primary m-0" (click)="modalRef.hide()">VOLTAR</button>
          <button class="btn btn-primary m-0" (click)="onClick()" [disabled]="!form.valid">ENVIAR</button>
        </div>
      </div>
    </div>
  </div>
</div>
