import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ProfileLimits } from 'src/app/shared/commons/ProfileLimits';
import { AssetColorsEnum } from 'src/app/shared/enums/asset-color.enum';
import { SuitabilityEnum, SuitabilityMainFeaturesEnum } from 'src/app/shared/enums/suitability-type.enum';

@Component({
  selector: 'app-suitability-main-features',
  templateUrl: './suitability-main-features.component.html',
  styleUrls: ['./suitability-main-features.component.scss']
})
export class SuitabilityMainFeaturesComponent implements AfterViewInit {

  @Input() actualProfileName: string
  msg: string;
  assetList: any = []

  constructor(public modalRef: BsModalRef) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      switch (this.actualProfileName) {
        case SuitabilityEnum.cautious:
          this.msg = SuitabilityMainFeaturesEnum.cautious
          break;
        case SuitabilityEnum.moderate:
          this.msg = SuitabilityMainFeaturesEnum.moderate
          break;
        case SuitabilityEnum.balanced:
          this.msg = SuitabilityMainFeaturesEnum.balanced
          break;
        case SuitabilityEnum.growth:
          this.msg = SuitabilityMainFeaturesEnum.growth
          break;
      }
      this.assetList = ProfileLimits.get(this.actualProfileName)      
    }, 100);
  }

  getColor(asset) {
    let result = ''
    Object.keys(this.AssetColors).forEach(el => {
      if (asset.toLowerCase().includes(el)) {
        result = AssetColorsEnum[el]
      }
    })
    return result
  }

  get AssetColors() {
    return AssetColorsEnum
  }
}
