<div class="modal-content" *ngIf="loaded_data">
  <div class="justify-content-center">
    <div class="col col-12 text-center">
      <p class="medium h1 my-4">Confirmação de pagamento</p>
    </div>
    <div class="col col-12 mb-4">
      <form class="row">
        <div class="col-4 text-right">
          <label>Código de barras</label>
        </div>
        <div class="col-12 col-md-8 text-left">
          <p class="text-muted small">{{loaded_data?.bar_code}}</p>
        </div>
        <div class="col-4 text-right">
          <label>Beneficiário</label>
        </div>
        <div class="col-12 col-md-8 text-left">
          <p class="text-muted small">{{loaded_data?.recipient}}</p>
        </div>
        <div class="col-4 text-right">
          <label>Vencimento</label>
        </div>
        <div class="col-12 col-md-8 text-left">
          <p class="text-muted small">{{loaded_data?.due_date | date: 'dd/MM/yyyy'}}</p>
        </div>
        <div class="col-4 text-right">
          <label>Pagar em</label>
        </div>
        <div class="col-12 col-md-8 text-left">
          <p class="text-muted small">{{loaded_data?.pay_date | date: 'dd/MM/yyyy'}}</p>
        </div>
        <div class="col-4 text-right">
          <label>Valor</label>
        </div>
        <div class="col-12 col-md-8 text-left">
          <p class="text-muted small">{{loaded_data?.value}}</p>
        </div>
        <div class="col-4 text-right">
          <label>Desconto</label>
        </div>
        <div class="col-12 col-md-8 text-left">
          <p class="text-muted small">{{loaded_data?.discount}}</p>
        </div>
        <div class="col-4 text-right">
          <label>Multas</label>
        </div>
        <div class="col-12 col-md-8 text-left">
          <p class="text-muted small">{{loaded_data?.fine}}</p>
        </div>
        <div class="col-4 text-right">
          <label>Juros</label>
        </div>
        <div class="col-12 col-md-8 text-left">
          <p class="text-muted small">{{loaded_data?.interest}}</p>
        </div>
        <div class="col-4 text-right">
          <label>Valor total</label>
        </div>
        <div class="col-12 col-md-8 text-left">
          <p class="text-muted small">{{loaded_data?.total_value}}</p>
        </div>
        <div class="col-4 text-right">
          <label>Descrição</label>
        </div>
        <div class="col-12 col-md-8 text-left">
          <p class="text-muted small">{{loaded_data?.description}}</p>
        </div>
      </form>
    </div>
    <!-- {{loaded_data | json}} -->
    <div class="col col-12 text-center mb-4 fw-bold small">
      Deseja confirmar o pagamento no valor de {{loaded_data?.total_value}}
    </div>
    <div class="col col-12 text-center mb-4 text-primary fw-bold small">
      Este pagamento terá um custo de {{cost | currency: 'BRL'}} (o valor do custo precisa estar diponível na sua conta). Você pode consultar o comprovante em extratos e comprovantes.
    </div>
  </div>
  <div class="btn-group my-3 mx-lg-3">
    <button type="button" class="btn btn-outline-dark w-100" loaded_data-dismiss="modal"
      (click)="modalRef.hide()">VOLTAR</button>
    <button type="button" class="btn btn-primary w-100" (click)="onConfirm()">CONFIRMAR</button>
  </div>
</div>