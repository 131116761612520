import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[autoTab]'
})
export class AutoTabDirective {

  @Input() autoTab: any

  constructor(private _el: ElementRef) { }

  @HostListener('keyup', ['$event']) onInput(event: any) {
    const initalValue = this._el.nativeElement.value
    const maxLength = event.target.attributes.maxlength.value
    const backspace = event.code == 'Backspace'
    let el

    if (backspace && initalValue.length == 0)
      el = event.srcElement.previousElementSibling
    else if (initalValue.length == maxLength)
      el = event.srcElement.nextElementSibling

    if (el == null)
      return
    else
      el.focus()
  }

}
