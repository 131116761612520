import { CurrencyPipe } from '@angular/common';
import { FormGroup } from '@angular/forms'
import * as _ from 'lodash';
import * as moment from 'moment';
import { Banks } from './Banks'

export class Utils {
  public static getEmailPattern(): RegExp {
    return /^(([^<>()\[\]\\.,:\s@"]+(\.[^<>()\[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  }

  public static getNamePattern(): string {
    return `^[a-zA-ZàáâãèéêėìíîòóôõùúûüūýżźñçÀÁÂÃÅĆČĖÈÉÊÌÍÎÒÓÔÕÙÚÛŪÝŻŹÑÇ'\s]{2,100}(?: [a-zA-ZàáâãèéêėìíîòóôõùúûüūýżźñçÀÁÂÃÅĆČĖÈÉÊÌÍÎÒÓÔÕÙÚÛŪÝŻŹÑÇ'\s]{1,100})+[ ]*$`
  }

  public static capitalizeName(full_name: string): string {
    let separateWord = full_name.toLowerCase().split(' ');
    for (let i = 0; i < separateWord.length; i++) {
      separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
        separateWord[i].substring(1);
    }
    return separateWord.join(' ');
  }

  public static unMask(value: string): string {
    if (typeof value == 'string') {
      return value.replace(new RegExp(/[^\d]/, 'g'), '')
    } else {
      return value
    }
  }

  public static removeSpecialCharacters(text: string) {
    const a = 'àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:; '
    const b = 'aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------ '
    const p = new RegExp(a.split('').join('|'), 'g')
    return text.toString().toLowerCase().trim()
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special chars
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[^\w\s]/gi, '') // Replace spaces, non-word characters and dashes with a single dash (-)
      .replace(/\s+/g, ' ')
  }

  public static moneyTofloat(value: string) {
    if (typeof value == 'string') {
      value = value.replace(/[^0-9\,-]+/g, '').replace(',', '.')
    }

    //Caso se a string for vazia retornar zero
    if (value == '' || value == undefined || value == null) {
      value = '0';
    }

    return parseFloat(value)
  }

  public static maskMoney(value, symbol = true, currency = 'BRL') {
    const currencyPipe: CurrencyPipe = new CurrencyPipe('pt-BR');
    if (symbol)
      return currencyPipe.transform(value, currency)
    else
      return currencyPipe.transform(value, currency, '')
  }

  public static getFirstName(value: string) {
    return value ? value.split(' ').slice(0, 1).join(' ') : ''
  }

  public static getLastName(value: string) {
    return value ? value.split(' ').slice(-1).join(' ') : ''
  }

  public static maskCpf(value: string): string {
    return value ? value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4') : ''
  }

  public static maskCpfCnpj(value: string): string {
    if (value?.length == 11) {
      return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4')
    } else if (value?.length == 14) {
      return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/, '$1.$2.$3/$4-$5')
    }
  }

  public static maskAccount(value: string): string {
    value = value.replace(new RegExp(/[^\d]/, 'g'), '')
    const length = value.length - 1
    return `${value.substring(0, length)}-${value.substring(length)}`
  }

  public static formatUnix(date: number): Date {
    return new Date(date * 1000);
  }

  public static maskMobile(value: string): string {
    return value ? this.unMask(value).replace(/^(\d{2})(\d{5})(\d{4}).*/, '($1) $2-$3') : ''
  }

  public static maskMobileInternational(value: string): string {
    return value ? this.unMask(value).replace(/^(\d{2})(\d{2})(\d{5})(\d{4}).*/, '+$1 ($2) $3-$4') : ''
  }

  public static maskMobileToNational(value: string): string {
    return value ? this.unMask(value).replace(/^(\d{2})(\d{2})(\d{5})(\d{4}).*/, '($2) $3-$4') : ''
  }

  public static maskZipCode(value: string): string {
    return value ? this.unMask(value).replace(/^(\d{5})(\d{3}).*/, '$1-$2') : ''
  }

  public static parseFloat(value) {
    return parseFloat(value)
  }

  public static verifyFormValidations(formGroup: FormGroup) {
    let listErrors: any = []
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field)
      if (control instanceof FormGroup) {
        this.verifyFormValidations(control)
      }
      listErrors.push(`${field}: ${JSON.stringify(control.errors)}`)
    })
    return listErrors
  }

  public static generateColor(value) {
    const randomColor = Math.floor(value * 16777215).toString(16)
    return `#${randomColor}`
  }

  public static variableColorsRGB(index, rgb) {
    let result: any = []
    rgb.forEach(el => {
      if (index > 0) {
        el = parseInt(el > 245 ? 255 : el + 10)
        result.push(el)
      }
    })
    return result.length ? result : rgb
  }

  public static rgbToHex(rgb) {
    let r = this.convertRgbToHex(rgb[0])
    var g = this.convertRgbToHex(rgb[1])
    var b = this.convertRgbToHex(rgb[2])

    return `#${r}${g}${b}`
  }

  private static convertRgbToHex(value) {
    let hex = Number(value).toString(16)
    if (hex.length < 2) {
      hex = '0' + hex
    }
    return hex
  }

  public static hexToRgbNew(hex) {
    hex = hex.replace('#', '')
    var arrBuff = new ArrayBuffer(4);
    var vw = new DataView(arrBuff);
    vw.setUint32(0, parseInt(hex, 16), false);
    var arrByte = new Uint8Array(arrBuff);

    return arrByte[1] + "," + arrByte[2] + "," + arrByte[3];
  }

  public static filterBanks(bankCode) {
    if (bankCode) {
      const filter: any = Banks.get().find(b => b['code'] === bankCode)
      return { code: bankCode, name: filter?.bank }
    }
    return null
  }

  public static formatBankDigit(account) {
    if (account) {
      const t = account.length - 1
      return `${account.substring(0, t)}-${account.substring(t)}`.replace('--', '-')
    }
    return null
  }

  public static isNullOrEmpty(field) {
    if (!field || field == '' || field == 'undefined' || field == undefined) {
      return null
    } else {
      return field
    }
  }

  public static validNumber(field) {
    if (!field || field == '' || field == 'undefined' || field == undefined) {
      return 0
    } else {
      return field
    }
  }

  public static protectedCPF(cpf) {
    const cpfUnmask = this.unMask(cpf)
    return cpfUnmask.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '***.$2.$3-**')
  }

  public static months(count) {
    let months = []
    for (let index = 1; index <= count; index++) {
      months.push(moment().subtract(count - index, 'M').format("YYYY-MM"))
    }
    return months
  }

  /**
   * Verifica o dígito verificador da conta
   */
  public static digitChecker(account) {
    let multiplicador = []
    let initialize = 9
    let sum = 0
    for (let _value of account) {
      initialize = initialize > 0 ? initialize : 9
      multiplicador.push(initialize)
      initialize--
    }
    multiplicador = multiplicador.reverse()

    for (let index = 0; index < account.length; index++) {
      sum += account[index] * multiplicador[index]
    }
    return sum % 11 == 10 ? 0 : sum % 11
  }
}

export const valueOrDefault = (value: any, value_default: any) => {
  if (value) return value;
  else return value_default;
};