import { Component, OnInit, Input, ChangeDetectorRef, ViewRef, ChangeDetectionStrategy } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'icon-svg',
  templateUrl: './icon-svg.component.html',
  styleUrls: ['./icon-svg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconSvgComponent implements OnInit {

  @Input() public color: string;
  @Input() public hoverColor: string;
  @Input() public size: string;
  @Input() public svgName: string;
  @Input() public textTooltip: string;
  @Input() public tooltipClass = 'bottom';
  @Input() public dataBadge = null;
  @Input() public id?: string;

  @Input() public classes: any = {};
  public svgIcon: any = '';
  mouseOver = false;

  constructor(
    private http: HttpClient,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.initialize()
  }
  
  ngOnChanges(changes: any) {
    if (changes.svgName.previousValue && changes.svgName.currentValue != changes.svgName.previousValue) {
      this.initialize();
    }
  }

  initialize() {
    this.id = this.id ? this.id : this.svgName.replace('/', '-')
    this.http.get(`assets/images/svg/${this.svgName}.svg`, { responseType: 'text' }).subscribe((data) => {
      this.svgIcon = data.toString();
      setTimeout(() => {
        if (this.cdr && !(this.cdr as ViewRef).destroyed) {
          const el = document.getElementById(this.id).firstChild as HTMLElement;
          if (el?.style && this.size) {
            el.style.width = `${this.size}rem`;
            el.style.height = `${this.size}rem`;
          }
          this.cdr.detectChanges();
        }
      });
    });
  }
  
  onMouseOver() {
    if (this.hoverColor) {
      this.mouseOver = !this.mouseOver;
      const path = document.getElementsByTagName('path')[0];
      if (this.mouseOver) {
        path.style.fill = this.hoverColor;
      } else {
        path.style.fill = this.color;
      }
    }
  }
}
