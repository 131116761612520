<div class="modal-content">
  <div class="modal-header border-0">
    <span class="medium text-center font-weight-bold w-100 m-auto"> Editar favorito </span>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span class="material-icons-outlined">highlight_off</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="row" [formGroup]="formGroup" autocomplete="off" novalidate>
      <div class="form-group col-12">
        <label for="nameFavorite">Nome do favorecido</label>
        <input type="text" class="form-control" name="nameFavorite" [(ngModel)]="editModel.name" formControlName="nameFavorite">
        <invalid-feedback [field]="f.nameFavorite"></invalid-feedback>
      </div>
      <div class="form-group col-12">
        <label for="bank">Nome do banco</label>
        <div class="dropdown">
          <select class="form-control" [(ngModel)]="editModel.bank_code" formControlName="bank">
            <option selected value="">Selecione...</option>
            <option *ngFor="let item of bankList" [ngValue]="item.code">{{item.code}} {{item.bank}}</option>
          </select>
          <invalid-feedback [field]="f.bank"></invalid-feedback>
        </div>
      </div>
      <div class="form-group col-4">
        <label for="agency">Agência</label>
        <input type="text" OnlyNumber class="form-control" id="agency" name="agency" [(ngModel)]="editModel.branch"
          formControlName="agency">
        <invalid-feedback [field]="f.agency"></invalid-feedback>
      </div>
      <div class="form-group col-6">
        <label for="account">Conta</label>
        <input type="text" OnlyNumber class="form-control" id="account" name="account" [(ngModel)]="editModel.account"
          formControlName="account">
        <invalid-feedback [field]="f.account"></invalid-feedback>
      </div>
      <div class="form-group col-2">
        <label for="digit">Dígito</label>
        <input type="text" OnlyNumber class="form-control" id="digit" name="digit" [(ngModel)]="editModel.digit"
          formControlName="digit" maxlength="1">
        <invalid-feedback [field]="f.digit"></invalid-feedback>
      </div>
      <div class="form-group col-12">
        <label for="document">CPF/CNPJ</label>
        <input type="text" CpfCnpjMask class="form-control" id="document" name="document"
          [(ngModel)]="editModel.document_number" formControlName="document">
        <invalid-feedback [field]="f.document" label="CPF/CNPJ"></invalid-feedback>
      </div>
      <!-- <div class="form-group col-12">
        <label for="accountType">Tipo de conta</label>
        <div class="dropdown">
          <select class="form-control" [(ngModel)]="editModel.accountType" formControlName="accountType">
            <option selected value="">Selecione...</option>
            <option *ngFor="let item of AccountType" [ngValue]="item.name">{{item.name}}</option>
          </select>
          <invalid-feedback [field]="f.accountType"></invalid-feedback>
        </div>
      </div> -->
    </form>
  </div>
  <div class="modal-footer text-center border-0 m-auto">
    <button type="button" class="btn btn-outline-primary" (click)="modalRef.hide()">CANCELAR</button>
    <button type="button" class="btn btn-primary" (click)="onConfirm()" [disabled]="formGroup.invalid">SALVAR</button>
  </div>
</div>