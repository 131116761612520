export enum CategoryEnum {
  'Alimentação' = 'icon-alimentacao',
  'Assinatura' = 'icon-outros-gastos',
  'Beleza e Cuidados Pessoais' = 'icon-beleza',
  'Bônus' = 'icon-bonus',
  'Cartões' = 'icon-cartoes',
  'Contas Fixas (água - gás - luz)' = 'icon-contas-fixas',
  'Educação' = 'icon-educacao',
  'Empréstimo' = 'icon-emprestimo',
  'Empréstimos' = 'icon-emprestimos',
  'Estilo de Vida' = 'icon-estilo',
  'Impostos e tributos' = 'icon-impostos',
  'Impostos' = 'icon-impostos',
  'Investimento' = 'icon-investimentos',
  'Taxas' = 'icon-impostos',
  'Lazer' = 'icon-lazer',
  'Mercado' = 'icon-mercado',
  'Moradia' = 'icon-moradia',
  'Outras Despesas' = 'icon-outros-casos',
  'Outros Gastos' = 'icon-outros-gastos',
  'Outras Receitas' = 'icon-outras-rendas',
  'Outras Rendas' = 'icon-outras-rendas',
  'Previdência' = 'icon-aposentadoria',
  'Reembolso' = 'icon-outras-rendas',
  'Remuneração' = 'icon-remuneracao',
  'Rendimento' = 'icon-rendimento',
  'Recebimento' = 'icon-rendimento',
  'Saúde' = 'icon-saude',
  'Saque' = 'icon-saque',
  'Serviços' = 'icon-servicos',
  'Transferência' = 'icon-transferencia-enviada',
  'Transferência Recebida' = 'icon-transferencia-recebida',
  'Transporte' = 'icon-transporte',
  'Viagens' = 'icon-viagens',
}