import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppNavRouteLink } from 'src/app/shared/commons/AppNavRouteLink';

@Component({
  templateUrl: './manage-card-limit.component.html',
  styleUrls: ['./manage-card-limit.component.scss']
})
export class ManageCardLimitComponent {

  minimumBalance: number;
  accountBalance: number;
  investmentBalance: number;

  constructor(
    public modalRef: BsModalRef,
    private router: Router
  ) { }

  adjustMyLimit() {
    this.modalRef.hide();
    this.router.navigate([AppNavRouteLink.TABS_MY_DATA.AUTOMATIC_PILOT])
  }
}
