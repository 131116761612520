import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ClientService } from '../services/client.service';
import { UtilService } from '../services/util.service';

@Injectable({
  providedIn: 'root'
})
export class ProtectedRoutersGuard implements CanActivate {
  client: any;

  constructor(
    private router: Router,
    private clientService: ClientService,
    private utilService: UtilService
  ) { 
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      this.clientService.clientData.subscribe(d => {
          if (!d) { return; }
          this.client = d;
      });

      if (this.client) {
          if (this.utilService.routerIsProtected(state.url, this.client)) {


              this.router.navigate(['/login']);
              return false;
          }

          return true;
      } else {
          if (this.utilService.routerIsProtected(state.url)) {


              this.router.navigate(['/login']);
              return false;
          }

          return true;
      }
  }
  
}
