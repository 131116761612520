<div class="modal-content">
  <div class="modal-header border-0">
    <span class="medium text-center font-weight-bold w-100 m-auto"> Escolha a classe do ativo </span>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span class="material-icons-outlined">highlight_off</span>
    </button>
  </div>
  <p class="text-center small">Selecione uma classe de ativo para adicionar um produto na carteira.</p>
  <div class="modal-body">
    <div class="row">
      <ul *ngFor="let item of listAsset" class="col-4">
        <li class="border card pointer" [class.disabled]="!item.enabled">
          <div class="card-body left rounded-left p-4" [style.border-left-color]="AssetColor[item.value]" (click)="confirmAndClose(item)">
            <strong class="poppins small-medium">{{item.name}}</strong>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>