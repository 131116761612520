import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { LoaderService } from 'src/app/shared/services/loader.service'

@Component({
  selector: 'app-custom-loader',
  templateUrl: './custom-loader.component.html',
  styleUrls: ['./custom-loader.component.scss']
})
export class CustomLoaderComponent implements OnInit, AfterViewInit {

  loading: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private loaderService: LoaderService
  ) {
    
  }

  ngAfterViewInit(): void {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
      this.cdr.detectChanges()
    });
  }
  
  ngOnInit() {
  }

}
