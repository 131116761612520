import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ConfirmDialogViewModel } from 'src/app/components/confirm-dialog/confirm-dialog.view-model';
import { AppNavRouteLink } from 'src/app/shared/commons/AppNavRouteLink';
import { ClientService } from 'src/app/shared/services/client.service';
import { StorageService as storage } from 'src/app/shared/services/storage.service';
import { Utils } from 'src/app/shared/commons/Utils';
import { Validations } from 'src/app/shared/commons/Validations';
import { AppConfigService } from 'src/app/shared/services/app-config.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  formLogin: FormGroup;
  typeInput = 'password';
  iconPassword = 'visibility';
  viewModel: ConfirmDialogViewModel = new ConfirmDialogViewModel();
  storageEvent$: any;
  autocomplete = false

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private clientService: ClientService,
    private appConfigService: AppConfigService
  ) { }

  ngOnInit(): void {
    this.formLogin = this.formBuilder.group({
      login: ['', Validators.compose([Validators.required, Validations.CPF])],
      password: ['', [Validators.required, Validations.Password]]
    });
  }

  previewPassword() {
    if (this.typeInput === 'password') {
      this.typeInput = 'text';
      this.iconPassword = 'visibility_off';
    } else {
      this.typeInput = 'password';
      this.iconPassword = 'visibility';
    }
  }

  login(e: Event) {
    let data = {};
    if (this.formLogin.valid) {
      data = this.formLogin.value;
      // document number
      if (/[\d\.]+/.test(data['login'])) {
        data['document_number'] = Utils.unMask(data['login']);
        // email
      } else if (Utils.getEmailPattern().test(data['login'])) {
        data['email'] = data['login'];
      } else {
        // error
      }

      delete data['login'];

      this.clientService.login(data)
        .then(res => {
          storage.saveClientJwt(res['jwt']);
          storage.saveClientUuid(res['client_uuid']);
          storage.save('is_authenticate', true);
          
          // expires session
          if ('expires_in' in res) {
            storage.save('session_expires_in', res['expires_in']);
          }
          
          this.router.navigate([AppNavRouteLink.NAV.DASHBOARD]);
        })
        .catch(err => {
          if (err.status === 400) {
            this.viewModel.subTitle = 'Usuário e/ou senha incorretos.';
            this.dialogService.showDialogTryAgain(this.viewModel);
          } else {
            this.dialogService.showDialogError('Serviço temporariamente indisponível. Tente novamente mais tarde.');
          }
        })
    } else {
      this.formLogin.markAllAsTouched()
    }
  }

  onForgotPassword() {
    this.dialogService.showForgotPassword().subscribe(x => {
      const model = new ConfirmDialogViewModel();
      if (x === true) {
        model.subTitle = 'Sucesso!'
        model.msg = '<p>Enviamos um link para redefinição de senha ao e-mail cadastrado.</p><strong>Verifique também se a sua mensagem não foi enviada para a caixa de SPAM</strong>';
        this.dialogService.showDialogSuccess(model);
      } else {
        this.dialogService.showDialogError('CPF não encontrado!');
      }
    });
  }

  onOpenAccount(): void {
    this.router.navigate([AppNavRouteLink.NAV.OPEN_ACCOUNT]);
  }

  back() {
    this.clientService.redirectLandingPage()
  }

  // convenience getter for easy access to form fields
  get f() { return this.formLogin.controls; }
}
