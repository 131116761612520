import { ActionCardEnum } from "../enums/status-card.enum";

export class BlockStatusCard {
  public static get(): any[] {

    const status = [
      { value: 'shipping', description: 'Envio', type: [] },
      { value: 'embossing', description: 'Erro de gravação', type: [ActionCardEnum.duplicate] },
      { value: 'renew', description: 'Renovar', type: [] },
      { value: 'auto_renew', description: 'Renovação automática', type: [] },
      { value: 'security', description: 'Segurança', type: [] },
      { value: 'shipping_lost', description: 'Envio perdido', type: [ActionCardEnum.duplicate] },
      { value: 'misguided', description: 'Não solicitado', type: [ActionCardEnum.cancel] },
      { value: 'preventive', description: 'Prevenção', type: [] },
      { value: 'fraud', description: 'Fraude', type: [] },
      { value: 'settlement_fraud', description: 'Negociação fraude', type: [] },
      { value: 'adulterate', description: 'Adulterado', type: [] },
      { value: 'faked', description: 'Falsificação', type: [ActionCardEnum.block] },
      { value: 'lost', description: 'Perda', type: [ActionCardEnum.duplicate, ActionCardEnum.block, ActionCardEnum.cancel] },
      { value: 'theft', description: 'Roubo', type: [ActionCardEnum.duplicate, ActionCardEnum.block, ActionCardEnum.cancel] },
      { value: 'purged', description: 'Expurgo', type: [] }

    ]

    return status.sort((a, b) => parseInt(a['code']) - parseInt(b['code']));
  }

}