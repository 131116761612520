import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Utils } from 'src/app/shared/commons/Utils';
import { Validations } from 'src/app/shared/commons/Validations';
import { AddressTypeEnum } from 'src/app/shared/enums/address-type.enum';
import { ApisEnum } from 'src/app/shared/enums/apis.enum';
import { MaritalStatusEnum } from 'src/app/shared/enums/marital-status.enum';
import { AddressModel } from 'src/app/shared/interfaces/address-model';
import { ClientModel } from 'src/app/shared/interfaces/client-model';
import { AddressService } from 'src/app/shared/services/address.service';
import { ClientService } from 'src/app/shared/services/client.service';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-personal-data-dialog',
  templateUrl: './personal-data-dialog.component.html',
  styleUrls: ['./personal-data-dialog.component.scss'],
  providers: [CurrencyPipe]
})
export class PersonalDataDialogComponent implements OnInit {

  client = {} as ClientModel;
  address = {} as AddressModel;
  disableAddress: any = {
    street: true,
    district: true,
    city: true,
    state: true
  }
  noNumber = false
  formGroup: FormGroup;
  confirmResult: Subject<boolean | string>;
  autoCompleteList: [] = null;
  listMaritalStatus: any = []
  birthDate: string
  income: string
  lastZipCode: string

  constructor(
      public modalRef: BsModalRef,
      private formBuilder: FormBuilder,
      private clientService: ClientService,
      private addressService: AddressService,
      private utilService: UtilService,
      private currencyPipe: CurrencyPipe
  ) { }

  ngOnInit() {
    this.confirmResult = new Subject();
    this.listMaritalStatus = this.getMaritalStatus()
    this.clientService.clientData.subscribe(d => {
      if (!d) { return; }
      this.client = d;
      this.birthDate = moment(this.client.birth_date).format('DD/MM/YYYY');
      this.income = this.currencyPipe.transform(this.client.income, 'BRL')
    });

    this.buildInformationForm();
    this.getAddress();

  }

  buildInformationForm() {
    this.formGroup = this.formBuilder.group({
      client: this.formBuilder.group({
        friendly_name: [{
          value: this.client.friendly_name
        }],
        name: [{
          value: this.client.name,
        }, Validators.compose([
          Validators.required,
          Validators.pattern(Utils.getNamePattern())
        ])],
        document_number: [{
          value: this.client.document_number,
        }, Validators.compose([
          Validators.required,
          Validations.CPF
        ])],
        marital_status: [{
          value: this.client.marital_status,
        }, Validators.required],
        secondary_document_number: [{
          value: this.client.secondary_document_number,
        }, Validators.compose([
          Validators.required
        ])],
        mother_name: [{
          value: this.client.mother_name,
        }, Validators.compose([
          Validators.required,
          Validators.pattern(Utils.getNamePattern())
        ])],
        birth_date: [{
          value: this.birthDate,
        }, Validators.compose([
          Validators.required,
          Validations.Date,
          Validations.IsMinor
        ])],
        income: [this.income, Validators.required],
        occupation: [this.client.occupation, Validators.required]
      }),
      address: this.formBuilder.group({
        zip_code: [{
          value: this.address.zip_code,
        }, Validators.compose([
          Validators.required
        ])],
        street: [{
          value: this.address.street,
        }, Validators.required],
        street_number: [{
          value: this.address.street_number,
        }, Validators.compose([
          Validators.required
        ])],
        state: [{
          value: this.address.state,
        }, Validators.required],
        district: [{
          value: this.address.district,
        }, Validators.required],
        city: [{
          value: this.address.city,
        }, Validators.required],
        type: AddressTypeEnum.comercial,
        complement: [{
          value: this.address.complement
        }]
      })
    })

    // if (this.client.marital_status) {
    //   this.groupClient().marital_status.setValue(this.client.marital_status)
    // } else {
    //   this.client.marital_status = 'single';
    // }
  }

  getAddress() {
    this.addressService.read(ApisEnum.Client).then(result => {
      this.address = result.addresses.filter(x => x.id !== undefined).shift();
      if (this.address) {
        this.lastZipCode = this.address.zip_code
        this.address.zip_code = Utils.maskZipCode(this.address.zip_code)
        if (this.address.street_number == 'S/N') {
          this.setNoNumber(true, this.address.street_number)
        }
      } else {
        this.address = {} as AddressModel;
      }
    })
  }

  getZipCode() {
    const zip_code = Utils.unMask(this.address.zip_code);
    if (this.lastZipCode != zip_code && zip_code.length === 8) {
      this.lastZipCode = zip_code
      this.address.zip_code = Utils.maskZipCode(zip_code)
      this.addressService.readByCep(zip_code).then(result => {
        this.disableAddress = {
          street: result.logradouro != '',
          district: result.bairro != '',
          city: result.localidade != '',
          state: result.uf != ''
        }
        this.address.district = result.bairro;
        this.address.city = result.localidade;
        this.address.street = result.logradouro;
        this.address.state = result.uf;
        this.address.type = AddressTypeEnum.comercial;
        this.setNoNumber(false, null)
        this.address.complement = null
        this.utilService.mark_controls_dirty(this.groupAddress);
      })
    }
  }

  setNoNumber(no_number, value) {
    if (no_number) {
      this.groupAddress.street_number.setValue('S/N')
      this.groupAddress.street_number.disable()
      this.groupAddress.street_number.markAsDirty()
    } else {
      this.groupAddress.street_number.setValue(value)
      this.groupAddress.street_number.enable()
      this.groupAddress.street_number.markAsDirty()
    }
    this.noNumber = no_number
  }

  getProfessions(page: number = 1, name?: string) {
    let data: any = {
      page: page,
      profession: name
    };

    if (data.profession) {
      this.clientService.getCob(this.client.uuid, data).then(res => {
        this.autoCompleteList = res.professions
      })
    }
  }

  getMaritalStatus() {
    return Object.keys(MaritalStatusEnum).map(key => {
      return ({ value: key.toString(), name: MaritalStatusEnum[key]})
    })
  }

  setOccupation(event) {
    this.client.occupation = event
    this.client.occupation_id = '000100';
  }

  save() {
    if (this.formGroup.valid) {
      const clientData = Object.assign({} as any, this.utilService.dirty_controls_data(this.groupClient));
      const addressData = Object.assign({} as any, this.utilService.dirty_controls_data(this.groupAddress));

      if (Object.keys(clientData).length > 0) {
        if (clientData.document_number) {
          clientData.document_number = Utils.unMask(clientData.document_number);
        }
        if (clientData.birth_date) {
          clientData.birth_date = moment(moment(clientData.birth_date, 'DD/MM/YYYY').toDate()).format('YYYY-MM-DD');
        }
        if (clientData.income) {
          clientData.income = Utils.moneyTofloat(clientData.income)
        }

        clientData.occupation_id = '000100';

        delete clientData.confirm_email;
        this.clientService.update(clientData).then(result => {
          this.client = result;
        })
      }

      if (Object.keys(addressData).length > 0) {
        if (addressData.zip_code) {
          addressData.zip_code = Utils.unMask(addressData.zip_code);
        }
        this.addressService.update(ApisEnum.Client, this.address.id, addressData).then(result => {
          this.address = result
        })
      }

      this.closeSuccess();
    } else {
      this.formGroup.markAllAsTouched()
    }
  }

  close() {
    this.confirmResult.next(false);
    this.modalRef.hide();
  }

  closeSuccess() {
    this.confirmResult.next(true);
    this.modalRef.hide();
  }

  get groupClient() { return this.formGroup.get('client')['controls']; }
  get groupAddress() { return this.formGroup.get('address')['controls']; }

}
