import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { AssetColorsEnum } from './../../shared/enums/asset-color.enum';

export interface Asset {
  value: string;
  name: string;
  description: string;
  enabled: boolean;
}
@Component({
  selector: 'app-asset-dialog',
  templateUrl: './asset-dialog.component.html',
  styleUrls: ['./asset-dialog.component.scss']
})
export class AssetDialogComponent implements OnInit {

  confirmResult: Subject<Asset>;
  listAsset: Asset[] = [];

  constructor(public modalRef: BsModalRef) { }

  ngOnInit() {
    this.confirmResult = new Subject();

    this.listAsset = [
      {
        value: 'direct-treasure',
        name: 'Tesouro direto',
        description: 'Uma forma de investir em títulos do governo federal por um prazo e rentabilidade definidos.',
        enabled: true
      },
      {
        value: 'fixed-income',
        name: 'Renda fixa privada',
        description: 'Uma forma de investir em títulos de bancos e empresas para um prazo definido. Observe: esses ativos têm risco de crédito.',
        enabled: true
      },
      {
        value: 'variable-income',
        name: 'Renda variável',
        description: 'Uma forma de investir ações etc sem precisar usar plataformas de corretoras.',
        enabled: true
      },
      {
        value: 'exchange',
        name: 'Criptomoedas',
        description: 'Uma forma de investir em ativos com vantagens de impostos quando obedecer as condições',
        enabled: false
      },
      {
        value: 'investment-fund',
        name: 'Fundo de investimentos',
        description: 'Uma forma de investir em produtos de gestoras renovadas',
        enabled: true
      },
      {
        value: 'retirement',
        name: 'Previdência privada',
        description: 'Uma forma de investir em ativos com vantagens de impostos quando obedecer as condições',
        enabled: false
      }
    ]
  }

  confirmAndClose(value: Asset) {
    this.confirmResult.next(value);
    this.modalRef.hide();
  }

  get AssetColor() {
    return AssetColorsEnum;
  }
}
