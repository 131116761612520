import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { FinancialService } from 'src/app/shared/services/financial.service';
import { StorageService as storage } from 'src/app/shared/services/storage.service';
import { GoalModel } from 'src/app/shared/interfaces/goal.model';
import { ClientService } from 'src/app/shared/services/client.service';
import * as moment from 'moment';

@Component({
  selector: 'app-plans-dialog',
  templateUrl: './plans-dialog.component.html',
  styleUrls: ['./plans-dialog.component.scss']
})
export class PlansDialogComponent implements OnInit {

  confirmResult: Subject<string>;
  listGoal: any[] = [];
  listGoalsSaved: any[] = [];

  constructor(
    public modalRef: BsModalRef,
    private client: ClientService,
    public financial: FinancialService
  ) { }

  ngOnInit(): void {
    this.confirmResult = new Subject();
    this.listGoal = this.financial.goals.filter(value => value.contem != false);
    this.getGoals();
  }

  getGoals(){
    this.financial.getPlansBack(storage.getClientUuid(), storage.getClientJwt()).then(result => {
      this.listGoalsSaved = result?.goals;
    })
  }

  deleteItens(arr){
    arr.forEach(element => {
      this.financial.deleteGoal(storage.getClientUuid(), element.id, storage.getClientJwt()).then(
        res => {
          console.log("Excluído: ", element.id);
        }
      )
    });
  }

  onClose(): void {
    this.confirmAndClose('objectives');
  }

  onConfirm(): void {
    // if (this.goalActive) {
    //   alert(`TODO: O sistema frontend deverá apresentar direcionar para a página Criar Objetivo - ${this.goalActive.name}. 
    //   E  em seguida, o novo objetivo criado deve ser incluso no final da lista, ou seja, com a menor prioridade e deve ter o flag “garantido” desativado. 
    //   A barra de tempo até atingimento deve estar em 0% e as chances de atingimento devem estar em 0%.`);
    // }
    this.onClose()
  }

  private confirmAndClose(value: string) {
    let listArr = this.prepareToGoal();
    let countPost = 0;
    listArr.forEach(key => {
      countPost++;
      this.financial.createFinancial(storage.getClientUuid(), key, storage.getClientJwt())
      .then(
        result => {
          console.log("result: ", result);
        }, 
        err => {
          console.log("result: ", err);
      })

      //Fecha o modal quando fizer todos os posts
      if(countPost == listArr.length){
        this.confirmResult.next(value);
        this.modalRef.hide();
      }
    })  
  }

  prepareToGoal(){
    let arrPost = [];
    this.listGoal.forEach( key => {
      //Só adiciona itens que não estejam salvos
      if(!this.searchItem(this.listGoalsSaved, 'type', key.value)){
        let objGoal = {} as GoalModel;
        objGoal.currency = 'BRL';
        objGoal.date_first_payment =  Math.floor(+new Date().getTime()/1000);
        objGoal.description = 'Criado a partir de situação de vida em ' + moment().format('DD/MM/YYYY, HH:mm');
        objGoal.frequency = 30;
        // objGoal.goal_parent_id = null;
        objGoal.is_regular = true;
        objGoal.must_be_guaranteed = false;
        objGoal.name     = key.name;
        objGoal.numeraire_asset_index_uuid = this.client.getNumaraires(this.financial.getCurrency(key.value));
        objGoal.priority = key.priority;       
        objGoal.probability_attain = 0;          
        objGoal.repetitions = 0;       
        objGoal.tenor = 0;       
        objGoal.tenor_type = 'actual_actual';       
        objGoal.type    = key.value;
        objGoal.value    = 0;
        arrPost.push(objGoal);
      }
    })

    return arrPost;
  }

  searchItem(arr, prop, item){
    let foundItem = false;
    for(let key of arr){
      if(key[prop] == item){
        foundItem = true;
      }
    }
    return foundItem;
  }
}
