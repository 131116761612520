import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-selfie-info-dialog',
  templateUrl: './selfie-info-dialog.component.html',
  styleUrls: ['./selfie-info-dialog.component.scss']
})
export class SelfieInfoDialogComponent implements OnInit {

  @Input() title: string;
  @Input() msg: string;
  @Input() txtOk = 'OK';
  @Input() txtCancel = 'CANCELAR';
  @Input() hiddenCancel?: boolean;
  @Input() classWidth: string;
  @Input() txtLink: string;

  confirmResult: Subject<boolean | string>;

  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {
    this.confirmResult = new Subject();
  }

  onConfirm(): void {
    this.confirmAndClose(true);
  }

  onClose(): void {
    this.confirmAndClose(false);
  }

  onLink(): void {
    this.confirmAndClose('redirect');
  }

  private confirmAndClose(value: boolean | string) {
    this.confirmResult.next(value);
    this.modalRef.hide();
  }
}


