export enum KeyPixTypeEnum {
  evp = 'evp',
  document = 'document',
  mobile = 'mobile',
  email = 'email'
}

export enum KeyPixDescriptionEnum {
  evp = 'Chave aleatória',
  document = 'CPF/CNPJ',
  mobile = 'Celular',
  email = 'E-mail'
}

export enum PixOptionsEnum {
  transfers = 'transfers',
  portability = 'portability'
}
