import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { AppNavRouteLink, RouterEnum } from 'src/app/shared/commons/AppNavRouteLink';
import { GoalModel } from 'src/app/shared/interfaces/goal.model';
import { StorageService as storage } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-goal-insight-dialog',
  templateUrl: './goal-insight-dialog.component.html',
  styleUrls: ['./goal-insight-dialog.component.scss']
})
export class GoalInsightDialogComponent implements OnInit {

  @Input() public model: any;

  confirmResult: Subject<string>
  private objGoal = {} as GoalModel

  constructor(
    public modalRef: BsModalRef,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.confirmResult = new Subject()
  }

  onConfirm() {
    if (this.model.goalId) {
      this.router.navigate([AppNavRouteLink.NAV.NEW_GOAL, RouterEnum.CUSTOM, this.model.goalId]);
    } else {
      this.objGoal.description = this.model.category == 'Sem categoria' ? 'Despesas extras' : this.model.category
      this.objGoal.value = this.model.budget
      this.objGoal.frequency = 30
      this.objGoal.repetitions = 24
      this.objGoal.extra = {
        type: 'recurrent',
        insight_id: this.model.id || 0
      }
  
      storage.save('goal-insight', this.objGoal)

      this.router.navigate([AppNavRouteLink.NAV.NEW_GOAL, RouterEnum.CUSTOM]);
    }
    this.modalRef.hide()
  }

  onClose(value) {
    this.confirmResult.next(value)
    this.modalRef.hide()
  }
}
