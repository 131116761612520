import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppNavRouteLink } from 'src/app/shared/commons/AppNavRouteLink';

@Component({
  selector: 'app-dialog-without-accounts',
  templateUrl: './dialog-without-accounts.component.html',
  styleUrls: ['./dialog-without-accounts.component.scss']
})
export class DialogWithoutAccountsComponent {


  constructor(
    public modalRef: BsModalRef,
    private router: Router
  ) { }

  close() {
    this.router.navigate([AppNavRouteLink.TABS_MY_DATA.MANAGE_ACCOUNTS])
    this.modalRef.hide()
  }

}
