import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService as storage } from '../services/storage.service'
import { AppNavRouteLink } from '../commons/AppNavRouteLink';

@Injectable({
  providedIn: 'root'
})
export class ContactGuard implements CanActivate {

  constructor(
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (storage.getClientJwt() !== null) {
      return true;
    }
    
    this.router.navigate([AppNavRouteLink.PAGE.LOGIN]);
    
    return false;
  }
}