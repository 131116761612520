import { Directive, HostListener } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[CpfCnpjMask]'
})
export class CpfCnpjMaskDirective {
  customMask = '';

  @HostListener('keypress', ['$event'])
  onkeypress($event: any) {
    const fieldSize = $event.target.value.length;
    let boolMask;

    const keyCode = $event.keyCode;
    const exp = /\-|\.|\/|\(|\)|\ |\d+$ /g;
    const filter = $event.target.value.toString().replace(exp, '');

    let position = 0;
    let newValue = '';
    let maskSize = filter.length;

    if (keyCode == 8 && maskSize <= 12) {
      this.customMask = '###.###.###-##';
    } else if ($event.type == 'paste' && maskSize == 11) {
      this.customMask = '###.###.###-##';
    } else if ($event.type == 'paste' && maskSize == 14) {
      this.customMask = '##.###.###/####-##';
    } else if ($event.type == 'blur' && maskSize <= 11) {
      this.customMask = '###.###.###-##';
    } else if (maskSize < 11) {
      this.customMask = '###.###.###-##';
    } else {
      this.customMask = '##.###.###/####-##';
    }

    if (fieldSize === this.customMask.length
      || !(keyCode >= 48 && keyCode <= 57)) {
      $event.preventDefault();
    }

    // if (keyCode !== 8) { // backspace
    for (let i = 0; i <= maskSize; i++) {
      boolMask = ((this.customMask.charAt(i) === '-') || (this.customMask.charAt(i) === '.')
        || (this.customMask.charAt(i) === '/'));
      boolMask = boolMask || ((this.customMask.charAt(i) === '(')
        || (this.customMask.charAt(i) === ')') || (this.customMask.charAt(i) === ' '));
      if (boolMask) {
        newValue += this.customMask.charAt(i);
        maskSize++;
      } else {
        newValue += filter.charAt(position);
        position++;
      }
    }
    
    $event.target.value = newValue;
  }

  @HostListener('keyup', ['$event'])
  onkeyup($event: any) {
    if ($event.keyCode == 8) {
      this.onkeypress($event);
    }
  }

  @HostListener('blur', ['$event'])
  onBlur($event: any) {
    this.onkeypress($event);
  }

  @HostListener('paste', ['$event'])
  paste($event: any) {
    let clipboardData = $event.clipboardData || window.Clipboard;
    let pastedText = clipboardData.getData('text');
    $event.preventDefault()
    $event.target.value = pastedText
    this.onkeypress($event)
  }
}
