import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UploadFileViewModel } from './upload-file.view-model';
import { ApisEnum } from 'src/app/shared/enums/apis.enum';
import { UtilService } from 'src/app/shared/services/util.service';
import { StorageService as storage } from 'src/app/shared/services/storage.service'
import { Router } from '@angular/router';
import { AppUpdateService } from 'src/app/shared/services/app-update.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  emitterChangeFile = new EventEmitter<any>();

  constructor(
    private http: HttpClient,
    private router: Router,
    private utilService: UtilService,
    private appUpdateService: AppUpdateService
  ) { }

  /**
   * Check App Update
   * @param res Response
   */
  private checkAppUpdate(res: Response | any): Response {
    if (res.headers.has('x-appversion')) {
      this.appUpdateService.appVersion.emit(res.headers.get('x-appversion'));
    }
    return res;
  }

  /**
   * CREATE FILE DOCUMENT
   * @param file 
   * @param blob 
   * @param type 
   * @param subType 
   * @param client_uuid 
   * @param jwt 
   */
  create(file: File, blob: any, type: string, subType: string, client_uuid?: string, jwt?: string): Observable<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid || storage.getClientUuid(), 'upload-file', { type: type }, { sub_type: subType }, { jwt: jwt || storage.getClientJwt() }]);

    let formData = new FormData();
    formData.append('file', blob, file.name);
    formData.append('name', file.name);

    return this.http.post<UploadFileViewModel>(url, formData, {
      observe: 'events', reportProgress: true
    }).pipe(
      catchError(err => this.utilService.handleError(err, this.router))
    )
  }

  /**
   * GET ENTITY BY FILTER
   * @param type 
   * @param subType 
   * @param client_uuid 
   * @param jwt 
   */
  readByFilter(type: string, subType: string, client_uuid?: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid || storage.getClientUuid(), 'upload-file', { type: type }, { sub_type: subType }, { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * GET ALL ENTITY
   * @param client_uuid 
   * @param jwt 
   */
  read(client_uuid?: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([ApisEnum.Client, client_uuid || storage.getClientUuid(), 'upload-file', { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  changeFileUpload(file: any) {
    this.emitterChangeFile.emit(file)
  }

  /**
   * Convert image Base64 to Blob
   * @param base64Image Base64 Image
   */
  base64ToFile(base64Image: string): Blob {
    const split = base64Image.split(',');
    const type = split[0].replace('data:', '').replace(';base64', '');
    const byteString = atob(split[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type });
  }
}
