<footer class="col-12 footer">
  <div class="row">
    <div class="col-12 col-lg-11 mx-auto">
      <div class="row">
        <div class="col-md-4 col-lg-3 d-flex align-items-start mt-4">
          <img src="assets/images/png/logo_rodape_ladybank.png" alt="" class="pr-2 pr-md-4 pr-lg-5">
        </div>
        <div class="col-md-4 col-lg-3 mt-4">
          <h2 class="fw-bold medium mb-2">Transparência</h2>
          <p class="small-plus poppins">
            <a class="text-decoration-none text-dark" href="https://www.ladybank.com.br/documentos">Documentos</a>
          </p>
        </div>
        <div class="col-md-4 col-lg-3 mt-4">
          <h2 class="fw-bold medium mb-2">Tire suas dúvidas</h2>
          <p class="small-plus">
            <a class="text-decoration-none text-dark" href="https://blog.ladybank.com.br/index.php/faq/">FAQ</a>
          </p>
        </div>
        <div class="col-12 col-lg-3 mt-4 pl-2 pl-md-4 pl-lg-5">
          <h2 class="fw-bold medium mb-2">Fale com a gente</h2>
          <p class="small-plus poppins mb-1 fw-bold">Telegram: <a href="https://t.me/LadyBank_Bot" target="_blank"
              class="text-decoration-none color-brand-7" title="Telegram LadyBank">Clique aqui</a></p>
          <p class="small-plus poppins mb-1">De seg à sex, das 9h às 17h, exceto feriados</p>
        </div>
      </div>
      <div class="row d-flex justify-content-md-end pb-3">
        <div class="col-12 col-md-6 col-lg-3 order-2 order-lg-1 mr-lg-5">
          <div class="row justify-content-center justify-content-lg-end mt-3">
            <a class="col px-1 text-center" href="https://blog.ladybank.com.br/" target="_blank">
              <icon-svg svgName="social_media/blog"></icon-svg>
            </a>
            <a class="col px-1 text-center" href="https://www.facebook.com/LadyBankBr" target="_blank">
              <icon-svg svgName="social_media/facebook"></icon-svg>
            </a>
            <a class="col px-1 text-center" href="https://twitter.com/_LadyBank" target="_blank">
              <icon-svg svgName="social_media/twitter"></icon-svg>
            </a>
            <a class="col px-1 text-center" href="https://www.linkedin.com/company/lady-bank/?viewAsMember=true"
              target="_blank">
              <icon-svg svgName="social_media/linkedin"></icon-svg>
            </a>
            <a class="col px-1 text-center" href="https://www.instagram.com/_ladybank/" target="_blank">
              <icon-svg svgName="social_media/instagram"></icon-svg>
            </a>
            <a class="col px-1 text-center" href="https://www.youtube.com/channel/UCxMkGutMqse4y0rkKCK3TJA"
              target="_blank">
              <icon-svg svgName="social_media/youtube"></icon-svg>
            </a>
          </div>
        </div>
        <div class="col-12 col-lg-3 pl-2 pl-md-4 pl-lg-5 order-1 order-lg-2 mt-3">
          <p class="small-plus poppins mb-1"><a class="text-decoration-none text-dark"
              href="mailto:atendimento@ladybank.com.br">atendimento@ladybank.com.br</a></p>
          <p class="small-plus poppins mb-1"><a class="text-decoration-none text-dark"
              href="mailto:ouvidoria@ladybank.com.br">ouvidoria@ladybank.com.br</a></p>
        </div>
      </div>
    </div>
  </div>
  <div class="row mx-auto">
    <div class="col-12 border-top border-dark"></div>
    <div class="col-12 col-lg-11 small-medium fw-bold mx-auto my-2">
      <div class="row d-flex justify-content-between">
        <div class="col-12 col-md-9">
          ElasTech SA - CNPJ: 36.325.279/0001-20 <br />
          Av. das Nações Unidas, 14401, Torre C2, Sala 1910 - Vila Gertrudes, São Paulo, SP CEP: 04794-000 <br />
          © 2020 LadyBank
        </div>
        <div class="col-12 col-md-3 text-right">
          <img src="assets/images/png/cvm.png" alt="">
        </div>
      </div>
    </div>
  </div>
</footer>