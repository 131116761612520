export class ProfileLimits {
  
  public static get(filter): any[] {
    const profileLimits: any = [
      {
        "type": "Investidor de Liquidez",
        "options": [
          {
            "color": "#038a27",
            "asset": "Renda fixa Pública - Total",
            "percent": 1
          },
          {
            "color": "#038a27",
            "asset": "Renda fixa Pública - NTN-Bs",
            "percent": 0.2
          },
          {
            "color": "#038a27",
            "asset": "Renda fixa Pública - SELIC",
            "percent": 1
          },
          {
            "color": "#03758a",
            "asset": "Renda fixa Privada - Total",
            "percent": 0.3
          },
          {
            "color": "#03758a",
            "asset": "Renda fixa Privada - CDBs Préfixados",
            "percent": 0.1
          },
          {
            "color": "#03758a",
            "asset": "Renda fixa Privada - CDBs Pósfixados",
            "percent": 0.3
          },
          {
            "color": "#03758a",
            "asset": "Renda fixa Privada - LCI,LCA,CRI,CRA",
            "percent": 0.05
          },
          {
            "color": "#03758a",
            "asset": "Renda fixa Privada - Debêntures",
            "percent": 0.1
          },
          {
            "color": "#fa9200",
            "asset": "Renda Variável - Total",
            "percent": 0.15
          },
          {
            "color": "#fa9200",
            "asset": "Renda Variável - ETF",
            "percent": 0.15
          },
          {
            "color": "#fa9200",
            "asset": "Renda Variável - Ações Brasil",
            "percent": 0.1
          },
          {
            "color": "#fa9200",
            "asset": "Renda Variável - BDRs",
            "percent": 0.05
          },
          {
            "color": "#9200fa",
            "asset": "Fundos - Multimercado",
            "percent": 0.1
          },
          {
            "color": "#9200fa",
            "asset": "Fundos - Imobiliários",
            "percent": 0.05
          },
          {
            "color": "#9200fa",
            "asset": "Fundos - Renda Variável",
            "percent": 0.15
          },
          {
            "color": "#9200fa",
            "asset": "Fundos - Cambial",
            "percent": 0.1
          },
          {
            "color": "#9200fa",
            "asset": "Fundos - Multimercado",
            "percent": 0.1
          },
          {
            "color": "#fa4300",
            "asset": "Moedas",
            "percent": 0
          },
          {
            "color": "#fa4300",
            "asset": "Cripto-Moedas",
            "percent": 0
          },
          {
            "color": "#fa4300",
            "asset": "Demais Ativos",
            "percent": 0
          }
        ]
      },
      {
        "type": "Investidor Moderado",
        "options": [
          {
            "color": "#038a27",
            "asset": "Renda fixa Pública - Total",
            "percent": 1
          },
          {
            "color": "#038a27",
            "asset": "Renda fixa Pública - NTN-Bs",
            "percent": 0.4
          },
          {
            "color": "#038a27",
            "asset": "Renda fixa Pública - SELIC",
            "percent": 1
          },
          {
            "color": "#03758a",
            "asset": "Renda fixa Privada - Total",
            "percent": 0.4
          },
          {
            "color": "#03758a",
            "asset": "Renda fixa Privada - CDBs Préfixados",
            "percent": 0.3
          },
          {
            "color": "#03758a",
            "asset": "Renda fixa Privada - CDBs Pósfixados",
            "percent": 0.4
          },
          {
            "color": "#03758a",
            "asset": "Renda fixa Privada - LCI,LCA,CRI,CRA",
            "percent": 0.05
          },
          {
            "color": "#03758a",
            "asset": "Renda fixa Privada - Debêntures",
            "percent": 0.2
          },
          {
            "color": "#fa9200",
            "asset": "Renda Variável - Total",
            "percent": 0.3
          },
          {
            "color": "#fa9200",
            "asset": "Renda Variável - ETF",
            "percent": 0.3
          },
          {
            "color": "#fa9200",
            "asset": "Renda Variável - Ações Brasil",
            "percent": 0.2
          },
          {
            "color": "#fa9200",
            "asset": "Renda Variável - BDRs",
            "percent": 0.1
          },
          {
            "color": "#9200fa",
            "asset": "Fundos - Multimercado",
            "percent": 0.2
          },
          {
            "color": "#9200fa",
            "asset": "Fundos - Imobiliários",
            "percent": 0.1
          },
          {
            "color": "#9200fa",
            "asset": "Fundos - Renda Variável",
            "percent": 0.3
          },
          {
            "color": "#9200fa",
            "asset": "Fundos - Cambial",
            "percent": 0.2
          },
          {
            "color": "#fa4300",
            "asset": "Moedas",
            "percent": 0
          },
          {
            "color": "#fa4300",
            "asset": "Cripto-Moedas",
            "percent": 0
          },
          {
            "color": "#fa4300",
            "asset": "Demais Ativos",
            "percent": 0
          }
        ]
      },
      {
        "type": "Investidor Balanceado",
        "options": [
          {
            "color": "#038a27",
            "asset": "Renda fixa Pública - Total",
            "percent": 1
          },
          {
            "color": "#038a27",
            "asset": "Renda fixa Pública - NTN-Bs",
            "percent": 0.75
          },
          {
            "color": "#038a27",
            "asset": "Renda fixa Pública - SELIC",
            "percent": 1
          },
          {
            "color": "#03758a",
            "asset": "Renda fixa Privada - Total",
            "percent": 0.6
          },
          {
            "color": "#03758a",
            "asset": "Renda fixa Privada - CDBs Préfixados",
            "percent": 0.6
          },
          {
            "color": "#03758a",
            "asset": "Renda fixa Privada - CDBs Pósfixados",
            "percent": 0.6
          },
          {
            "color": "#03758a",
            "asset": "Renda fixa Privada - LCI,LCA,CRI,CRA",
            "percent": 0.1
          },
          {
            "color": "#03758a",
            "asset": "Renda fixa Privada - Debêntures",
            "percent": 0.3
          },
          {
            "color": "#fa9200",
            "asset": "Renda Variável - Total",
            "percent": 0.5
          },
          {
            "color": "#fa9200",
            "asset": "Renda Variável - ETF",
            "percent": 0.3
          },
          {
            "color": "#fa9200",
            "asset": "Renda Variável - Ações Brasil",
            "percent": 0.5
          },
          {
            "color": "#fa9200",
            "asset": "Renda Variável - BDRs",
            "percent": 0.15
          },
          {
            "color": "#9200fa",
            "asset": "Fundos - Multimercado",
            "percent": 0.3
          },
          {
            "color": "#9200fa",
            "asset": "Fundos - Imobiliários",
            "percent": 0.15
          },
          {
            "color": "#9200fa",
            "asset": "Fundos - Renda Variável",
            "percent": 0.5
          },
          {
            "color": "#9200fa",
            "asset": "Fundos - Cambial",
            "percent": 0.3
          },
          {
            "color": "#9200fa",
            "asset": "Fundos - Multimercado",
            "percent": 0.1
          },
          {
            "color": "#fa4300",
            "asset": "Moedas",
            "percent": 0.15
          },
          {
            "color": "#fa4300",
            "asset": "Cripto-Moedas",
            "percent": 0.075
          },
          {
            "color": "#fa4300",
            "asset": "Demais Ativos",
            "percent": 0
          }
        ]
      },
      {
        "type": "Investidor de Crescimento",
        "options": [
          {
            "color": "#038a27",
            "asset": "Renda fixa Pública - Total",
            "percent": 1
          },
          {
            "color": "#038a27",
            "asset": "Renda fixa Pública - NTN-Bs",
            "percent": 1
          },
          {
            "color": "#038a27",
            "asset": "Renda fixa Pública - SELIC",
            "percent": 1
          },
          {
            "color": "#03758a",
            "asset": "Renda fixa Privada - Total",
            "percent": 0.5
          },
          {
            "color": "#03758a",
            "asset": "Renda fixa Privada - CDBs Préfixados",
            "percent": 1
          },
          {
            "color": "#03758a",
            "asset": "Renda fixa Privada - CDBs Pósfixados",
            "percent": 1.0
          },
          {
            "color": "#03758a",
            "asset": "Renda fixa Privada - LCI,LCA,CRI,CRA",
            "percent": 0.5
          },
          {
            "color": "#03758a",
            "asset": "Renda fixa Privada - Debêntures",
            "percent": 0.5
          },
          {
            "color": "#fa9200",
            "asset": "Renda Variável - Total",
            "percent": 0.75
          },
          {
            "color": "#fa9200",
            "asset": "Renda Variável - ETF",
            "percent": 1
          },
          {
            "color": "#fa9200",
            "asset": "Renda Variável - Ações Brasil",
            "percent": 0.75
          },
          {
            "color": "#fa9200",
            "asset": "Renda Variável - BDRs",
            "percent": 0.5
          },
          {
            "color": "#9200fa",
            "asset": "Fundos - Multimercado",
            "percent": 0.75
          },
          {
            "color": "#9200fa",
            "asset": "Fundos - Imobiliários",
            "percent": 0.05
          },
          {
            "color": "#9200fa",
            "asset": "Fundos - Renda Variável",
            "percent": 0.75
          },
          {
            "color": "#9200fa",
            "asset": "Fundos - Cambial",
            "percent": 0.1
          },
          {
            "color": "#fa4300",
            "asset": "Moedas",
            "percent": 0.3
          },
          {
            "color": "#fa4300",
            "asset": "Cripto-Moedas",
            "percent": 0.1
          },
          {
            "color": "#fa4300",
            "asset": "Demais Ativos",
            "percent": 0
          }
        ]
      }
    ]

    return profileLimits.find(x => x.type == filter)
  }
}