<div class="modal-content">
  <div id="comprovante" class="m-0 m-md-2 m-lg-3">
    <div class="modal-header border-0 py-4 background-gradient">
      <div class="float-left logo"></div>
      <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span class="material-icons-outlined">highlight_off</span>
      </button> -->
    </div>
    <div class="modal-body" *ngIf="receipt && receipt.amount">
      <div class="row bg-green poppins small text-body rounded py-2 py-md-4">
        <div class="col-sm-12 col-md-6 col-lg-6">
          <span *ngIf="receipt.schedule_date" class="font-weight-bold">Comprovante de TED agendado</span>
          <span *ngIf="receipt?.mappedType" class="font-weight-bold">Comprovante de {{
            translate_type(receipt['mappedType'])}} realizado</span>
          <span *ngIf="receipt.operationType == 'CARD_AUTHORIZATION'" class="font-weight-bold">Comprovante de compra
            realizada</span>
          <p *ngIf="receipt.day">Data de pagamento:
            <strong>{{utils.formatUnix(receipt.day) | date: 'dd/MM/yyyy - HH:MM'}}</strong></p>
          <p *ngIf="receipt.schedule_date">Data do agendamento:
            <strong>{{utils.formatUnix(receipt.schedule_date) | date: 'dd/MM/yyyy'}}</strong></p>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <span><strong>Valor</strong></span>
          <p class="mb-0">{{-1 * receipt.amount | currency: 'BRL' }}</p>
        </div>
      </div>
      <div class="row mb-md-5 poppins" *ngIf="receipt.operationType == 'CARD_AUTHORIZATION'">
        <div class="col-12 col-lg-4 mt-4">
          <span class="text-muted x-small">Id da Transação</span>
          <p class="text-body small font-weight-bold">{{receipt['otherInfo']['transactionId']}}</p>
        </div>
        <div class="col-12 col-lg-4 mt-4">
          <span class="text-muted x-small">Forma de autorização</span>
          <p class="text-body small font-weight-bold">{{receipt['otherInfo']['captureMode']}}</p>
        </div>
        <div class="col-12 col-lg-4 mt-4">
          <span class="text-muted x-small">Nome estabelecimento</span>
          <p class="text-body small font-weight-bold">{{receipt['otherInfo']['merchant']['name']}}</p>
        </div>
      </div>
      <div class="row mb-md-5 poppins" *ngIf="receipt.operationType == 'SENT_TRANSFERENCE'">
        <div class="col-12 col-lg-4 mt-4">
          <span class="text-muted x-small">Nome</span>
          <p class="text-body small font-weight-bold">{{receipt?.otherInfo?.fullName || receipt?.nameFavorite}}</p>
        </div>
        <div class="col-12 col-lg-2 mt-4">
          <span class="text-muted x-small">CPF</span>
          <p class="text-body small font-weight-bold">{{getDocument(receipt)}}</p>
        </div>
        <div class="col-12 col-lg-2 mt-4">
          <span class="text-muted x-small">Instituição</span>
          <p class="text-body small font-weight-bold">{{receipt?.bank?.name ? receipt?.bank?.name : 'LadyBank'}}</p>
        </div>
        <div class="col-12 col-lg-2 mt-4" *ngIf="receipt?.agency">
          <span class="text-muted x-small">Agência</span>
          <p class="text-body small font-weight-bold">{{receipt?.agency}}</p>
        </div>
        <div class="col-12 col-lg-2 mt-4" *ngIf="receipt?.account && receipt?.account_digit">
          <span class="text-muted x-small">Conta</span>
          <p class="text-body small font-weight-bold">{{receipt?.account}}-{{receipt?.account_digit}}</p>
        </div>
        <div class="col-12 col-lg-4 mt-4" *ngIf="receipt?.otherInfo?.transferId">
          <span class="text-muted x-small">Id da Transação</span>
          <p class="text-body small font-weight-bold">{{receipt['otherInfo']['transferId']}}</p>
        </div>
      </div>
      <div class="row mb-md-5 poppins"
        *ngIf="receipt.operationType == 'BANK_ACCOUNT_WITHDRAWAL' || receipt.operationType == 'PIX_CASH_OUT_TRANSACTION'">
        <div class="col-12 mt-2 mt-md-4">
          <h4 class="font-weight-bold">Favorecido</h4>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <span class="text-muted x-small">Nome</span>
          <p class="text-body small font-weight-bold">{{getName(receipt)}}</p>
        </div>
        <div class="col-12 col-md">
          <span class="text-muted x-small">CPF/CNPJ</span>
          <p class="text-body small font-weight-bold">{{getDocument(receipt)}}</p>
        </div>
        <div class="col-12 col-md">
          <span class="text-muted x-small">Instituição</span>
          <p class="text-body small font-weight-bold" *ngIf="receipt.operationType == 'BANK_ACCOUNT_WITHDRAWAL'">
            {{filterBankName(receipt)}}</p>
          <p class="text-body small font-weight-bold" *ngIf="receipt.operationType == 'PIX_CASH_OUT_TRANSACTION'">
            {{receipt.otherInfo.receiver.ispbName}}</p>
        </div>
        <div class="col-12" *ngIf="receipt['mappedType'] == 'pix'">
          <span class="text-muted x-small">Id da Transação</span>
          <p class="text-body small font-weight-bold">{{receipt['otherInfo']['id']}}</p>
        </div>
        <div class="col-12 col-md-6 col-lg-2" *ngIf="receipt['mappedType'] == 'transfer'">
          <span class="text-muted x-small">Agência</span>
          <p class="text-body small">
            <strong
              *ngIf="receipt?.otherInfo?.destination?.bankAccount?.bankBranch">{{receipt?.otherInfo?.destination?.bankAccount?.bankBranch}}</strong>
            <strong
              *ngIf="!receipt?.otherInfo?.destination?.bankAccount?.bankBranch && receipt?.account_data?.branch">{{receipt?.account_data?.branch}}</strong>
          </p>
        </div>
        <div class="col-12 col-md-6 col-lg-2" *ngIf="receipt['mappedType'] == 'transfer'">
          <span class="text-muted x-small">Conta</span>
          <p class="text-body small font-weight-bold">
            <strong
              *ngIf="receipt?.otherInfo?.destination?.bankAccount?.bankAccountNumber">{{receipt?.otherInfo?.destination?.bankAccount?.bankAccountNumber}}</strong>
            <strong
              *ngIf="receipt?.otherInfo?.destination?.bankAccount?.bankAccountDigit">-{{receipt?.otherInfo?.destination?.bankAccount?.bankAccountDigit}}</strong>
          </p>
        </div>
      </div>
      <div class="row my-2 my-md-4 poppins">
        <div class="col-12">
          <h4 class="font-weight-bold">Origem</h4>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <span class="text-muted x-small">Nome</span>
          <p class="text-body small font-weight-bold">{{client['name']}}</p>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <span class="text-muted x-small">Instituição</span>
          <p class="text-body small font-weight-bold">LadyBank</p>
        </div>
        <div class="col-6 col-xl-2">
          <span class="text-muted x-small">Agência</span>
          <p class="text-body small font-weight-bold">{{account['branch']}}</p>
        </div>
        <div class="col">
          <span class="text-muted x-small">Conta</span>
          <p class="text-body small font-weight-bold">{{utils.formatBankDigit(account['number'])}}</p>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <span class="text-muted x-small">CPF</span>
          <p class="text-body small font-weight-bold">{{utils.maskCpf(client['document_number'])}}</p>
        </div>
        <div class="col-12">
          <span class="text-muted x-small">Descrição</span>
          <p class="text-body small font-weight-bold" *ngIf="receipt.operationType == 'CARD_AUTHORIZATION'">
            {{receipt?.description}}
          </p>
          <p class="text-body small font-weight-bold" *ngIf="receipt['mappedType'] == 'pix'">
            {{receipt?.receiver?.description}}
          </p>
          <p class="text-body small font-weight-bold" *ngIf="receipt['mappedType'] == 'transfer'">
            {{receipt?.otherInfo?.destination?.description || receipt?.otherInfo?.description || receipt?.description}}
          </p>
          <p class="text-body small font-weight-bold" *ngIf="receipt.operationType == 'BANK_SLIP_PAYMENT'">
            {{receipt?.description}}
          </p>
          <p class="text-body small font-weight-bold" *ngIf="receipt.operationType == 'SENT_TRANSFERENCE'">
            {{receipt?.description_client}}
          </p>
        </div>
        <div class="poppins" *ngIf="receipt.operationType == 'BANK_SLIP_PAYMENT'">
          <div class="col-12 col-lg-4 my-4">
            <span class="text-muted x-small">Id da Transação</span>
            <p class="text-body small font-weight-bold">{{receipt['otherInfo']['boletoPaymentId']}}</p>
          </div>
          <div class="col-12" *ngIf="this.receipt['otherInfo']['cupom']">
            <span class="text-muted x-small">Cupom</span>
            <p class="text-body small font-weight-bold" *ngFor="let item of infoCupom">{{item}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- {{receipt | json}} -->
  <div class="justify-content-center align-items-center mb-4 d-flex">
    <button type="button" class="btn btn-primary btn-sm" (click)="close()">Fechar</button>
    <button type="button" class="btn btn-primary btn-sm" (click)="shareProof($event)" [disabled]="disableSharedButton"
      [tooltip]="disableSharedButton ? 'Seu browser não permite o compartilhamento. Por favor, use um outro browser e entre em contato com nosso suporte. Pedimos desculpas pela inconveniência.' : '' ">
      Compartilhar
    </button>
    <icon-svg svgName="/clickable/pdf" class="ml-lg-5" (click)="onPrint()" role="button"></icon-svg>
  </div>
  <div class="col-12 px-4 pb-4 text-justify">
    <strong class="text-danger small poppins">Configurações de segurança ou cookies do navegador podem impedir a
      visualização do comprovante em PDF. Para que isso não ocorra, você deverá habilitar a criação de pop-up no seu
      browser. Se não souber como fazer, contate nossa central de atendimento.</strong>
  </div>
</div>
<div id="newPdf" #pagePDF></div>