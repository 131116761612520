<div class="modal-content">
  <div class="modal-header border-0">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span class="material-icons-outlined">highlight_off</span>
    </button>
  </div>
  <div class="text-center">    
    <p class="x-medium mb-4">
      Você não possui contas conectadas
    </p>
    <icon-svg svgName="without-accounts"></icon-svg>
    <p class="small m-4">
      Conecte as suas contas de outras instituições financeiras e acesse todas as informações em um só lugar.
    </p>
  </div>
  <div class="col mb-4 text-center">
    <button type="button" class="btn btn-primary" (click)="close()">INCLUIR NOVA CONTA</button>
  </div>
</div>