import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmDialogViewModel } from 'src/app/components/confirm-dialog/confirm-dialog.view-model';
import { AppNavRouteLink } from 'src/app/shared/commons/AppNavRouteLink';
import { Validations } from 'src/app/shared/commons/Validations';
import { ClientModel } from 'src/app/shared/interfaces/client-model';
import { ClientService } from 'src/app/shared/services/client.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { StorageService as storage } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-access-password',
  templateUrl: './access-password.component.html',
  styleUrls: ['./access-password.component.scss']
})
export class AccessPasswordComponent implements OnInit {

  client = {} as ClientModel;
  formPassword: FormGroup;
  showPassword = false;
  showConfirmPassword = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private clientService: ClientService,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.formPassword = this.formBuilder.group(
      {
        password: ['', Validators.compose([
          Validators.required,
          Validations.Password
        ])],
        confirm_password: ['', Validators.compose([
          Validators.required
        ])],
        whatsapp_opt_in: [false],
        email_opt_in: [false],
        accepted_terms: [false, [Validators.required, Validators.requiredTrue]]
      },
      {
        validator: Validations.PasswordMatch
      }
    )
  }

  openTerm() {
    this.dialogService.showPdfDialog(
      'https://elasbank-public.s3-sa-east-1.amazonaws.com/documents/ladybank_termos_e_condicoes.pdf',
      'Termo de uso'
    )
  }

  openPrivacyPolicy() {
    this.dialogService.showPdfDialog(
      'https://elasbank-public.s3-sa-east-1.amazonaws.com/documents/elasbank_politica_de_protecao_de_dados.pdf',
      'Política de privacidade'
      )
  }

  cancel() {
    this.clientService.redirectLandingPage()
  }

  next() {
    if (this.formPassword.valid) {
      const clientData = Object.assign({} as any, this.utilService.dirty_controls_data(this.formPassword.controls));
      delete clientData.confirm_password;
      this.clientService.update(clientData).then(result => {
        if (result.status === 200) {
          this.modalSuitability()
        } else {
          this.dialogService.showDialogError('Não foi possível definir a senha');
        }
      })
    } else {
      this.formPassword.markAllAsTouched()
    }
  }

  modalSuitability() {
    const viewModel = new ConfirmDialogViewModel();
    viewModel.class = 'modal-md';
    viewModel.subTitle = 'Deseja definir seu perfil de investidor agora?';
    viewModel.msg = '<label class="small">Ao definir o seu perfil de investidor, você poderá investir de forma mais adequada e segura. Isso é importante para proteger o seu dinheiro e evitar que você invista em algo que não seja adequado ao seu perfil.</label>';
    viewModel.txtOk = 'RESPONDER';
    viewModel.txtCancel = 'AGORA, NÃO';

    this.dialogService.showConfirm(viewModel).subscribe(x => {
      if (x === true) {
        this.router.navigate([AppNavRouteLink.NAV.SUITABILITY]);
      } else {
        this.clientService.redirectLandingPage();
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.formPassword.controls }
}
