<div class="modal-content">
  <div class="modal-header border-0">
    <span class="medium text-center font-weight-bold w-100 m-auto"> Digite a senha do seu cartão </span>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span class="material-icons-outlined">highlight_off</span>
    </button>
  </div>
  <p class="text-center small">Informe sua senha para liberarmos a chave para a outra conta</p>
  <div class="modal-body">
    <form [formGroup]="formGroup" class="col-12" autocomplete="off" novalidate>
      <div class="form-group">
        <label for="password">Senha</label>
        <input type="password" [(ngModel)]="password" class="form-control" name="password" formControlName="password">
        <invalid-feedback [field]="formGroup.controls.password"></invalid-feedback>
      </div>
      <div class="row justify-content-center mt-3">
        <button type="button" class="btn btn-outline-dark" data-dismiss="modal" (click)="modalRef.hide()">CANCELAR</button>
        <button type="submit" class="btn btn-primary btn-block mx-0" (click)="next()" [disabled]="formGroup.invalid">PRÓXIMO</button>
      </div>
    </form>
  </div>
</div>