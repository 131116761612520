export enum GoalEnum {
    car = 'new-car',
    house = 'new-house',
    mariage = 'marriage',
    travel = 'travels',
    emergency = 'emergency-reserve',
    retirement = 'retirement',
    others = 'others',
    wealth = 'wealth',
    health = 'health',
    education = 'studies',
    growth = 'growth',
  }
  
