<div class="modal-content">
  <div class="modal-header border-0 pb-0 pb-md-3">
    <span class="medium h1 w-100 m-0">Editar contato</span>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span class="material-icons-outlined">highlight_off</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="formGroup" class="row" autocomplete="off" novalidate>
      <div formGroupName="client" class="col-12">
        <div class="form-group">
          <label for="email">E-mail</label>
          <input type="email" class="form-control" id="email" name="email" [(ngModel)]="client.email"
            formControlName="email" aria-describedby="emailHelp" maxlength="128">
          <div *ngIf="(!groupClient.email.pristine || groupClient.email.touched) && groupClient.email.errors"
            class="invalid-feedback x-small">
            <div *ngIf="groupClient.email.errors.required"> Campo obrigatório. </div>
            <div *ngIf="groupClient.email.errors?.pattern"> E-mail inválido. Informe um e-mail válido. </div>
          </div>
        </div>
        <div class="form-group">
          <label for="confirm_email">Confirmar E-mail</label>
          <input type="email" class="form-control" id="confirm_email" name="confirm_email" PreventCopyPast
            [(ngModel)]="confirmEmail" formControlName="confirm_email" maxlength="128">
          <div *ngIf="(!groupClient.confirm_email.pristine || groupClient.confirm_email.touched) && groupClient.confirm_email.errors"
            class="invalid-feedback x-small">
            <div *ngIf="groupClient.confirm_email.errors?.required"> Campo obrigatório. </div>
            <div *ngIf="groupClient.confirm_email.errors?.pattern"> E-mail inválido. Informe um e-mail válido. </div>
            <div *ngIf="groupClient.confirm_email.errors?.doNotMatch"> E-mail não correspondente. </div>
          </div>
        </div>
      </div>
      <div formGroupName="phone" class="col-12">
        <div class="form-group">
          <label for="phone">Celular</label>
          <input type="text" class="form-control" id="phone" name="phone" Mask="(##) #####-####" [(ngModel)]="strPhone"
            formControlName="phone">
          <div *ngIf="(!groupPhone.phone.pristine || groupPhone.phone.touched) && groupPhone.phone.errors"
            class="invalid-feedback x-small">
            <div *ngIf="groupPhone.phone.errors.required"> Campo obrigatório. </div>
            <div *ngIf="groupPhone.phone.errors.invalid"> Telefone inválido. </div>
          </div>
        </div>
      </div>
      <div formGroupName="client" class="col-12">
        <div class="form-group">
          <label for="optradio">Permite contato via WhatsApp?</label>
          <div class="form-check form-check-inline">
            <input type="radio" class="form-check-input" id="edit_allow_contact_false"
              [(ngModel)]="client.whatsapp_opt_in" name="whatsapp_opt_in" [value]="false"
              formControlName="whatsapp_opt_in">
            <label class="form-check-label" for="edit_allow_contact_false">Não</label>
          </div>
          <div class="form-check form-check-inline">
            <input type="radio" class="form-check-input" id="edit_allow_contact_true"
              [(ngModel)]="client.whatsapp_opt_in" name="whatsapp_opt_in" [value]="true"
              formControlName="whatsapp_opt_in">
            <label class="form-check-label" for="edit_allow_contact_true">Sim</label>
          </div>
        </div>
      </div>
    </form>
    <div class="row justify-content-around justify-content-md-center mb-3 mb-md-0">
    <button type="button" class="btn btn-outline-primary" (click)="close()">CANCELAR</button>
    <button type="submit" class="btn btn-primary" (click)="update()">SALVAR</button>
    </div>
  </div>
</div>
