<div class="modal-content">
  <div class="container">
    <div class="modal-header border-0">
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span class="material-icons-outlined">highlight_off</span>
      </button>
    </div>
    <div class="row justify-content-md-center pb-5">
      <div class="col col-12 text-center">
        <p class="big h1">Confirmação</p>
      </div>
      <div class="col-12 col-lg-11 mx-auto small">
        <p class="poppins">Enviamos um código de confirmação via SMS para seu celular</p>
        <p class="poppins text-danger fw-bold">Atenção!</p>
        <p class="poppins">A LadyBank nunca entra em contato para solicitar o código de confirmação por telefone, e-mail
          ou outro canal de atendimento.
        </p>
      </div>
      <form [formGroup]="formToken" class="d-flex px-3 justify-content-center" autocomplete="off" novalidate>
        <input type="text" autoTab class="auto-tab" maxlength="1" class="form-control mx-2"
          formControlName="sms_code_1">
        <input type="text" autoTab class="auto-tab" maxlength="1" class="form-control mx-2"
          formControlName="sms_code_2">
        <input type="text" autoTab class="auto-tab" maxlength="1" class="form-control mx-2"
          formControlName="sms_code_3">
        <input type="text" autoTab class="auto-tab" maxlength="1" class="form-control mx-2"
          formControlName="sms_code_4">
        <input type="text" autoTab class="auto-tab" maxlength="1" class="form-control mx-2"
          formControlName="sms_code_5">
        <input type="text" autoTab class="auto-tab" maxlength="1" class="form-control mx-2"
          formControlName="sms_code_6">
      </form>
      <div *ngIf="(!formToken.pristine || formToken.touched) && formToken.invalid" class="d-flex px-3 justify-content-center">
        <div class="invalid-feedback x-small"> Campo obrigatório. </div>
      </div>
      <div class="col col-12 text-center my-3">
        <button class="btn link" (click)="onResend()">
          <icon-svg svgName="reload" class="float-left mr-2"></icon-svg>REENVIAR
        </button>
      </div>
      <div class="row col-12 mx-auto text-center justify-content-center">
        <button type="button" class="col col-12 col-md-5 btn btn-outline-dark" data-dismiss="modal"
          (click)="modalRef.hide()">CANCELAR</button>
        <button type="button" type="submit" class="col col-12 col-md-5 my-3 my-md-0 btn btn-primary"
          (click)="onConfirm()">VALIDAR</button>
      </div>
    </div>
  </div>
</div>