import { formatDate } from '@angular/common';
import { AfterViewInit, Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import html2pdf from 'html2pdf.js';
import { BsModalRef } from "ngx-bootstrap/modal";
import { DialogService } from 'src/app/shared/services/dialog.service';
import { StorageService as storage } from 'src/app/shared/services/storage.service';
import { Utils } from "../../../shared/commons/Utils";
import { ReceiptPdfComponent } from '../../receipt-pdf/receipt-pdf.component';

declare let Android: any;

@Component({
  selector: 'app-receipt-dialog',
  templateUrl: './receipt-dialog.component.html',
  styleUrls: ['./receipt-dialog.component.scss']
})
export class ReceiptDialogComponent implements OnInit, AfterViewInit {

  @ViewChild('pagePDF', { static: true, read: ViewContainerRef }) pagePDF: ViewContainerRef;

  @Input() public client;
  @Input() public account;
  @Input() public receipt;

  disableSharedButton = true
  bankListIspb = storage.get('ListBanks');
  infoCupom: any = []
  internalAccountInfo: any

  translated_types = {
    'bank_slip': 'boleto',
    'transfer': 'ted',
    'pix': 'pix',
    'internal': 'transferência entre contas',
    'schedule_transfer': 'ted agendado'
  }

  constructor(
    private dialogService: DialogService,
    public modalRef: BsModalRef,
    private readonly resolver: ComponentFactoryResolver
  ) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.receipt?.otherInfo?.cupom) {
        let list = this.receipt['otherInfo']['cupom'].split('@@')
        for (let index = 0; index < list.length; index++) {
          if (index > 1) {
            const item = list[index].replace('@', ' ').replace('@', '').replace(/( )+/g, ' ').trim()
            if (!item.includes('ewally'))
              this.infoCupom.push(item);
          }
        }
      }
    }, 100);
  }

  ngOnInit(): void {
    if (window.navigator.share || typeof (Android) !== 'undefined') {
      this.disableSharedButton = false
    }
  }

  getName(receipt) {
    let name = ''
    if (receipt?.receiver?.name)
      name = receipt.receiver.name
    else if (receipt?.otherInfo?.destination?.bankAccount?.fullName)
      name = receipt.otherInfo.destination.bankAccount.fullName

    return Utils.capitalizeName(name)
  }

  filterBankName(favored) {
    return `${this.bankListIspb.find(x => x.compe == favored.otherInfo.destination.bankAccount.bankCode)?.name}`
  }

  getDocument(receipt) {
    let document = ''
    if (receipt?.operationType == 'SENT_TRANSFERENCE')
      return Utils.maskCpfCnpj(receipt?.otherInfo?.document || receipt?.document)
    else if (receipt?.otherInfo?.receiver?.personType == 'LEGAL_PERSON')
      return Utils.maskCpfCnpj(receipt?.receiver?.document)
    else if (receipt?.receiver?.document)
      document = receipt.receiver.document
    else if (receipt?.otherInfo?.destination?.bankAccount?.document)
      document = receipt.otherInfo.destination.bankAccount.document

    return Utils.protectedCPF(document)
  }

  onPrint() {
    this.pagePDF.clear()

    const factory = this.resolver.resolveComponentFactory(ReceiptPdfComponent)
    const componentRef = this.pagePDF.createComponent(factory);

    componentRef.instance.client = this.client
    componentRef.instance.account = this.account
    componentRef.instance.receipt = this.receipt

    componentRef.instance.emitter.subscribe(() => {
      const config = {
        filename: 'comprovante_' + formatDate(new Date(), 'yyyyMMdd_HmmssSSS', 'pt'),
        html2canvas: {
          scale: 1,
          scrollX: 0,
          scrollY: 0,
        },
        jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' } // orientation: 'landscape'
      }

      this.print(componentRef.location.nativeElement, config)
      componentRef.destroy()
    })
  }

  private print(content: any, config: any): void {
    html2pdf()
      .set(config)
      .from(content)
      .toPdf()
      .save()
      // .outputPdf('dataurlnewwindow') // abrir nova aba;
  }

  shareProof(e) {
    e.preventDefault()
    this.pagePDF.clear()

    const factory = this.resolver.resolveComponentFactory(ReceiptPdfComponent)
    const componentRef = this.pagePDF.createComponent(factory);

    componentRef.instance.client = this.client
    componentRef.instance.account = this.account
    componentRef.instance.receipt = this.receipt

    componentRef.instance.emitter.subscribe(() => {
      const config = {
        filename: 'comprovante_' + formatDate(new Date(), 'yyyyMMdd_HmmssSSS', 'pt'),
        html2canvas: {
          scale: 1,
          scrollX: 0,
          scrollY: 0,
        },
      }

      html2pdf().set(config).from(componentRef.location.nativeElement).toCanvas().outputImg('datauri').then(res => {
        // Share on Android
        if (typeof (Android) !== 'undefined') {
          const file_name = 'comprovante_' + formatDate(new Date(), 'yyyyMMdd_HmmssSSS', 'pt');
          Android.compartilharPDFApp(res.src, file_name, '.png', '', '');
          return;
        }

        // Share on Browser
        fetch(res)
          .then(res => res.blob())
          .then(blob => {
            const file = new File([blob], 'image.png', { type: 'image/png' });
            window.navigator.share({ text: 'Comprovante', files: [file] } as ShareData);
          }).catch(() => {
            this.dialogService.showDialogError('Seu browser não permite o compartilhamento. Por favor, use um outro browser e entre em contato com nosso suporte. Pedimos desculpas pela inconveniência.')
          }).finally(() => {
            this.modalRef.hide()
          })
      })
      componentRef.destroy()
    })
  }

  close(): void {
    this.confirmAndClose(false);
  }

  translate_type(type) {
    return this.translated_types[type].charAt(0).toUpperCase() + this.translated_types[type].slice(1);
  }

  private confirmAndClose(value: boolean | string) {
    this.modalRef.hide();
  }

  get utils() { return Utils };
}
