import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { AppNavRouteLink, RouterEnum } from "../../shared/commons/AppNavRouteLink";
import { GoalEnum } from "../../shared/enums/goals.enum";
import { ConfirmDialogViewModel } from "../confirm-dialog/confirm-dialog.view-model";
import { StorageService as storage } from "../../shared/services/storage.service";
import { Router } from "@angular/router";
import { DialogService } from "../../shared/services/dialog.service";
import { FinancialService } from "../../shared/services/financial.service";
import * as moment from "moment";

@Component({
  selector: 'app-invalid-goal-dialog',
  templateUrl: './invalid-goal-dialog.component.html',
  styleUrls: ['./invalid-goal-dialog.component.scss']
})
export class InvalidGoalDialogComponent implements OnInit {
  listGoals = [];
  today = new Date;
  constructor(
    public modalRef: BsModalRef,
    private dialogService: DialogService,
    private router: Router,
    private financial: FinancialService,
  ) { }

  ngOnInit(): void {
    this.getGoals();
  }

  onClose(): void {
    this.confirmAndClose('objectives');
  }

  onConfirm(): void {
    // if (this.goalActive) {
    //   alert(`TODO: O sistema frontend deverá apresentar direcionar para a página Criar Objetivo - ${this.goalActive.name}.
    //   E  em seguida, o novo objetivo criado deve ser incluso no final da lista, ou seja, com a menor prioridade e deve ter o flag “garantido” desativado.
    //   A barra de tempo até atingimento deve estar em 0% e as chances de atingimento devem estar em 0%.`);
    // }
  }

  private confirmAndClose(value: string) {
    this.modalRef.hide();
  }

  getGoals() {
    this.financial.getPlansBack(storage.getClientUuid(), storage.getClientJwt()).then(result => {
      this.listGoals = this.financial.orderList(result?.goals);
      this.listGoals.forEach(item => {
        const newProps = {
          img: this.financial.getImgGoal(item.type),
          color: this.financial.getColorGoal(item.type),
          date: moment.unix(item.date_first_payment).format("MM/DD/YYYY"),
          days: moment(moment.unix(item.date_first_payment).format("MM/DD/YYYY")).diff(moment(this.today), 'day'),
          viewObjective: this.viewObjective(item)
        };
        return Object.assign(item, newProps);

      }); this.validateGoals();
    });
  }

  validObjetiveAfter(item) {
    let index;
    index = this.listGoals.indexOf(item);
    if (this.listGoals[index - 1]?.must_be_guaranteed || index == 0) {
      return true
    } else {
      return false
    }
  }

  viewObjective(item) {
    if (item.must_be_guaranteed || this.validObjetiveAfter(item)) {
      return true;
    } else {
      return false;
    }
  }

  validateGoals() {

    let i;
    const forDeletion = [];

    for (i = 0; i < this.listGoals.length; i++) {

      if (!this.listGoals[i].days) forDeletion.push(i);
      else if (this.listGoals[i].value <= 0) forDeletion.push(i);

    }

    const temp = [];

    for (i = 0; i < forDeletion.length; i++) {

      temp.push(this.listGoals[forDeletion[i]]);

    }

    this.listGoals = [...temp];

    if (this.listGoals.length == 0) {

      this.modalRef.hide();
      window.location.reload();

    }
  }

  editGoal(goal) {
    switch (goal.type) {
      case 'mariage':
        this.router.navigate([AppNavRouteLink.NAV.NEW_GOAL, GoalEnum.mariage, goal.id]);
        break;
      case 'house':
        this.router.navigate([AppNavRouteLink.NAV.NEW_GOAL, GoalEnum.house, goal.id]);
        break;
      case 'education':
        this.router.navigate([AppNavRouteLink.NAV.NEW_GOAL, GoalEnum.education, goal.id]);
        break;
      case 'travel':
        this.router.navigate([AppNavRouteLink.NAV.NEW_GOAL, GoalEnum.travel, goal.id]);
        break;
      case 'emergency':
        this.router.navigate([AppNavRouteLink.NAV.NEW_GOAL, GoalEnum.emergency, goal.id]);
        break;
      case 'retirement':
        this.router.navigate([AppNavRouteLink.NAV.NEW_GOAL, GoalEnum.retirement, goal.id]);
        break;
      case 'health':
        this.router.navigate([AppNavRouteLink.NAV.NEW_GOAL, GoalEnum.health, goal.id]);
        break;
      case 'wealth':
        this.router.navigate([AppNavRouteLink.NAV.NEW_GOAL, GoalEnum.growth, goal.id]);
        break;
      case 'car':
        this.router.navigate([AppNavRouteLink.NAV.NEW_GOAL, GoalEnum.car, goal.id]);
        break;
      case 'other':
        this.router.navigate([AppNavRouteLink.NAV.NEW_GOAL, RouterEnum.CUSTOM, goal.id]);
        break;
      default:
        this.router.navigate([AppNavRouteLink.NAV.NEW_GOAL, RouterEnum.CUSTOM, goal.id]);
        break;
    } this.modalRef.hide();
  }

  removeGoal(i) {
    const model = new ConfirmDialogViewModel();
    model.subTitle = 'Deseja deletar esse objetivo?';
    model.txtOk = 'SIM';
    model.msg = '<span class="small">Se deletar o objetivo, ele será removido da sua lista. Querendo incluí-lo novamente, teria que criá-lo.</span>'
    model.txtCancel = 'NÃO';
    const result$ = this.dialogService.showConfirm(model);
    result$.subscribe(confirm => {
      if (confirm) {
        this.modalRef.hide()
        this.financial.deleteGoal(storage.getClientUuid(), i.id, storage.getClientJwt()).then(
          res => {
            this.dialogService.showSnackBar('O item foi excluído com sucesso.', 'success');
            this.getGoals();
          },
          err => {
            let msg = 'Houve um problema para excluir o item.'
            if (err.error.errors.goal_parent_id) {
              msg = 'Primeiro exclua o objetivo de entrada'
            }
            this.dialogService.showDialogError(msg)
          }
        )
      }
    });
  }
}
