export enum AssetsEnum {
  'direct-treasure' = 'Tesouro direto',
  'fixed-income' = 'Renda fixa',
  'public-fixed-income' = 'Renda fixa pública',
  'private-fixed-income' = 'Renda fixa privada',
  'variable-income' = 'Renda variável',
  'exchange' = 'Câmbio',
  'investment-fund' = 'Fundos de investimento',
  'retirement' = 'Aposentadoria',
  'actions' = 'Ações e etfs',
  'bitcoin' = 'Cripto moedas',
  'savings' = 'Poupança',
  'derivative' = 'Derivativo',
  'real-estate-funds' = 'Fundos imobiliários',
  'american-shares' = 'Açoes americanas',
  'others' = 'Outros',
  'index' = 'Índice',
  'multimarket' = 'Multimercado',
}
