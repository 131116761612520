import { Directive, Input, HostListener, ViewChild, ElementRef } from '@angular/core';

@Directive({
  selector: '[PasswordStrength]'
})
export class PasswordStrengthDirective {

  @Input('PasswordStrength') passwordStrength: string;
  
  constructor() { }

  @HostListener('keyup', ['$event'])
  onkeyup($event: any) {
    const field = $event.target.value;
    const size = field.length;
    
    let el = document.getElementById('password-strength')
    if (el) {
      if ((size > 0 && size < 6) && (!field.match(/[0-9]/g) || !field.match(/[$&+=?@#|'<>.^*()%!-]/g))) {
        el.className  = 'bg-low';
      } else if (field.match(/[0-9]/g)?.length > 1 || field.match(/[$&+=?@#|'<>.^*()%!-]/g)?.length > 1) {
        el.className = 'bg-strong';
      } else if (field.match(/[0-9]/g)?.length > 0 && field.match(/[$&+=?@#|'<>.^*()%!-]/g)?.length > 0) {
        el.className = 'bg-good';
      } else {
        el.className = ''
      }
    }
    
  }
}
