export enum SuitabilityTypeEnum {
  cvm = 'cvm',
  dna = 'dna',
  personality = 'personality'
}

export enum SuitabilityEnum {
  cautious = 'Investidor de Liquidez',
  moderate = 'Investidor Moderado',
  balanced = 'Investidor Balanceado',
  growth = 'Investidor de Crescimento'
}

export enum SuitabilityMinScore {
  growth = 50,
  balanced = 38,
  moderate = 26,
  cautious = 13
}

export enum SuitabilityDescriptionEnum {
  cautious = `Pessoas desse perfil preferem investir seu dinheiro em produtos que apresentem nenhum ou baixo risco. Geralmente, o investidor de Liquidez busca receber ganhos reais com o menor risco possível, mesmo que para isso tenha que abrir mão de certa rentabilidade.`,
  moderate = `Pessoas desse perfil procuram ter a maior parte dos seus investimentos em segurança. Mas, o investidor Moderado já aceita flutuações de riscos de longo prazo - o objetivo é equilibrar rentabilidade versus risco e evitar que as variações no mercado impactem de forma relevante o patrimônio atual.`,
  balanced = `Como Investidor Balanceado você procura uma fonte de crescimento do seu patrimônio e não depende dos investimentos como sua única fonte de renda no curto prazo. Flutuações nos valores dos investimentos são temporariamente aceitáveis. No longo prazo o resultado dos investimentos deve seguir um crescimento relevante.`,
  growth = `Investidores desse perfil estão vendo os investimentos como uma oportunidade de médio-longo prazo. A prioridade não é mais uma renda de curto prazo, mas acumulação de patrimônio. Flutuações temporárias (inclusive de alguns meses, como na crise covid-19) podem ser aceitas e absorvidas.`
}

export enum SuitabilityMainFeaturesEnum {
  cautious = 'Um <strong class="text-success">Investidor de LIQUIDEZ</strong> deve evitar volatilidade. Os ativos escolhidos devem ter retornos com maior grau de certeza, mesmo que isso significa retornos menos expressivos. Cuidados também com o risco de não-pagamento da empresa (default). O foco é na tranquilidade, robustez e previsibilidade.',
  moderate = 'Um <strong class="text-warning">Investidor MODERADO</strong> já aceita um pouco mais de risco que um investidor de liquidez. As posições em renda fixa com rentabilidades maiores aumentam. É possível incluir fundos multimercado de baixo risco e com proporções reduzidas. Uma diversificação em ações, com limites reduzidos, também pode ser considerada',
  balanced = 'Um <strong class="text-caution">Investidor BALANCEADO</strong> procura retornos maiores, e aceita os riscos de curto e médio prazo. No longo prazo, porém, existe a demanda de proteção de patrimônio. As pessoas desse perfil aumentam a exposição em fundos multimercado, renda fixa mais agressiva e renda variável. Podem entrar também investimentos internacionais.',
  growth = 'Um <strong class="text-danger">Investidor de CRESCIMENTO</strong> procura retornos diferenciados, com o objetivo de crescer o seu patrimônio inclusive para cumprir alguns objetivos da sua vida, como aposentar mais cedo, viver da renda de seus investimentos, etc. Grande parte do dinheiro é investido em fundos multimercado, renda variável e investimentos internacionais.'
}